import { DashboardActions } from "./types";
import { DashboardState } from "../../types/dashboard";

const initialState: DashboardState = {
    latestResults: [],
    upcomingCourses: [],
    loading: false,
    error: undefined
};

export function dashboardReducer(state = initialState, action: typeof DashboardActions): DashboardState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case DashboardActions.GET_LATESTRESULTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case DashboardActions.GET_LATESTRESULTS_SUCCESS:
            return {
                ...state,
                latestResults: action.response,
                loading: false
            };
        case DashboardActions.GET_LATESTRESULTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case DashboardActions.GET_UPCOMING_COURSES_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case DashboardActions.GET_UPCOMING_COURSES_SUCCESS:
            return {
                ...state,
                upcomingCourses: action.response,
                loading: false
            };
        case DashboardActions.GET_UPCOMING_COURSES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}