import { REQUIRED_FIELD, MAX_LENGTH, MIN_LENGTH, TYPE_NUMBER, INVALID_EMAIL, INVALID_ZIPCODE, INVALID_PHONE, INVALID_DATE, INVALID_STRING_SPECIAL_CHARS, PASSWORD_TOO_WEAK, STRING_TOO_LONG, HOUSENUMBER_INVALID } from "../constants/errors";
import { EMAIL_REGEX, ZIPCODE_REGEX, SPECIAL_CHAR_REGEX, PHONE_REGEX, VALID_CHARACTERS_REGEX, HOUSENUMBER_REGEX } from "./regex";
import { passwordChecker, PasswordStrength } from "../helperfunctions/passwordChecker";
import isWithinInterval from "date-fns/isWithinInterval";
import subYears from "date-fns/subYears";
import parse from "date-fns/parse";

export const isEmail: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => value ? EMAIL_REGEX.test(value.trim()) ? undefined : INVALID_EMAIL : undefined;
export const isNumber: (value: any) => string | undefined = (value: any): string | undefined => value && isNaN(Number(value.trim())) ? TYPE_NUMBER : undefined;
export const isZipCode: (value: string) => string | undefined = (value: string): string | undefined => value ? ZIPCODE_REGEX.test(value.trim()) ? undefined : INVALID_ZIPCODE : undefined;
export const isPhone: (value: string ) => string | undefined = (value: string | undefined): string | undefined => value ? value.length < 17 && PHONE_REGEX.test(value) ? undefined : INVALID_PHONE : undefined;
export const noSpecialChars: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => value ? SPECIAL_CHAR_REGEX.test(value) ? undefined : INVALID_STRING_SPECIAL_CHARS : undefined;
export const maxLength = (max: number) => (value: string) => value && value.length > max ? MAX_LENGTH(max) : "";
export const minLength = (min: number) => (value: string) => value && value.length < min ? MIN_LENGTH(min) : "";
export const required: (value: string) => string | undefined = (value: string): string | undefined => {return(!value || value === "{}" || value.trim().length === 0) ? REQUIRED_FIELD : undefined};
export const passwordStrongEnough: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => value && passwordChecker(value) > PasswordStrength.MODERATE ? undefined : PASSWORD_TOO_WEAK;
export const validString: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => value ? value.length < 128 ? !VALID_CHARACTERS_REGEX.test(value) ? undefined : INVALID_STRING_SPECIAL_CHARS : STRING_TOO_LONG : undefined;
export const validLongString: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => value ? value.length < 900 ? !VALID_CHARACTERS_REGEX.test(value) ? undefined : INVALID_STRING_SPECIAL_CHARS : STRING_TOO_LONG : undefined;
export const validDate: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => {
    return value && isWithinInterval(parse(value, "dd-MM-yyyy", new Date()), {
        start: subYears(new Date(), 90),
        end: subYears(new Date(), 10)
    }) ? undefined : INVALID_DATE;
}
export const isValidHousenumber: (value: string | undefined) => string | undefined = (value: string | undefined): string | undefined => value ? HOUSENUMBER_REGEX.test(value) ? undefined : HOUSENUMBER_INVALID : undefined;