import graphqlHelperService from "./graphqlHelper.service";
import { SampleExam, SampleExamAdminInput, SampleExamFilledInput, ExamResult, SampleExamAdmin } from "../types/sampleExam";
import { SampleExamLog } from "../types/sampleExamLog";

export const sampleExamService = {
    sampleExam: async (sampleExamId: number, projectId: number): Promise<SampleExam> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            sampleExamQuery(sampleExamId, projectId)
        );
        return apolloResult.sampleExam as SampleExam;
    },
    sampleExamsByCategoryId: async (categoryId: number): Promise<SampleExam[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            sampleExamsByCategoryIdQuery(categoryId)
        );
        return apolloResult.sampleExamsByCategoryId as SampleExam[];
    },
    sampleExamLogs: async (): Promise<SampleExamLog[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            sampleExamLogsQuery()
        );
        return apolloResult.sampleExamLogs as SampleExamLog[];
    },
    createSampleExam: async (sampleExam: SampleExamAdminInput): Promise<number> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            createSampleExamQuery(sampleExam)
        );
        return apolloResult.createSampleExam as number;
    },
    updateSampleExam: async (sampleExam: SampleExamAdmin): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateSampleExamQuery(sampleExam)
        );
        return apolloResult.updateSampleExam as boolean;
    },
    deleteSampleExam: async (sampleExamId: number): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            deleteSampleExamQuery(sampleExamId)
        );
        return apolloResult.deleteSampleExam as boolean;
    },
    checkSampleExam: async (sampleExam: SampleExamFilledInput): Promise<ExamResult> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            checkSampleExamQuery(sampleExam)
        );
        return apolloResult.checkSampleExam as ExamResult;
    },
}

const sampleExamQuery = (sampleExamId: number, projectId: number) => `query{
    sampleExam(sampleExamId: ${sampleExamId}, projectId: ${projectId}) {
        sampleExamId
        curinfoId
        categoryId
        duration
        amountOfQuestions
        amountCorrect
        questions {
            questionId
            categoryId
            text
            possibleAnswers {
                answerId
                questionId
                text
                correct
            }
            active
        }
    }
}`;

const sampleExamsByCategoryIdQuery = (categoryId: number) => `query{
    sampleExamsByCategoryId(categoryId: ${categoryId}) {
        sampleExamId
        categoryId
        duration
        amountOfQuestions
        amountCorrect
    }
}`;

const sampleExamLogsQuery = () => `query {
    sampleExamLogs{
        sampleExamLogId
        sampleExamId
        cursistNr
        entryDate
        amountCorrect
        amountOfQuestions
        grade
    }
}`;

const createSampleExamQuery = (sampleExam: SampleExamAdminInput) => `mutation{
    createSampleExam(sampleExam: {
        categoryId: ${sampleExam.categoryId},
        duration: ${sampleExam.duration},
        amountOfQuestions: ${sampleExam.amountOfQuestions},
        amountCorrect: ${sampleExam.amountCorrect}
    })
}`;

const updateSampleExamQuery = (sampleExam: SampleExamAdmin) => `mutation{
    updateSampleExam(sampleExam: {
        sampleExamId: ${sampleExam.sampleExamId},
        categoryId: ${sampleExam.categoryId},
        duration: ${sampleExam.duration},
        amountOfQuestions: ${sampleExam.amountOfQuestions},
        amountCorrect: ${sampleExam.amountCorrect}
    })
}`;

const deleteSampleExamQuery = (sampleExamId: number) => `mutation{
    deleteSampleExam(sampleExamId: ${sampleExamId})
}`;

const checkSampleExamQuery = (sampleExam: SampleExamFilledInput) => `mutation{
    checkSampleExam(sampleExam: {
        sampleExamId: ${sampleExam.sampleExamId},
        curinfoId: "${sampleExam.curinfoId}",
        questions: [
            ${sampleExam.questions && sampleExam.questions.map((question) => `
            {
                questionId: ${question.questionId},
                ${question.givenAnswerId ? `givenAnswerId: ${question.givenAnswerId},` : ``}
             }
        `)}
        ]
    }){
        passed
        grade
        questions {
            questionId
            givenAnswer 
            text
            correctAnswers
            correct
        }
    }
}`