import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { animated, useSpring, config } from "react-spring";
import { FaCheck } from "react-icons/fa";
import { withRouter, RouteComponentProps } from "react-router";

const useStyles = makeStyles((theme) => ({
    check: {
        verticalAlign: "middle",
        fontSize: "100px",
        color: theme.palette.primary.main,
    },
    subtitle: {
        marginTop: theme.spacing(2),
    },
    loading: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    },
    colorContrast: {
        color: theme.palette.primary.main
    },
    linkButton: {
        "&:hover": {
            textDecoration: "underline",
        },
    }
}));

const PaymentComplete = ({ location, history }: RouteComponentProps) => {
    const classes = useStyles();
    const checkProps = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        config: config.default
    });

    return (
        <>
            <Grid container justify="center" alignItems="center" style={{ padding: "2em" }} direction="column">
                <Grid item xs={12} md={6}>
                    <Grid container justify="center" alignItems="center" direction="column">
                        <animated.div style={checkProps}>
                            <FaCheck className={classes.check} />
                        </animated.div>
                        <Grid container justify="center" alignItems="center" direction="column">
                            <Typography variant="h5">Betaling succesvol</Typography>
                            {location.pathname.startsWith('/factuur-betaald-emaillink') ?
                            <div></div> :
                            <Button onClick={() => history.push("/facturen")} variant="contained" color="secondary" id="login-login-button">
                                <Typography variant="button" style={{ color: "#fff" }}>
                                    TERUG NAAR FACTUREN
                                </Typography>
                            </Button>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(PaymentComplete);