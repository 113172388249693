import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let shTheme = createTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "#D85AA2",
                "&$checked": {
                    color: "#D85AA2",
                },
            },
        },
        MuiRadio: {
            colorSecondary: {
                color: "#D85AA2",
                "&$checked": {
                    color: "#D85AA2",
                },
            },
        },
        MuiTypography: {
            colorTextSecondary: {
                color: "#fff"
            },
        },
    },
    palette: {
        primary: {
            main: "#958fc9",
            contrastText: "#FFFFFF",
            dark: "#6b63b0",
            light: "#aea7e8"
        },
        secondary: {
            main: "#D85AA2",
            contrastText: "#FFFFFF",
            dark: "#B62D7B",
            light: "#FA8DCC"
        },
        error: {
            main: "#EF2C30",
            contrastText: "#FFFFFF",
            dark: "#CB1316",
            light: "#FF494E"
        },
        text: {
            primary: "#363636",
            secondary: "#363636",
            hint: "#666666",
            disabled: "#c4c4c4"
        }
    },
    typography: {
        fontFamily: "Open Sans, sans-serif",
    }
});

shTheme = responsiveFontSizes(shTheme);

export default shTheme;