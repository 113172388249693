import React from "react"
import { makeStyles, Divider, Grid, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    header: {
        width: "100%",
        backgroundColor: "#fff"
    },
    text: {
        padding: "10px",
        color: "gray",
        marginTop: "0px",
        marginBottom: "5px",
        fontSize: "15pt",
        fontWeight: "bolder"
    }
}))

interface CardHeaderProps {
    text: string,
    button?: boolean,
    buttonLink?: string,
    buttonText?: string,
}

const CardHeader = ({ text, button, buttonLink, buttonText }: CardHeaderProps) => {
    const classes = useStyles()
    return (
        <div className={classes.header}>
            <Grid container justify='space-between'>
                <Grid item>
                    <p className={classes.text}>{text}</p>
                </Grid>
                {button && buttonLink && buttonText &&
                    <Grid item style={{padding: 10}}>
                        <Link to={buttonLink} style={{ textDecoration: "none" }}>
                            <Button variant="contained" color="secondary">{buttonText}</Button>
                        </Link>
                    </Grid>
                }
            </Grid>
            <Divider />
        </div>
    )
}

export default CardHeader
