import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useButtonStyles = makeStyles((theme: Theme) => ({
    secondary: {
        margin: theme.spacing(1),
        color: "#fff"
    },
    secondaryApp: {
        margin: theme.spacing(1),
    },
    primary: {
        margin: theme.spacing(1)
    }
}));