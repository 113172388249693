import React from "react";
import { FormHelperText, Switch } from "@material-ui/core";

export interface RenderSwitchProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: string;
    };
    light: boolean;
}

const RenderSwitch = ({
    input,
    label,
    meta: { touched, error },
    light,
}: RenderSwitchProps) => {
    return (
        <>
            <Switch
                checked={!!input.value}
                {...input}
            />
            {(touched && !!error) && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
    );
}

export default RenderSwitch;