import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration, ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";

import environment from "./config/environment";

export function SetupSentry() {
    Sentry.init({
        dsn: "https://5741e044abd94e41a65aa4ca7f71f1b7@o460187.ingest.sentry.io/4505591627251712",
        environment: environment.name,
        integrations: [
            new ExtraErrorDataIntegration(),
            new CaptureConsoleIntegration(),
        ]
    });
}

export function SentryCapture(value: unknown, context?: string) {
    if (value instanceof Error) {
        if (context) {
            value.message += `\nSentryCapture Context: ${context}`;
        }
        Sentry.captureException(value);
    } else {
        Sentry.captureMessage(
            `Non-Error capture:\n` +
                (context ? `Context: ${context}\n` : '') +
                `Data:\n${JSON.stringify(value)}\n`
        );
    }
}
