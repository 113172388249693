import React, { useState } from "react";
import { Grid, Button, List, ListItem, Collapse, Paper, Typography, Theme, makeStyles } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { animated, useSpring, config } from "react-spring";
import { FaBook, FaUsers, FaAddressCard, FaReceipt } from "react-icons/fa";

import { useButtonStyles } from "../../../../styles/button";
import { RegisterState, RegisterPriceResponse } from "../../../../types/register";
import Course from "./Course";
import ConfirmationPayment from "../ConfirmationPayment";
import Company from "./Company";
import Freelancer from "./Freelancer";
import Students from "./Students";
import { Role } from "../../../../types/enum";
import { CourseDetail } from "../../../../types/course";
import CustomPrompt from "../../../notification/CustomPrompt";

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
    },
    listWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(2),
    }
}));

interface ConfirmationComponentProps {
    courseDetail: CourseDetail;
    register: RegisterState;
    discountString?: string;
    discountCode?: string;
    currentUser: Role.COMPANY | Role.FREELANCER;
    handleConfirm: () => void;
    handleBack: () => void;
    prices: RegisterPriceResponse[];
}

const ConfirmationComponent = ({ discountCode, discountString, courseDetail, register, currentUser, handleConfirm, handleBack, prices }: ConfirmationComponentProps) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const checkProps = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        config: config.default
    });
    const [openCourse, setOpenCourse] = useState(true);
    const [openData, setOpenData] = useState(true);
    const [openStudents, setOpenStudents] = useState(true);
    const [openPayment, setOpenPayment] = useState(true);
    const { registerFreelancerUserFormData, registerCompanyData, students, registerPayment } = register;

    return (
        <animated.div style={checkProps}>
            <CustomPrompt when={true} />
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Typography variant="h4">Bevestigen</Typography>
                        <Grid item xs={12} className={classes.listWrapper}>
                            <Paper>
                                <List>
                                    <ListItem button onClick={() => {
                                        setOpenCourse(!openCourse)}}>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Grid container>
                                                    <div className={classes.icon}>
                                                        <FaBook />
                                                    </div>
                                                    <Typography variant="overline" className={classes.title}>Cursus</Typography>
                                                </Grid>
                                            </Grid>
                                            {openCourse ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                    </ListItem>
                                    <Collapse in={openCourse} timeout="auto" unmountOnExit>
                                        {courseDetail && <Course courseDetail={courseDetail} />}
                                    </Collapse>
                                    <ListItem button onClick={() => setOpenData(!openData)}>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Grid container>
                                                    <div className={classes.icon}>
                                                        <FaAddressCard />
                                                    </div>
                                                    <Typography variant="overline" className={classes.title}>{registerFreelancerUserFormData ? "Uw gegevens" : "Bedrijfsgegevens"}</Typography>
                                                </Grid>
                                            </Grid>
                                            {openData ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                    </ListItem>
                                    <Collapse in={openData} timeout="auto" unmountOnExit>
                                        {currentUser === Role.FREELANCER && registerFreelancerUserFormData && <Freelancer {...registerFreelancerUserFormData} />}
                                        {currentUser === Role.COMPANY && registerCompanyData && <Company {...registerCompanyData} />}
                                    </Collapse>
                                    {currentUser === Role.COMPANY && students.length > 0 && <>
                                        <ListItem button onClick={() => setOpenStudents(!openStudents)}>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Grid container>
                                                        <div className={classes.icon}>
                                                            <FaUsers />
                                                        </div>
                                                        <Typography variant="overline" className={classes.title}>Deelnemers</Typography>
                                                    </Grid>
                                                </Grid>
                                                {openStudents ? <ExpandLess /> : <ExpandMore />}
                                            </Grid>
                                        </ListItem>
                                        <Collapse in={openStudents} timeout="auto" unmountOnExit>
                                            {students && <Students students={students} />}
                                        </Collapse>
                                    </>}
                                    <ListItem button onClick={() => setOpenPayment(!openPayment)}>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Grid container>
                                                    <div className={classes.icon}>
                                                        <FaReceipt />
                                                    </div>
                                                    <Typography variant="overline" className={classes.title}>Betaalwijze</Typography>
                                                </Grid>
                                            </Grid>
                                            {openPayment ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                    </ListItem>
                                    <Collapse in={openPayment} timeout="auto" unmountOnExit>
                                        {registerPayment && <ConfirmationPayment  
                                            {...registerPayment}
                                            discountString={discountString}
                                            prices={prices}
                                        />}
                                    </Collapse>
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Button
                            onClick={handleBack}
                            classes={{
                                root: buttonClasses.secondaryApp,
                            }}>
                            Terug</Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => handleConfirm()}
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            Inschrijven bevestigen</Button>
                    </Grid>
                </Grid>
            </Grid>
        </animated.div >
    );
};

export default ConfirmationComponent;