import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";

import { getAssignmentsByCourseId, downloadAssignment } from "../../store/assignment/actions"
import { getCourseDetails, getParticipatingStudents } from "../../store/course/actions";
import { getTestExamsByCategoryId } from "../../store/testExams/actions";
import { CourseState } from "../../types/course";
import CoursesComponent from "./CoursesComponent";
import { AuthenticationState } from "../../types/auth";
import { SampleExamState } from "../../types/sampleExam";
import { AssignmentState } from "../../types/assignment";
import { ApplicationState } from "../../store";
import { Role } from "../../types/enum";

interface CoursesContainerProps {
    auth: AuthenticationState;
    course: CourseState;
    testExams: SampleExamState
    assignment: AssignmentState;
    studentId?: string;
    getCourseDetails: (studentId?: string) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
    getParticipatingStudents: (projectId: number) => void;
    getTestExamsByCategoryId: (categoryId: number, projectId?: number) => void;
    getAssignmentsByCourseId: (courseId: string) => void;
}

const mapStateToProps = ({
    auth,
    course,
    testExams,
    assignment,
    student
}: ApplicationState) => {
    return ({
        auth,
        course,
        testExams,
        assignment,
        studentId: student.studentDetail?.studentId
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getCourseDetails, getTestExamsByCategoryId, getParticipatingStudents, getAssignmentsByCourseId, downloadAssignment }, dispatch);
}

const CoursesContainer = ({ studentId, auth, course, getCourseDetails, getParticipatingStudents, getTestExamsByCategoryId, testExams, getAssignmentsByCourseId, assignment, downloadAssignment }: CoursesContainerProps) => {    
    
    useEffect(() => {
        if (studentId && auth.role === Role.ADMIN) getCourseDetails(studentId);
        else if (auth.role !== Role.ADMIN) getCourseDetails();
    }, [auth, studentId, getCourseDetails]);

    return <CoursesComponent
        auth={auth}
        courseDetail={course.courseDetail}
        loading={course.loading}
        loadingParticipatingStudents={course.loadingParticipatingStudents}
        error={course.error}
        testExams={testExams}
        assignment={assignment}
        getParticipatingStudents={getParticipatingStudents}
        getTestExamsByCategoryId={getTestExamsByCategoryId}
        getAssignmentsByCourseId={getAssignmentsByCourseId}
        downloadAssignment={downloadAssignment}
    />
};

export default connect(mapStateToProps, mapDispatchToProps)(CoursesContainer);