import graphqlHelperService from "./graphqlHelper.service";
import { Address } from "../types/register";

export const zipCodeAPIService = {
    getAddress: async (houseNumber: string, zipCode: string): Promise<Address> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            zipCodeAPIQuery(houseNumber, zipCode)
        );
        return apolloResult.getAddress as Address;
    },
};

const zipCodeAPIQuery = (houseNumber: string, zipCode: string) => `query {
    getAddress(houseNumber: "${houseNumber}", zipCode: "${zipCode}") {
        address
        place
    }
}`