import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { updateRegisterBooks, addRegisterStudent, removeRegisterStudent, doRegister, setRegisterStudent, downloadAgreement, resetRegisterStateCompany, getPrices, setCompanyFormData, editRegisterStudent, setRegisterPayment } from "../../../../store/register/actions";
import CompanyData, { CompanyDataFormData } from "./CompanyData";
import { RegisterState, Registration, RegistrationPriceInfo } from "../../../../types/register";
import Payment, { PaymentFormData } from "../Payment";
import { RegisterCourseState } from "../../../../types/registerCourse";
import CompanyStudentData from "../StudentData";
import { getAddress, registerInformationKHN } from "../../../../store/register/actions";
import ConfirmationContainer from "../Confirmation/ConfirmationContainer";
import { setMailingLink } from "../../../../store/registerCourse/actions";
import RegisterComplete from "../RegisterComplete";
import RegisterCourseContainer from "../Course/RegisterCourseContainer";
import { checkDiscountCode } from "../../../../store/discount/actions";
import { trimFields } from "../../../../helperfunctions/trimFields";
import { ApplicationState } from "../../../../store";
import { StudentDetail } from "../../../../types/student";
import { CheckoutPaymentFormData } from "../../../Checkout/CheckoutPayment";
import { getPaymentMethodsAllowed } from "../../../../helperfunctions/paymentMethod";
import { RegisterUserType } from "../../../../types/enum";
import { isNil } from "lodash";

interface CompanyStepperContainerState {
    registerCourse: RegisterCourseState;
    register: RegisterState;
    discountString?: string;

    registerInformationKHN: (khnId: number, zipCode: string, identifier: string, formValues: any) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    editRegisterStudent: (student: StudentDetail, studentId: number) => void;
    setRegisterStudent: (student: StudentDetail) => void;
    setCompanyFormData: (registerCompanyData: CompanyDataFormData) => void;
    addRegisterStudent: (newRegisterStudent: StudentDetail) => void;
    removeRegisterStudent: (selectedStudentIndex: number) => void;
    setRegisterPayment: (registerCompanyPayment: PaymentFormData) => void;
    setMailingLink: (categoryId?: number, courseId?: string, place?: string, locationCode?: string) => void;
    checkDiscountCode: (discountCode: string) => void;

    doRegister: (registration: Registration[], paymentData: CheckoutPaymentFormData) => void;
    downloadAgreement: () => void;
    resetRegisterStateCompany: () => void;
    updateRegisterBooks: (studentIndex: number) => void;
    getPrices: (priceItems: RegistrationPriceInfo[]) => void;
    stateDiscountCode?: string;
}

interface CompanyStepperContainerProps {
    categoryId?: number | undefined;
    courseId?: string | undefined;
    place?: string | undefined;
    locationCode?: string | undefined;
    discountCode?: string | undefined;

    currentStep: number;
    handleNext: () => void;
    handleBack: () => void;
}

const mapStateToProps = ({ registerCourse, register, discount: { discountCode, discountString } }: ApplicationState) => {
    return ({
        registerCourse,
        register,
        discountString,
        stateDiscountCode: discountCode,
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        downloadAgreement,
        registerInformationKHN,
        getAddress,
        checkDiscountCode,
        setRegisterStudent,
        setCompanyFormData,
        addRegisterStudent,
        editRegisterStudent,
        removeRegisterStudent,
        setRegisterPayment,
        doRegister,
        setMailingLink,
        resetRegisterStateCompany,
        updateRegisterBooks,
        getPrices,
    }, dispatch);
}

let CompanyStepperContainer = ({
    downloadAgreement,
    registerInformationKHN,
    categoryId,
    courseId,
    place,
    locationCode,
    setMailingLink,
    handleNext,
    handleBack,
    currentStep,
    registerCourse,
    setCompanyFormData,
    addRegisterStudent,
    removeRegisterStudent,
    setRegisterPayment,
    doRegister,
    setRegisterStudent,
    editRegisterStudent,
    register,
    getAddress,
    discountCode,
    discountString,
    checkDiscountCode,
    resetRegisterStateCompany,
    updateRegisterBooks,
    getPrices,
    stateDiscountCode,
}: CompanyStepperContainerProps & CompanyStepperContainerState) => {
    useEffect(() => {
        let priceItems: RegistrationPriceInfo[] = []
        if (registerCourse.course.type?.courseId) {
            const registrationPriceInfo: RegistrationPriceInfo = {
                courseId: registerCourse.course.type?.courseId,
                studentBooks: register.students ? register.students.map((student) => student.books || false) : [],
                discountCode: stateDiscountCode,
                khnId: register.registerCompanyData?.khnId
            }
            priceItems.push(registrationPriceInfo)
        }
        if (priceItems.length > 0) {
            getPrices(priceItems);
        }
    }, [register.registerCompanyData, register.students, getPrices, registerCourse.course.type, stateDiscountCode]);

    useEffect(() => {
        categoryId && setMailingLink(categoryId, courseId, place, locationCode);
    }, [setMailingLink, categoryId, courseId, place, locationCode]);

    useEffect(() => {
        discountCode && checkDiscountCode(discountCode);
    }, [discountCode, checkDiscountCode])

    useEffect(() => {
        register.registerPayment?.discountCode && checkDiscountCode(register.registerPayment?.discountCode);
    }, [register.registerPayment, checkDiscountCode])

    switch (currentStep) {
        case 0:
            return <RegisterCourseContainer
                handleNext={handleNext}
                userType={RegisterUserType.Company}
            />;
        case 1:
            return <CompanyData
                registerInformationKHN={registerInformationKHN}
                getAddress={getAddress}
                setRegisterCompanyData={setCompanyFormData}
                handleNext={handleNext}
                handleBack={handleBack}
            />;
        case 2:
            return <CompanyStudentData
                updateRegisterBooks={updateRegisterBooks}
                hasBookDiscount={registerCourse.course.type?.hasBookDiscount || false}
                priceIncludesBook={!isNil(registerCourse.course.type?.priceIncludesBook) ? registerCourse.course.type?.priceIncludesBook : true}
                editRegisteringStudent={editRegisterStudent}
                setRegisterStudent={setRegisterStudent}
                register={register}
                getAddress={getAddress}
                onAdd={addRegisterStudent}
                onRemove={removeRegisterStudent}
                handleNext={handleNext}
                handleBack={handleBack}
            />;
        case 3:
            return <Payment
                prices={register.prices}
                downloadAgreement={downloadAgreement}
                onSubmitFunction={setRegisterPayment}
                handleNext={handleNext}
                handleBack={handleBack}
                checkDiscountCode={checkDiscountCode}
                discountString={discountString}
                paymentMethodsAllowed={getPaymentMethodsAllowed(register.prices)}
            />;
        case 4:
            return <ConfirmationContainer
                handleConfirm={() => {
                    resetRegisterStateCompany();
                    const { registerCompanyData, students, registerPayment } = register;

                    if (registerCourse && registerCompanyData && students && registerPayment) {
                        let registration: Registration = {
                            course: registerCourse.course,
                            relation: {
                                contact: {
                                    sex: registerCompanyData.sexContact,
                                    initials: registerCompanyData.initialsContact,
                                    prefix: registerCompanyData.infixContact,
                                    firstName: registerCompanyData.firstNameContact,
                                    lastName: registerCompanyData.lastNameContact,
                                    email: registerCompanyData.emailContact,

                                },
                                invoice: {
                                    byMail: !!registerCompanyData.byMail,
                                    address: registerCompanyData.invoiceAddress,
                                    houseNumber: registerCompanyData.invoiceHouseNumber,
                                    zipCode: registerCompanyData.invoiceZipCode,
                                    place: registerCompanyData.invoicePlace,
                                    email: registerCompanyData.emailInvoice,
                                },
                                companyName: registerCompanyData.nameCompany,
                                zipCode: registerCompanyData.zipCodeCompany,
                                houseNumber: registerCompanyData.houseNumberCompany,
                                address: registerCompanyData.adresCompany,
                                place: registerCompanyData.placeCompany,
                                workNumber: registerCompanyData.phoneCompany,
                                mobileNumber: registerCompanyData.phoneMobileContact,
                                email: registerCompanyData.emailCompany,
                                password: registerCompanyData.passwordCompany,
                            },
                            students,
                        };
                        let totalPrice = (register.prices && register.prices.length > 0 && register.prices.reduce((a, b) => ({ price: (a.price || 0) + (b.price || 0) })).price) || 0;
                        (window as any).dataLayer.push({ 'event': 'register-company', 'price': totalPrice, 'discountcode': stateDiscountCode, 'students': students.length, 'course': registerCourse.course.type?.courseId });

                        doRegister([trimFields(registration)], { ...registerPayment, khnId: registerCompanyData.khnId, discountCode: stateDiscountCode });
                        handleNext();
                    }
                }}
                handleBack={handleBack}
            />

        default:
            return <RegisterComplete error={register.registerError} loading={register.registerLoading} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyStepperContainer);