import React, { useState } from "react"
import withWidth, { isWidthUp, WithWidthProps } from '@material-ui/core/withWidth';
import { Grid, Card, CardContent, makeStyles, CardActionArea, Typography, Divider, Theme, CardHeader, Tooltip } from "@material-ui/core";
import { useSpring, animated, config } from "react-spring";
import { FaCheck, FaTimes, FaChevronRight } from "react-icons/fa";
import { RouteComponentProps, withRouter } from "react-router";

import Error from "../../FeedBack/Error";
import { LoadingContentInfoSmall } from "../../FeedBack/LoadingContent";
import "../Dashboard.css"
import { LatestResult, DashboardState } from "../../../types/dashboard";
import { Role } from "../../../types/enum";

const useStyles = makeStyles((theme: Theme) => ({
    action: {
        padding: theme.spacing(1)
    },
    card: {
        borderRadius: "12px",
        maxWidth: "fit-content",
        boxShadow: "2px 10px 50px rgba(0, 0, 0, 0.16)",
        margin: theme.spacing(2.5),
        [theme.breakpoints.up("xs")]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down("lg")]: {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(1),
    },
    data: {
        marginTop: "auto",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("lg")]: {
            marginLeft: theme.spacing(1),
        },
    },
    description: {
        marginTop: theme.spacing(2),
    },
    image: {
        width: "100px"
    },
    result: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));

interface LatestResultsProps {
    role: string;
    dashboard: DashboardState;
}

const LatestResults = ({ role, dashboard, history, width }: RouteComponentProps<{}> & LatestResultsProps & WithWidthProps) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);
    const props = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        delay: 600,
        config: config.default
    });
    const iProps = useSpring({
        to: { transform: hover ? "translateX(3px)" : "translateX(0px)", marginLeft: "5px" },
        config: config.wobbly
    });
    const propsHover = useSpring({
        to: { transform: hover ? " scale(1.01) translateY(-3px)" : " scale(1) translateY(0px)", },
        config: config.wobbly
    });
    const navigateToPage = (path: string) => {
        history.push(path);
    };

    return (
        <animated.div style={props}>
            <animated.div style={propsHover}>
                <Card className={classes.card}>
                    <CardHeader title={"Laatste resultaten"} />
                    <Divider />
                    <CardContent>
                        {(dashboard.loading) &&
                            <LoadingContentInfoSmall />
                        }
                        {(!dashboard.loading && dashboard.error) &&
                            <Error />
                        }
                        {(!dashboard.loading && !dashboard.error && dashboard) &&
                            <Grid item>
                                {dashboard.latestResults && dashboard.latestResults.map((latestResult: LatestResult, index) => {
                                    return (
                                        <Grid container spacing={2} key={index}>
                                            {role === Role.COMPANY && width && isWidthUp("sm", width) && <Grid item xs={6}>
                                                    <Typography variant="subtitle1">{`${latestResult.firstName} ${!latestResult.prefix || latestResult.prefix === "null" ? "" : latestResult.prefix} ${latestResult.lastName}`}</Typography>
                                            </Grid>}
                                            <Grid item xs={12} sm={role !== Role.COMPANY ? 12 : 6}>
                                                <Grid container direction="row" justify="flex-end" alignItems="center">
                                                    <Typography style={{ marginTop: '5px' }} variant="body2">{latestResult.passed && !latestResult.failed ? 'Resultaat: Geslaagd' : 'Resultaat: Afgewezen'}</Typography>
                                                    <Tooltip title={latestResult.passed && !latestResult.failed ? "Geslaagd" : "Afgewezen"}>
                                                        {latestResult.passed && !latestResult.failed ? <FaCheck color="green" size="1.5em" style={{ marginLeft: 20 }} /> : <FaTimes color="red" size="1.5em" style={{ marginLeft: 20 }} />}
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            {role === Role.COMPANY && width && !isWidthUp("sm", width) && <Grid item xs={12}>
                                                    <Typography variant="subtitle1">{`${latestResult.firstName} ${!latestResult.prefix || latestResult.prefix === "null" ? "" : latestResult.prefix} ${latestResult.lastName}`}</Typography>
                                            </Grid>}
                                            <Grid item xs={12}>                
                                                <Typography variant="body2">Afgerond op: {latestResult.date}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>                
                                                <Typography variant="body2">{latestResult.courseName}</Typography>
                                            </Grid>
                                            <Grid item xs={12} style={{ paddingTop: "1em" }}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                {
                                    dashboard.latestResults && !(dashboard.latestResults.length > 0) &&
                                    <Typography variant="subtitle1">U heeft nog geen resultaten</Typography>
                                }
                            </Grid>
                        }
                    </CardContent>
                    <CardActionArea onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.action} onClick={navigateToPage.bind(undefined, "/cursussen")}>
                        <Grid container justify="space-between">
                            <Typography variant="button">BEKIJK ALLE RESULTATEN</Typography>
                            <animated.span style={iProps}><FaChevronRight size="1.5em" /></animated.span>
                        </Grid>
                    </CardActionArea>
                </Card>
            </animated.div>
        </animated.div>
    );
};

export default withRouter(withWidth()(LatestResults));