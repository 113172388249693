import React, { useState } from 'react';
import { TextField, Checkbox, IconButton } from '@material-ui/core';
import { AnswerInput } from '../../../types/question';
import { FaPlus } from 'react-icons/fa';

interface NewAnswerComponentProps {
    questionId: number;
    createAnswer: (answer: AnswerInput) => void;
}

const NewAnswerComponent = ({ questionId, createAnswer }: NewAnswerComponentProps) => {
    const [correct, setCorrect] = useState<boolean>(false);
    const [text, setText] = useState<string>("");

    return (
        <table style={{ width: "100%" }}>
            <tbody>
                <tr>
                    <td style={{ width: "8%" }}>
                        <Checkbox
                            size="small"
                            name="correct"
                            checked={correct}
                            value={correct}
                            onChange={() => setCorrect(!correct)}
                        />
                    </td>
                    <td style={{ width: "82%" }}>
                        <TextField
                            name="text"
                            fullWidth
                            value={text}
                            onChange={({ target }) => setText(target.value)}
                        />
                    </td>
                    <td style={{ width: "10%" }}>
                        <IconButton onClick={() => createAnswer({ text, correct, questionId })} disabled={text === ''}><FaPlus /></IconButton>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default NewAnswerComponent;