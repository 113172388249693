import { DiscountActions } from "./types";
import { DiscountState } from "../../types/discount";
import { RegisterActions } from "../register/types";

const initialState: DiscountState = {
    discountCode: undefined,
    discountString: undefined,
    codes: [],
    loading: false,
    error: undefined,
};

export function discountReducer(state = initialState, action: typeof DiscountActions): DiscountState {
    switch (action.type) {
        case RegisterActions.RESET_REGISTER_STATE:
        case RegisterActions.REGISTER_SUCCESS:
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case DiscountActions.CHECK_SUCCESS:
            return {
                ...state,
                discountCode: action.discountCode,
                discountString: action.discountString
            };
        case DiscountActions.CHECK_ERROR:
            return {
                ...state,
                discountCode: action.discountCode,
                discountString: undefined
            }
        case DiscountActions.GET_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case DiscountActions.GET_SUCCESS:
            return {
                ...state,
                codes: action.response,
                loading: false,
            };
        case DiscountActions.GET_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DiscountActions.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case DiscountActions.CREATE_SUCCESS:
            return {
                ...state,
                codes: [...state.codes, { ...action.discount, amount: action.discount.amount || 0, percentage: action.discount.percentage || 0 }],
                loading: false,
            };
        case DiscountActions.CREATE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DiscountActions.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case DiscountActions.UPDATE_SUCCESS:
            return {
                ...state,
                codes: state.codes ? state.codes.map(code => code.discountCode === action.discount.discountCode ? { ...action.discount, amount: action.discount.amount || 0, percentage: action.discount.percentage || 0 } : code) : [],
                loading: false,
            };
        case DiscountActions.UPDATE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case DiscountActions.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case DiscountActions.DELETE_SUCCESS:
            return {
                ...state,
                codes: state.codes ? state.codes.filter(code => code.discountCode !== action.discountCode) : [],
                loading: false,
            };
        case DiscountActions.DELETE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}