import React, { useState } from 'react'
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';

import EditStudentComponent from './EditStudentComponent'
import { UserInformation, UserInformationState } from "../../../types/userInformation";
import { requestEdit } from "../../../store/userInformation/actions";
import { getAddress } from '../../../store/register/actions';
import { ApplicationState } from '../../../store';
import EditRequestComplete from '../../FeedBack/EditRequestComplete';

interface EditStudentContainerProps {
    userInformation: UserInformationState;
    requestEdit: (userInformation: UserInformation) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
};

const mapStateToProps = ({ userInformation }: ApplicationState) => {
    return ({ userInformation });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ requestEdit, getAddress }, dispatch);
};

const EditStudentContainer = ({ requestEdit, getAddress, userInformation }: EditStudentContainerProps & RouteComponentProps) => {
    const [requestMade, setRequestMade] = useState<boolean>(false);
    const [newStudent, setNewStudent] = useState<boolean>(false);

    return (
        requestMade ?
        <EditRequestComplete loading={userInformation.loading} error={userInformation.error} newStudent={newStudent} />
        :
        <EditStudentComponent
            requestEdit={(updatedUser: UserInformation) => {
                setNewStudent(updatedUser.student?.studentId?.slice(-1) === "N" ? true : false)
                requestEdit(updatedUser);
                setRequestMade(true);
            }}
            getAddress={(houseNumber: string, zipCode: string, identifier: string, formValues: any) => getAddress(houseNumber, zipCode, identifier, formValues)}
        />
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditStudentContainer));
