import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import { Box } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import Login from "../components/Login/Login";
import ForgotPassword from "../components/Login/ForgotPassword";
import InvoiceContainer from "../components/Invoices/InvoicesContainer";
import DashboardContainer from "../components/Dashboard/DashboardContainer";
import PrivateRoute from "./PrivateRoute";
import RegisterAsContainer from "../components/Register/Register/RegisterAs/RegisterAsContainer";
import CoursesContainer from "../components/Courses/CoursesContainer";
import FreelancerRegister from "../components/Register/Register/Freelancer/FreelancerRegister";
import CompanyRegister from "../components/Register/Register/Company/CompanyRegister";
import RegisterApp from "../components/Register/RegisterApp/RegisterApp";
import StudentDetailContainer from "../components/StudentDetail/StudentDetailContainer";
import { ApplicationState } from "../store";
import { AuthenticationState } from "../types/auth";
import NotFound from "../components/FeedBack/NotFound";
import SettingsContainer from "../components/Settings/SettingsContainer";
import StudentContainer from "../components/Students/StudentsContainer";
import TestExamContainer from "../components/SampleExam/TestExamContainer";
import StudentAddContainer from "../components/StudentAdd/StudentAddContainer";
import CheckoutContainer from "../components/Checkout/CheckoutContainer";
import EditShoppingCartContainer from "../components/ShoppingCart/EditShoppingCartContainer";
import UserInformationContainer from "../components/userInformation/UserInformationContainer";
import AdminStudentsContainer from "../components/Admin/Students/AdminStudentsContainer";
import EditRelationContainer from "../components/userInformation/EditForms/EditRelationContainer";
import EditStudentContainer from "../components/userInformation/EditForms/EditStudentContainer";
import DiscountCodesContainer from "../components/Admin/DiscountCodes/DiscountCodesContainer";
import ConfigurePasswordContainer from "../components/Login/ConfigurePasswordContainer";
import CategoryContainer from "../components/Admin/Categories/CategoryContainer";
import CourseTypesContainer from "../components/Admin/CourseTypes/CourseTypesContainer";
import CourseContainer from "../components/Admin/Course/CourseContainer";
import LinkBuilderContainer from "../components/Admin/LinkBuilder/LinkBuilderContainer";
import { ScrollToTopController } from "../helperfunctions/ScrollToTopController";
import { Dispatch, bindActionCreators } from "redux";
import { setDestination } from "../store/auth/actions";
import SubAccountsContainer from "../components/Login/SubAccountsContainer";
import InvoicePaymentContainer from "../components/Invoices/InvoicePaymentContainer";
import InvoicePayedContainer from "../components/Invoices/InvoicePayedContainer";
import { Role } from "../types/enum";
import KHNCustomersContainer from "../components/Admin/KHNCustomers/KHNCustomersContainer";
import RegistrationPayedContainer from "../components/FeedBack/RegistrationPayedContainer";
import AgreementContainer from "../components/Admin/Agreement/AgreementContainer";
import SampleExamLogContainer from "../components/Admin/SampleExam/SampleExamLogContainer";
import InvoicePaymentViaLinkContainer from "../components/Invoices/InvoicePaymentViaLinkContainer";
import InvoicePayedViaLinkContainer from "../components/Invoices/InvoicePayedViaLinkContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            paddingTop: "70px",
            width: 'calc(100% - 240px)',
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 0,
        },
        contentlogin: {
            flexGrow: 1,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 0,
        },
        toolbar: theme.mixins.toolbar,
    }),
);

interface MainProps {
    setDestination: (destination: string) => void;
    auth: AuthenticationState;
    showMenu: boolean;
}

const mapStateToProps = ({
    auth
}: ApplicationState) => {
    return {
        auth
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        setDestination
    }, dispatch);
}

const Main = ({ auth, showMenu, history, setDestination }: MainProps & RouteComponentProps) => {
    const classes = useStyles();

    const mainClass = history.location.pathname === "/" ||
        history.location.pathname === "/inschrijven-als" ||
        history.location.pathname === "/wachtwoord-vergeten" ||
        history.location.pathname === "/wachtwoord-veranderen"
        ? classes.contentlogin : classes.content;

    useEffect(() => {
        if (history.location.pathname === "/" && auth.token) {
            history.push("/dashboard")
        }
    }, [history, auth.token])

    useEffect(() => {
        if (history.location.pathname === "/inschrijven") {
            if (history.location.search) {
                setDestination(history.location.pathname + history.location.search);
            } else {
                setDestination(history.location.pathname);
            }
        }
    }, [history, auth.token, setDestination])

    return (
        <main className={mainClass}>
            <ScrollToTopController path={history.location.pathname} />
            <Box display={showMenu ? "none" : ""}><div className={classes.toolbar} /></Box>
            {!auth.token ?
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/inschrijven-als" component={RegisterAsContainer} />
                    <Route exact path="/inschrijven/particulier" component={FreelancerRegister} />
                    <Route exact path="/inschrijven/bedrijf" component={CompanyRegister} />
                    <Route exact path="/wachtwoord-vergeten" component={ForgotPassword} />
                    <Route exact path="/wachtwoord-veranderen/:token" component={ConfigurePasswordContainer} />
                    <Route exact path="/wachtwoord-instellen/:token" component={ConfigurePasswordContainer} />
                    <Route exact path="/sub-accounts" component={SubAccountsContainer} />
                    <Route exact path="/inschrijving-betaald" component={RegistrationPayedContainer} />
                    <Route exact path="/factuur-betaald" component={InvoicePayedContainer} />
                    <Route exact path="/factuur-betaald-emaillink" component={InvoicePayedViaLinkContainer} />
                    <Route exact path="/factuur-betalen-emaillink/:encodedInvoice" component={InvoicePaymentViaLinkContainer} />
                    <Route component={Login} />
                </Switch>
                :
                <Switch>
                    <Route exact path="/factuur-betaald" component={InvoicePayedContainer} />
                    <Route exact path="/factuur-betalen/:invoiceYear/:invoiceNumber" component={InvoicePaymentContainer} />
                    <Route exact path="/inschrijving-betaald" component={RegistrationPayedContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN, Role.COMPANY, Role.FREELANCER, Role.STUDENT]} exact path="/dashboard" component={DashboardContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN, Role.COMPANY, Role.FREELANCER, Role.STUDENT]} exact path="/cursussen" component={CoursesContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/studenten" component={AdminStudentsContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY]} exact path="/medewerkers" component={StudentContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY]} exact path="/nieuwe-medewerker" component={StudentAddContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER]} exact path="/facturen" component={InvoiceContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER]} exact path="/factuur-betaald" component={InvoicePayedContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER]} exact path="/inschrijven" component={RegisterApp} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.STUDENT]} exact path="/inschrijven/particulier" component={FreelancerRegister} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY]} exact path="/deelnemer-overzicht/:studentId" component={StudentDetailContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.FREELANCER, Role.STUDENT]} exact path="/proefexamens" component={TestExamContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/categorieën" component={CategoryContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/cursussen/:categoryId" component={CourseTypesContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/cursus/:courseId" component={CourseContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/khn-klanten" component={KHNCustomersContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/voorwaarden" component={AgreementContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN, Role.COMPANY, Role.FREELANCER, Role.STUDENT]} exact path="/instellingen" component={SettingsContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.FREELANCER, Role.STUDENT]} exact path="/proefexamen/:sampleExamId/:course" component={TestExamContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER]} exact path="/bevestigen" component={CheckoutContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER, Role.STUDENT]} exact path="/winkelmand" component={EditShoppingCartContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER, Role.STUDENT]} exact path="/persoonlijke-gegevens" component={UserInformationContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER]} exact path="/bedrijfsgegevens" component={UserInformationContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER]} exact path="/wijzig-bedrijfs-gegevens" component={EditRelationContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.COMPANY, Role.FREELANCER, Role.STUDENT]} exact path="/wijzig-persoons-gegevens" component={EditStudentContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/kortingscodes" component={DiscountCodesContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/link-builder" component={LinkBuilderContainer} />
                    <PrivateRoute setDestination={setDestination} token={auth.token} role={auth.role} access={[Role.ADMIN]} exact path="/admin/proefexamenlog" component={SampleExamLogContainer} />
                    <Route component={NotFound} />
                </Switch>
            }
        </main>
    );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));