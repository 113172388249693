import { Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button, ListItemAvatar, Avatar, makeStyles, Typography, IconButton, Grid, Tooltip, Paper, Table, TableRow, TableCell, TableBody, TableHead } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { FaBook, FaEdit, FaTrash, FaShoppingCart } from "react-icons/fa";

import { Registration, RegisterPriceResponse } from "../../types/register";
import { parseMoney } from "../../helperfunctions/money";
import { Role } from "../../types/enum";

export interface ShoppingCartComponentProps {
    open: boolean;
    role: string;
    shoppingCartItems: Registration[];
    handleClose: () => void;
    onRemove: (index: number) => void;
    prices: RegisterPriceResponse[];
    resetStepper: (reset: boolean) => void;
}

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 1300
    },
    avatar: {
        margin: 10,
        color: "#fff",
        backgroundColor: theme.palette.secondary.main,
    },
    listText: {
        maxWidth: "400px"
    }
}));


const ShoppingCartComponent = ({ role, resetStepper, open, handleClose, shoppingCartItems, history, onRemove, prices }: ShoppingCartComponentProps & RouteComponentProps) => {
    const classes = useStyles();
    const totalPrice = (prices && prices.length > 0 && prices.reduce((a, b) => ({ price: (a.price || 0) + (b.price || 0) })).price) || 0;

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle><FaShoppingCart style={{ marginRight: "1em" }} />Uw winkelmandje</DialogTitle>
                <Divider />
                <DialogContent>
                {!shoppingCartItems.length ? <Typography>Uw winkelmandje is leeg!</Typography> :
                    <Paper className={classes.root}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>Cursus categorie</Typography>
                                    </TableCell>
                                    {role === Role.COMPANY ? <TableCell>
                                        <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>Deelnemers</Typography>
                                    </TableCell> : null}
                                    <TableCell>
                                        <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>Prijs</Typography>
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shoppingCartItems.map(({ students, course }, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Grid container spacing={2} alignItems="center" >
                                                <Grid item>
                                                    <ListItemAvatar>
                                                        <Avatar className={classes.avatar}>
                                                            <FaBook />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title={<Grid>
                                                        <Typography variant="body1">{course.category?.category}</Typography>
                                                        <Typography variant="body1">{course.type?.type}</Typography>
                                                        <Typography variant="body1">{course.place}</Typography>
                                                        {
                                                            course.date?.dates && course.date.dates.map((d, i) => {
                                                                if (d && Array.isArray(d)) return (
                                                                    d.map((date, i) => <Typography key={i} variant="body1">{`${date}`}</Typography>)
                                                                )
                                                                if (d) return <Typography key={i} variant="body1">{`${d}`}</Typography>;
                                                                return null;
                                                            })
                                                        }
                                                    </Grid>}>
                                                        <Typography variant="body1" style={{ color: '#363636' }}>{course.category?.category}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        {role === Role.COMPANY ? <TableCell scope="row">
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item>
                                                    <Tooltip title={<Grid container>
                                                        {(students || []).map(({ firstName, initials, prefix, lastName }, index) => <Grid item xs={12} key={index}>
                                                            <Typography variant="body1">{firstName || initials} {prefix ? prefix + " " : ""}{lastName}</Typography>
                                                        </Grid>)}
                                                    </Grid>}>
                                                        <Typography display="inline" variant="body1">{`${(students || []).length} ${(students || []).length === 1 ? "deelnemer" : "deelnemers"}`}</Typography>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </TableCell> : null}
                                        <TableCell scope="row">
                                            {prices && prices.length > index && <Grid container direction="column" alignItems="flex-start" justify="flex-start" spacing={2}>
                                                <Typography variant="body1"><b>{parseMoney(prices[index].price)}</b></Typography>
                                                <Typography variant="body1">{prices[index].discountInfo?.toString()}</Typography>
                                            </Grid>}
                                        </TableCell>
                                        <TableCell scope="row">
                                            <Tooltip title="Inschrijving bewerken">
                                                <IconButton onClick={() => {
                                                    history.push("/bevestigen")
                                                    handleClose()
                                                }} edge="end" aria-label="delete">
                                                    <FaEdit />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>    
                                        <TableCell scope="row">
                                            <Tooltip title="Inschrijving verwijderen">
                                                <IconButton onClick={() => onRemove(index)} edge="end" aria-label="delete">
                                                    <FaTrash />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>                 
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>}
                    < Grid container justify="flex-end" style={{ marginTop: '1em' }}>
                        <Grid item xs={1}>
                            <Typography variant="subtitle1">{shoppingCartItems.length !== 0 && `Totaal:`}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="subtitle1"><b>{shoppingCartItems.length !== 0 && totalPrice && `${parseMoney(totalPrice)}`}</b></Typography>
                        </Grid>
                    </Grid>
                    <DialogActions style={{ marginTop: "1em" }}>
                        <Grid container justify="space-between">
                            <Grid item>
                                <Button onClick={() => handleClose()} >Annuleren</Button>
                            </Grid>
                            <Grid item>
                                { (role === Role.COMPANY || role === Role.FREELANCER) && <Button onClick={() => {
                                    history.push("/inschrijven");
                                    handleClose();
                                    resetStepper(true);
                                }} variant="contained" color="secondary">Inschrijven</Button>}
                                { role === Role.STUDENT && <Button onClick={() => {
                                    history.push("/inschrijven/particulier");
                                    handleClose();
                                    resetStepper(true);
                                }} variant="contained" color="secondary">Inschrijven</Button>}
                                <Button onClick={() => {
                                    history.push("/bevestigen");
                                    handleClose();
                                }} variant="contained" style={{ marginLeft: "1em" }} disabled={!shoppingCartItems.length ? true : false} color="secondary">Bevestigen</Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div >
    )
}

export default withRouter(ShoppingCartComponent)
