import graphqlHelperService from "./graphqlHelper.service";
import { store } from "../components/Root";
import { AuthActions } from "../store/auth/types";
import { LoginResponse } from "../types/auth"

export const authenticationService = {
    login: async (username: string, password: string): Promise<LoginResponse[]> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            loginQuery(username, password),
            true
        );
        return apolloResult.login as LoginResponse[];
    },
    refreshToken: async (refreshToken: string): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            refreshTokenQuery(refreshToken)
        );

        const newToken: string = apolloResult.refreshToken;
        store.dispatch({ type: AuthActions.REFRESH_TOKEN, newToken });
        return newToken;
    },
    changePassword: async (newPassword: string, oldPassword: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            changePasswordQuery(newPassword, oldPassword)
        );
        return apolloResult.changePassword as boolean;
    },
    forgotPasswordRequest: async (username: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            forgotPasswordQuery(username)
        );
        return apolloResult.forgotPasswordRequest as boolean;
    },
    resetPassword: async (inputToken?: string, newPassword?: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            resetPasswordQuery(inputToken, newPassword)
        );
        return apolloResult.resetPassword as boolean;
    },
    getAuthHeader: (token: string): string => {
        return token ? `Bearer ${token}` : ``;
    },
    checkToken: async (token: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            checkTokenQuery(token)
        );
        return apolloResult.checkToken as boolean;
    },
    checkRefreshToken: async (refreshToken: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            checkRefreshTokenQuery(refreshToken)
        );
        return apolloResult.checkRefreshToken as boolean;
    },
    loginByEntranceCode: async (entranceCode: string): Promise<LoginResponse> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            loginByEntranceCodeQuery(entranceCode)
        );
        return apolloResult.loginByEntranceCode as LoginResponse;
    },
    deleteEntranceCode: async (entranceCode: string): Promise<boolean> => {
        graphqlHelperService.sendQuery(
            deleteEntranceCodeQuery(entranceCode)
        );
        return true;
    },
    userId: async (): Promise<number> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            userIdQuery()
        );
        return apolloResult.me.userId;
    }
}

const userIdQuery = () => `query {
    me {
        userId
    }
}`

const deleteEntranceCodeQuery = (entranceCode: string) => `mutation {
    deleteEntranceCode(entranceCode: "${entranceCode}")
}`

const loginByEntranceCodeQuery = (entranceCode: string) => `query {
    loginByEntranceCode(entranceCode: "${entranceCode}") {
        token
        refreshToken
        role
        companyName
        initials
        firstName
        prefix
        lastName
        address
        houseNumber
        zipCode
        place
        specialPriceAgreement
    }
}`

const checkTokenQuery = (token: string) => `query {
    checkToken(token: "${token}")
}`

const checkRefreshTokenQuery = (refreshToken: string) => `query {
    checkRefreshToken(refreshToken: "${refreshToken}")
}`

const loginQuery = (username: string, password: string) => `mutation{
    login(username:"${username}", password: "${password}") {
        token
        refreshToken
        role
        companyName
        initials
        firstName
        prefix
        lastName
        address
        houseNumber
        zipCode
        place
        specialPriceAgreement
    }
}`

const changePasswordQuery = (newPassword: string, oldPassword: string) => `mutation{
    changePassword(
        newPassword:"${newPassword}",
        currentPassword:"${oldPassword}"
      )
}`

const forgotPasswordQuery = (username: string) => `mutation{
    forgotPasswordRequest(
        username: "${username}"
    )
}`

const resetPasswordQuery = (inputToken?: string, newPassword?: string) => `mutation{
    resetPassword(
        inputToken:"${inputToken}",
        newPassword: "${newPassword}"
      )
}`

const refreshTokenQuery = (refreshToken: string) => `mutation{
    refreshToken(token:"Bearer ${refreshToken}")
}`
