import { createActionsWithPrefix } from "../../helperfunctions/action";

export const DiscountActions = createActionsWithPrefix("DISCOUNT/", {
    GET_REQUEST: "GET_REQUEST",
    GET_SUCCESS: "GET_SUCCESS",
    GET_ERROR: "GET_ERROR",
    CREATE_REQUEST: "CREATE_REQUEST",
    CREATE_SUCCESS: "CREATE_SUCCESS",
    CREATE_ERROR: "CREATE_ERROR",
    UPDATE_REQUEST: "UPDATE_REQUEST",
    UPDATE_SUCCESS: "UPDATE_SUCCESS",
    UPDATE_ERROR: "UPDATE_ERROR",
    DELETE_REQUEST: "DELETE_REQUEST",
    DELETE_SUCCESS: "DELETE_SUCCESS",
    DELETE_ERROR: "DELETE_ERROR",
    CHECK_SUCCESS: "CHECK_SUCCESS",
    CHECK_ERROR: "CHECK_ERROR",
});