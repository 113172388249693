import React, { useState, useEffect } from "react";

import { Grid, Tooltip, Typography, makeStyles, Paper } from "@material-ui/core";
import { CourseDetail, SummaryItem } from "../../types/course";
import { useSpring, config, animated } from "react-spring";
import { FaQuestion, FaHourglassHalf, FaLocationArrow, FaCalendarDay, FaCheck, FaClock, FaCalendar, FaFile, FaTimes, FaInfo, FaGraduationCap, FaUserCheck, FaEuroSign, FaTag, FaUserTimes, FaUser, FaStar } from "react-icons/fa";
import { parseGrade } from "../../helperfunctions/grade";
import { parseMoney } from "../../helperfunctions/money";
import { Role } from "../../types/enum";
import { withRouter, RouteComponentProps } from "react-router";
import { IconOption, getIconOptionByValue } from "../../types/iconSelection";

interface SummaryProps {
    role?: string
    pathname?: string
}

const useStyles = makeStyles({
    infoWrapper: {
        display: "flex",
        flexFlow: "column",
        marginTop: "1em",
        height: "100%",
    },
    info: {
        width: "100%",
        padding: "1em",
        backgroundColor: "#EDEDED"
    },
    icon: {
        fontSize: "20pt"
    }
})

export const getIcon = (name: string, icon?: IconOption) => {
    if (icon) {
        return getIconOptionByValue(icon).label;
    }

    switch (name) {
        case "Huiswerkbegeleiding":
            return (<FaUser />);
        case "Prijs":
        case "Prijs per cursist":
        case "Prijs per cursist bij meer dan 10 cursisten":
            return (<FaEuroSign />);
        case "Vaste prijs":
            return (<FaTag />);
        case "Locatie":
        case "Plaats":
        case "Locaties":
            return (<FaLocationArrow />);
        case "Toets":
            return (<FaFile />);
        case "Les data":
        case "Frequentie":
            return (<FaCalendar />);
        case "Cursus lengte":
            return (<FaClock />);
        case "Examen datum":
        case "Datum afgerond":
            return (<FaCalendarDay />);
        case "Certificaat":
        case "Cijfer":
        case "Proefexamen cijfer":
        case "Theorie cijfer":
        case "Coachen & Begeleiden cijfer":
        case "Portfolio cijfer":
            return (<FaGraduationCap />);
        case "BTW":
            return (<FaEuroSign />);
        case "Aanwezig bij examen":
        case "Geslaagd":
            return (<FaCheck />);
        case "Afgewezen":
        case "Afwezig bij examen":
            return (<FaTimes />);
        case "Naam":
        case "Cursussen":
            return (<FaInfo />);
        case "Aanwezigheid":
            return (<FaUserCheck />)
        case "Rating":
            return (<FaStar />)
        case "Afwezig":
            return (<FaUserTimes />)
        case "Status":
            return (<FaHourglassHalf />)
        default:
            return (<FaQuestion />);
    }
}

export const Summary = ({ name, variable, icon }: SummaryItem & SummaryProps) => {
    return (
        <Grid item xs={12} md={12} lg={12} >
            <Grid container spacing={2}>
                <Tooltip title={name} placement="bottom">
                    <Grid item xs={2} className="center-v">
                        {getIcon(name, icon)}
                    </Grid>
                </Tooltip>
                <Grid item xs={10}>
                    {Array.isArray(variable) ?
                        variable && variable.map((d, i) => {
                            if (d) return (
                                <Grid key={i} container>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" className="course-second-column" >{d}</Typography>
                                    </Grid>
                                </Grid>
                            );
                            return null;
                        })
                        : <Typography variant="body1" className="course-second-column">{variable}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    )
}

interface CourseDetailItemProps {
    courseDetail: CourseDetail;
    role: string;
}

const CourseDetailItem = ({ courseDetail, role, location }: CourseDetailItemProps & RouteComponentProps) => {
    let summaryItems: SummaryItem[] = [];
    const classes = useStyles()
    const [reset, setReset] = useState(false)
    const [register, setRegister] = useState(false)

    useEffect(() => {
        setReset(true)
        location.pathname.includes("inschrijven") ? setRegister(true) : setRegister(false)
        setTimeout(() => {
            setReset(false)
        }, 500);
    }, [courseDetail, location])

    if (courseDetail) {
        if (courseDetail.type) summaryItems.push({ name: "Naam", variable: courseDetail.type });
        if (courseDetail.address && courseDetail.place && courseDetail.locationName) summaryItems.push({ name: "Locatie", variable: courseDetail.locationName + ", " + courseDetail.address + ", " + courseDetail.place });
        if (courseDetail.place && (!courseDetail.locationName || !courseDetail.address)) summaryItems.push({ name: "Plaats", variable: courseDetail.place });
        if (courseDetail.certificate) summaryItems.push({ name: "Certificaat", variable: courseDetail.certificate });
        if (courseDetail.exam) summaryItems.push({ name: "Toets", variable: courseDetail.exam });
        if (courseDetail.dates && courseDetail.dates.length > 0) summaryItems.push({ name: "Les data", variable: courseDetail.dates });
        if (courseDetail.timeSpan) summaryItems.push({ name: "Cursus lengte", variable: courseDetail.timeSpan });
        if (courseDetail.frequency) summaryItems.push({ name: "Frequentie", variable: courseDetail.frequency });
        if (courseDetail.locations) summaryItems.push({ name: "Locaties", variable: courseDetail.locations });
        if (courseDetail.rating) summaryItems.push({ name: "Rating", variable: courseDetail.rating })
        if (courseDetail.homeworkSupport) summaryItems.push({ name: "Huiswerkbegeleiding", variable: "Huiswerkbegeleiding" })
        if (courseDetail.freeField1) summaryItems.push({ name: courseDetail.freeField1Caption ? courseDetail.freeField1Caption : "", variable: courseDetail.freeField1, icon: courseDetail.freeField1Icon })
        if (courseDetail.freeField2) summaryItems.push({ name: courseDetail.freeField2Caption ? courseDetail.freeField2Caption : "", variable: courseDetail.freeField2, icon: courseDetail.freeField2Icon })
        if (courseDetail.examDate) summaryItems.push({ name: "Examen datum", variable: [courseDetail.examDate] });
        if (courseDetail.dateDone) summaryItems.push({ name: "Datum afgerond", variable: [courseDetail.dateDone] });
        if (courseDetail.passed === true) summaryItems.push({ name: "Geslaagd", variable: "Geslaagd" });
        if (courseDetail.failed === true) summaryItems.push({ name: "Afgewezen", variable: "Afgewezen" });
        if (!courseDetail.passed && !courseDetail.failed && courseDetail.dateDone && role !== Role.COMPANY && !courseDetail.expired) summaryItems.push({ name: "Resultaat", variable: "Onbekend" });
        if (courseDetail.sampleExamGrade) summaryItems.push({ name: "Proefexamen cijfer", variable: parseGrade(courseDetail.sampleExamGrade) });
        if (courseDetail.grade) summaryItems.push({ name: "Cijfer", variable: parseGrade(courseDetail.grade) });
        if (courseDetail.theoreticGrade) summaryItems.push({ name: "Theorie cijfer", variable: parseGrade(courseDetail.theoreticGrade) });
        if (courseDetail.coachingGrade) summaryItems.push({ name: "Coachen & Begeleiden cijfer", variable: parseGrade(courseDetail.coachingGrade) });
        if (courseDetail.portfolioGrade) summaryItems.push({ name: "Portfolio cijfer", variable: parseGrade(courseDetail.portfolioGrade) });
        if (courseDetail.presentAtExam === false) summaryItems.push({ name: "Afwezig", variable: "Afwezig" });
        if (courseDetail.price && courseDetail.btw) summaryItems.push({ name: "Prijs per cursist", variable: parseMoney(courseDetail.price) + " (" + courseDetail.btw + ")" });
        if (!register && !courseDetail.dateDone && role === Role.COMPANY) summaryItems.push({ name: "Status", variable: 'Lopend' });
        if (!register && courseDetail.dateDone && role === Role.COMPANY) summaryItems.push({ name: "Status", variable: 'Afgerond' });
    }

    const animatedProps = useSpring({
        from: { opacity: 0, transform: "translateX(-1rem)", width: "100%" },
        to: { opacity: 1, transform: "translateX(0rem)", width: "100%" },
        reset: reset,
        reverse: reset,
        config: config.stiff
    })

    return (
        <Grid>
            <Typography variant="overline">DETAILS</Typography>
            <Paper className={classes.info}>
                <Grid container className="course-data-container">
                    {!summaryItems || summaryItems.length === 0 ?
                        <Typography>Selecteer een cursus</Typography>
                        :
                        <animated.div style={!register ? animatedProps : { width: "100%" }}>
                            {summaryItems && summaryItems.map((summaryItem, index) => {
                                return <Summary name={summaryItem.name} variable={summaryItem.variable} key={index} icon={summaryItem.icon} />
                            })}
                        </animated.div>
                    }
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withRouter(CourseDetailItem);
