import { connect } from "react-redux";
import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "../../../../store";
import { CourseDetail } from "../../../../types/course";
import RegisterCourseComponent from "./RegisterCourseComponent";
import { RegisterCourse } from "../../../../types/registerCourse";
import { getCourseDetail, setFreelanceBook } from "../../../../store/registerCourse/actions";
import { AuthenticationState } from "../../../../types/auth";
import { RegisterUserType } from "../../../../types/enum";
import { isNil } from "lodash";

interface RegisterCourseContainerProps {
    course: RegisterCourse
    courseDetail: CourseDetail;
    getCourseDetail: (course: RegisterCourse, discountCode?: string) => void;
    setFreelanceBook: (freelancebook: boolean) => void;
    handleNext: () => void;
    discountCode?: string;
    auth: AuthenticationState;
    freelanceBooks: boolean;
    userType: RegisterUserType;
}

const mapStateToProps = ({ registerCourse: { courseDetail, course, freelanceBooks }, discount: { discountCode }, auth }: ApplicationState) => {
    return ({
        courseDetail,
        course,
        discountCode,
        auth,
        freelanceBooks: !!freelanceBooks,
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getCourseDetail, setFreelanceBook }, dispatch);
};

const RegisterCourseContainer = ({ course, courseDetail, handleNext, getCourseDetail, discountCode, setFreelanceBook, auth, freelanceBooks, userType }: RegisterCourseContainerProps) => {
    useEffect(() => {
        getCourseDetail(course, discountCode);
    }, [getCourseDetail, course, discountCode]);

    return (
        <RegisterCourseComponent
            role={auth.role}
            setFreelanceBook={setFreelanceBook}
            completed={course.completed}
            hasBook={course.type && course.type.hasBookDiscount}
            courseDetail={courseDetail}
            handleNext={handleNext}
            freelanceBooks={freelanceBooks}
            userType={userType}
            priceIncludesBook={!isNil(course.type?.priceIncludesBook) ? course.type?.priceIncludesBook : true}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterCourseContainer);