import React from "react"
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import CategoryItem from "./CategoryItem";
import { CourseCategory } from "../../../types/registerCourse";

interface CategoryComponentProps {
    categories: CourseCategory[];
}

const CategoryComponent = ({ categories }: CategoryComponentProps) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid alignItems="center" container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5">Cursus categorieën</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: ".5em" }}>
                    <Grid item xs={12} style={{ display: "flex", flexDirection: small ? "column" : "row", flexWrap: "wrap" }}>
                        {categories && categories.map(category => <CategoryItem {...category} key={category.categoryId} />)}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default CategoryComponent;
