import { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { ApplicationState } from "../../store";
import { getIssuersList } from "../../store/payment/actions";
import IssuersComponent from "./IssuersComponent";
import IssuersModal from "./IssuersModal";
import { Issuer } from "../../types/payment";

interface IssuersContainerState {
    issuers: Issuer[];
    loading: boolean;
    modal? : boolean;
    open? : boolean;
    setOpen: (open: boolean) => void;
    getIssuersList: () => void;
    setSelectedIssuer: (issuer: Issuer) => void
}

interface IssuersContainerProps {
    handleNext?: (issuerId?: string) => void; 
}

const mapStateToProps = ({ payment: { issuers, loading } }: ApplicationState) => ({
    issuers,
    loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    getIssuersList, 
}, dispatch);

const IssuersContainer = ({ issuers, getIssuersList, handleNext, loading, modal, setSelectedIssuer, open, setOpen }: IssuersContainerState & IssuersContainerProps) => {
    useEffect(() => getIssuersList(), [getIssuersList])

    return modal ?
        <IssuersModal
            open={open}
            issuers={issuers}
            loading={loading}
            setOpen={(open: boolean) => setOpen(open)}
            setSelectedIssuer={(issuer) => setSelectedIssuer(issuer)}
        />
        :
        <IssuersComponent
            issuers={issuers}
            loading={loading}
            handleNext={(issuerId: string | undefined) => {
                handleNext && handleNext();
            }}
        />
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssuersContainer);