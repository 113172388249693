import { useEffect } from "react"
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import ShoppingCartComponent from "./ShoppingCartComponent";
import { RegistrationPriceInfo, Registration, RegisterPriceResponse } from "../../types/register";
import { removeFromShoppingCart, getPrices, resetStepper } from "../../store/register/actions"
import { ApplicationState } from "../../store";
import { Role } from "../../types/enum";
import { StudentDetail } from "../../types/student";

interface ShoppingCartContainerProps {
    open: boolean
    handleClose: () => void;
    discountCode?: string;
    removeFromShoppingCart: (index: number) => void;
    shoppingCart: Registration[];
    getPrices: (priceItems: RegistrationPriceInfo[]) => void;
    prices: RegisterPriceResponse[];
    role: string;
    resetStepper: (reset: boolean) => void;
}

const mapStateToProps = ({ register: { shoppingCart, prices }, auth: { role }, discount: { discountCode } }: ApplicationState) => {
    return({shoppingCart, role, prices, discountCode})
}


const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ removeFromShoppingCart, getPrices, resetStepper }, dispatch);
};

const ShoppingCartContainer = ({ discountCode, open, handleClose, role, shoppingCart, removeFromShoppingCart, getPrices, prices, resetStepper }: ShoppingCartContainerProps) => {
    useEffect(() => {
        let priceItems: RegistrationPriceInfo[] = []
        shoppingCart && shoppingCart.forEach((item: Registration) => {
            if (item.course.type?.courseId && role === Role.COMPANY) {
                let studentBooks: boolean[] = item.students ? item.students.map((student: StudentDetail) => student.books || false): [];

                const registrationPriceInfo: RegistrationPriceInfo = {
                    courseId: item.course.type?.courseId,
                    studentBooks: studentBooks,
                    discountCode: discountCode,
                }
                priceItems.push(registrationPriceInfo)
            } else if (item.course.type?.courseId && role === Role.FREELANCER) {
                const registrationPriceInfo: RegistrationPriceInfo = {
                    courseId: item.course.type?.courseId,
                    studentBooks: [(!!item.freelancerBooks || false)],
                    discountCode: discountCode
                }
                priceItems.push(registrationPriceInfo)
            }
        })
        if (priceItems.length > 0) {
            getPrices(priceItems);
        }
    }, [getPrices, role, shoppingCart, discountCode]);

    return (
        <div>
            <ShoppingCartComponent 
                resetStepper={resetStepper}
                onRemove={(index) => removeFromShoppingCart(index)} 
                prices={prices} 
                shoppingCartItems={shoppingCart} 
                open={open} 
                handleClose={handleClose} 
                role={role}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCartContainer)
