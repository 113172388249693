import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, useMediaQuery, Theme, IconButton } from "@material-ui/core";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { FaTimesCircle } from "react-icons/fa";

import RenderTextField from "../TextField";
import { useTheme } from "@material-ui/styles";
import { required, validString } from "../../validators/form";

interface ForgotPasswordForm {
    username: string;
}

interface ForgotPasswordProps {
    open: boolean;
    handleClose: () => void;
    handleSend: (email: string) => void;
}

const ForgotPassword = ({ open, handleClose, handleSend, handleSubmit, reset }: ForgotPasswordProps & InjectedFormProps<ForgotPasswordForm, ForgotPasswordProps>) => {
    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const close = () => {
        reset();
        handleClose();
    }

    return (
        <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
            <form onSubmit={handleSubmit(({ username }: ForgotPasswordForm) => {handleSend(username); close()})} style={{ height: (fullScreen ? "100vh" : "") }}>
                <Grid container direction="column" style={{ height: (fullScreen ? "100vh" : "") }}>
                    <Grid item xs={12} style={{ flexBasis: 0 }}>
                        <Grid container justify="space-between">
                            <DialogTitle>Wachtwoord vergeten</DialogTitle>
                            <IconButton style={{padding:"1em"}} onClick={close}>
                                <FaTimesCircle />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ flexBasis: 0 }}>
                        <Grid container direction="column">
                            <DialogContent>
                                <DialogContentText color="textPrimary">Vul uw email of cursistnummer in, wij sturen u vervolgens per e-mail een link om uw wachtwoord te veranderen.</DialogContentText>
                                <Field
                                    name="username"
                                    variant="outlined"
                                    validate={[validString, required]}
                                    component={RenderTextField}
                                    placeholder="Email of cursistnummer"
                                />
                            </DialogContent>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: (fullScreen ? "auto" : "0"), flexBasis: 0 }}>
                        <Grid container direction="column" justify="flex-end" >
                            <DialogActions style={{ paddingLeft: "1.5em", paddingRight: "1.5em" }}>
                                <Grid container justify="space-between">
                                    <Button onClick={close} color="primary">Terug</Button>
                                    <Button type="submit" color="secondary" variant="contained">Verstuur</Button>
                                </Grid>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Dialog>
    );
}

export default reduxForm<ForgotPasswordForm, ForgotPasswordProps>({
    form: "forgotPasswordForm"
})(ForgotPassword);
