import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { resetCourseDetail } from "../../../store/registerCourse/actions";
import { addRegisterStudent, addToShoppingCart, setRegisterStudent, emptyRegisterStudent, removeRegisterStudent, removeAllRegisterStudents, editRegisterStudent, updateRegisterBooks } from "../../../store/register/actions";
import RegisterAppStudentData from "./RegisterAppStudentData";
import { RegisterState, Registration } from "../../../types/register";
import RegisterAppComplete from "./RegisterAppComplete";
import { RegisterCourseState } from "../../../types/registerCourse";
import { AuthenticationState } from "../../../types/auth";
import RegisterAppConfirmation from "./RegisterAppConfirmation";
import { getStudents } from "../../../store/student/actions";
import { StudentState, StudentDetail } from "../../../types/student";
import { resetCourseRegister } from "../../../store/registerCourse/actions";
import { setMailingLink } from "../../../store/registerCourse/actions";
import { checkDiscountCode } from "../../../store/discount/actions";
import { getAddress } from "../../../store/register/actions";
import { RouterState } from "connected-react-router";
import { RegisterUserType, Role } from "../../../types/enum";
import RegisterCourseContainer from "../Register/Course/RegisterCourseContainer";
import { DiscountState } from "../../../types/discount";
import { isNil } from "lodash";

interface CompanyStepperContainerState {
    registerCourse: RegisterCourseState;
    register: RegisterState;
    auth: AuthenticationState;
    student: StudentState;
    router: RouterState;
    discount: DiscountState;

    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;

    setActiveStep: (step: number) => void;
    getStudents: () => void;

    setRegisterStudent: (student: StudentDetail) => void;
    addRegisterStudent: (student: StudentDetail) => void;
    editRegisterStudent: (student: StudentDetail, studentId: number) => void;
    removeRegisterStudent: (studentIndex: number) => void;
    removeAllRegisterStudents: () => void;
    addToShoppingCart: (newShoppingCartItem: Registration) => void;
    emptyRegisterStudent: () => void;

    checkDiscountCode: (discountCode: string) => void;
    setMailingLink: (categoryId?: number, courseId?: string, location?: string, locationCode?: string) => void;
    updateRegisterBooks: (studentIndex: number) => void;
    resetCourseDetail: () => void;
    resetCourseRegister: () => void;
}

interface CompanyStepperContainerProps {
    categoryId?: number | undefined;
    courseId?: string | undefined;
    place?: string | undefined;
    locationCode?: string | undefined;
    discountCode?: string | undefined;

    currentStep: number;
    handleNext: () => void;
    handleBack: () => void;
}

const mapStateToProps = ({ registerCourse, register, auth, student, router, discount }: CompanyStepperContainerState) => {
    return ({
        registerCourse,
        register,
        auth,
        student,
        router,
        discount
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        removeRegisterStudent,
        getAddress,
        getStudents,
        addRegisterStudent,
        addToShoppingCart,
        removeAllRegisterStudents,
        resetCourseRegister,
        resetCourseDetail,
        editRegisterStudent,
        setRegisterStudent,
        emptyRegisterStudent,
        setMailingLink,
        checkDiscountCode,
        updateRegisterBooks
    }, dispatch);
}

let RegisterAppStepperContainer = ({
    getStudents,
    handleNext,
    handleBack,
    currentStep,
    addRegisterStudent,
    removeRegisterStudent,
    register,
    auth,
    student,
    addToShoppingCart,
    setActiveStep,
    removeAllRegisterStudents,
    resetCourseRegister,
    resetCourseDetail,
    getAddress,
    setRegisterStudent,
    editRegisterStudent,
    emptyRegisterStudent,
    registerCourse,
    categoryId,
    courseId,
    place,
    locationCode,
    setMailingLink,
    discountCode,
    checkDiscountCode,
    updateRegisterBooks
}: CompanyStepperContainerProps & CompanyStepperContainerState) => {
    const { role } = auth
    useEffect(() => {
        getStudents()
    }, [getStudents]);

    useEffect(() => {
        categoryId && setMailingLink(categoryId, courseId, place, locationCode);
    }, [setMailingLink, categoryId, courseId, place, locationCode]);

    useEffect(() => {
        discountCode && checkDiscountCode(discountCode);
    }, [discountCode, checkDiscountCode])

    if (role === Role.COMPANY) {
        switch (currentStep) {
            case 0:
                return <RegisterCourseContainer
                    handleNext={handleNext}
                    userType={RegisterUserType.Company}
                />;
            case 1:
                return <RegisterAppStudentData
                    hasBookDiscount={registerCourse.course.type?.hasBookDiscount || false}
                    priceIncludesBook={!isNil(registerCourse.course.type?.priceIncludesBook) ? registerCourse.course.type?.priceIncludesBook : true}
                    emptyRegisterStudent={emptyRegisterStudent}
                    setRegisterStudent={setRegisterStudent}
                    student={student}
                    register={register}
                    addRegisterStudent={addRegisterStudent}
                    editRegisterStudent={editRegisterStudent}
                    removeRegisterStudent={removeRegisterStudent}
                    updateRegisterBooks={updateRegisterBooks}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    getAddress={getAddress}
                />
            case 2:
                return <RegisterAppConfirmation
                    resetCourseDetail={resetCourseDetail}
                    resetCourseRegister={resetCourseRegister}
                    resetStudents={removeAllRegisterStudents}
                    courseDetail={registerCourse.courseDetail}
                    register={register}
                    role={role}
                    handleBack={handleBack}
                    handleConfirm={() => {
                        const { students } = register;
                        if (students && registerCourse.course.type) {
                            addToShoppingCart({
                                course: registerCourse.course,
                                students,
                            })
                            handleNext();
                            removeAllRegisterStudents();
                        }
                    }}
                />
            default:
                return <RegisterAppComplete setActiveStep={setActiveStep} />;
        }
    } else {
        switch (currentStep) {
            case 0:
                return <RegisterCourseContainer
                    handleNext={handleNext}
                    userType={RegisterUserType.Freelancer}
                />;
            case 1:
                return <RegisterAppConfirmation
                    resetStudents={removeAllRegisterStudents}
                    resetCourseDetail={resetCourseDetail}
                    courseDetail={registerCourse.courseDetail}
                    register={register}
                    role={role}
                    handleBack={handleBack}
                    resetCourseRegister={resetCourseRegister}
                    handleConfirm={() => {
                        if (registerCourse.course.type) {
                            addToShoppingCart({
                                course: registerCourse.course,
                                freelancerBooks: registerCourse.freelanceBooks,
                                students: []
                            });
                            handleNext();
                        }
                    }}
                />
            default:
                return <RegisterAppComplete setActiveStep={setActiveStep} />;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAppStepperContainer);