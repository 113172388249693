import { Dispatch } from "redux";

import { PaymentService } from "../../services/payment.service";
import messages from "../../constants/messages";
import { toast } from "react-toastify";
import { PaymentActions } from "./types";
import { Issuer } from "../../types/payment";

export const getIssuersList = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: PaymentActions.GET_ISSUERS_REQUEST });
        const issuersList: Issuer[] = [];
        dispatch({ type: PaymentActions.GET_ISSUERS_SUCCESS, issuersList });
    } catch (error) {
        toast.error(messages.SOMETHING_WENT_WRONG)
        dispatch({ type: PaymentActions.GET_ISSUERS_ERROR, error });
    }
};

export const getIdealLink = (invoiceId: string, description: string, issuerId: string, returnUrl: string) => async (dispatch: Dispatch) => {
    try {
        const invoiceLink = await PaymentService.invoiceLink(invoiceId, description, issuerId, returnUrl);
        if (invoiceLink) {
            window.location.assign(invoiceLink);
        } else {
            toast.error(messages.SOMETHING_WENT_WRONG)
        }
    } catch (error) {
        toast.error(messages.SOMETHING_WENT_WRONG)
    }
};

export const paymentReturned = (transactionId: string) => async (dispatch: Dispatch) => {
    try {
        PaymentService.paymentReturned(transactionId);
    }
    catch (error) { }
}

