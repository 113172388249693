import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useSpring, animated, config } from "react-spring";
import { Grid, Card, Theme, CardActionArea, Typography, CardContent, CardHeader, Divider, useTheme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FaChevronRight } from "react-icons/fa";

import "./Dashboard.css";

interface DashboardCardType {
    title: string;
    description: string;
    image: string;
    path: string;
    index: number;
    button: string;
}

interface DashboardCardProps {
    scrollY: number
}

const useStyles = makeStyles((theme: Theme) => ({
    action: {
        padding: theme.spacing(1)
    },
    card: {
        borderRadius: "12px",
        maxWidth: "fit-content",
        boxShadow: "2px 10px 50px rgba(0, 0, 0, 0.16)",
        margin: theme.spacing(2.5),
        [theme.breakpoints.up("xs")]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down("lg")]: {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(1),
    },
    data: {
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("lg")]: {
            marginLeft: theme.spacing(1),
        },
    },
    image: {
        width: "100px"
    }
}));

type Props = RouteComponentProps<{}> & DashboardCardType & DashboardCardProps;

const DashboardCard = ({ history, title, description, image, path, button, index, scrollY }: Props) => {
    const classes = useStyles();
    const theme: Theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("md"));
    const [hover, setHover] = useState(false);
    const navigateToPage = (path: string) => {
        history.push(path);
    };

    const props = useSpring({
        from: { opacity: 0 },
        to: { opacity: scrollY > 100 && !small && index < 3 ?  0 : 1 },
        delay: scrollY > 100 ? 0 : (index * 200) + 200,
        config: config.default
    });

    const propsHover = useSpring({
        to: { transform: hover ? " scale(1.01) translateY(-3px)" : " scale(1) translateY(0px)", },
        config: config.wobbly
    });

    const iProps = useSpring({
        to: { transform: hover ? "translateX(3px)" : "translateX(0px)", marginLeft: "5px" },
        config: config.wobbly
    });
    return (
        <Grid item xs={12} lg={4} style={{ zIndex: small ? 0 : theme.zIndex.drawer + 2 }}>
            <animated.div style={props}>
                <animated.div style={propsHover}>
                    <Card className={classes.card}>
                        <CardHeader title={title} />
                        <Divider />
                        <CardContent>
                            <Grid container direction="column">
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <img src={`../assets/img/card-icons/${image}.png`} alt={`${image}`} className={classes.image} />
                                        </Grid>
                                        <Grid item className={classes.data}>
                                            <Grid container direction="column">
                                                <Typography variant="subtitle1">{description}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActionArea onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.action} onClick={navigateToPage.bind(undefined, path)}>
                            <Grid container justify="space-between">
                                <Typography style={{ marginLeft: "8px" }} variant="button">{`${button}`}</Typography>
                                <animated.span style={iProps}><FaChevronRight size="1.5em" /></animated.span>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </animated.div>
            </animated.div>
        </Grid>
    );
}

export default withRouter(DashboardCard);