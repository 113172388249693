import React, { useState } from 'react';
import { makeStyles, Dialog, DialogContent, Grid, Paper, List, ListItem, Typography, Divider, ListItemSecondaryAction, IconButton, Button, DialogActions } from '@material-ui/core';
import { useSpring, animated, config } from 'react-spring';
import { FaTrash, FaUsers, FaUserPlus, FaEdit } from "react-icons/fa";

import { RegisterCourseState } from '../../types/registerCourse';
import { StudentState, StudentDetail } from "../../types/student";
import { Registration } from '../../types/register';
import AddStudentDialog from '../Register/RegisterApp/AddStudentDialog';
import AddNewStudentDialog from '../Register/RegisterApp/AddNewStudentDialog';
import CourseContainer from '../Register/Register/Course/CourseContainer';
import { AuthenticationState } from '../../types/auth';
import { RegisterUserType, Role } from '../../types/enum';

interface EditShoppingcartComponentProps {
    registerCourse: RegisterCourseState;
    student: StudentState;
    pathname: string;
    loading: boolean
    openCourse: boolean;
    itemIndex: number;
    openCheckoutItemIndex: number | undefined;
    checkoutItems: Registration[];
    auth: AuthenticationState;

    setOpenCheckoutItemIndex: (openCheckoutItemIndex: number | undefined) => void;
    setOpenCourse: (openCourse: boolean) => void;

    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    emptyRegisterStudent: () => void;
    setRegisterStudent: (student: StudentDetail) => void;

    addShoppingCartStudent: (itemIndex: number, student: StudentDetail) => void;
    editShoppingCartStudent: (itemIndex: number, studentIndex: number, student: StudentDetail) => void;
    removeShoppingCartStudent: (itemIndex: number, studentIndex: number) => void;
    hasBookDiscount: boolean;
    priceIncludesBook: boolean;
}

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        margin: 10,
        color: "#fff",
        backgroundColor: theme.palette.secondary.main,
    },
    divider: {
        backgroundColor: "#EDEDED",
        opacity: 0.5,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    listText: {
        maxWidth: "400px"
    },
    addStudentListItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2)
    },
    addStudentListItemIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2)
    },
    studentListItem: {
        padding: theme.spacing(2)
    },
    studentListWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    noStudentListItem: {
        padding: theme.spacing(2)
    },
    linkButton: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
    colorContrast: {
        color: theme.palette.primary.main
    },
}));

const EditShoppingcartComponent = ({
    registerCourse,
    getAddress,
    emptyRegisterStudent,
    checkoutItems,
    openCourse,
    setOpenCourse,
    itemIndex,
    openCheckoutItemIndex,
    setOpenCheckoutItemIndex,
    student,
    setRegisterStudent,
    hasBookDiscount,
    priceIncludesBook,
    addShoppingCartStudent,
    editShoppingCartStudent,
    removeShoppingCartStudent,
    auth
}: EditShoppingcartComponentProps) => {
    const classes = useStyles();
    const [studentIndex, setStudentIndex] = useState<number>(0)
    const [noStudent, setNoStudent] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [addStudentsOpen, setAddStudentsOpen] = useState(false);
    const [newStudentOpen, setNewStudentOpen] = useState<boolean>(false);

    const handleSelect = (selectedStudent: StudentDetail[]) => {
        selectedStudent && selectedStudent.map((student: StudentDetail) => addShoppingCartStudent(itemIndex, student));
    };

    const noStudentProps = useSpring({
        from: { color: "black" },
        to: { color: noStudent ? "red" : "black" },
        config: config.stiff
    });

    function renderEditShoppingCartDialog() {
        if (openCheckoutItemIndex !== undefined && checkoutItems[openCheckoutItemIndex] !== undefined) {
            const students = checkoutItems[openCheckoutItemIndex].students;
            let selectedIds: string[] = [];
            for (const student of students) {
                if (student.studentId) selectedIds.push(student.studentId);
            }

            return (
                <Dialog
                    fullWidth={true}
                    maxWidth="xl"
                    open={openCheckoutItemIndex !== undefined}
                    onClose={() => students.length < 1 && students.length < 1 ? setNoStudent(true) : setOpenCheckoutItemIndex(undefined)}>
                    <DialogContent>
                        <AddStudentDialog handleSelect={handleSelect} selectedIds={selectedIds} students={student.students} open={addStudentsOpen} handleClose={() => setAddStudentsOpen(false)} />
                        <AddNewStudentDialog
                            hasBookDiscount={hasBookDiscount}
                            priceIncludesBook={priceIncludesBook}
                            studentIndex={studentIndex}
                            edit={edit}
                            onEdit={(student, studentIndex) => editShoppingCartStudent(itemIndex, studentIndex, student)}
                            getAddress={getAddress}
                            open={newStudentOpen}
                            handleClose={() => {
                                setEdit(false)
                                setNewStudentOpen(false)
                                emptyRegisterStudent()
                            }}
                            onAdd={(student: StudentDetail) => addShoppingCartStudent(itemIndex, student)}
                        />
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Paper className={classes.studentListWrapper}>
                                    <List disablePadding>
                                        {students.length < 1 &&
                                            <>
                                                <ListItem dense className={classes.noStudentListItem}>
                                                    <animated.div style={noStudentProps}>
                                                        <Typography>U heeft nog geen medewerkers toegevoegd</Typography>
                                                    </animated.div>
                                                </ListItem>
                                                <Divider />
                                            </>
                                        }
                                        {students.length !== 0 &&
                                            <Grid container style={{ padding: 16 }}>
                                                <Grid item xs={hasBookDiscount ? 3 : 4}>
                                                    <Typography variant="subtitle1"><b>Naam</b></Typography>
                                                </Grid>
                                                <Grid item xs={hasBookDiscount ? 3 : 4}>
                                                    <Typography variant="subtitle1"><b>E-mail</b></Typography>
                                                </Grid>
                                                <Grid item xs={hasBookDiscount ? 3 : 4}>
                                                    <Typography variant="subtitle1"><b>Adres</b></Typography>
                                                </Grid>
                                                {hasBookDiscount &&
                                                    <Grid item xs={3}>
                                                        <Typography variant="subtitle1">{priceIncludesBook ? "Lesboek in bezit?" : "Lesboek toevoegen?"}</Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                        }
                                        {students && students.map((student: StudentDetail, studentIndex: number) => {
                                            return (
                                                <div key={studentIndex}>
                                                    <ListItem dense className={classes.studentListItem}>
                                                        <Grid item md={9} sm={9} xs={12} lg={10}><Typography style={{ color: student.email ? "gray" : "red" }} variant="subtitle2">{`${student.firstName} ${student.prefix ? student.prefix : ""} ${student.lastName}`}</Typography></Grid>
                                                        <Grid item md={9} sm={9} xs={12} lg={10}><Typography style={{ color: student.email ? "gray" : "red" }} variant="subtitle2">{student.email ? `${student.email}` : `Geen e-mailadres bekend`}</Typography></Grid>
                                                        <Grid item md={9} sm={9} xs={12} lg={10}><Typography style={{ color: student.address ? "gray" : "red" }} variant="subtitle2">{student.address ? `${student.address} ${student.houseNumber}, ${student.place}` : `Geen adres bekend`}</Typography></Grid>
                                                        {hasBookDiscount &&
                                                            <>
                                                                <Grid item md={9} sm={9} xs={12} lg={10}><Typography variant="subtitle2">{student.books ? `ja` : `nee`}</Typography></Grid>
                                                            </>
                                                        }

                                                        <ListItemSecondaryAction>
                                                            <IconButton edge="end" onClick={() => {
                                                                setRegisterStudent(student)
                                                                setStudentIndex(studentIndex)
                                                                setEdit(true)
                                                                setNewStudentOpen(true)
                                                            }}>
                                                                <FaEdit />
                                                            </IconButton>
                                                            <IconButton edge="end" onClick={() => removeShoppingCartStudent(itemIndex, studentIndex)}>
                                                                <FaTrash />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    <Divider />
                                                </div>
                                            );
                                        })}
                                        <ListItem dense className={classes.addStudentListItem}>
                                            <Grid container spacing={2} style={{ textAlign: "center" }}>
                                                <Grid item xs={6}>
                                                    <Button onClick={() => setNewStudentOpen(true)} fullWidth={true} color="secondary"><FaUserPlus style={{ marginRight: "1em" }} />Nieuwe medewerker Toevoegen</Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button onClick={() => setAddStudentsOpen(true)} fullWidth={true} color="secondary"><FaUsers style={{ marginRight: "1em" }} />bestaande medewerker(s) Toevoegen</Button>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Grid container justify="flex-end">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => students.length < 1 ? setNoStudent(true) : setOpenCheckoutItemIndex(undefined)}
                                    disabled={(students.length < 1)}>
                                    Opslaan
                                </Button>
                            </Grid>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            );
        }
    };

    return (
        <div>
            <Dialog fullWidth={true} maxWidth="lg" open={openCourse} onClose={() => setOpenCourse(false)}>
                <DialogContent>
                    <CourseContainer userType={auth && auth.role === Role.COMPANY ? RegisterUserType.Company : RegisterUserType.Freelancer} isMailLinkBuilder={false} />
                    <DialogActions>
                        <Grid container justify-content="flex-end">
                            <Button
                                color="secondary"
                                variant="contained"
                                disabled={!registerCourse.course.completed}
                                onClick={() => setOpenCourse(false)}>
                                Opslaan
                            </Button>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            {renderEditShoppingCartDialog()}
        </div>
    );
};

export default EditShoppingcartComponent