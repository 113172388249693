import { createStore, combineReducers, Store, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware, connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import { authReducer } from "./auth/reducers";
import { invoiceReducer } from "./invoices/reducers";
import { InvoiceState } from "../types/invoice"
import { AuthenticationState } from "../types/auth";
import { registerReducer } from "./register/reducers";
import { RegisterState } from "../types/register";
import { registerCourseReducer, emptyCourse } from "./registerCourse/reducers";
import { RegisterCourseState } from "../types/registerCourse";
import { courseReducer } from "./course/reducers";
import { studentReducer } from "./student/reducers";
import { saveState, loadState } from "../helperfunctions/localstorage";
import { StudentState } from "../types/student";
import { testExamReducer } from "./testExams/reducers";
import { SampleExamState } from "../types/sampleExam";
import { CourseState } from "../types/course";
import { DashboardState } from "../types/dashboard";
import { dashboardReducer } from "./dashboard/reducers";
import { UserInformationState } from "../types/userInformation";
import { userInformationReducer } from "./userInformation/reducers";
import { assignmentReducer } from "./assignment/reducers";
import { AssignmentState } from "../types/assignment";
import { QuestionState } from "../types/question";
import { questionReducer } from "./question/reducers";
import { discountReducer } from "./discount/reducers";
import { DiscountState } from "../types/discount";
import { MailingLinkState } from "../types/mailingLink";
import { mailingLinkReducer } from "./mailingLink/reducers";
import { PaymentState } from "../types/payment";
import { paymentReducer } from "./payment/reducers";
import { MetaDataState } from "../types/metaData";
import { metaDataReducer } from "./metadata/reducers";
import { SampleExamLogState } from "../types/sampleExamLog";
import { sampleExamLogsReducer } from "./sampleExamLogs/reducers";

export interface ApplicationState {
    assignment: AssignmentState;
    auth: AuthenticationState;
    invoice: InvoiceState;
    course: CourseState;
    discount: DiscountState;
    student: StudentState;
    register: RegisterState;
    registerCourse: RegisterCourseState;
    testExams: SampleExamState;
    router: RouterState;
    dashboard: DashboardState;
    question: QuestionState;
    userInformation: UserInformationState;
    mailingLink: MailingLinkState;
    payment: PaymentState;
    metaData: MetaDataState;
    sampleExamLogs: SampleExamLogState;
}

export const createRootReducer = (history: History) =>
    combineReducers({
        assignment: assignmentReducer,
        auth: authReducer,
        invoice: invoiceReducer,
        course: courseReducer,
        discount: discountReducer,
        student: studentReducer,
        form: formReducer,
        register: registerReducer,
        registerCourse: registerCourseReducer,
        testExams: testExamReducer,
        dashboard: dashboardReducer,
        userInformation: userInformationReducer,
        question: questionReducer,
        mailingLink: mailingLinkReducer,
        payment: paymentReducer,
        metaData: metaDataReducer,
        sampleExamLogs: sampleExamLogsReducer,
        router: connectRouter(history),
    });

export const configureStore = (
    history: History
): Store<ApplicationState> => {
    const composeEnhancers = composeWithDevTools({});
    const persistentState = loadState();
    const store = createStore(
        createRootReducer(history),
        persistentState,
        composeEnhancers(applyMiddleware(routerMiddleware(history), thunk)),
    );

    store.subscribe(() => {
        saveState({
            auth: {
                token: store.getState().auth.token,
                companyName: store.getState().auth.companyName,
                refreshToken: store.getState().auth.refreshToken,
                role: store.getState().auth.role,
                lastName: store.getState().auth.lastName,
                firstName: store.getState().auth.firstName,
                prefix: store.getState().auth.prefix,
                initials: store.getState().auth.initials,
            },
            register: {
                registerCompanyData: store.getState().register.registerCompanyData,
                registerFreelancerUserFormData: store.getState().register.registerFreelancerUserFormData,
                registerPayment: store.getState().register.registerPayment,
                students: store.getState().register.students ? store.getState().register.students : [],
                shoppingCart: store.getState().register.shoppingCart ? store.getState().register.shoppingCart : [],
            },
            registerCourse: {
                course: store.getState().registerCourse.course || emptyCourse,
                freelanceBooks: store.getState().registerCourse.freelanceBooks || false,
            }
        });
    });

    return store;
};