let dashboardCard1 = {
    title: "Cursussen",
    description: "Een overzicht van je cursussen ",
    image: "course",
    path: "/cursussen",
    button: "BEKIJK ALLE CURSUSSEN"
}
let dashboardCard2 = {
    title: "Medewerkers",
    description: "Een overzicht van je medewerkers",
    image: "student",
    path: "/medewerkers",
    button: "BEKIJK ALLE GEGEVENS"
}
let dashboardCard3 = {
    title: "Facturatie",
    description: "Een overzicht van je facturen",
    image: "invoice",
    path: "/facturen",
    button: "BEKIJK ALLE FACTUREN"
}
let dashboardCard5 = {
    title: "Kortingscodes",
    description: "Een overzicht van de kortingscodes",
    image: "sampleExam",
    path: "/admin/kortingscodes",
    button: "BEKIJK ALLE KORTINGSCODES"
}
let dashboardCard6 = {
    title: "Inschrijven",
    description: "Schrijf je medewerker(s) in voor een cursus",
    image: "register",
    path: "/inschrijven",
    button: "DIRECT INSCHRIJVEN"
}
let dashboardCard7 = {
    title: "Inschrijven",
    description: "Schrijf je in voor een cursus",
    image: "register",
    path: "/inschrijven",
    button: "DIRECT INSCHRIJVEN"
}
let dashboardCard8 = {
    title: "Cursus categorieën",
    description: "Een overzicht van de cursus categorieën ",
    image: "course",
    path: "/admin/categorieën",
    button: "BEKIJK ALLE CURSUS CATEGORIEËN"
}

export const dashboardCardsCompany = [dashboardCard1, dashboardCard2, dashboardCard3, dashboardCard6];
export const dashboardCardsFreelancer = [dashboardCard1, dashboardCard3, dashboardCard7];
export const dashboardCardsStudent = [dashboardCard1];
export const dashboardCardsAdmin = [dashboardCard8, dashboardCard5];