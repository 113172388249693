import { createActionsWithPrefix } from "../../helperfunctions/action";

export const AuthActions = createActionsWithPrefix("AUTH/", {
    RESET_DESTINATION: "RESET_DESTINATION",
    SET_DESTINATION: "SET_DESTINATION",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_ERROR: "LOGOUT_ERROR",
    SEND_EMAIL_REQUEST: "SENDEMAIL_REQUEST",
    SEND_EMAIL_SUCCESS: "SENDEMAIL_SUCCESS",
    SEND_EMAIL_ERROR: "SENDEMAIL_ERROR",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",
    SET_PASSWORD_REQUEST: "SET_PASSWORD_REQUEST",
    SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
    SET_PASSWORD_ERROR: "SET_PASSWORD_ERROR",
    REFRESH_TOKEN: "REFRESH_TOKEN",
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
    SET_ACCOUNTS: "SET_ACCOUNTS",
    RESET_ACCOUNTS: "RESET_ACCOUNTS",
    CHANGE_REFRESHTOKEN: "CHANGE_REFRESHTOKEN",
});