import { RouteComponentProps } from "react-router";
import { InvoiceService } from "../../services/invoice.service";
import { useEffect, useState } from "react";
import { Invoice } from "../../types/invoice";
import { parseMoney } from "../../helperfunctions/money";
import InvoicePaymentComponent from "./InvoicePaymentComponent";
import { PaymentService } from "../../services/payment.service";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import "./InvoicePaymentViaLink.css";
import { getLogo } from "../../helperfunctions/getLogo";
import LogoBox from "../Login/LogoBox";
import { useTextStyles } from "../../styles/text";

interface InvoiceParams {
    encodedInvoice?: string;
};

function decodeInvoiceNumber(encoded: string) {
    const decoded = Buffer.from(encoded, 'base64').toString('utf8');
    const dashPos = decoded.indexOf('-');

    let invoiceNumber = decoded.substring(4, 9);
    if (dashPos > 9) {
        invoiceNumber += '-' + decoded.substring(10, dashPos - 1);
    }

    return {
        invoiceYear: parseInt(decoded.substring(0, 4)),
        invoiceNumber: invoiceNumber,
        invoiceAmountRemaining: parseInt(decoded.substring(dashPos + 1)),
    };
}

const InvoicePaymentViaLinkContainer = ({ match }: RouteComponentProps<InvoiceParams>) => {
    const nullInvoice: Invoice = {
        invoiceId: "",
        amount: 0,
        paid: false,
        date: ""
    };
    const [invoice, setInvoice] = useState(nullInvoice);

    const logo = getLogo();
    const textClasses = useTextStyles();

    useEffect(() => {
        (async () => {
            if (match.params.encodedInvoice) {
                try {
                    const { invoiceYear, invoiceNumber } = decodeInvoiceNumber(match.params.encodedInvoice);
                    const inv = await InvoiceService.invoiceByNumber(invoiceYear, invoiceNumber);
                    setInvoice(inv);
                } catch (err) {
                    console.log('Error occured when fetching invoice');
                    setInvoice({
                        invoiceId: "",
                        amount: 0,
                        paid: false,
                        date: ""
                    });
                }
            }
        })();
    }, [match.params.encodedInvoice]);

    return (
        <Grid item xs={12}>
            <Card>
                <LogoBox logo={logo} type="top-right" />
                <CardContent>
                    <div className="invoiceDetails">
                        <Typography classes={{
                                root: `${textClasses.pageTitle}`
                            }}>BETALEN</Typography>
                        <div>
                            <span className="invoiceDetailsLabel">Factuurnummer:</span>
                            <span className="invoiceNumber">{invoice.invoiceId}</span>
                        </div>
                        <div>
                            <span className="invoiceDetailsLabel">Totaalbedrag:</span>
                            <span className="invoiceAmount">{parseMoney(invoice.amount)}</span>
                        </div>
                        <div>
                        {invoice.paid ? <span className='invoiceDetailsLabel'>Is betaald</span> : <></>}
                        </div>
                    </div>
                    {invoice.invoiceId && !invoice.paid && !invoice.pending ?
                        <InvoicePaymentComponent handleNext={(issuerId: string) => {
                            PaymentService.invoiceLink(invoice.invoiceId, "(factuur) " + invoice.invoiceId, issuerId, "factuur-betaald-emaillink").then((invoiceLink) => {
                                window.location.assign(invoiceLink);
                            });
                        }} />
                        : <div></div>}
                </CardContent>
            </Card>
        </Grid>
    );
}

export default InvoicePaymentViaLinkContainer;
