import React from "react"
import { Link } from "react-router-dom";
import { Grid, Button, makeStyles } from "@material-ui/core";

import StudentResults from "./StudentDetailResults";
import StudentPersonal from "./StudentDetailPersonal";
import { StudentDetail } from "../../types/student";
import { Role } from "../../types/enum";

interface StudentDetailProps {
    title?: string;
    studentDetail?: StudentDetail;
    loading: boolean;
    error?: any;
    role?: string;
    addRegisterStudent?: (student: StudentDetail) => void;
    resetRegisterState?: () => void;
}

const useStyles = makeStyles(theme => ({
    buttonMobile: {
        marginBottom: "1em",
    },
    colorContrast: {
        color: theme.palette.primary.main
    },
}))

const StudentDetailComponent = ({ title, studentDetail, loading, error, role, addRegisterStudent, resetRegisterState }: StudentDetailProps) => {
    const classes = useStyles()

    const handleRegister = () => studentDetail && addRegisterStudent && addRegisterStudent(studentDetail);

    return (
        <div style={{ marginBottom: "10px" }}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="center">
                        {role === Role.COMPANY &&
                            <Grid item>
                                <Link to="/medewerkers" style={{ textDecoration: "none", marginRight: "1em" }}>
                                    <Button variant='contained' className={classes.buttonMobile}>TERUG</Button>
                                </Link>
                            </Grid>}
                        <Grid item>
                            {(role === Role.COMPANY || role === Role.FREELANCER) &&
                                <Link to="/inschrijven" style={{ textDecoration: "none" }}>
                                    <Button className={classes.buttonMobile} onClick={() => {
                                        resetRegisterState && resetRegisterState();
                                        role === Role.COMPANY && handleRegister();
                                    }} variant="contained" color="secondary">DIRECT INSCHRIJVEN</Button>
                                </Link>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <StudentPersonal role={role} studentDetail={studentDetail} loading={loading} error={error} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <StudentResults courseResults={studentDetail && studentDetail.courseResults} loading={loading} error={error} />
                </Grid>
            </Grid>
        </div>
    );
};

export default StudentDetailComponent;
