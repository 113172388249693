import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../store";
import { Dispatch, bindActionCreators } from "redux";
import ConfirmationComponent from "./ConfirmationComponent";
import { RegisterState } from "../../../../types/register";
import { Role } from "../../../../types/enum";
import { CourseDetail } from "../../../../types/course";
import { RegisterCourse } from "../../../../types/registerCourse";
import { RegistrationPriceInfo } from "../../../../types/register";
import { getCourseDetail } from "../../../../store/registerCourse/actions";
import { getPrices, resetRegisterStateCompany, resetRegisterStateFreelancer } from "../../../../store/register/actions";
import { withRouter, RouteComponentProps } from "react-router";


interface ConfirmationContainerProps {
    course: RegisterCourse;
    courseDetail: CourseDetail;
    register: RegisterState;

    getPrices: (priceItems: RegistrationPriceInfo[]) => void
    getCourseDetail: (course: RegisterCourse, discountCode?: string) => void;
    handleConfirm: () => void;
    handleBack: () => void;
    resetRegisterStateCompany: () => void;
    resetRegisterStateFreelancer: () => void;

    discountString?: string;
    discountCode?: string;
}

const mapStateToProps = ({ register, registerCourse: { courseDetail, course }, discount: { discountCode, discountString } }: ApplicationState) => {
    return ({
        courseDetail,
        course,
        register,
        discountCode,
        discountString,
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getCourseDetail, getPrices, resetRegisterStateCompany, resetRegisterStateFreelancer }, dispatch);
};

const ConfirmationContainer = ({ discountString, location, course, courseDetail, register, getCourseDetail, handleConfirm, handleBack, getPrices, discountCode, resetRegisterStateCompany, resetRegisterStateFreelancer }: ConfirmationContainerProps & RouteComponentProps) => {
    const currentUser = location.pathname.includes("bedrijf") ? Role.COMPANY : Role.FREELANCER;
    
    useEffect(() => {
        if (currentUser === Role.FREELANCER) resetRegisterStateFreelancer();
        else resetRegisterStateCompany();
    }, [currentUser, resetRegisterStateFreelancer, resetRegisterStateCompany])

    useEffect(() => {
        getCourseDetail(course, discountCode);
    }, [course, getCourseDetail, discountCode]);

    useEffect(() => {
        let priceItems: RegistrationPriceInfo[] = []
        if (course.type?.courseId) {
            if (currentUser === Role.FREELANCER) {
                const hasBooks = !!register.registerFreelancerUserFormData?.freelancerBooks || false
                const registrationPriceInfo: RegistrationPriceInfo = {
                    courseId: course.type?.courseId,
                    studentBooks: [hasBooks],
                    discountCode: discountCode,
                    khnId: register.registerFreelancerUserFormData?.khnId
                }
                priceItems.push(registrationPriceInfo)            
            } else {
                const registrationPriceInfo: RegistrationPriceInfo = {
                    courseId: course.type?.courseId,
                    studentBooks: register.students ? register.students.map((student) => student.books || false) : [],
                    discountCode: discountCode,
                    khnId: register.registerCompanyData?.khnId
                }
                priceItems.push(registrationPriceInfo)            
            }
        }
        if (priceItems.length > 0) {
            getPrices(priceItems);
        }
    }, [register.registerFreelancerUserFormData, register.registerCompanyData, register.students, getPrices, course.type, discountCode, currentUser]);

    return (
        <ConfirmationComponent
            prices={register.prices}
            courseDetail={courseDetail}
            register={register}
            handleConfirm={handleConfirm}
            handleBack={handleBack}
            currentUser={currentUser}
            discountCode={discountCode}
            discountString={discountString}
        />
    );
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmationContainer));