import React, { useState } from "react"
import { Grid, Card, CardContent, makeStyles, Divider, Theme, CardActionArea, Typography, CardHeader, Button, useTheme, Tooltip } from "@material-ui/core";
import { useSpring, animated, config } from "react-spring";
import { FaChevronRight, FaCertificate, FaBook, FaGlobe } from "react-icons/fa";
import { RouteComponentProps, withRouter } from "react-router";

import Error from "../../FeedBack/Error";
import { LoadingContentInfoSmall } from "../../FeedBack/LoadingContent";
import TestExamModal from "../../Courses/TestExamModal"
import HomeworkModal from "../../Courses/HomeworkModal"
import "../Dashboard.css";
import { DashboardState } from "../../../types/dashboard";
import { SampleExamState } from "../../../types/sampleExam";
import { AssignmentState } from "../../../types/assignment";

const useStyles = makeStyles((theme: Theme) => ({
    action: {
        padding: theme.spacing(1)
    },
    card: {
        borderRadius: "12px",
        maxWidth: "fit-content",
        boxShadow: "2px 10px 50px rgba(0, 0, 0, 0.16)",
        margin: theme.spacing(2.5),
        [theme.breakpoints.up("xs")]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down("lg")]: {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(1),
    },
    data: {
        marginTop: "auto",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("lg")]: {
            marginLeft: theme.spacing(1),
        },
    },
    description: {
        marginTop: theme.spacing(2),
    },
    image: {
        width: "100px"
    },
    upcomingCourseItem: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    button: {
        marginLeft: "1em",
        backgroundColor: "#f7f8f9",
        textAlign: "center",
        textTransform: "none",
    },
}));

interface UpcomingCoursesProps {
    dashboard: DashboardState;
    testExams: SampleExamState;
    assignment: AssignmentState;
    getTestExamsByCategoryId: (categoryId: number, projectId?: number) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
    getAssignments: (variables: any) => void;
}

const UpcomingCourses = ({ dashboard, getTestExamsByCategoryId, testExams, history, getAssignments, assignment, downloadAssignment }: RouteComponentProps<{}> & UpcomingCoursesProps) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);
    const theme: Theme = useTheme();
    const [openTestExamModal, setOpenTestExamModal] = useState<boolean>(false);
    const [openHomeworkModal, setOpenHomeworkModal] = useState<boolean>(false);
    const [courseName, setCourseName] = useState<string>("");

    const props = useSpring({
        from: { opacity: 0, },
        to: { opacity: 1, },
        delay: 900,
        config: config.default
    });
    const iProps = useSpring({
        to: { transform: hover ? "translateX(3px)" : "translateX(0px)", marginLeft: "5px" },
        config: config.wobbly
    });
    const propsHover = useSpring({
        to: { transform: hover ? " scale(1.01) translateY(-3px)" : " scale(1) translateY(0px)", },
        config: config.wobbly
    });
    const navigateToPage = (path: string) => {
        history.push(path);
    };
    return (
        <animated.div style={props}>
            <animated.div style={propsHover}>
                <Card className={classes.card}>
                    <CardHeader title={"Geplande cursussen"} />
                    <Divider />
                    <CardContent>
                        {(dashboard.loading) &&
                            <LoadingContentInfoSmall />
                        }
                        {(!dashboard.loading && dashboard.error) &&
                            <Error />
                        }
                        {(!dashboard.loading && !dashboard.error && dashboard) &&
                            <Grid item>
                                {dashboard.upcomingCourses && dashboard.upcomingCourses.map((upcomingCourse, index) => (
                                    <Grid alignItems="center" container key={index} style={{ paddingTop: ".5em", paddingBottom: ".5em" }}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2">{upcomingCourse.courseName}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: ".5em" }}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography variant="subtitle2">{upcomingCourse.date}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid container>
                                                        {upcomingCourse.showSampleExam &&
                                                            <Grid item>
                                                                <Tooltip title="Proefexamens">
                                                                    <Button onClick={() => {
                                                                        setCourseName(upcomingCourse.courseName)
                                                                        setOpenTestExamModal(!openTestExamModal)
                                                                        upcomingCourse.categoryId && getTestExamsByCategoryId(upcomingCourse.categoryId, upcomingCourse.projectId)
                                                                    }} className={classes.button} variant="outlined"><FaCertificate color={theme.palette.secondary.main} /></Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        }
                                                        {upcomingCourse.showHomework &&
                                                            <Grid item >
                                                                <Tooltip title="Huiswerk">
                                                                    <Button onClick={() => {
                                                                        setCourseName(upcomingCourse.courseName)
                                                                        setOpenHomeworkModal(!openHomeworkModal)
                                                                        getAssignments(upcomingCourse.courseId)
                                                                    }} className={classes.button} variant="outlined"><FaBook color={theme.palette.secondary.main} /></Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        }
                                                        {upcomingCourse.eLearning && upcomingCourse.eLearningLink &&
                                                            <Grid item >
                                                                <Tooltip title="E-Learning">
                                                                    <Button onClick={() => { window.open(upcomingCourse.eLearningLink); 
                                                                    }} className={classes.button} variant="outlined"><FaGlobe color={theme.palette.secondary.main} /></Button>
                                                                </Tooltip>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} style={{ paddingTop: ".5em" }}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                ))}
                                {dashboard.upcomingCourses.length < 1 &&
                                    <Typography variant="subtitle1">Er staan geen cursussen gepland</Typography>
                                }
                            </Grid>
                        }
                    </CardContent>
                    <CardActionArea onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={navigateToPage.bind(undefined, "/cursussen")} className={classes.action}>
                        <Grid container justify="space-between">
                            <Typography variant="button">BEKIJK ALLE GEPLANDE CURSUSSEN</Typography>
                            <animated.span style={iProps}><FaChevronRight size="1.5em" /></animated.span>
                        </Grid>
                    </CardActionArea>
                </Card>
            </animated.div>
            <TestExamModal course={courseName} testExams={testExams} open={openTestExamModal} handleClose={() => setOpenTestExamModal(false)} />
            <HomeworkModal downloadAssignment={downloadAssignment} open={openHomeworkModal} assignment={assignment} handleClose={() => setOpenHomeworkModal(false)} />
        </animated.div >
    )
}

export default withRouter(UpcomingCourses);