import React from "react";
import { SampleExamLog } from "../../../types/sampleExamLog";
import { CardContent, Grid, Paper, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import MaterialTable from "material-table";
import { materialTableLocalization } from "../../../constants/materialTableLocalization";

interface SampleExamLogComponentProps {
    sampleExamLogs: SampleExamLog[];
    loading: boolean;
    error?: string;
}

const SampleExamLogComponent = ({ error, sampleExamLogs, loading }: SampleExamLogComponentProps) => {
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container >
            <Grid item xs={12}>
                <Paper style={{ marginTop: "1em" }}>
                    <CardContent>
                        <MaterialTable
                            style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "300px" : "none" }}
                            columns={[
                                { title: "Id", field: "sampleExamLogId", type: "numeric", align: "left", defaultSort: "desc" },
                                { title: "Datum", field: "entryDate", type: "string" },
                                { title: "Cursistnr", field: "cursistNr", type: "numeric", align: "left" },
                                { title: "ProefexamenId", field: "sampleExamId", type: "numeric", align: "left" },
                                { title: "Aantal vragen", field: "amountOfQuestions", type: "numeric", align: "left" },
                                { title: "Aantal goed", field: "amountCorrect", type: "numeric", align: "left" },
                                { title: "Cijfer", field: "grade", type: "numeric", align: "left" }
                            ]}
                            options={{
                                pageSize: 10,
                                pageSizeOptions: [5, 10, 20, 50, 100],
                            }}
                            localization={materialTableLocalization}
                            data={sampleExamLogs}
                            title="Proefexamenlog" />
                    </CardContent>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SampleExamLogComponent;