const messages = {
    MISSING_FILE: "U heeft nog geen bestand geselecteerd",
    FILE_NOT_FOUND: "Geen bestanden gevonden",
    DELETE_STARTED: "De verwijdering is gestart, dit kan even duren.",
    DELETE_SUCCESSFULL: "De verwijdering is successvol afgerond.",
    DELETE_ERROR: "Sorry, er is iets mis gegaan tijdens het verwijderen. Probeer het later nog eens.",
    UPDATE_STARTED: "De update is gestart, dit kan even duren.",
    UPDATE_SUCCESSFULL: "De update is successvol afgerond",
    UPDATE_ERROR: "Sorry, er is iets mis gegaan tijdens het updaten. Probeer het later nog eens.",
    UPLOAD_STARTED: "De upload is gestart, dit kan even duren.",
    UPLOAD_SUCCESSFULL: "De upload is successvol afgerond",
    UPLOAD_ERROR: "Sorry, er is iets mis gegaan tijdens het uploaden. Probeer het later nog eens.",
    CONNECTION_TIMED_OUT_LOGIN: "Er is een fout opgetreden bij het inloggen. Probeer het later nog eens.",
    DOWNLOAD_START: "Uw download is onderweg, dit kan even duren.",
    ASSINGMENT_RETRIEVED: "Er wordt gecheckt of er gelinkte bestanden zijn.",
    INVALID_CREDENTIALS: "Onjuiste Inloggegevens",
    ADDRESS_WRONG_INPUT: "Deze combinatie van postcode en adres klopt niet. Probeer een juiste combinatie.",
    COULD_NOT_DOWNLOAD: "Er is iets mis gegaan met het downloaden van dit bestand. Probeer het later nog eens.",
    SOMETHING_WENT_WRONG: "Sorry, er is iets misgegaan",
    INVALID_USERNAME: "Deze gebruikersnaam is bij ons onbekend.",
    FORGOT_PASSWORD_REQUEST_SUCCESS: "Aanvraag successvol, controleer uw email.",
    SET_PASSWORD: "Wachtwoord ingesteld, u kunt nu inloggen met uw gegevens",
    INVALID_DISCOUNT: "Deze kortingscode is ongeldig",
    NO_ISSUER: "U heeft nog geen bank geselecteerd",
    WRONG_PASSWORD: "Uw wachwoord is incorrect",
    NO_ISSUER_SELECTED: "U heeft nog geen bank geselecteerd",
    KHN_ID_UNKNOWN: "Dit KHN accountnummer kennen wij niet",
    KHN_ID_IS_NUMBER: "Het KHN accountnummer moet een nummer zijn",
    KHN_DATA_RECEIVED: "De KHN data is ontvangen. U ontvangt een mail als de data verwerkt is",
    METADATA_NOT_FOUND: "De metadata kon niet worden opgehaald",
};

export default messages;