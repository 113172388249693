import { Dispatch } from "redux";

import { AssignmentService } from "../../services/assignment.service";
import { AssignmentActions } from "./types";
import { InputAssignment, ParsedInputAssignment } from "../../types/assignment";
import { toBase64, downloadFile } from "../../helperfunctions/files";
import messages from "../../constants/messages";
import { toast } from "react-toastify";

export const getAssignmentsByCourseId = (courseId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: AssignmentActions.ASSIGNMENTS_BY_COURSE_REQUEST });
        const response = await AssignmentService.assignmentsByCourseId(courseId);
        dispatch({ type: AssignmentActions.ASSIGNMENTS_BY_COURSE_SUCCESS, response });
    } catch (error) {
        dispatch({ type: AssignmentActions.ASSIGNMENTS_BY_COURSE_ERROR, error });
    }
};

export const deleteAssignment = (assignmentId: number) => async (dispatch: Dispatch) => {
    try {
        await AssignmentService.deleteAssignment(assignmentId);
        dispatch({ type: AssignmentActions.DELETE_SUCCESS, assignmentId });
        toast.success(messages.DELETE_SUCCESSFULL)
    } catch (error) {
        toast.error(messages.DELETE_ERROR)
    }
};

export const downloadAssignment = (assignmentId: number, name: string) => async (dispatch: Dispatch) => {
    try {
        toast.info(messages.DOWNLOAD_START)
        const response = await AssignmentService.assignmentFileByAssignmentId(assignmentId);

        if (response) downloadFile(response, name, "docx");
        else toast.error(messages.COULD_NOT_DOWNLOAD)
    } catch (error) {
        toast.error(messages.COULD_NOT_DOWNLOAD)
    }
};

export const uploadAssignment = (assignment: InputAssignment) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: AssignmentActions.UPLOAD_REQUEST });
        toast.info(messages.UPLOAD_STARTED)

        let content: string = "";
        if (typeof assignment.content === "string") content = assignment.content;
        else if (assignment.content) content = await toBase64(assignment.content as File);
        else toast.error(messages.MISSING_FILE)

        const newAssignment: ParsedInputAssignment = { ...assignment, content };
        const response = await AssignmentService.createAssignment(newAssignment);

        if (response) {
            toast.success(messages.UPLOAD_SUCCESSFULL)
            dispatch({
                type: AssignmentActions.UPLOAD_SUCCESS, assignment: {
                    assignmentId: response,
                    name: newAssignment.name,
                    showOnHSN: newAssignment.showOnHSN,
                    showOnHOC: newAssignment.showOnHOC,
                    showOnSH: newAssignment.showOnSH,
                }
            });
        } else {
            toast.error(messages.UPLOAD_ERROR)
            dispatch({ type: AssignmentActions.UPLOAD_ERROR });
        }
    } catch (error) {
        toast.error(messages.UPLOAD_ERROR)
        dispatch({ type: AssignmentActions.UPLOAD_ERROR });
    }
};

export const editAssignment = (assignmentId: number, assignment: InputAssignment) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: AssignmentActions.EDIT_REQUEST });
        toast.info(messages.UPDATE_STARTED)

        let content: string = "";
        if (typeof assignment.content === "string") content = assignment.content;
        else if (assignment.content) content = await toBase64(assignment.content as File);
        else toast.error(messages.MISSING_FILE)

        const edittedAssignment: ParsedInputAssignment = { ...assignment, content };
        const success = await AssignmentService.updateAssignment(assignmentId, edittedAssignment);

        if (success) {
            toast.success(messages.UPDATE_SUCCESSFULL)
            dispatch({
                type: AssignmentActions.EDIT_SUCCESS, assignment: {
                    assignmentId: assignmentId,
                    name: edittedAssignment.name,
                    showOnHSN: edittedAssignment.showOnHSN,
                    showOnHOC: edittedAssignment.showOnHOC,
                    showOnSH: edittedAssignment.showOnSH,
                    content: edittedAssignment.content
                }
            });
        } else {
            toast.error(messages.UPDATE_ERROR)
            dispatch({ type: AssignmentActions.EDIT_ERROR });
        }
    } catch (e) {
        toast.error(messages.UPDATE_ERROR)
        dispatch({ type: AssignmentActions.EDIT_ERROR });
    }
};

export const getAssignmentFile = (assignmentId: number) => async (dispatch: Dispatch) => {
    try {
        toast.info(messages.ASSINGMENT_RETRIEVED)
        const fileUrl = await AssignmentService.assignmentFileByAssignmentId(assignmentId);

        if (fileUrl) dispatch({ type: AssignmentActions.GET_FILE_SUCCESS, fileUrl });
        else toast.error(messages.FILE_NOT_FOUND)
    } catch (error) {
        toast.error(messages.FILE_NOT_FOUND)
    }
};