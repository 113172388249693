import React from "react"
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RouteComponentProps } from "react-router";
import { forgotPasswordResetPassword } from "../../store/auth/actions";

import ConfigurePassword from "./ConfigurePassword";
import { ApplicationState } from "../../store";

interface ConfigurePasswordContainerProps {
    forgotPasswordResetPassword: (token?: string, newPassword?: string) => void;
    loginError: string
    loading: boolean
};

interface MatchParams {
    token: string;
}

const mapStateToProps = ({ auth }: ApplicationState) => {
    const loginError = auth.error;
    const loading = auth.loading;
    return { loginError, loading };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ forgotPasswordResetPassword }, dispatch);
};

const ConfigurePasswordContainer = ({ match, loginError, loading, forgotPasswordResetPassword, location }: ConfigurePasswordContainerProps & RouteComponentProps<MatchParams>) => {
    return (
        <ConfigurePassword 
            loading={loading}
            loginError={loginError}
            handleChangePassword={(newPassword?: string) => forgotPasswordResetPassword(match.params.token, newPassword)}
            title={location.pathname === `/wachtwoord-instellen/${match.params.token}` ? "Wachtwoord instellen" : "Wachtwoord veranderen"}
        />
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurePasswordContainer);