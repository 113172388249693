import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { uploadAgreementFile, downloadAgreement, getUserAgreementFileName } from "../../../store/register/actions"
import AgreementComponent from "./AgreementComponent";
import { ApplicationState } from "../../../store";

interface AgreementContainerProps {
    uploadAgreementFile: (file?: File[]) => void;
    downloadAgreement: () => void;
    getUserAgreementFileName: () => void;
    
    userAgreementFileName?: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ uploadAgreementFile, downloadAgreement, getUserAgreementFileName }, dispatch);
};

const mapStateToProps = ({ register: { userAgreementFileName } }: ApplicationState) => {
    return ({
        userAgreementFileName
    });
};

const AgreementContainer = ({ uploadAgreementFile, downloadAgreement, userAgreementFileName, getUserAgreementFileName }: AgreementContainerProps) => {
    useEffect(() => {
        getUserAgreementFileName();
    }, [getUserAgreementFileName]);

    return (
        <AgreementComponent
            downloadAgreement={downloadAgreement}
            uploadFile={uploadAgreementFile}
            userAgreementFileName={userAgreementFileName}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementContainer);