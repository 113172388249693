import { Grid, useMediaQuery, Theme, useTheme, Card, Typography, Button, makeStyles } from "@material-ui/core";
import MaterialTable from "material-table";
import { FaCheck } from "react-icons/fa";

import { LoadingTable } from "../FeedBack/LoadingContent";
import Error from "../FeedBack/Error";
import { Invoice } from "../../types/invoice";
import "./Invoices.css"
import { materialTableLocalizationAction } from "../../constants/materialTableLocalization";
import { parseMoney } from "../../helperfunctions/money";
import { RouteComponentProps, withRouter } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
	colorContrast: {
		color: theme.palette.primary.main
	},
	linkButton: {
		"&:hover": {
			textDecoration: "underline",
		},
	}
}));

interface InvoiceComponentProps {
	getInvoicePDF: (invoiceId: string) => void;
	invoices: Invoice[];
	loading: boolean;
	loadingPDF: boolean;
	error?: any;
	setSelectedInvoice: (invoice: Invoice) => void;
}

type Props = RouteComponentProps<{}> & InvoiceComponentProps;

const InvoiceComponent = ({ history, invoices, loading, error, getInvoicePDF, loadingPDF, setSelectedInvoice }: Props) => {
	const classes = useStyles();
	const theme: Theme = useTheme();
	const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

	const navigateToPage = (invoice?: Invoice) => {
		if (!invoice) return;

		setSelectedInvoice(invoice);
		history.push({
			pathname: `/factuur-betalen/${invoice.invoiceId}`,
		});
	};

	return (
		<Grid container direction="column">
			{loading && <Grid item xs={12}> <LoadingTable /></Grid>}
			{error && <Error />}
			{(!loading && !error && invoices) &&
				<Card>
					{invoices.length === 0 ?
						<Typography style={{ padding: 50 }}>Geen facturen gevonden.</Typography>
						:
						<MaterialTable
							title="Facturen"
							style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "350px" : "none" }}
							columns={[
								{
									field: 'paid',
									title: 'Status betaling',
									render: (rowData: Invoice) => rowData.paid ? <FaCheck color="green" /> :
										rowData.pending ?
											<Button className={classes.colorContrast} disabled={true}>
												<Typography variant="overline" className={classes.linkButton}>IN BEHANDELING</Typography>
											</Button>
											:
											<Button onClick={(e) => navigateToPage(rowData)} variant="contained" color="secondary" id="login-login-button">
												<Typography variant="button" style={{ color: "#fff" }}>
													BETALEN
											</Typography>
											</Button>
								},
								{ title: "Factuurnummer", field: "invoiceId" },
								{ title: "Factuurdatum", field: "date" },
								{ title: "Te betalen voor", field: "paymentDate" },
								{ title: "Betreft", field: "description" },
								{ title: "Factuurbedrag", field: "amount", render: rowData => <Typography>{parseMoney(rowData.amount)}</Typography> },
							]}
							data={invoices}
							options={{
								sorting: true,
								pageSize: 10,
								actionsColumnIndex: -1,
								headerStyle: {
									color: "gray"
								}
							}}
							localization={materialTableLocalizationAction}
							actions={[
								{
									icon: "cloud_download",
									iconProps: { color: "secondary" },
									tooltip: "Factuur downloaden",
									onClick: (event, rowData) => {
										if (!Array.isArray(rowData)) {
											getInvoicePDF(rowData.invoiceId);
										}
									}
								}
							]}
						/>
					}
				</Card>
			}
		</Grid>
	)
};

export default withRouter(InvoiceComponent);
