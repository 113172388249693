import React from "react";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm, Field, getFormValues } from "redux-form";
import { Grid, Typography, Button, makeStyles, Theme, Divider, Paper, MenuItem } from "@material-ui/core";

import { required, isEmail, isPhone, validString, validDate } from "../../../validators/form";
import { UserInformation } from "../../../types/userInformation";
import RenderDatePicker from "../../TextField/RenderDatePicker";
import { useButtonStyles } from "../../../styles/button";
import { StudentDetail } from "../../../types/student";
import { useTextStyles } from "../../../styles/text";
import { ApplicationState } from "../../../store";
import RenderTextField from "../../TextField";
import { Role } from "../../../types/enum";
import { trimFields } from "../../../helperfunctions/trimFields";
import { useHistory } from "react-router";
import AutofillComponent from "../../../helperfunctions/AutofillComponent";
import CustomPrompt from "../../notification/CustomPrompt";
import RenderTextFieldDropdown from "../../TextField/TextFieldDropdown";
import { getWebsiteEmail } from "../../../helperfunctions/getWebsite";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    }
}));

interface EditStudentProps {
    formValues: any;
    requestEdit: (userInformation: UserInformation) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
}

const EditStudentComponent = ({ valid, handleSubmit, formValues, getAddress, requestEdit }: EditStudentProps & InjectedFormProps<StudentDetail, EditStudentProps>) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const textClasses = useTextStyles();
    const history = useHistory();

    return (
        <Grid container direction="column">
            <Grid item xs={12} className={classes.title}>
                <Typography classes={{
                    root: `${textClasses.pageTitle}`
                }}>Wijzig persoonlijke gegevens</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <form onSubmit={handleSubmit((userInformationInput: StudentDetail): void => {
                        userInformationInput = trimFields(userInformationInput)
                        let userInformation: UserInformation = { student: userInformationInput };
                        requestEdit(userInformation);
                    })}>
                        <CustomPrompt when={!valid} />
                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Persoonsgegevens</Typography>
                                <Typography style={{ color: "#FF0000"}}>
                                    Let op: Deze pagina is uitsluitend bedoeld om ontbrekende of onjuiste persoonsgegevens van een bestaande cursist aan te vullen of te wijzigen en niet om een cursist te wijzigen. Daarvoor kan contact worden opgenomen via {getWebsiteEmail()}
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Grid container direction="column" justify="center">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>GESLACHT</Typography>
                                        <Field
                                            name="sex"
                                            validate={[validString, required]}
                                            component={RenderTextFieldDropdown}
                                        >
                                            <MenuItem value={"H"} >De heer</MenuItem>
                                            <MenuItem value={"M"} >Mevrouw</MenuItem>
                                        </Field>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORLETTERS</Typography>
                                    <Field
                                        name="initials"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORNAAM</Typography>
                                    <Field
                                        name="firstName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TUSSENVOEGSEL</Typography>
                                    <Field
                                        name="prefix"
                                        variant="outlined"
                                        placeholder="Optioneel"
                                        validate={[validString]}
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>ACHTERNAAM</Typography>
                                    <Field
                                        name="lastName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>GEBOORTEDATUM</Typography>
                                    <Field
                                        name="birthDate"
                                        placeholder="Verplicht"
                                        validate={[validString, required, validDate]}
                                        component={RenderDatePicker}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>GEBOORTEPLAATS</Typography>
                                    <Field
                                        name="birthPlace"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Contactgegevens</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>EMAIL</Typography>
                                    <Field
                                        name="email"
                                        variant="outlined"
                                        validate={[validString, required, isEmail]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TELEFOON MOBIEL</Typography>
                                    <Field
                                        name="mobileNumber"
                                        variant="outlined"
                                        validate={[validString, required, isPhone]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TELEFOON PRIVE</Typography>
                                    <Field
                                        name="privateNumber"
                                        variant="outlined"
                                        validate={[validString, isPhone]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Adresgegevens</Typography>
                                <Divider />
                            </Grid>
                            <AutofillComponent identifier="EDIT_STUDENT" formValues={formValues} getAddress={getAddress} />
                            <Grid container justify="space-between">
                                <Button
                                    onClick={() => history.push('/persoonlijke-gegevens')}
                                    classes={{
                                        root: buttonClasses.primary,
                                    }}
                                >
                                    Annuleren
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    disabled={!valid}
                                    type="submit"
                                    classes={{
                                        root: buttonClasses.primary,
                                    }}
                                >
                                    Opslaan
                            </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
};

const DecoratedInitializeFromStateFormFunction = reduxForm<StudentDetail, EditStudentProps>({
    form: "EditStudentDataForm",
    enableReinitialize: true,
})(EditStudentComponent);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let studentDetail = (state.auth.role === Role.COMPANY) ? state.student.studentDetail : state.userInformation.userInformation.student;
        studentDetail = trimFields(studentDetail);

        return ({
            formValues: getFormValues("EditStudentDataForm")(state),
            initialValues: studentDetail,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;