import React from "react"
import { Grid, Button, Typography, makeStyles, Theme } from "@material-ui/core";
import { useDropzone } from 'react-dropzone';
import { getWebsite } from "../../../helperfunctions/getWebsite";

const useStyles = makeStyles((theme: Theme) => ({
    colorContrast: {
        color: theme.palette.primary.main,
        float: "right"
    },
    linkButton: {
        "&:hover": {
            textDecoration: "underline",
        },
    }
}));

interface AgreementComponentProps {
    uploadFile: (file?: File[]) => void;
    downloadAgreement: () => void;
    userAgreementFileName?: string;
}

const AgreementComponent = ({ uploadFile, downloadAgreement, userAgreementFileName }: AgreementComponentProps) => {
    const classes = useStyles();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const lastFile = acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[acceptedFiles.length - 1]
    const file = lastFile && <div key={lastFile.name}>{lastFile.name}</div>

    return (
        <div>
            <Grid alignItems="center" container>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">{`Upload hier de actuele studievoorwaarden voor ${getWebsite()}`}</Typography>
                </Grid>
            </Grid>
            {userAgreementFileName && <Grid alignItems="center" container>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2">{`Huidige voorwaarden: ${userAgreementFileName}.pdf`}</Typography>
                </Grid>
            </Grid>}
            <Grid item xs={12} style={{ marginTop: "1em" }}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ border: "2px dotted #e3e3e3", backgroundColor: "#f7f7f7", cursor: "pointer" }}
                    {...getRootProps({ className: 'dropzone' })}
                >
                    <input {...getInputProps()} />
                    <p style={{ margin: "3em" }}>Sleep een bestand, of klik op een bestand te selecteren</p>
                </Grid>
            </Grid>
            {file && <aside style={{ marginTop: "1em" }}>
                <Typography variant="overline">Bestand:</Typography>
                {file}
            </aside>}
            <Button onClick={(e) => uploadFile(acceptedFiles)} variant="contained" color="secondary" id="login-login-button">
                <Typography variant="button" style={{ color: "#fff" }}>
                    UPLOADEN
                </Typography>
            </Button>
            <Button onClick={(e) => downloadAgreement()} className={classes.colorContrast}>
                <Typography variant="overline" className={classes.linkButton}>
                    DOWNLOAD DE HUIDIGE VOORWAARDEN
                </Typography>
            </Button>
        </div>
    );
};

export default AgreementComponent;
