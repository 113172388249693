import { getWebsite } from "./getWebsite";
import { Website } from "../types/enum";
import hsnTheme from "../themes/HSNTheme";
import shTheme from "../themes/SHTheme";
import hocTheme from "../themes/HOCTheme";

export const getTheme = () => {
    switch (getWebsite()) {
        case Website.HSN:
            return hsnTheme;
        case Website.SH:
            return shTheme;
        case Website.HOC:
            return hocTheme;
        default:
            return hsnTheme;
    }
}