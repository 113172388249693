import IssuersContainer from "../Issuers/IssuersContainer";

interface InvoicePaymentProps {
    handleNext: (issuerId: string) => void;
}

const InvoicePaymentComponent = ({ handleNext }: InvoicePaymentProps) => {
	return (
		<IssuersContainer setOpen={() => null} setSelectedIssuer={() => null} handleNext={(issuerId: string) => handleNext(issuerId)} />
	)
};

export default InvoicePaymentComponent;