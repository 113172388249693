import React, { useState } from "react";
import { FaInfo } from "react-icons/fa";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

const UserDataTooltip = () => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div style={{ cursor: "pointer", display: "inline"}}>
            <FaInfo onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Persoonsgegevens</DialogTitle>
                <DialogContent >
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Sluiten</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default UserDataTooltip;