import graphqlHelperService from "./graphqlHelper.service";
import { Assignment, ParsedInputAssignment } from "../types/assignment";

export const AssignmentService = {
    assignmentsByCourseId: async (courseId: string): Promise<Assignment[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            assignmentsByCourseIdQuery(courseId)
        );
        return apolloResult.assignmentsByCourseId as Assignment[];
    },
    assignmentFileByAssignmentId: async (assignmentId: number): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            assignmentFileByAssignmentId(assignmentId)
        );
        return apolloResult.assignmentFile as string;
    },
    createAssignment: async (assignment: ParsedInputAssignment): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            createAssignmentQuery(assignment)
        );
        return apolloResult.createAssignment as string;
    },
    updateAssignment: async (assignmentId: number, assignment: ParsedInputAssignment): Promise<Assignment> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateAssignmentQuery(assignmentId, assignment)
        );
        return apolloResult.updateAssignment as Assignment;
    },
    deleteAssignment: async (assignmentId: number): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            deleteAssignmentQuery(assignmentId)
        );
        return apolloResult.deleteAssignment as boolean;
    }
}

const assignmentsByCourseIdQuery = (courseId: string) => `query{
    assignmentsByCourseId(courseId: "${courseId}"){
        assignmentId
        name
        showOnHSN
        showOnHOC
        showOnSH
    }
}`;

const assignmentFileByAssignmentId = (assignmentId: number) => `query{
    assignmentFile(assignmentId: ${assignmentId})
}`;

const createAssignmentQuery = (assignment: ParsedInputAssignment) => `mutation {
    createAssignment(
        assignment: {
            courseId: "${assignment.courseId}"
            name: "${assignment.name}"
            showOnHSN: ${assignment.showOnHSN}
            showOnHOC: ${assignment.showOnHOC}
            showOnSH: ${assignment.showOnSH}
            content: "${assignment.content}"
        }
    )
}`;

const updateAssignmentQuery = (assignmentId: number, assignment: ParsedInputAssignment) => `mutation {
    updateAssignment(
        assignment: {
            assignmentId: ${assignmentId},
            courseId: "${assignment.courseId}"
            name: "${assignment.name}"
            showOnHSN: ${assignment.showOnHSN}
            showOnHOC: ${assignment.showOnHOC}
            showOnSH: ${assignment.showOnSH}
            content: "${assignment.content}"
        }
    )
}`;

const deleteAssignmentQuery = (assignmentId: number) => `mutation{
    deleteAssignment(assignmentId: ${assignmentId})
}`;