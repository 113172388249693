import { Grid, Typography, makeStyles, Theme, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    data: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        backgroundColor: "#f0f0f0"
    },
    dataText: {
        display: "flex",
        alignItems: "center",
    },
    row: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    subtitle: {
        marginTop: theme.spacing(2),
    }
}));

const Details = ({ data }: any) => {
    const classes = useStyles();

    return <Grid container className={classes.data}>
        {data.map(({ title, elements }: any) => <>
            <Grid item xs={12} className={classes.subtitle}>
                <Typography variant="h6">{title}</Typography>
            </Grid>
            {elements.map(({ name, data }: any) => data ? <Grid item xs={12} lg={6} className={classes.row}>
                <Grid container>
                    <Grid item xs={12} lg={5}>
                        <Typography style={{ fontWeight: "bold" }} variant="body2">{name}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={7} className={classes.dataText}>
                        <Tooltip title={data} placement="bottom">
                            <Typography variant="body2" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{data}</Typography>
                        </Tooltip>
                    </Grid>
                </Grid> 
            </Grid>: null)}
        </>)}
    </Grid>
};

export default Details;