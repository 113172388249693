import graphqlHelperService from "./graphqlHelper.service";
import { Discount } from "../types/discount";

export const discountService = {
    checkDiscount: async (discountCode: string): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            checkDiscountQuery(discountCode)
        )
        return apolloResult.checkDiscount as string;
    },
    discounts: async (): Promise<Discount[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            discountsQuery()
        )
        return apolloResult.discounts as Discount[];
    },
    createDiscount: async (discount: Discount): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            createDiscountMutation(discount)
        );
        return apolloResult.createDiscount as boolean;
    },
    updateDiscount: async (discount: Discount): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateDiscountMutation(discount)
        );
        return apolloResult.updateDiscount as boolean;
    },
    deleteDiscount: async (discountCode: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            deleteDiscountMutation(discountCode)
        );
        return apolloResult.deleteDiscount as boolean;
    },
}

const checkDiscountQuery = (discountCode: string) => `query{
    checkDiscount(discountCode: "${discountCode}")
}`

const discountsQuery = () => `query{
    discounts{
        discountCode
        startDate
        endDate
        extra
        amount
        percentage
        active
        message
    }
}`

const createDiscountMutation = (discount: Discount) => `mutation{
    createDiscount(
        discount: {
            discountCode: "${discount.discountCode}"
            startDate: "${discount.startDate}"
            endDate: "${discount.endDate}"
            extra: "${discount.extra}"
            percentage: ${discount.percentage || 0}
            amount: ${discount.amount || 0}
            active: ${!!discount.active}
            message: "${discount.message}"
        }
    )
}`

const deleteDiscountMutation = (discountCode: string) => `mutation{
    deleteDiscount(
        discountCode: "${discountCode}"
    )
}`

const updateDiscountMutation = (discount: Discount) => `mutation{
    updateDiscount(
        discount: {
            discountCode: "${discount.discountCode}"
            startDate: "${discount.startDate}"
            endDate: "${discount.endDate}"
            extra: "${discount.extra}"
            percentage: ${discount.percentage || 0}
            amount: ${discount.amount || 0}
            active: ${!!discount.active}
            message: "${discount.message}"
        }
    )
}`
