import { Website } from "../types/enum"

export const getWebsite = (): string => {
    let website: string = "";
    if (window && "location" in window && "protocol" in window.location && "host" in window.location) {
        website = window.location.host;
    }

    if (website.indexOf('hoc') !== -1) return Website.HOC;
    if (website.indexOf('sh') !== -1) return Website.SH;
    return Website.HSN;
}

export const getWebsiteString = (): string => {
    let website = getWebsite();

    if (website === Website.HOC) return "Horeca Opleidingscentrum";
    if (website === Website.SH) return "Sociale-Hygiene.nl";
    return "Horeca Stichting Nederland";
}

export const getWebsiteStopRegisterWarning = (): string => {
    const currentWebsite = getWebsite();
    if (currentWebsite === Website.HOC)
        return "Als je de pagina sluit, wordt de inschrijving niet opgeslagen. Wil je graag eerst nog persoonlijk advies, bel ons op 085 – 202 66 66 of stuur je vraag naar info@hoc.nl"
    else if (currentWebsite === Website.SH)
        return "Als je de pagina sluit, wordt de inschrijving niet opgeslagen. Wil je graag eerst nog persoonlijk advies, bel ons op 076 - 513 80 45 of stuur je vraag naar info@sh.nl";
    else
        return "Als je de pagina sluit, wordt de inschrijving niet opgeslagen. Wil je graag eerst nog persoonlijk advies, bel ons op 076 - 523 36 66 of stuur je vraag naar info@hsn.nl"
}

export const getWebsiteEmail = (): string => {
    let website = getWebsite(); 
    
    if (website === Website.HOC) return "info@hoc.nl";
    if (website === Website.SH) return "info@sh.nl";
    return "info@hsn.nl";
}
