import { TestExamActions } from "./types";
import { SampleExamState } from "../../types/sampleExam";
import { QuestionFilledInput, Question } from "../../types/question";

const initialState: SampleExamState = {
    projectId: undefined,
    examDetail: undefined,
    examAnswers: [],
    exams: [],
    examResult: undefined,
    loading: false,
    loadingTestExamById: false,
    error: undefined
};

export function testExamReducer(state = initialState, action: typeof TestExamActions): SampleExamState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case TestExamActions.GET_TESTEXAMS_BY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case TestExamActions.GET_TESTEXAMS_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                exams: action.response,
                projectId: action.projectId,
                loading: false
            };
        case TestExamActions.GET_TESTEXAMS_BY_CATEGORY_ERROR:
            return {
                ...state,
                loadingTestExamById: false,
                error: action.error
            };
        case TestExamActions.GET_TESTEXAM_BY_ID_REQUEST:
            return {
                ...state,
                loadingTestExamById: true,
                error: undefined
            };
        case TestExamActions.GET_TESTEXAM_BY_ID_SUCCESS:
            const examAnswers: Array<QuestionFilledInput> = action.response?.questions && action.response?.questions.map((q: Question) => { return { questionId: q.questionId } });
            return {
                ...state,
                examDetail: action.response,
                examAnswers,
                loadingTestExamById: false,
            };
        case TestExamActions.GET_TESTEXAM_BY_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case TestExamActions.SET_TESTEXAM_ANSWER:
            const { questionId, givenAnswerId } = action;

            return {
                ...state,
                examAnswers: state.examAnswers ? state.examAnswers.map(examAnswer => {
                    if (examAnswer.questionId === questionId) {
                        return {
                            questionId: examAnswer.questionId,
                            givenAnswerId: givenAnswerId
                        }
                    } else {
                        return examAnswer;
                    }
                }) : [],
            };
        case TestExamActions.RESET_TESTEXAM_ANSWERS:
            return {
                ...state,
                examAnswers: []
            };
        case TestExamActions.CHECK_TESTEXAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case TestExamActions.CHECK_TESTEXAM_SUCCESS:
            return {
                ...state,
                examResult: action.response,
                loading: false,
            };
        case TestExamActions.CHECK_TESTEXAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case TestExamActions.CREATE_TESTEXAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case TestExamActions.CREATE_TESTEXAM_SUCCESS:
            let newExams = state.exams || [];
            newExams.push(action.createdTestExam);
            return {
                ...state,
                loading: false,
                exams: newExams,
                error: undefined
            };
        case TestExamActions.CREATE_TESTEXAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case TestExamActions.UPDATE_TESTEXAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case TestExamActions.UPDATE_TESTEXAM_SUCCESS:
            return {
                ...state,
                loading: false,
                exams: state.exams ? state.exams.map(exam => exam.sampleExamId === action.sampleExam.sampleExamId ? { ...action.sampleExam, questions: exam.questions } : exam) : [],
                error: undefined
            };
        case TestExamActions.UPDATE_TESTEXAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case TestExamActions.DELETE_TESTEXAM_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case TestExamActions.DELETE_TESTEXAM_SUCCESS:
            return {
                ...state,
                loading: false,
                exams: state.exams ? state.exams.filter(exam => exam.sampleExamId !== action.sampleExamId) : [],
                error: undefined
            };
        case TestExamActions.DELETE_TESTEXAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
