import graphqlHelperService from "./graphqlHelper.service";
import { CourseType, AvailableDate, CourseCategory, } from "../types/registerCourse";
import { RegisterUserType } from "../types/enum";

export const registerCourseService = {
    categories: async (userType: RegisterUserType): Promise<CourseCategory[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            categoriesQuery(userType)
        );

        return apolloResult.categories as CourseCategory[];
    },
    courseTypes: async (categoryId: number, userType: RegisterUserType): Promise<CourseType[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            courseTypesQuery(categoryId, userType)
        );
        return apolloResult.courseTypes as CourseType[];
    },
    courseLocations: async (courseId: string): Promise<string[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            courseLocationsQuery(courseId)
        );
        return apolloResult.courseLocations as string[];
    },
    locationOptions: async (courseId: string, place: string): Promise<AvailableDate[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            locationOptions(courseId, place)
        );
        return apolloResult.locationOptions as AvailableDate[];
    },
    getCategoryRegisterViaLinkOnly: async (categoryId: number): Promise<Boolean> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            getCategoryRegisterViaLinkOnlyQuery(categoryId)
        );
        return apolloResult.categoryRegisterViaLinkOnly as boolean;
    },
    updateCategoryRegisterViaLinkOnly: async (categoryId: number, categoryRegisterViaLinkOnly: boolean): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateCategoryRegisterViaLinkOnlyMutation(categoryId, categoryRegisterViaLinkOnly)
        );
        return apolloResult.updateCategoryRegisterViaLinkOnly as boolean;
    }
}

const categoriesQuery = (userType: RegisterUserType) => `query{
    categories(userType: ${userType}, showHiddenCategories: true) {
        categoryId
        category
        registerViaLinkOnly
    }
}`;

const courseTypesQuery = (categoryId: number, userType: RegisterUserType) => `query{
    courseTypes (categoryId: ${categoryId}, userType: ${userType}) {
        courseId
        type
        hasBookDiscount
        eLearning
        eLearningHybrid
        priceIncludesBook
    }
}`;

const courseLocationsQuery = (courseId: string) => `query{
    courseLocations (courseId: "${courseId}")
}`;

const locationOptions = (courseId: string, place: string) => `query{
    locationOptions (courseId: "${courseId}", place: "${place}") {
        locationCode
        dates 
    }
}`;

const getCategoryRegisterViaLinkOnlyQuery = (categoryId: number) => `query{
    categoryRegisterViaLinkOnly(categoryId: ${categoryId})
}`;

const updateCategoryRegisterViaLinkOnlyMutation = (categoryId: number, categoryRegisterViaLinkOnly: boolean) => `mutation{
    updateCategoryRegisterViaLinkOnly(categoryId: ${categoryId}, categoryRegisterViaLinkOnly: ${categoryRegisterViaLinkOnly})
}`;
