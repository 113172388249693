import React, { useState, useEffect } from "react";
import { reduxForm, Field, InjectedFormProps, getFormValues } from "redux-form";
import { useSpring, animated, config } from "react-spring";
import { connect } from "react-redux";
import { Grid, Typography, Button, FormControlLabel, Checkbox, Box, Divider, MenuItem } from "@material-ui/core";

import RenderTextField from "../../../TextField";
import RenderDatePicker from "../../../TextField/RenderDatePicker";
import { required, isEmail, isPhone, passwordStrongEnough, validString, validDate } from "../../../../validators/form";
import { ApplicationState } from "../../../../store";
import { useButtonStyles } from "../../../../styles/button";
import { useTextStyles } from "../../../../styles/text";
import RenderCheckBox from "../../../TextField/RenderCheckBox";
import AutofillComponent from "../../../../helperfunctions/AutofillComponent";
import AutofillKHNComponent from "../../../../helperfunctions/AutofillKHNComponent";
import { getWebsite } from "../../../../helperfunctions/getWebsite";
import { Role, Website } from "../../../../types/enum";
import RenderTextFieldPasswordChecker from "../../../TextField/TextFieldBar";
import { trimFields } from "../../../../helperfunctions/trimFields";
import PasswordTooltip from "../../../../helperfunctions/PasswordTooltip";
import { getPortalName } from "../../../../helperfunctions/getPortalName";
import CustomPrompt from "../../../notification/CustomPrompt";
import RenderTextFieldLogin from "../../../TextField/TextFieldLogin";
import { studentService } from "../../../../services/student.service";
import { getRole } from "../../../../helperfunctions/localstorage";
import { authenticationService } from "../../../../services/auth.service";
import UserDataTooltip from "../../../../helperfunctions/UserDataTooltip";
import { isNil } from "lodash";
import RenderTextFieldDropdown from "../../../TextField/TextFieldDropdown";

export interface FreelancerUserFormData {
    khnId?: string;
    initials: string;
    address: string;
    sex: string;
    birthDate: Date;
    birthPlace: string;
    firstName: string;
    prefix: string;
    lastName: string;
    email: string;
    password: string;
    nameCompany: string;
    place: string;
    workNumber: string;
    mobileNumber: string;
    phone: string;
    zipCode: string;
    houseNumber: string;
    byMail: boolean;
    adresInvoice: string;
    numberInvoice: string;
    zipCodeInvoice: string;
    placeInvoice: string;
    emailInvoice: string;
    freelancerBooks: boolean;
    autoFilled: boolean;
    studentId?: string;
    books: boolean;
}

interface FreelancerUserDataProps {
    formValues: any
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    setRegisterFreelancerUserFormData: (registerFreelancerUserFormData: FreelancerUserFormData) => void;
    registerInformationKHN: (khnId: number, zipCode: string, identifier: string, formValues: any) => void;
    handleNext: () => void;
    handleBack: () => void;
    hasBookDiscount: boolean;
    priceIncludesBook: boolean;
}

let FreelancerUserData = ({ valid, hasBookDiscount, priceIncludesBook, handleSubmit, submitting, handleNext, handleBack, registerInformationKHN, setRegisterFreelancerUserFormData, getAddress, formValues }: FreelancerUserDataProps & InjectedFormProps<FreelancerUserFormData, FreelancerUserDataProps>) => {
    const buttonClasses = useButtonStyles();
    const textClasses = useTextStyles();
    const [byMail, setByMail] = useState<boolean>(false);
    const userProps = useSpring({
        from: { opacity: 0, transform: "translateX(200px)" },
        to: { opacity: 1, transform: "translateX(-0px)" },
        config: config.stiff
    })

    const initialMail = !!formValues?.byMail;
    useEffect(() => {
        setByMail(initialMail);
    }, [initialMail, setByMail]);

    useEffect(() => {
        const getStudentData = async () => {
            const studentId = await authenticationService.userId();
            const userFormData = await studentService.studentDetail(studentId.toString()) as unknown as FreelancerUserFormData;
            userFormData.autoFilled = true;
            setRegisterFreelancerUserFormData(userFormData);
        }
        if (getRole() === Role.STUDENT) {
            getStudentData();
        }
    });

    return (
        <>
            <form onSubmit={handleSubmit((registerFreelancerUserFormData: FreelancerUserFormData): void => {
                registerFreelancerUserFormData = trimFields(registerFreelancerUserFormData)
                setRegisterFreelancerUserFormData({ ...registerFreelancerUserFormData, byMail });
                handleNext();
            })}>
                <animated.div style={userProps} >
                    <CustomPrompt when={!valid} />
                    <Grid container>
                        {getWebsite() === Website.HOC &&
                            <>
                                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                    <Typography variant="h6">KHN lidmaatschap</Typography>
                                    <Divider />
                                </Grid>
                                <AutofillKHNComponent
                                    identifier="FREELANCER"
                                    formValues={formValues}
                                    registerInformationKHN={registerInformationKHN}
                                />
                            </>}
                        <Grid item xs={12} style={{ marginBottom: "1em" }}>
                            <Typography variant="h6">Persoonsgegevens  <UserDataTooltip /></Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Grid container direction="column" justify="center">
                                    <Typography classes={{ root: `${textClasses.label}` }}>GESLACHT</Typography>
                                    <Field
                                        name="sex"
                                        validate={[validString, required]}
                                        component={RenderTextFieldDropdown}
                                    >
                                        <MenuItem value={"H"} >De heer</MenuItem>
                                        <MenuItem value={"M"} >Mevrouw</MenuItem>
                                    </Field>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>VOORLETTERS</Typography>
                                <Field
                                    name="initials"
                                    variant="outlined"
                                    validate={[validString, required]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>VOORNAAM</Typography>
                                <Field
                                    name="firstName"
                                    variant="outlined"
                                    validate={[validString, required]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>TUSSENVOEGSEL</Typography>
                                <Field
                                    name="prefix"
                                    variant="outlined"
                                    validate={[validString]}
                                    placeholder="Optioneel"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>ACHTERNAAM</Typography>
                                <Field
                                    name="lastName"
                                    variant="outlined"
                                    validate={[validString, required]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>GEBOORTEDATUM</Typography>
                                <Field
                                    name="birthDate"
                                    variant="outlined"
                                    placeholder="Verplicht"
                                    validate={[validString, required, validDate]}
                                    component={RenderDatePicker}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>GEBOORTEPLAATS</Typography>
                                <Field
                                    name="birthPlace"
                                    variant="outlined"
                                    validate={[validString, required]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "1em" }}>
                            <Typography variant="h6">Contactgegevens</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>EMAIL</Typography>
                                <Field
                                    name="email"
                                    variant="outlined"
                                    validate={[validString, required, isEmail]}
                                    type="email"
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {(isNil(formValues) || !formValues.autoFilled) ? <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>WACHTWOORD {getPortalName()}</Typography>
                                <Field
                                    name="password"
                                    variant="outlined"
                                    validate={[validString, required, passwordStrongEnough]}
                                    type="password"
                                    placeholder="Verplicht"
                                    component={RenderTextFieldLogin}
                                    InputProps={{
                                        endAdornment: <PasswordTooltip />,
                                    }}
                                />
                                <Field
                                    name="password"
                                    validate={[validString, required, passwordStrongEnough]}
                                    component={RenderTextFieldPasswordChecker}
                                />
                            </div> : <></>}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>TELEFOON</Typography>
                                <Field
                                    name="workNumber"
                                    variant="outlined"
                                    validate={[validString, isPhone]}
                                    placeholder="Optioneel"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>MOBIELE TELEFOON</Typography>
                                <Field
                                    name="mobileNumber"
                                    variant="outlined"
                                    validate={[validString, isPhone, required]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "1em" }}>
                            <Typography variant="h6">Adresgegevens</Typography>
                            <Divider />
                        </Grid>
                        <AutofillComponent identifier="FREELANCER" formValues={formValues} getAddress={getAddress} /><p>{priceIncludesBook}</p>
                        <Grid item xs={12}>
                            <Grid container>
                                {hasBookDiscount && <Grid item xs={6}>
                                    <div className="textfield-spacing textfield-spacing-h">
                                        <Field
                                            name="freelancerBooks"
                                            label={priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                                            component={RenderCheckBox}
                                        />
                                    </div>
                                </Grid>}
                                <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                                    <Typography variant="h6">Factuurgegevens</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="textfield-spacing textfield-spacing-h">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>FACTURATIE EMAIL</Typography>
                                        <Field
                                            name="emailInvoice"
                                            variant="outlined"
                                            validate={[validString, isEmail]}
                                            type="email"
                                            placeholder="Optioneel"
                                            component={RenderTextField}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ marginBottom: "auto", marginTop: "auto", paddingLeft: ".5em" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={byMail}
                                                onClick={() => setByMail(!byMail)}
                                            />
                                        }
                                        label={"afwijkend factuuradres"}
                                        classes={{
                                            root: `${textClasses.subLabel}`
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Box display={byMail ? "" : "none"} style={{ width: "100%" }}>
                            <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                                <Typography variant="h6">Factuurgegevens</Typography>
                                <Divider />
                            </Grid>
                            <Grid container>
                                <AutofillComponent
                                    identifier="FREELANCER_INVOICE"
                                    formValues={formValues}
                                    shouldValidate={byMail}
                                    getAddress={getAddress}
                                    config="invoice"
                                />
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container justify="space-between">
                        <Button
                            onClick={handleBack}
                            classes={{
                                root: buttonClasses.secondaryApp,
                            }}>
                            Terug
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            type="submit"
                            disabled={submitting}
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            Volgende
                        </Button>
                    </Grid>
                </animated.div>
            </form>
        </>
    );
}

const DecoratedInitializeFromStateFormFunction = reduxForm<FreelancerUserFormData, FreelancerUserDataProps>({
    form: "freelancerUserDataForm",
    enableReinitialize: true,
})(FreelancerUserData);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let initialValues = state.register.registerFreelancerUserFormData;
        initialValues = trimFields(initialValues)

        return ({
            formValues: getFormValues("freelancerUserDataForm")(state),
            initialValues: initialValues,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;