import { Dispatch } from "redux";

import { QuestionActions } from "./types";
import { questionService } from "../../services/question.service";
import { QuestionInput, AnswerInput } from "../../types/question";

export const getQuestions = (categoryId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.QUESTIONS_BY_CATEGORY_REQUEST });
        const response = await questionService.questions(categoryId);
        dispatch({ type: QuestionActions.QUESTIONS_BY_CATEGORY_SUCCESS, response });
    } catch (error) {
        dispatch({ type: QuestionActions.QUESTIONS_BY_CATEGORY_ERROR, error });
    }
};

export const createQuestion = (question: QuestionInput) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.CREATE_QUESTION_REQUEST });
        const questionId = await questionService.createQuestion(question);

        if (questionId) {
            dispatch({ type: QuestionActions.CREATE_QUESTION_SUCCESS, response: { ...question, loading: false, questionId } });
        } else {
            dispatch({ type: QuestionActions.CREATE_QUESTION_ERROR });
        }
    } catch (error) {
        dispatch({ type: QuestionActions.CREATE_QUESTION_ERROR, error });
    }
};

export const updateQuestion = (questionId: number, question: QuestionInput) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.UPDATE_QUESTION_REQUEST });
        const editted = await questionService.updateQuestion(questionId, question);

        if (editted) {
            dispatch({ type: QuestionActions.UPDATE_QUESTION_SUCCESS, question: { ...question, loading: false, questionId } });
        } else {
            dispatch({ type: QuestionActions.UPDATE_QUESTION_ERROR });
        }
    } catch (error) {
        dispatch({ type: QuestionActions.UPDATE_QUESTION_ERROR, error });
    }
};

export const deleteQuestion = (questionId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.DELETE_QUESTION_REQUEST });
        const deleted = await questionService.deleteQuestion(questionId);

        if (deleted) {
            dispatch({ type: QuestionActions.DELETE_QUESTION_SUCCESS, questionId });
        } else {
            dispatch({ type: QuestionActions.DELETE_QUESTION_ERROR });
        }
    } catch (error) {
        dispatch({ type: QuestionActions.DELETE_QUESTION_ERROR, error });
    }
};

export const createAnswer = (answer: AnswerInput) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.CREATE_ANSWER_REQUEST, questionId: answer.questionId });
        const answerId = await questionService.createAnswer(answer);

        if (answerId) {
            dispatch({ type: QuestionActions.CREATE_ANSWER_SUCCESS, response: { ...answer, answerId } });
        } else {
            dispatch({ type: QuestionActions.CREATE_ANSWER_ERROR, questionId: answer.questionId });
        }
    } catch (error) {
        dispatch({ type: QuestionActions.CREATE_ANSWER_ERROR, questionId: answer.questionId });
    }
};

export const updateAnswer = (answerId: number, answer: AnswerInput) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.UPDATE_ANSWER_REQUEST, questionId: answer.questionId });
        const updated = await questionService.updateAnswer(answerId, answer);

        if (updated) {
            dispatch({ type: QuestionActions.UPDATE_ANSWER_SUCCESS, response: { ...answer, answerId } });
        } else {
            dispatch({ type: QuestionActions.UPDATE_ANSWER_ERROR, questionId: answer.questionId });
        }
    } catch (error) {
        dispatch({ type: QuestionActions.UPDATE_ANSWER_ERROR, questionId: answer.questionId });
    }
};

export const deleteAnswer = (answerId: number, questionId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: QuestionActions.DELETE_ANSWER_REQUEST, questionId });
        const deleted = await questionService.deleteAnswer(answerId);

        if (deleted) {
            dispatch({ type: QuestionActions.DELETE_ANSWER_SUCCESS, response: { answerId, questionId } });
        } else {
            dispatch({ type: QuestionActions.DELETE_ANSWER_ERROR, questionId });
        }
    } catch (error) {
        dispatch({ type: QuestionActions.DELETE_ANSWER_ERROR, questionId });
    }
};
