import { useEffect } from "react";
import LinkBuilderComponent from './LinkBuilderComponent';
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { ApplicationState } from "../../../store";
import { getDiscounts, setDiscountCode } from '../../../store/discount/actions';
import { createOrUpdateMailingLink } from "../../../store/mailingLink/actions";
import { MailingLink } from "../../../types/mailingLink";
import { RegisterCourse } from "../../../types/registerCourse";
import { DiscountState } from "../../../types/discount";

interface LinkBuilderContainerProps {
    course: RegisterCourse;
    discount: DiscountState;
    getDiscounts: () => void;
    setDiscountCode: (discountCode: string) => void;
    createOrUpdateMailingLink: (mailingLink: MailingLink) => void;
}

const mapStateToProps = ({ registerCourse: { course }, discount }: ApplicationState) => {
    return ({
        course,
        discount
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getDiscounts, setDiscountCode, createOrUpdateMailingLink }, dispatch);
};

const LinkBuilderContainer = ({ course, getDiscounts, setDiscountCode, discount, createOrUpdateMailingLink }: LinkBuilderContainerProps) => {
    useEffect(() => {
        getDiscounts();
    }, [getDiscounts]);

    return (
        <LinkBuilderComponent
            course={course}
            discounts={discount.codes}
            discountCode={discount.discountCode}
            setDiscountCode={setDiscountCode}
            createOrUpdateMailingLink={createOrUpdateMailingLink}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkBuilderContainer);