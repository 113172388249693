import React, { useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import InvoiceComponent from "./InvoicesComponent";
import { InvoiceState, Invoice } from "../../types/invoice";
import { getInvoices, getInvoicePDF, setSelectedInvoice } from "../../store/invoices/actions";

interface InvoiceContainerProps {
    invoice: InvoiceState;
    getInvoices: () => void;
    getInvoicePDF: (invoiceId: string) => void;
    setSelectedInvoice: (invoice: Invoice) => void;
}

const mapStateToProps = ({ invoice }: InvoiceContainerProps) => {
    return ({
        invoice
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getInvoices, getInvoicePDF, setSelectedInvoice }, dispatch);
}

const InvoiceContainer = ({ invoice, getInvoices, getInvoicePDF, setSelectedInvoice }: InvoiceContainerProps) => {
    useEffect(() => {
        getInvoices();
    }, [getInvoices]);

    return <InvoiceComponent
        getInvoicePDF={getInvoicePDF}
        setSelectedInvoice={setSelectedInvoice}
        invoices={invoice.invoices}
        loading={invoice.loading}
        loadingPDF={invoice.loadingPDF}
        error={invoice.error}
    />
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContainer);