import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";
import MaterialTable from "material-table";

import { SampleExam, SampleExamAdmin, SampleExamAdminInput } from "../../../types/sampleExam";
import { renderSkeleton } from "../../FeedBack/Skeleton";
import Error from "../../FeedBack/Error";
import { materialTableLocalization } from "../../../constants/materialTableLocalization";

interface TextExamComponentProps {
    categoryId: number;
    error: any;
    exams: SampleExam[];
    loading: boolean;
    createTestExam: (sampleExam: SampleExamAdminInput) => void;
    updateTestExam: (sampleExam: SampleExamAdmin) => void;
    deleteTestExam: (sampleExamId: number) => void;
}

const TestExamAdminComponent = ({ error, exams, loading, createTestExam, updateTestExam, deleteTestExam, categoryId }: TextExamComponentProps) => {
    return (
        <Grid container>
            {loading ?
                <Grid container>
                    {renderSkeleton(1, { xs: 12 }, 400, "rect")}
                </Grid>
                :
                <>
                    {error && <Error />}
                    <Card elevation={2} style={{ width: "100%" }}>
                        <CardContent>
                            <MaterialTable
                                title="Proefexamens"
                                style={{ boxShadow: "none", width: "100%" }}
                                columns={[
                                    { title: "ID", field: "sampleExamId", editable: "never", searchable: true },
                                    { title: "Duur (minuten)", field: "duration", defaultSort: "asc", type: "numeric", searchable: false },
                                    { title: "Aantal vragen", field: "amountOfQuestions", defaultSort: "asc", type: "numeric", searchable: false },
                                    { title: "Aantal goed voor voldoende", field: "amountCorrect", defaultSort: "asc", type: "numeric", searchable: false },
                                ]}
                                data={exams}
                                editable={{
                                    onRowAdd: ({ amountOfQuestions, amountCorrect, duration }: SampleExam) =>
                                        new Promise(() => {
                                            createTestExam({
                                                categoryId,
                                                amountCorrect,
                                                amountOfQuestions,
                                                duration
                                            });
                                        }),
                                    onRowUpdate: ({ amountOfQuestions, amountCorrect, duration }: SampleExam, oldData?: SampleExam) =>
                                        new Promise(() => {
                                            if (oldData) {
                                                updateTestExam({
                                                    categoryId,
                                                    sampleExamId: oldData.sampleExamId,
                                                    amountCorrect,
                                                    amountOfQuestions,
                                                    duration
                                                });
                                            }
                                        }),
                                    onRowDelete: ({ sampleExamId }: SampleExam) =>
                                        new Promise(() => {
                                            deleteTestExam(sampleExamId);
                                        }),
                                }}
                                options={{
                                    sorting: true,
                                    pageSize: 5,
                                    headerStyle: {
                                        color: "gray"
                                    },
                                    actionsColumnIndex: -1,
                                }}
                                localization={materialTableLocalization}
                            />
                        </CardContent>
                    </Card>
                </>
            }
        </Grid>
    );
};

export default TestExamAdminComponent;