export const getWebsiteDomain = (): string => {
    let url: string = "http://";
    let website: string = "";
    if (window && "location" in window && "protocol" in window.location && "host" in window.location) {
        website = window.location.host;
    }

    if (process.env.NODE_ENV === "production") {
        url = "https://"
        if (website.indexOf('hoc') !== -1) url += "hoc-portal.hsn.nl/#";
        else if (website.indexOf('sh') !== -1) url += "sh-portal.hsn.nl/#";
        else url += "hsn-portal.hsn.nl/#";
    }
    else {
        if (website.indexOf('hoc') !== -1) url += "hoc.localhost:3000/#";
        else if (website.indexOf('sh') !== -1) url += "sh.localhost:3000/#";
        else url += "hsn.localhost:3000/#";
    }
    return url;
}