import graphqlHelperService from "./graphqlHelper.service";
import { MailingLink } from "../types/mailingLink";
import { RegisterCourse } from "../types/registerCourse";
import { emptyCourse } from "../store/registerCourse/reducers";

export const MailingLinkService = {
    mailingLinks: async (): Promise<MailingLink[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            mailingLinksQuery()
        );
        return apolloResult.mailingLinks as MailingLink[];
    },
    createOrUpdateMailingLink: async (mailingLink: MailingLink): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            createOrUpdateMailingLinkQuery(mailingLink)
        );
        return apolloResult.createOrUpdateMailingLink as boolean;
    },
    deleteMailingLink: async (name: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            deleteMailingLinkQuery(name)
        );
        return apolloResult.deleteMailingLink as boolean;
    },
    checkMailingLink: async (categoryId?: number, courseId?: string, place?: string, locationCode?: string): Promise<RegisterCourse> => {
        if (!categoryId) return emptyCourse;
        const apolloResult = await graphqlHelperService.sendQuery(
            checkCourseQuery(categoryId, courseId, place, locationCode)
        );
        return apolloResult.checkCourse as RegisterCourse;
    }
}

const mailingLinksQuery = () => `query {
    mailingLinks {
        name
        link
    }
}`;

const createOrUpdateMailingLinkQuery = (mailingLink: MailingLink) => `mutation {
    createOrUpdateMailingLink(mailingLink: {
        name: "${mailingLink.name}",
        link: "${mailingLink.link}"
    })
}`;

const deleteMailingLinkQuery = (name: string) => `mutation {
    deleteMailingLink(name: "${name}")
}`;

const checkCourseQuery = (categoryId: number, courseId?: string, place?: string, locationCode?: string) => `query {
    checkCourse(
        categoryId: ${categoryId},
        ${courseId ? `courseId: "${courseId}",` : ``}
        ${place ? `location: "${place}",` : ``}
        ${locationCode ? `locationCode: "${locationCode}",` : ``}
    ) {
        category{
            categoryId
            category
        }
        type {
            courseId
            type
            hasBookDiscount
            eLearning
            eLearningHybrid
            priceIncludesBook
        }
        place
        date {
            locationCode
            dates 
        }
        completed
    }
}`;
