import React from "react";
import { TextField, FormHelperText } from "@material-ui/core";
import { useInputStyles } from "../../styles/input";

export interface RenderTextFieldDropdownProps {
    input: any;
    label: any;
    meta: { touched: any, error: any };
    children: any;
    custom: any;
}

const RenderTextFieldDropdown = ({ input, label, meta: { touched, error }, children, ...custom }: RenderTextFieldDropdownProps) => {
    const inputClasses = useInputStyles();
    return (
        <>
            <TextField
                select
                fullWidth
                {...input}
                children={children}
                classes={{
                    root: inputClasses.root,
                }}
                FormHelperTextProps={{
                    classes: {
                        root: inputClasses.formHelperText,
                    }
                }}
                SelectProps={{
                    classes: {
                        icon: inputClasses.icon,
                    }
                }}
                InputProps={{
                    classes: {
                    },
                }}
                variant="outlined"
                {...custom}
            />
            {(!!error) && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
    );
}

export default RenderTextFieldDropdown;