import React, { useState } from "react";
import { FaInfo } from "react-icons/fa";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { getPortalName } from "./getPortalName";

const PasswordTooltip = () => {
    const [open, setOpen] = useState<boolean>(false);

    return (
        <div style={{ cursor: "pointer" }}>
            <FaInfo onClick={() => setOpen(true)}/>
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Wachtwoord {getPortalName()}</DialogTitle>
                <DialogContent >
                    Het wachtwoord moet minimaal 8 karakters lang zijn.<br/>
                    Het wachtwoord moet minimaal 1 hoofdletter en 1 kleine letter bevatten.<br/>
                    Het wachtwoord moet minimaal 1 cijfer en 1 speciaal karakter bevatten.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Sluiten</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default PasswordTooltip;