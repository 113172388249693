import React from "react";
import { Typography, makeStyles, Theme, useTheme } from "@material-ui/core/";
import { animated, useSpring } from "react-spring";
import { Role } from "../../types/enum";

const useStyles = makeStyles(() => ({
    name: {
        marginTop: "10px",
        opacity: 0.5,
    },
    avatar: {
        marginBottom: "10px"
    }
}));

interface ProfileProps {
    firstName?: string;
    lastName: string;
    prefix: string;
    name: string;
    role: string;
}

const Profile = ({ name, firstName, lastName, prefix, role }: ProfileProps) => {
    const theme: Theme = useTheme();
    const classes = useStyles();
    const props = useSpring({
        to: { opacity: 1, maxWidth: "75px", textAlign: "center" },
        from: { opacity: 0, maxWidth: "75px" },
    });

    return (
        <animated.div style={props}>
            <div
                style={{
                    width: '76px',
                    height: '76px',
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: '50%',
                    // paddingTop: '15px',
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                          }}
                      >
                <Typography style={{ textAlign: 'center', fontSize: 10 }} variant='caption'>{firstName || ''}<br />{prefix} {lastName}</Typography></div>
        {role !== Role.STUDENT && <Typography variant="caption" className={classes.name} >{name}</Typography>}
        </animated.div >
    );
}

export default Profile;