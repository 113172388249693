import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { Field } from "redux-form";
import RenderTextField from "../components/TextField";
import { useTextStyles } from "../styles/text";
import { required, isZipCode, isValidHousenumber, validString } from "../validators/form";
import { isEmpty, isNil } from "lodash";

interface AutofillComponentProps {
    config?: "normal" | "invoice";
    shouldValidate?: boolean;
    identifier: string;
    formValues: any;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
}

const normalConfig = {
    zipcodeLabel: "POSTCODE", 
    zipcodeName: "zipCode",
    numberLabel: "HUISNUMMER", 
    numberName: "houseNumber", 
    placeLabel: "PLAATS", 
    placeName: "place",
    addressLabel: "STRAATNAAM", 
    addressName: "address", 
}

const companyConfig = {
    numberName: "houseNumberCompany",
    placeName: "placeCompany",
    zipcodeName: "zipCodeCompany",
    addressName: "adresCompany",
}

const companyInvoiceConfig = {
    numberName: "invoiceHouseNumber",
    placeName: "invoicePlace",
    zipcodeName: "invoiceZipCode",
    addressName: "invoiceAddress",
}

const invoiceConfig = {
    zipcodeLabel: "FACTURATIE POSTCODE", 
    zipcodeName: "zipCodeInvoice",
    numberLabel: "FACTURATIE HUISNUMMER", 
    numberName: "numberInvoice", 
    placeLabel: "FACTURATIE PLAATS", 
    placeName: "placeInvoice",
    addressLabel: "FACTURATIE STRAATNAAM", 
    addressName: "adresInvoice", 
}

const checkZipCode = (zipCode: string): boolean => {
    return (!isNil(zipCode) && !isEmpty(zipCode)) && isZipCode(zipCode) === undefined;
}

const checkHouseNumber = (houseNumber: string): boolean => {
    return (!isNil(houseNumber) && !isEmpty(houseNumber)) && isValidHousenumber(houseNumber) === undefined;
}

const AutofillComponent = ({ config="normal", shouldValidate=true, identifier, formValues, getAddress }: AutofillComponentProps) => {
    const textClasses = useTextStyles();

    let configNames = config === "normal" ? normalConfig : invoiceConfig;
    if (identifier === "COMPANY") configNames = { ...configNames, ...companyConfig };
    if (identifier === "COMPANY_INVOICE" || identifier === "EDIT_COMPANY_INVOICE") configNames = { ...configNames, ...companyInvoiceConfig };

    const [zipCode, setZipCode] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string>("");

    const handleBlur = () => {
        if (checkZipCode(zipCode) && checkHouseNumber(houseNumber)) {
            getAddress(houseNumber, zipCode, identifier, formValues);
        }
    };

    return (
        <>
            <Grid item xs={12} md={6}>
                <div className="textfield-spacing textfield-spacing-h">
                    <Typography classes={{
                        root: `${textClasses.label}`
                    }}>{configNames.zipcodeLabel}</Typography>
                    <Field
                        variant="outlined"
                        name={configNames.zipcodeName}
                        validate={shouldValidate ? [required, isZipCode, validString] : [validString]}
                        placeholder="Verplicht"
                        component={RenderTextField}
                        handleChange={(value: string) => setZipCode(value)}
                        handleBlur={handleBlur}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="textfield-spacing textfield-spacing-h">
                    <Typography classes={{
                        root: `${textClasses.label}`
                    }}>{configNames.numberLabel}</Typography>
                    <Field
                        variant="outlined"
                        name={configNames.numberName}
                        validate={shouldValidate ? [required, isValidHousenumber, validString] : [validString]}
                        maxLength={10}
                        placeholder="Verplicht"
                        component={RenderTextField}
                        handleChange={(value: string) => setHouseNumber(value)}
                        handleBlur={handleBlur}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="textfield-spacing textfield-spacing-h">
                    <Typography classes={{
                        root: `${textClasses.label}`
                    }}>{configNames.addressLabel}</Typography>
                    <Field
                        name={configNames.addressName}
                        variant="outlined"
                        validate={shouldValidate ? [required, validString] : [validString]}
                        placeholder="Verplicht"
                        component={RenderTextField}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="textfield-spacing textfield-spacing-h">
                    <Typography classes={{
                        root: `${textClasses.label}`
                    }}>{configNames.placeLabel}</Typography>
                    <Field
                        name={configNames.placeName}
                        variant="outlined"
                        validate={shouldValidate ? [required, validString] : [validString]}
                        placeholder="Verplicht"
                        component={RenderTextField}
                    />
                </div>
            </Grid>
        </>
    );
};

export default AutofillComponent;