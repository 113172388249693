import { createActionsWithPrefix } from "../../helperfunctions/action";

export const MailingLinkActions = createActionsWithPrefix("MAILINGLINK/", {
    GET_MAILING_LINKS_REQUEST: "GET_MAILING_LINKS_REQUEST",
    GET_MAILING_LINKS_SUCCESS: "GET_MAILING_LINKS_SUCCESS",
    GET_MAILING_LINKS_ERROR: "GET_MAILING_LINKS_ERROR",
    CREATE_OR_UPDATE_MAILING_LINK_REQUEST: "CREATE_OR_UPDATE_MAILING_LINK_REQUEST",
    CREATE_OR_UPDATE_MAILING_LINK_SUCCESS: "CREATE_OR_UPDATE_MAILING_LINK_SUCCESS",
    CREATE_OR_UPDATE_MAILING_LINK_ERROR: "CREATE_OR_UPDATE_MAILING_LINK_ERROR",
    DELETE_MAILING_LINK_REQUEST: "DELETE_MAILING_LINK_REQUEST",
    DELETE_MAILING_LINK_SUCCESS: "DELETE_MAILING_LINK_SUCCESS",
    DELETE_MAILING_LINK_ERROR: "DELETE_MAILING_LINK_ERROR",
});