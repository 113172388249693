import { createActionsWithPrefix } from "../../helperfunctions/action";

export const TestExamActions = createActionsWithPrefix("TESTEXAMS/", {
    GET_TESTEXAMS_BY_CATEGORY_REQUEST: "GET_TESTEXAMS_BY_CATEGORY_REQUEST",
    GET_TESTEXAMS_BY_CATEGORY_SUCCESS: "GET_TESTEXAMS_BY_CATEGORY_SUCCESS",
    GET_TESTEXAMS_BY_CATEGORY_ERROR: "GET_TESTEXAMS_BY_CATEGORY_ERROR",
    GET_TESTEXAM_BY_ID_REQUEST: "GET_TESTEXAM_BY_ID_REQUEST",
    GET_TESTEXAM_BY_ID_SUCCESS: "GET_TESTEXAM_BY_ID_SUCCESS",
    GET_TESTEXAM_BY_ID_ERROR: "GET_TESTEXAM_BY_ID_ERROR",
    SET_TESTEXAM_ANSWER: "SET_TESTEXAM_ANSWER",
    SEND_TESTEXAM_ANSWERS: "SEND_TESTEXAM_ANSWERS",
    RESET_TESTEXAM_ANSWERS: "RESET_TESTEXAM_ANSWERS",
    CHECK_TESTEXAM_REQUEST: "CHECK_TESTEXAM_REQUEST",
    CHECK_TESTEXAM_SUCCESS: "CHECK_TESTEXAM_SUCCESS",
    CHECK_TESTEXAM_ERROR: "CHECK_TESTEXAM_ERROR",
    CREATE_TESTEXAM_REQUEST: "CREATE_TESTEXAM_REQUEST",
    CREATE_TESTEXAM_SUCCESS: "CREATE_TESTEXAM_SUCCESS",
    CREATE_TESTEXAM_ERROR: "CREATE_TESTEXAM_ERROR",
    UPDATE_TESTEXAM_REQUEST: "UPDATE_TESTEXAM_REQUEST",
    UPDATE_TESTEXAM_SUCCESS: "UPDATE_TESTEXAM_SUCCESS",
    UPDATE_TESTEXAM_ERROR: "UPDATE_TESTEXAM_ERROR",
    DELETE_TESTEXAM_REQUEST: "DELETE_TESTEXAM_REQUEST",
    DELETE_TESTEXAM_SUCCESS: "DELETE_TESTEXAM_SUCCESS",
    DELETE_TESTEXAM_ERROR: "DELETE_TESTEXAM_ERROR"
});