import { InvoicesActions } from "./types";
import { InvoiceState } from "../../types/invoice";

const initialState: InvoiceState = {
    invoices: [],
    invoice: undefined,
    outstandingInvoices: 0,
    loading: false,
    loadingPDF: false,
    error: undefined,
    errorPDF: undefined,
};

export function invoiceReducer(state = initialState, action: typeof InvoicesActions): InvoiceState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case InvoicesActions.GET_INVOICES_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case InvoicesActions.GET_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.invoices,
                outstandingInvoices: action.outstandingInvoices,
                loading: false
            };
        case InvoicesActions.GET_INVOICES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case InvoicesActions.GET_INVOICESPDF_REQUEST:
            return {
                ...state,
                loadingPDF: true,
                errorPDF: undefined
            };
        case InvoicesActions.GET_INVOICESPDF_SUCCESS:
            return {
                ...state,
                loadingPDF: false
            };
        case InvoicesActions.GET_INVOICESPDF_ERROR:
            return {
                ...state,
                loadingPDF: false,
                errorPDF: action.error
            };
        case InvoicesActions.SET_INVOICE:
            return {
                ...state,
                invoice: action.invoice
            }
        default:
            return state;
    }
}