import MaterialTable from 'material-table';
import { Grid, Card, CardContent, Theme, useTheme, useMediaQuery } from '@material-ui/core';
import { DiscountState, Discount } from '../../../types/discount';
import { LoadingTable } from '../../FeedBack/LoadingContent';
import { materialTableLocalization } from '../../../constants/materialTableLocalization';
import { validDiscount } from '../../../helperfunctions/validDiscount';
import { toast } from 'react-toastify';

interface DiscountCodesComponentProps {
    discount: DiscountState;
    createDiscount: (discount: Discount) => void;
    updateDiscount: (discount: Discount) => void;
    deleteDiscount: (discountCode: string) => void;
}

const DiscountCodesComponent = ({ discount, createDiscount, updateDiscount, deleteDiscount }: DiscountCodesComponentProps) => {
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container style={{ marginTop: "2em" }}>
            <Grid item xs={12}>
                {discount.loading && <Grid item xs={12}> <LoadingTable /></Grid>}
                {(!discount.loading && discount.codes) &&
                    <Card elevation={2}>
                        <CardContent>
                            <MaterialTable
                                style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "300px" : "none" }}
                                columns={[
                                    { title: "Actief", field: "active", type: "boolean" },
                                    { title: "Start datum", field: "startDate", type: "string" },
                                    { title: "Eind datum", field: "endDate", type: "string" },
                                    { title: "Kortingscode", field: "discountCode", defaultSort: "asc", editable: "onAdd" },
                                    { title: "Extra", field: "extra", type: "string", },
                                    { title: "Percentage %", field: "percentage", type: "numeric" },
                                    { title: "Bedrag €", field: "amount", type: "numeric" },
                                    { title: "Omschrijving", field: "message", type: "string", },
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                }}
                                editable={{
                                    onRowAdd: (newData: Discount) => {
                                        const error: string | undefined = validDiscount(newData)
                                        if (error) {
                                            toast.error(error);
                                            return Promise.resolve();
                                        } else return new Promise(() => {
                                            createDiscount(newData);
                                        })},
                                    onRowUpdate: (newData: Discount) => {
                                        const error: string | undefined = validDiscount(newData)
                                        if (error) {
                                            toast.error(error);                                            
                                            return Promise.resolve();
                                        } else return new Promise(() => {
                                            updateDiscount(newData);
                                        })},
                                    onRowDelete: (oldData: Discount) =>
                                        new Promise(() => {
                                            deleteDiscount(oldData.discountCode);
                                        }),
                                }}
                                localization={materialTableLocalization}
                                data={discount.codes}
                                title="Kortingscodes"
                            />
                        </CardContent>
                    </Card>
                }
            </Grid>
        </Grid>
    );
};

export default DiscountCodesComponent;
