import React from 'react'
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FaCheck, FaTimes } from "react-icons/fa";

import { ExamResult } from '../../types/sampleExam'
import Loading from '../FeedBack/Loading'
import Error from '../FeedBack/Error'

interface TestExamResultProps {
    testExamResult?: ExamResult
    loading?: boolean
    error?: string
}

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
}));

const TestExamResult = ({ testExamResult, loading, error }: TestExamResultProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const getPassed = (passed: boolean) => {
        if (passed === true) {
            return "Geslaagd"
        } else {
            return "Afgewezen"
        }
    }

    return (
        <div>
            {loading && <Loading />}
            {!loading && error && <Error />}
            {!loading && !error && testExamResult &&
                <Grid spacing={3} container alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Resultaat</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={5} md={3}><Typography variant="h6">Resultaat:</Typography></Grid> <Grid item xs={7} md={8}><Typography style={{ color: testExamResult.passed ? "green" : "red" }} variant="h6">{getPassed(testExamResult.passed)}</Typography></Grid>
                                    <Grid item xs={5} md={3}><Typography variant="h6">Cijfer:</Typography></Grid> <Grid item xs={7} md={8}><Typography variant="h6">{testExamResult.grade}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>Controleer je antwoorden</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid item xs={12}>
                                    {testExamResult.questions && testExamResult.questions.map((question, index) =>
                                        <Grid key={index} style={{ marginTop: "5px" }} item xs={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{backgroundColor: question.correct ? "#90EE90" : "#ffcccb"}}
                                                >
                                                    <Typography style={{ display: "flex", justifyContent: "left", alignItems: "center" }} className={classes.heading}>{`vraag: ${index + 1}  `}{question.correct ? <FaCheck color="green" style={{ marginLeft: "5px" }} /> : <FaTimes color="red" style={{ marginLeft: "5px" }} />}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{backgroundColor: question.correct ? "#90EE90" : "#ffcccb"}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5} md={3}><Typography variant="h6">Vraag:</Typography></Grid> <Grid item xs={7} md={8}><Typography variant="h6">{question.text}</Typography></Grid>
                                                        <Grid item xs={5} md={3}><Typography variant="body1">Gegeven antwoord:</Typography></Grid> <Grid item xs={7} md={8}><Typography variant="body1">{question.givenAnswer}</Typography></Grid>
                                                        {question.correctAnswers.length > 0 && <Grid item xs={5} md={3}><Typography variant="body1">Juiste antwoord: </Typography></Grid>} {question.correctAnswers.map((answer, index) => <Grid key={index} item xs={7} md={8}><Typography variant="body1">{answer}</Typography></Grid>)}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

export default TestExamResult
