import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";

export const renderSkeleton = (length: number, width: {
    xs?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined,
    sm?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined,
    md?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined,
    lg?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined,
    xl?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined,
}, height: string | number, variant: "rect" | "circle" | "text") =>
    [...Array(length)].map((e, i) =>
        <Grid
            item
            xs={width.xs}
            sm={width.sm}
            md={width.md}
            lg={width.lg}
            xl={width.xl}
            key={i}
            style={{ padding: ".5em" }}
        ><Skeleton variant={variant} width={"100%"} height={height} /></Grid>);