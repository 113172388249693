import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from "redux";

import CheckoutPayment, { CheckoutPaymentFormData } from './CheckoutPayment';
import { RegisterState, Registration, RegistrationPriceInfo } from '../../types/register';
import { doRegister, getPrices, downloadAgreement, editShoppingCartItemFreelanceBooks } from '../../store/register/actions';
import { checkDiscountCode } from '../../store/discount/actions'
import { setCourse } from '../../store/registerCourse/actions';
import { RegisterCourse } from '../../types/registerCourse';
import Complete from '../FeedBack/Complete';
import { Role } from '../../types/enum';
import { ApplicationState } from '../../store';
import { AuthenticationState } from '../../types/auth';
import { trimFields } from '../../helperfunctions/trimFields';
import { getPaymentMethodsAllowed } from '../../helperfunctions/paymentMethod';

interface CheckoutStepperContainerProps {
    currentStep: number;
    checkoutItems: Registration[]
    register: RegisterState
    handleNext: () => void;
    setActiveStep: (step: number) => void;
    onRemove: (index: number) => void;
    role: string;
    auth: AuthenticationState
    downloadAgreement: () => void;
    doRegister: (registration: Registration[], paymentData: CheckoutPaymentFormData) => void;
    setCourse: (course: RegisterCourse) => void;
    getPrices: (priceItems: RegistrationPriceInfo[]) => void;
    checkDiscountCode: (discountCode: string) => void;
    discountString?: string;
    discountCode?: string;
    editShoppingCartItemFreelanceBooks: (id: number, freelanceBooks: boolean) => void
}

const mapStateToProps = ({ register, discount: { discountString, discountCode }, auth }: ApplicationState) => {
    return ({
        register,
        discountString,
        discountCode,
        auth,
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        downloadAgreement, editShoppingCartItemFreelanceBooks, doRegister, setCourse, getPrices, checkDiscountCode
    }, dispatch);
}

const CheckoutStepperContainer = ({
    downloadAgreement,
    currentStep,
    handleNext,
    checkoutItems,
    role,
    onRemove,
    register,
    doRegister,
    setCourse,
    getPrices,
    discountString,
    checkDiscountCode,
    discountCode,
    editShoppingCartItemFreelanceBooks,
    auth
}: CheckoutStepperContainerProps) => {
    const [khnId, setKhnId] = useState<string | undefined>(undefined);

    useEffect(() => {
        let priceItems: RegistrationPriceInfo[] = []
        checkoutItems && checkoutItems.forEach((item) => {
            if (item.course.type?.courseId && role === Role.COMPANY) {
                let studentBooks: boolean[] = item.students ? item.students.map(student => student.books || false) : [];

                const registrationPriceInfo: RegistrationPriceInfo = {
                    courseId: item.course.type?.courseId,
                    studentBooks: studentBooks,
                    discountCode,
                    khnId
                }
                priceItems.push(registrationPriceInfo)
            } else if (item.course.type?.courseId && role === Role.FREELANCER) {
                const registrationPriceInfo: RegistrationPriceInfo = {
                    courseId: item.course.type?.courseId,
                    studentBooks: [(item.freelancerBooks || false)],
                    discountCode,
                    khnId
                }
                priceItems.push(registrationPriceInfo)
            }
        })
        if (priceItems.length > 0) {
            getPrices(priceItems);
        }
    }, [checkoutItems, getPrices, role, discountCode, khnId]);

    switch (currentStep) {
        case 0:
            return <CheckoutPayment
                editShoppingCartItemFreelanceBooks={editShoppingCartItemFreelanceBooks}
                downloadAgreement={downloadAgreement}
                prices={register.prices}
                checkoutItems={checkoutItems}
                handleNext={(paymentData: CheckoutPaymentFormData) => {
                    const { shoppingCart } = register;
                    if (shoppingCart) {
                        shoppingCart.map((registration: Registration) => trimFields(registration));

                        doRegister(shoppingCart, paymentData);
                        handleNext();
                    }
                }}
                discountString={discountString}
                checkDiscountCode={checkDiscountCode}
                setCourse={setCourse}
                onRemove={onRemove}
                role={auth.role}
                specialPriceAgreement={auth.specialPriceAgreement || false}
                setKhnId={setKhnId}
                paymentMethodsAllowed={getPaymentMethodsAllowed(register.prices)}
            />;
        default:
            return <Complete error={register.registerError} loading={register.registerLoading} toDashboard={true} />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepperContainer);