import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Grid, Typography, List, ListItem, Button, IconButton, ListItemSecondaryAction, Paper, Divider, Checkbox, FormControlLabel } from "@material-ui/core";
import { reduxForm, InjectedFormProps } from "redux-form";
import { animated, useSpring, config } from "react-spring";
import { FaUsers, FaUserPlus, FaTrash, FaEdit } from "react-icons/fa";

import { RegisterState } from "../../../types/register";
import { useButtonStyles } from "../../../styles/button";
import AddStudentDialog from "./AddStudentDialog";
import AddNewStudentDialog from "./AddNewStudentDialog";
import { StudentState, StudentDetail } from "../../../types/student";

const useStyles = makeStyles((theme: Theme) => ({
    addStudentListItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2)
    },
    addStudentListItemIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2)
    },
    studentListItem: {
        padding: theme.spacing(2)
    },
    studentListWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    noStudentListItem: {
        padding: theme.spacing(2)
    }
}));

export interface RegisterAppStudentDataForm {
    initials: string;
    firstName: string;
    infix: string;
    lastName: string;
    sex: string;
    dateOfBirth: string;
    email: string;
    phonePrivate: string;
    phoneWork: string;
    mobilePhone: string;
    zipCode: string;
    number: string;
    hasBook: boolean;
}

interface RegisterAppStudentDataProps {
    register: RegisterState;
    student: StudentState;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    setRegisterStudent: (student: StudentDetail) => void;
    emptyRegisterStudent: () => void;

    addRegisterStudent: (student: StudentDetail) => void;
    editRegisterStudent: (student: StudentDetail, studentId: number) => void;
    removeRegisterStudent: (studentIndex: number) => void;
    updateRegisterBooks: (studentIndex: number) => void;

    handleNext: () => void;
    handleBack: () => void;
    hasBookDiscount: boolean;
    priceIncludesBook: boolean;
}

const RegisterAppStudentData = ({
    getAddress,
    reset,
    handleSubmit,
    student,
    handleNext,
    handleBack,
    emptyRegisterStudent,
    register,
    setRegisterStudent,
    hasBookDiscount,
    priceIncludesBook,
    addRegisterStudent,
    editRegisterStudent,
    removeRegisterStudent,
    updateRegisterBooks}: RegisterAppStudentDataProps & InjectedFormProps<RegisterAppStudentDataForm, RegisterAppStudentDataProps>) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [studentIndex, setStudentIndex] = useState<number>(0)
    const [NewStudentopen, setNewStudentOpen] = useState<boolean>(false);
    const [noStudent, setNoStudent] = useState<boolean>(false);
    const studentProps = useSpring({
        from: { opacity: 0, transform: "translateX(100px)" },
        to: { opacity: 1, transform: "translateX(-0px)" },
        config: config.stiff
    });
    const noStudentProps = useSpring({
        from: { color: "black" },
        to: { color: noStudent ? "red" : "black" },
        config: config.stiff
    });

    const handleSelect = (selectedStudents: StudentDetail[]) => {
        selectedStudents && selectedStudents.map((student) => addRegisterStudent(student));
    };

    const selectedIds: string[] = [""];
    register.students && register.students.forEach(student => {
        if (student.studentId) selectedIds.push(student.studentId)
    });

    return (
        <form onSubmit={handleSubmit((data: RegisterAppStudentDataForm): void => {
            reset();
            setOpen(false);
        })}>
            <animated.div style={studentProps}>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <Paper className={classes.studentListWrapper}>
                            <List disablePadding>
                                {(!register.students || register.students.length < 1) &&
                                    <>
                                        <ListItem dense className={classes.noStudentListItem}>
                                            <animated.div style={noStudentProps}>
                                                <Typography>U heeft nog geen medewerkers toegevoegd</Typography>
                                            </animated.div>
                                        </ListItem>
                                        <Divider />
                                    </>
                                }
                                {register.students && register.students.length > 0 && register.students.map((student: StudentDetail, index: number) => {
                                    return (
                                        <div key={index}>
                                            <ListItem dense className={classes.studentListItem}>
                                                <Grid container>
                                                    <Grid item xs={12}><Typography variant="subtitle1">{`${student.firstName} ${student.prefix ? student.prefix : ""} ${student.lastName}`}</Typography></Grid>
                                                    <Grid item md={3} sm={3} xs={12} lg={2}>
                                                        <Typography style={{ color: "gray" }} variant="subtitle2">E-mailadres: </Typography>
                                                    </Grid>
                                                    <Grid item md={9} sm={9} xs={12} lg={10}>
                                                        <Typography style={{ color: student.email ? "gray" : "red" }} variant="subtitle2">{student.email ? `${student.email}` : `Geen e-mailadres bekend`}</Typography>
                                                    </Grid>
                                                    <Grid item md={3} sm={3} xs={12} lg={2}>
                                                        <Typography style={{ color: "gray" }} variant="subtitle2">Adres: </Typography>
                                                    </Grid>
                                                    <Grid item md={9} xs={12} sm={9} lg={10}>
                                                        <Typography style={{ color: student.address ? "gray" : "red" }} variant="subtitle2">{student.address ? `${student.address} ${student.houseNumber}, ${student.place}` : `Geen adres bekend`}</Typography>
                                                    </Grid>
                                                    {hasBookDiscount && <Grid xs={12}>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={student.books || false}
                                                                onChange={(e: any) => updateRegisterBooks(index)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />}
                                                            label={priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                                                        />
                                                    </Grid>}
                                                </Grid>
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => {
                                                        setRegisterStudent(student)
                                                        setStudentIndex(index)
                                                        setEdit(true)
                                                        setNewStudentOpen(true)
                                                    }}>
                                                        <FaEdit />
                                                    </IconButton>
                                                    <IconButton edge="end" onClick={() => removeRegisterStudent(index)}>
                                                        <FaTrash />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    );
                                })}
                                <ListItem dense className={classes.addStudentListItem}>
                                    <Grid container spacing={2} style={{ textAlign: "center" }}>
                                        <Grid item xs={6}>
                                            <Button onClick={() => setNewStudentOpen(true)} fullWidth={true} color="secondary"><FaUserPlus style={{ marginRight: "1em" }} />Nieuwe medewerker Toevoegen</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button onClick={() => setOpen(true)} fullWidth={true} color="secondary"><FaUsers style={{ marginRight: "1em" }} />bestaande medewerker(s) Toevoegen</Button>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </animated.div>
            <Grid container justify="space-between">
                <Button
                    onClick={handleBack}
                    classes={{
                        root: buttonClasses.secondaryApp,
                    }}
                >
                    Terug
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={register.students.length < 1}
                    onClick={() => register.students.length < 1 ? setNoStudent(true) : handleNext()}
                    classes={{
                        root: buttonClasses.primary,
                    }}
                >
                    Volgende
                    </Button>
            </Grid>
            <AddStudentDialog
                handleSelect={(students) => handleSelect(students)}
                students={student.students}
                open={open}
                handleClose={() => setOpen(false)}
                selectedIds={selectedIds}
            />
            <AddNewStudentDialog
                hasBookDiscount={hasBookDiscount}
                priceIncludesBook={priceIncludesBook}
                studentIndex={studentIndex}
                edit={edit}
                onEdit={editRegisterStudent}
                getAddress={getAddress}
                open={NewStudentopen}
                handleClose={() => {
                    setEdit(false)
                    setNewStudentOpen(false)
                    emptyRegisterStudent()
                }}
                onAdd={addRegisterStudent}
            />

        </form >
    );
}

export default
    reduxForm<RegisterAppStudentDataForm, RegisterAppStudentDataProps>({
        form: "companyStudentDataForm"
    })(RegisterAppStudentData);