import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let hsnTheme = createTheme({
    overrides: {
        MuiCheckbox: {
            colorSecondary: {
                color: "#EF2C30",
                "&$checked": {
                    color: "#EF2C30",
                },
            },
        },
        MuiRadio: {
            colorSecondary: {
                color: "#EF2C30",
                "&$checked": {
                    color: "#EF2C30",
                },
            },
        },
        MuiTypography: {
            colorTextSecondary: {
                color: "#fff"
            },
        },
    },
    palette: {
        primary: {
            main: "#01224B",
            contrastText: "#FFFFFF",
            dark: "#011D40",
            light: "#2F4570"
        },
        secondary: {
            main: "#EF2C30",
            contrastText: "#FFFFFF",
            dark: "#CB1316",
            light: "#FF494E"
        },
        error: {
            main: "#EF2C30",
            contrastText: "#FFFFFF",
            dark: "#CB1316",
            light: "#FF494E"
        },
        text: {
            primary: "#363636",
            secondary: "#363636",
            hint: "#666666",
            disabled: "#c4c4c4"
        }
    },
    typography: {
        fontFamily: "Open Sans, sans-serif",
    }
});

hsnTheme = responsiveFontSizes(hsnTheme);

export default hsnTheme;