
export interface StudentState {
    students: StudentDetail[];
    studentDetail?: StudentDetail;
    studentAddSuccess: boolean;
    loading: boolean;
    error?: any;
}

export interface StudentDetail {
    relationId?: string;
    studentId?: string;
    initials?: string;
    firstName?: string;
    prefix: string;
    lastName: string;
    sex: string;
    birthDate: string;
    birthPlace: string;
    email: string;
    privateNumber: string;
    mobileNumber: string;
    zipCode: string;
    houseNumber: string;
    address: string;
    place: string;
    books: boolean;
    courseResults?: CourseResult[];
    fullName?: string;
    fullAddress?: string;
    touched?: boolean;
}

export interface CourseResult {
    name: string;
    passed: boolean;
    failed: boolean;
    absent?: string;
    date?: string;
    grade?: number;
    theoreticGrade?: number,
    portfolioGrade?: number,
    coachingGrade?: number,
    place?: string;
    madeSampleExams?: boolean;
    sampleExamGrade?: number;
    presentAtExam?: boolean;
}

export const emptyStdDetail: StudentDetail = {
    initials: "",
    birthPlace: "",
    books: false,
    privateNumber: "",
    courseResults: [],
    mobileNumber: "",
    prefix: "",
    address: "",
    email: "",
    houseNumber: "",
    birthDate: "",
    firstName: "",
    lastName: "",
    place: "",
    sex: "",
    zipCode: ""
}
