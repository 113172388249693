import { CourseActions } from "./types";
import { CourseState } from "../../types/course";

const initialState: CourseState = {
    courseDetail: [],
    searchedCourseDetail: [],
    loadingParticipatingStudents: false,
    loadingAssignments: false,
    analyticLink: undefined,
    loadingAnalyticLink: false,
    loading: false,
    error: undefined,
};

export function courseReducer(state = initialState, action: typeof CourseActions): CourseState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case CourseActions.GET_COURSE_DETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case CourseActions.GET_COURSE_DETAIL_SUCCESS:
            return {
                ...state,
                courseDetail: action.response,
                searchedCourseDetail: action.response,
                loading: false,
            };
        case CourseActions.GET_COURSE_DETAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case CourseActions.GET_PARTICIPATINGSTUDENTS_REQUEST:
            return {
                ...state,
                loadingParticipatingStudents: true,
                error: undefined,
            };
        case CourseActions.GET_PARTICIPATINGSTUDENTS_SUCCESS:
            return {
                ...state,
                courseDetail: state.courseDetail ? state.courseDetail.map((courseDetailObj) => {
                    if (courseDetailObj.projectId === action.payload.projectId) {
                        let newCourseDetailObj = courseDetailObj;
                        newCourseDetailObj.participatingStudents = action.payload.participatingStudents;
                        return newCourseDetailObj;
                    } else {
                        return courseDetailObj;
                    }
                }) : [],
                loadingParticipatingStudents: false,
            };
        case CourseActions.GET_PARTICIPATINGSTUDENTS_ERROR:
            return {
                ...state,
                loadingParticipatingStudents: false,
                error: action.error,
            };
        case CourseActions.GET_COURSE_ASSIGNMENTS_REQUEST:
            return {
                ...state,
                loadingAssignments: true,
                error: undefined
            };
        case CourseActions.GET_COURSE_ASSIGNMENTS_SUCCESS:
            return {
                ...state,
                courseDetail: state.courseDetail ? state.courseDetail.map((courseDetailObj) => {
                    if (courseDetailObj.courseId === action.payload.courseId) {
                        let newCourseDetailObj = courseDetailObj;
                        newCourseDetailObj.assignments = action.payload.assignments ? action.payload.assignments : [];
                        return newCourseDetailObj;
                    } else {
                        return courseDetailObj;
                    }
                }) : [],
                loadingAssignments: false
            };
        case CourseActions.GET_COURSE_ASSIGNMENTS_ERROR:
            return {
                ...state,
                loadingAssignments: false,
                error: action.error
            };
        case CourseActions.GET_COURSE_GOOGLE_ANALYTICS_REQUEST:
            return {
                ...state,
                loadingAnalyticLink: true
            };
        case CourseActions.GET_COURSE_GOOGLE_ANALYTICS_SUCCESS:
            return {
                ...state,
                analyticLink: action.googleAnalyticsUrl,
                loadingAnalyticLink: false,
            };
        case CourseActions.GET_COURSE_GOOGLE_ANALYTICS_ERROR:
            return {
                ...state,
                loading: false,
                loadingAnalyticLink: action.error
            };
        case CourseActions.EDIT_COURSE_GOOGLE_ANALYTICS_REQUEST:
            return {
                ...state,
                loadingAnalyticLink: true
            };
        case CourseActions.EDIT_COURSE_GOOGLE_ANALYTICS_SUCCESS:
            return {
                ...state,
                loadingAnalyticLink: false,
            };
        case CourseActions.EDIT_COURSE_GOOGLE_ANALYTICS_ERROR:
            return {
                ...state,
                loadingAnalyticLink: false,
                error: action.error
            };
        case CourseActions.DELETE_COURSE_GOOGLE_ANALYTICS_REQUEST:
            return {
                ...state,
                loadingAnalyticLink: true
            };
        case CourseActions.DELETE_COURSE_GOOGLE_ANALYTICS_SUCCESS:
            return {
                ...state,
                loadingAnalyticLink: false,
            };
        case CourseActions.DELETE_COURSE_GOOGLE_ANALYTICS_ERROR:
            return {
                ...state,
                loadingAnalyticLink: false,
                error: action.error
            };
        default:
            return state;
    }
}