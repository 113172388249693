import { RegisterCourseActions } from "./types";
import { RegisterCourseState, RegisterCourse } from "../../types/registerCourse";
import { CourseDetail } from "../../types/course";
import { RegisterActions } from "../register/types";

export const emptyCourseDetail: CourseDetail = {
    courseId: undefined,
    projectId: undefined,
    category: undefined,
    type: undefined,
    address: undefined,
    zipCode: undefined,
    place: undefined,
    dates: [],
    examDate: undefined,
    certificate: undefined,
    exam: undefined,
    price: undefined,
    passed: undefined,
    isHomeStudy: undefined,
    amountOfParticipants: undefined,
    showHomework: false,
    showSampleExam: false,
    assignments: [],
    participatingStudents: [],
    role: undefined,
    pathname: undefined,
    expired: false,
    freeField1Icon: "FaQuestion",
    freeField2Icon: "FaQuestion"
};

export const emptyCourse: RegisterCourse = {
    category: undefined,
    type: undefined,
    place: undefined,
    date: undefined,
    completed: false,
}

const initialState: RegisterCourseState = {
    course: emptyCourse,
    categoryId: 0,
    categoryRegisterViaLinkOnly: false,
    categories: [],
    types: [],
    places: [],
    availableDates: [],
    courseDetail: emptyCourseDetail,
    freelanceBooks: false,
    loading: false,
};

export function registerCourseReducer(state = initialState, action: typeof RegisterCourseActions): RegisterCourseState {
    state.course.completed = (state.course.category && state.course.type && (state.course.type.courseId.slice(-1) === "S" || (state.course.type.eLearning && !state.course.type.eLearningHybrid))) || (state.course.category && state.course.type && state.course.place && state.course.date) ? true : false;
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case RegisterActions.SET_SHOPPINGCART_COURSE:
            return {
                ...state,
                course: emptyCourse,
            }
        case RegisterCourseActions.GET_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true,
                categories: [],
            };
        case RegisterCourseActions.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                categories: action.categories,
            };
        case RegisterCourseActions.GET_CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                categories: [],
            };
        case RegisterCourseActions.GET_TYPES_REQUEST:
            return {
                ...state,
                loading: true,
                types: [],
            };
        case RegisterCourseActions.GET_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                types: action.types,
            };
        case RegisterCourseActions.GET_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                types: [],
            };
        case RegisterCourseActions.GET_PLACES_REQUEST:
            return {
                ...state,
                loading: true,
                places: [],
            };
        case RegisterCourseActions.GET_PLACES_SUCCESS:
            return {
                ...state,
                loading: false,
                places: action.places,
            };
        case RegisterCourseActions.GET_PLACES_ERROR:
            return {
                ...state,
                loading: false,
                places: [],
            };
        case RegisterCourseActions.GET_AVAILABLE_DATES_REQUEST:
            return {
                ...state,
                loading: true,
                availableDates: [],
            };
        case RegisterCourseActions.GET_AVAILABLE_DATES_SUCCESS:
            return {
                ...state,
                loading: false,
                availableDates: action.availableDates,
            };
        case RegisterCourseActions.GET_AVAILABLE_DATES_ERROR:
            return {
                ...state,
                loading: false,
                availableDates: [],
            };
        case RegisterCourseActions.SET_CATEGORY:
            return {
                ...state,
                course: {
                    ...state.course,
                    category: action.category,
                    type: undefined,
                    place: undefined,
                    date: undefined
                }
            }
        case RegisterCourseActions.SET_TYPE:
            return {
                ...state,
                course: {
                    ...state.course,
                    type: action.courseType,
                    place: undefined,
                    date: undefined,
                    completed: ((state.course.type?.courseId.slice(-1) === "S" || state.course.type?.eLearning) && !state.course.type?.eLearningHybrid) ? true : false
                }
            }
        case RegisterCourseActions.SET_PLACE:
            return {
                ...state,
                course: {
                    ...state.course,
                    place: action.place,
                    date: undefined
                }
            }
        case RegisterCourseActions.SET_DATE:
            return {
                ...state,
                course: {
                    ...state.course,
                    date: action.availableDate,
                    completed: true,
                }
            }
        case RegisterCourseActions.SET_COURSE:
            return {
                ...state,
                course: action.course
            }
        case RegisterActions.RESET_REGISTER_STATE:
        case RegisterActions.REGISTER_SUCCESS:
            return initialState;
        case RegisterCourseActions.RESET_COURSE:
            return {
                ...state,
                course: emptyCourse
            }
        case RegisterCourseActions.GET_COURSE_DETAIL_REQUEST:
            return {
                ...state,
            };
        case RegisterCourseActions.GET_COURSE_DETAIL_SUCCESS:
            return {
                ...state,
                courseDetail: action.courseDetail,
            };
        case RegisterCourseActions.GET_COURSE_DETAIL_ERROR:
            return {
                ...state,
                courseDetail: emptyCourseDetail,
            };
        case RegisterCourseActions.RESET_COURSE_DETAIL:
            return {
                ...state,
                courseDetail: emptyCourseDetail,
            };
        case RegisterCourseActions.SET_FREELANCEBOOK:
            return {
                ...state,
                freelanceBooks: action.freelancebook,
            };
        case RegisterCourseActions.GET_CATEGORYREGISTERVIALINKONLY_REQUEST:
            return {
                ...state
            };
        case RegisterCourseActions.GET_CATEGORYREGISTERVIALINKONLY_SUCCESS:
            return {
                ...state,
                categoryRegisterViaLinkOnly: action.categoryRegisterViaLinkOnly
            };
        case RegisterCourseActions.GET_CATEGORYREGISTERVIALINKONLY_ERROR:
            return {
                ...state,
                categoryRegisterViaLinkOnly: false
            };
        default:
            return state;
    }
}