import React from "react"
import { MenuItem, ListItemText, makeStyles, Box } from "@material-ui/core/";

import { PageProps } from "./SideMenu";
import "./SideMenu.css";

const useStyles = makeStyles(theme => ({
    menuItemSelected: {
        minHeight: "75px",
        "&$selected": {
            backgroundColor: "#E8E8E8",
            borderLeft: "4px solid " + theme.palette.secondary.main
        }
    },
    selected: {},
}));

interface SideMenuItemProps {
    role: string;
    selected: boolean;
    navigateToPage: (path: string) => void;
}

const SideMenuItem = ({ allowedRoles, icon, path, title, role, selected, navigateToPage }: PageProps & SideMenuItemProps) => {
    const classes = useStyles();
    const GenericIcon: React.FC<any> = icon as React.FC;

    return (
        <Box display={allowedRoles.includes(role) ? "block" : "none"} key={title}>
            <MenuItem
                classes={{
                    root: classes.menuItemSelected,
                    selected: classes.selected
                }}
                selected={selected}
                onClick={() => navigateToPage(path)}
                button >
                <div id="menu-item-wrapper">
                    <div id="menu-item-icon"><GenericIcon /></div>
                    <div id="menu-item-text"><ListItemText primary={title} /></div>
                </div>
            </MenuItem>
        </Box>
    );
};

export default SideMenuItem;