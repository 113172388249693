import { Dispatch } from "redux";

import { CourseActions } from "./types";
import { courseService } from "../../services/course.service";
import { courseDetailService } from "../../services/courseDetail.service";
import { toast } from "react-toastify";

export const getCourseDetails = (studentId?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CourseActions.GET_COURSE_DETAIL_REQUEST });
        const response = await courseDetailService.courseDetails(studentId);
        dispatch({ type: CourseActions.GET_COURSE_DETAIL_SUCCESS, response });
    } catch (error) {
        dispatch({ type: CourseActions.GET_COURSE_DETAIL_ERROR, error });
    }
};

export const getCourseDetailsAdmin = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CourseActions.GET_COURSE_DETAIL_REQUEST });
        const response = await courseDetailService.courseDetailsAdmin();
        dispatch({ type: CourseActions.GET_COURSE_DETAIL_SUCCESS, response });
    } catch (error) {
        dispatch({ type: CourseActions.GET_COURSE_DETAIL_ERROR, error });
    }
};

export const getParticipatingStudents = (projectId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CourseActions.GET_PARTICIPATINGSTUDENTS_REQUEST });
        const participatingStudents = await courseDetailService.participatingStudents(projectId);
        dispatch({ type: CourseActions.GET_PARTICIPATINGSTUDENTS_SUCCESS, payload: { participatingStudents, projectId } });
    } catch (error) {
        dispatch({ type: CourseActions.GET_PARTICIPATINGSTUDENTS_ERROR, error });
    }
};

export const getCourseGoogleAnalytics = (courseId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CourseActions.GET_COURSE_GOOGLE_ANALYTICS_REQUEST });
        const googleAnalyticsUrl = await courseService.getCourseGoogleAnalyticsUrl(courseId);
        dispatch({ type: CourseActions.GET_COURSE_GOOGLE_ANALYTICS_SUCCESS, googleAnalyticsUrl });
    } catch (error) {
        dispatch({ type: CourseActions.GET_COURSE_GOOGLE_ANALYTICS_ERROR, error });
    }
};

export const editCourseGoogleAnalytics = (courseId: string, url: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CourseActions.EDIT_COURSE_GOOGLE_ANALYTICS_REQUEST });
        await courseService.editCourseGoogleAnalyticsUrl(courseId, url);
        dispatch({ type: CourseActions.EDIT_COURSE_GOOGLE_ANALYTICS_SUCCESS });
        toast.success("Google analytics url aangepast")
    } catch (error) {
        dispatch({ type: CourseActions.EDIT_COURSE_GOOGLE_ANALYTICS_ERROR, error });
    }
};

export const deleteCourseGoogleAnalytics = (courseId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: CourseActions.DELETE_COURSE_GOOGLE_ANALYTICS_REQUEST });
        await courseService.deleteCourseGoogleAnalyticsUrl(courseId);
        dispatch({ type: CourseActions.DELETE_COURSE_GOOGLE_ANALYTICS_SUCCESS });
        toast.success("Google analytics url verwijderd")
    } catch (error) {
        dispatch({ type: CourseActions.DELETE_COURSE_GOOGLE_ANALYTICS_ERROR, error });
    }
};