import { dashboardCardsFreelancer, dashboardCardsAdmin, dashboardCardsCompany, dashboardCardsStudent } from "../constants/DashboardCards"
import { Role } from "../types/enum";

export const getDashboardCards = (role: string | null) => {
    if (role === Role.ADMIN) {
        return dashboardCardsAdmin;
    } else if (role === Role.COMPANY) {
        return dashboardCardsCompany;
    } else if (role === Role.FREELANCER) {
        return dashboardCardsFreelancer;
    } else if (role === Role.STUDENT) {
        return dashboardCardsStudent;
    } else {
        return [];
    }
};