import { createActionsWithPrefix } from "../../helperfunctions/action";

export const RegisterCourseActions = createActionsWithPrefix("REGISTERCOURSE/", {
    GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
    GET_TYPES_REQUEST: "GET_TYPES_REQUEST",
    GET_TYPES_SUCCESS: "GET_TYPES_SUCCESS",
    GET_TYPES_ERROR: "GET_TYPES_ERROR",
    GET_PLACES_REQUEST: "GET_PLACES_REQUEST",
    GET_PLACES_SUCCESS: "GET_PLACES_SUCCESS",
    GET_PLACES_ERROR: "GET_PLACES_ERROR",
    GET_AVAILABLE_DATES_REQUEST: "GET_AVAILABLE_DATES_REQUEST",
    GET_AVAILABLE_DATES_SUCCESS: "GET_AVAILABLE_DATES_SUCCESS",
    GET_AVAILABLE_DATES_ERROR: "GET_AVAILABLE_DATES_ERROR",
    SET_CATEGORY: "SET_CATEGORY",
    SET_TYPE: "SET_TYPE",
    SET_PLACE: "SET_PLACE",
    SET_DATE: "SET_DATE",
    SET_COURSE: "SET_COURSE",
    RESET_COURSE: "RESET_COURSE",
    GET_COURSE_DETAIL_REQUEST: "GET_COURSE_DETAIL_REQUEST",
    GET_COURSE_DETAIL_SUCCESS: "GET_COURSE_DETAIL_SUCCESS",
    GET_COURSE_DETAIL_ERROR: "GET_COURSE_DETAIL_ERROR",
    RESET_COURSE_DETAIL: "RESET_COURSE_DETAIL",
    SET_FREELANCEBOOK: "SET_FREELANCEBOOK",
    GET_CATEGORYREGISTERVIALINKONLY_REQUEST: "GET_CATEGORYREGISTERVIALINKONLY_REQUEST",
    GET_CATEGORYREGISTERVIALINKONLY_SUCCESS: "GET_CATEGORYREGISTERVIALINKONLY_SUCCESS",
    GET_CATEGORYREGISTERVIALINKONLY_ERROR: "GET_CATEGORYREGISTERVIALINKONLY_ERROR",
    UPDATE_CATEGORYREGISTERVIALINKONLY_REQUEST: "UPDATE_CATEGORYREGISTERVIALINKONLY_REQUEST",
    UPDATE_CATEGORYREGISTERVIALINKONLY_SUCCESS: "UPDATE_CATEGORYREGISTERVIALINKONLY_SUCCESS",
    UPDATE_CATEGORYREGISTERVIALINKONLY_ERROR: "UPDATE_CATEGORYREGISTERVIALINKONLY_ERROR"
});