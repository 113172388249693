import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import CourseTypesComponent from "./CourseTypesComponent";
import { getCategoryRegisterViaLinkOnly, getTypes, updateCategoryRegisterViaLinkOnly } from "../../../store/registerCourse/actions";
import { ApplicationState } from "../../../store";
import { RouteComponentProps } from "react-router";
import { CourseCategory, CourseType } from "../../../types/registerCourse";
import { getQuestions, createQuestion, updateQuestion, deleteQuestion, createAnswer, updateAnswer, deleteAnswer } from "../../../store/question/actions";
import { QuestionState, QuestionInput, AnswerInput } from "../../../types/question";
import QuestionComponent from "../Question/QuestionComponent";
import TestExamAdminContainer from "../SampleExam/TestExamAdminContainer";
import { RegisterUserType } from "../../../types/enum";

interface CourseTypesContainerProps {
    categories: CourseCategory[];
    types: CourseType[];
    question: QuestionState;
    categoryRegisterViaLinkOnly: boolean;
    getTypes: (categoryId: number, userType: RegisterUserType) => void;
    getQuestions: (categoryId: number) => void;
    createQuestion: (question: QuestionInput) => void;
    updateQuestion: (questionId: number, question: QuestionInput) => void;
    deleteQuestion: (questionId: number) => void;
    createAnswer: (answer: AnswerInput) => void;
    updateAnswer: (answerId: number, answer: AnswerInput) => void;
    deleteAnswer: (answerId: number, questionId: number) => void;
    getCategoryRegisterViaLinkOnly: (categoryId: number) => void;
    updateCategoryRegisterViaLinkOnly: (categoryId: number, categoryRegisterViaLinkOnly: boolean) => void;
}

interface MatchParams {
    categoryId: string;
}

const mapStateToProps = ({ registerCourse: { types, categoryRegisterViaLinkOnly }, question, auth }: ApplicationState) => {
    return ({
        types,
        categoryRegisterViaLinkOnly,
        question,
        auth
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getTypes, getQuestions, createQuestion, updateQuestion, deleteQuestion, createAnswer, updateAnswer, deleteAnswer, getCategoryRegisterViaLinkOnly, updateCategoryRegisterViaLinkOnly }, dispatch);
};

const CourseTypesContainer = ({ types, match, question, getTypes, getQuestions, createQuestion, updateQuestion, deleteQuestion, createAnswer, updateAnswer, deleteAnswer, categoryRegisterViaLinkOnly, getCategoryRegisterViaLinkOnly, updateCategoryRegisterViaLinkOnly}: CourseTypesContainerProps & RouteComponentProps<MatchParams>) => {
    const categoryId = Number(match.params.categoryId);

    useEffect(() => {
        getTypes(categoryId, RegisterUserType.Everyone);
        getQuestions(categoryId);
        getCategoryRegisterViaLinkOnly(categoryId);
    }, [categoryId, categoryRegisterViaLinkOnly, getTypes, getQuestions, getCategoryRegisterViaLinkOnly]);

    return (
        <>
            <CourseTypesComponent
                types={types}
                categoryId={categoryId}
                categoryRegisterViaLinkOnly={categoryRegisterViaLinkOnly}
                updateCategoryRegisterViaLinkOnly={updateCategoryRegisterViaLinkOnly}
            />
            <QuestionComponent
                categoryId={categoryId}
                questions={question.questions}
                loading={question.loading}
                error={question.error}
                createQuestion={createQuestion}
                updateQuestion={updateQuestion}
                deleteQuestion={deleteQuestion}
                createAnswer={createAnswer}
                updateAnswer={updateAnswer}
                deleteAnswer={deleteAnswer}
            />
            <TestExamAdminContainer categoryId={categoryId} />
        </>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseTypesContainer);