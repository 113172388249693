import React from "react";
import { TextField, FormHelperText } from "@material-ui/core";
import { useInputStyles } from "../../styles/input";

export interface TextFieldProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: string;
    };
    textarea: boolean;
    handleChange?: (v: string) => void;
    handleBlur?: () => void;
}

const RenderTextField = ({
    input,
    label,
    meta: { touched, error },
    handleBlur,
    handleChange,
    ...custom
}: TextFieldProps) => {
    const inputClasses = useInputStyles();
    if (input.value !== undefined || input.value !== "") touched = true;

    return (
        <>
            <TextField
                fullWidth
                label={label}
                error={touched && !!error}
                autoComplete="on"
                classes={{
                    root: inputClasses.root
                }}
                FormHelperTextProps={{
                    classes: {
                        root: inputClasses.formHelperText,
                    }
                }}
                InputProps={{
                    classes: {
                        focused: inputClasses.cssFocused,
                    },
                }}
                {...input}
                {...custom}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    input.onChange(e);
                    handleChange && handleChange(e.target.value);
                }}
                onBlur={(e: React.SyntheticEvent<EventTarget>) => handleBlur && handleBlur()}
            />
            {(touched && !!error) && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
    );
}

export default RenderTextField;