import React from "react";
import { TextField, FormHelperText, InputAdornment, makeStyles, Theme } from "@material-ui/core";
import { useInputStyles } from "../../styles/input";

export interface TextFieldProps {
    icon: string;
    input: string;
    label: string;
    meta: {
        touched: boolean;
        error: string;
    };
    custom: any
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        color: theme.palette.secondary.main,
    },
}));

const RenderTextFieldLogin = ({
    input,
    label,
    icon,
    meta: { touched, error },
    ...custom
}: TextFieldProps) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();

    return (
        <>
            <TextField
                fullWidth
                label={label}
                error={touched && !!error}
                variant="outlined"
                InputProps={icon ? {
                    startAdornment: (
                        <InputAdornment position="start">
                            <i className={`${icon} ${classes.icon}`}></i>
                        </InputAdornment>
                    ),
                    classes: {
                    },
                } : {
                    classes: {
                    },
                }}
                classes={{
                    root: inputClasses.root
                }}
                FormHelperTextProps={{
                    classes: {
                        root: inputClasses.formHelperText,
                    }
                }}
                {...input}
                {...custom}
            />
            {(touched && !!error) && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
    );
}

export default RenderTextFieldLogin;