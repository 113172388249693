import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, ListItemText, DialogActions, Button, CardActionArea, Typography, useTheme, Theme } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { FaCertificate } from "react-icons/fa";
import { animated, config, useSpring } from "react-spring";
import { SampleExamState } from "../../types/sampleExam";
import { renderSkeleton } from "../FeedBack/Skeleton";
import Error from "../FeedBack/Error";

interface TestExamModalProps {
    testExams: SampleExamState
    course: string
    open: boolean;
    handleClose: () => void;
}

const TestExamModal = ({ open, handleClose, course, testExams, history }: TestExamModalProps & RouteComponentProps) => {
    const theme: Theme = useTheme();
    const [hover, setHover] = useState(false)
    const navigateToPage = (id: string) => {
        history.push(`/proefexamen/${id}/${course}`)
    }
    const hoverProps = useSpring({
        to: { transform: hover ? " translateX(-3px)" : " translateX(0px)" },
        config: config.wobbly
    });
    return (
        <Dialog open={open} maxWidth="sm" fullWidth={true} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Proefexamens</DialogTitle>
            <DialogContent >
                {testExams.loading && renderSkeleton(1, { xs: 12, sm: 12, lg: 8 }, 10, "text")}
                {testExams.error && <Error />}
                {((testExams.exams && testExams.exams.length === 0) || !testExams.exams) && !testExams.loading && <Typography variant="body1">Er zijn geen proefexamens gevonden voor deze cursus.</Typography>}
                {!testExams.loading && !testExams.error && testExams.exams && testExams.exams.length > 0 && <List>
                    {testExams.exams.map((exam, index) => {
                        return (
                            <div key={index}>
                                <CardActionArea onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => navigateToPage(exam.sampleExamId.toString())}>
                                    <ListItem style={{ width: "500px" }}>
                                        <animated.div style={hoverProps}>
                                            <ListItemIcon>
                                                <FaCertificate color={theme.palette.secondary.main} size="1.5em" />
                                            </ListItemIcon>
                                        </animated.div>
                                        <ListItemText
                                            primary={course}
                                            secondary={<Typography variant="body1" style={{ color: '#000' }}>{exam.duration} minuten en {exam.amountOfQuestions} vragen</Typography>}
                                        />
                                    </ListItem>
                                </CardActionArea>
                            </div>
                        )
                    }
                    )}
                </List>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuleren</Button>
            </DialogActions>
        </Dialog>
    )
}

export default withRouter(TestExamModal)
