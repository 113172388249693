import { useState, useEffect } from "react";
import { isNil } from "lodash";
import { connect } from "react-redux";
import { reduxForm, Field, InjectedFormProps, getFormValues } from "redux-form";
import { animated, useSpring, config } from "react-spring";
import { Link, Grid, Theme, Typography, Button, Divider, MenuItem, FormControlLabel, Checkbox, Avatar, Select, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import RenderTextField from "../../TextField";
import RenderTextFieldDropdown from "../../TextField/TextFieldDropdown";
import { required, validLongString, validString } from "../../../validators/form";
import { ApplicationState } from "../../../store";
import { useTextStyles } from "../../../styles/text";
import { useButtonStyles } from "../../../styles/button";
import { PaymentMethod, Website } from "../../../types/enum";
import { getWebsite } from "../../../helperfunctions/getWebsite";
import { getIssuerLogo } from "../../../helperfunctions/getIssuerLogo";
import messages from "../../../constants/messages";
import IssuersContainer from "../../Issuers/IssuersContainer";
import { foundOptionsStandard } from "../../../helperfunctions/foundOptions";
import { trimFields } from "../../../helperfunctions/trimFields";
import { Issuer } from "../../../types/payment";
import { parseMoney } from "../../../helperfunctions/money";
import { RegisterPriceResponse } from "../../../types/register";
import CustomPrompt from "../../notification/CustomPrompt";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) => ({
    divider: {
        backgroundColor: "#EDEDED",
        opacity: 0.5,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    issuerLogo: {
        width: theme.spacing(8),
        height: theme.spacing(5),
        borderRadius: 5
    },
}));

export interface PaymentFormData {
    paymentMethod: string;
    discountCode?: string;
    discountString?: string;
    note: string;
    issuerId?: string;
    issuer?: Issuer;
    remark?: string;
    paymentFeature?: string;
}

interface PaymentProps {
    onSubmitFunction: (registerPayment: PaymentFormData) => void;
    handleNext: () => void;
    handleBack: () => void;
    checkDiscountCode: (discountCode: string) => void;
    discountString?: string;
    downloadAgreement: () => void;
    prices: RegisterPriceResponse[];
    formValues: any;
    paymentMethodsAllowed: string[];
}

const Payment = ({ valid, formValues, prices, downloadAgreement, checkDiscountCode, handleSubmit, submitting, handleNext, handleBack, onSubmitFunction, discountString, paymentMethodsAllowed }: PaymentProps & InjectedFormProps<PaymentFormData, PaymentProps>) => {
    const classes = useStyles();
    const textClasses = useTextStyles();
    const buttonClasses = useButtonStyles();
    const [paymentMethod, setPaymentMethod] = useState<"I" | "A">('I');
    const [openIssuerModal, setOpenIssuerModal] = useState(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [selectedIssuer, setSelectedIssuer] = useState<Issuer | undefined>(undefined);
    const [textColor, setTextColor] = useState("#000000");

    const [discount, setDiscountCode] = useState<string>("");

    const handleBlur = () => {
        checkDiscountCode(discount);
    };

    const initialIssuer: Issuer | undefined = formValues?.issuer;
    useEffect(() => {
        setSelectedIssuer(initialIssuer);
    }, [initialIssuer, setSelectedIssuer]);

    let initialPaymentMethod: "I" | "A" | undefined = formValues?.paymentMethod;

    if ((initialPaymentMethod === "I") && !(paymentMethodsAllowed.includes(PaymentMethod.Ideal))) {
        initialPaymentMethod = "A";
    }
    else if ((initialPaymentMethod === "A") && !(paymentMethodsAllowed.includes(PaymentMethod.Invoice))) {
        initialPaymentMethod = "I";
    }

    useEffect(() => {
        initialPaymentMethod && setPaymentMethod(initialPaymentMethod);
    }, [initialPaymentMethod, setPaymentMethod]);

    const paymentProps = useSpring({
        from: { opacity: 0, transform: "translateX(200px)" },
        to: { opacity: 1, transform: "translateX(-0px)" },
        config: config.stiff
    });

    const totalPrice = (prices && prices.length > 0 && prices.reduce((a, b) => ({ price: (a.price || 0) + (b.price || 0) })).price) || 0;
    const totalPriceWithoutBtw = totalPrice / 1.21;

    return (
        <form onSubmit={handleSubmit((data: PaymentFormData): void => {
            if (paymentMethod === 'I') {
                data.paymentMethod = 'I';
                data.issuerId = undefined;
                data.issuer = undefined;
            } else {
                data.paymentMethod = "A";
                data.issuerId = undefined;
                data.issuer = undefined;
            }
            data = trimFields(data)
            onSubmitFunction(data);
            handleNext();
        })}>
            <animated.div style={paymentProps}>
                <CustomPrompt when={!valid} />
                <IssuersContainer setOpen={(open) => setOpenIssuerModal(open)} open={openIssuerModal} modal setSelectedIssuer={(issuer) => setSelectedIssuer(issuer)} />
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                        <Typography variant="h6">Betaling</Typography>
                        <Divider classes={{
                            root: classes.divider
                        }} />
                    </Grid>
                    <Grid item xs={12} md={paymentMethod === 'I' ? 8 : 12}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>BETAALMETHODE</Typography>
                            <Select variant="outlined" fullWidth style={{ marginTop: 8 }}
                                name="paymentMethod"
                                value={paymentMethod}
                                onChange={(e: any) => {
                                    setSelectedIssuer(undefined);
                                    setPaymentMethod(e.target.value);
                                }}
                            >
                                {paymentMethodsAllowed.includes(PaymentMethod.Invoice) &&
                                    <MenuItem value={"A"}>Factuur</MenuItem>

                                }
                                {paymentMethodsAllowed.includes(PaymentMethod.Ideal) &&
                                    <MenuItem value={"I"}>iDeal</MenuItem>
                                }
                            </Select>
                        </div>
                    </Grid>
                    {getWebsite() === Website.HSN &&
                        <>
                            <Grid item xs={12} md={8}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>KORTINGSCODE</Typography>
                                    <Field
                                        name="discountCode"
                                        variant="outlined"
                                        validate={[validString]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                        handleChange={(value: string) => setDiscountCode(value)}
                                        handleBlur={handleBlur}
                                    />
                                </div>
                            </Grid>
                            {!isNil(discountString) &&
                                <Grid item xs={12} md={4}>
                                    <div className="textfield-spacing textfield-spacing-h">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>KORTING</Typography>
                                        <Paper style={{ display: 'table', height: 70 }} elevation={0}>
                                            <Typography style={{ display: 'table-cell', verticalAlign: 'middle' }} variant='subtitle2'>{discountString}</Typography>
                                        </Paper>
                                    </div>
                                </Grid>
                            }
                        </>
                    }

                    <Grid item xs={12} md={4}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>PRIJS</Typography>
                            {getWebsite() !== Website.HOC && <Paper style={{ display: 'table', height: 70 }} elevation={0}>
                                <Typography style={{ display: 'table-cell', verticalAlign: 'middle' }} variant='subtitle2'>Totaal: <b>{parseMoney(totalPrice)}</b> </Typography>
                            </Paper>}
                            {getWebsite() === Website.HOC && <>
                            <Paper style={{ display: 'table', height: 25 }} elevation={0}>
                                <Typography style={{ display: 'table-cell', verticalAlign: 'middle' }} variant='subtitle2'>Cursuskosten: <b>{parseMoney(totalPriceWithoutBtw)}</b> </Typography>
                            </Paper>
                            <Paper style={{ display: 'table', height: 20 }} elevation={0}>
                                <Typography style={{ display: 'table-cell', verticalAlign: 'middle' }} variant='subtitle2'>Totaal incl. BTW: <b>{parseMoney(totalPrice)}</b> </Typography>
                            </Paper></>}
                        </div>
                    </Grid>

                    <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                        <Typography variant="h6">Overig</Typography>
                        <Divider classes={{
                            root: classes.divider
                        }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography classes={{ root: `${textClasses.label}` }}>HOE HEEFT U ONS GEVONDEN?</Typography>
                        <Field
                            name="note"
                            validate={[required, validString]}
                            component={RenderTextFieldDropdown}
                        >
                            {foundOptionsStandard && foundOptionsStandard.map((option: any, index: number) => <MenuItem value={option} >{option}</MenuItem>)}
                        </Field>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: ".5em", marginTop: ".5em" }}>
                        <Typography>Eventueel betalingskenmerk / ordernummer</Typography>
                        <Field
                            name="paymentFeature"
                            variant="outlined"
                            validate={[validString]}
                            placeholder="Optioneel"
                            component={RenderTextField}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: ".5em", marginTop: ".5em" }}>
                        <Typography>Eventuele opmerkingen</Typography>
                        <Field
                            name="remark"
                            variant="outlined"
                            validate={[validLongString]}
                            placeholder="Optioneel"
                            component={RenderTextField}
                            props={{ multiline: true }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: ".5em", marginTop: ".5em" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(e: any) => setChecked(!checked)}
                                    name="termsOfService"
                                    required
                                />
                            }
                            label={<Typography>Ik ben bekend en ga akkoord met de <Link onClick={() => downloadAgreement()} >Studievoorwaarden</Link></Typography>}
                            classes={{
                                root: `${textClasses.subLabel}`
                            }}
                        />
                    </Grid>
                    <Grid container justify="space-between">
                        <Button
                            onClick={handleBack}
                            classes={{
                                root: buttonClasses.secondaryApp,
                            }}>
                            Terug
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={submitting || !checked || !valid}
                            type="submit"
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            Volgende
                        </Button>
                    </Grid>
                </Grid>
            </animated.div>
        </form>
    );
}

const DecoratedInitializeFromStateFormFunction = reduxForm<PaymentFormData, PaymentProps>({
    form: "paymentForm"
})(Payment);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let initialValues = {
            discountString: state.discount.discountString,
            discountCode: state.discount.discountCode,
            ...state.register.registerPayment
        }
        initialValues = trimFields(initialValues)

        return ({
            initialValues: initialValues,
            formValues: getFormValues("paymentForm")(state),
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;