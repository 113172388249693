import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router";

import StudentAddComponent from "./StudentAddComponent";
import { createNewStudent } from "../../store/student/actions";
import { StudentState, StudentDetail } from "../../types/student";
import { getAddress } from "../../store/register/actions";

interface StudentAddContainerProps {
    student: StudentState;
    createNewStudent: (newStudent: StudentDetail) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
}

const mapStateToProps = ({ student }: StudentAddContainerProps) => {
    return ({
        student,
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ createNewStudent, getAddress }, dispatch);
};

const StudentAddContainer = ({ student, createNewStudent, getAddress }: StudentAddContainerProps & RouteComponentProps) => {
    return <StudentAddComponent
        student={student}
        getAddress={getAddress}
        createNewStudent={createNewStudent}
    />;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentAddContainer));