import React, { useState, useEffect } from "react";
import { InjectedFormProps, reduxForm, Field, getFormValues } from "redux-form";
import { Grid, Typography, Button, makeStyles, Theme, Divider, Paper, Box, FormControlLabel, Checkbox, MenuItem } from "@material-ui/core";

import { useButtonStyles } from "../../../styles/button";
import { useTextStyles } from "../../../styles/text";
import { required, isEmail, isPhone, validString } from "../../../validators/form";
import RenderTextField from "../../TextField";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { UserInformation, RelationDetail } from "../../../types/userInformation";
import AutofillComponent from "../../../helperfunctions/AutofillComponent";
import { trimFields } from "../../../helperfunctions/trimFields";
import { useHistory } from "react-router";
import CustomPrompt from "../../notification/CustomPrompt";
import RenderTextFieldDropdown from "../../TextField/TextFieldDropdown";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    }
}));

interface EditRelationProps {
    formValues: any
    requestEdit: (userInformation: UserInformation) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
}

const EditRelationComponent = ({ valid, handleSubmit, getAddress, formValues, requestEdit }: EditRelationProps & InjectedFormProps<RelationDetail, EditRelationProps>) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const [byMail, setByMail] = useState<boolean>(false);
    const history = useHistory();
    const textClasses = useTextStyles();

    const initialMail = !!formValues?.byMail;
    useEffect(() => {
        setByMail(initialMail);
    }, [initialMail, setByMail])

    return (
        <Grid container direction="column">
            <CustomPrompt when={!valid} />
            <Grid item xs={12} className={classes.title}>
                <Typography classes={{
                    root: `${textClasses.pageTitle}`
                }}>Wijzig bedrijfsgegevens</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <form onSubmit={handleSubmit((userInformationInput: RelationDetail): void => {
                        userInformationInput = trimFields(userInformationInput)
                        const userInformation: UserInformation = { relation: { ...userInformationInput, byMail } };
                        requestEdit(userInformation);
                    })}>
                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Bedrijfsgegevens</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>BEDRIJFSNAAM</Typography>
                                    <Field
                                        name="companyName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <AutofillComponent identifier="EDIT_RELATION" formValues={formValues} getAddress={getAddress} />
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Contactgegevens</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Grid container direction="column" justify="center">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>GESLACHT</Typography>
                                        <Field
                                            name="sex"
                                            validate={[validString, required]}
                                            component={RenderTextFieldDropdown}
                                        >
                                            <MenuItem value={"H"} >De heer</MenuItem>
                                            <MenuItem value={"M"} >Mevrouw</MenuItem>
                                        </Field>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORLETTERS</Typography>
                                    <Field
                                        name="initials"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORNAAM</Typography>
                                    <Field
                                        name="firstName"
                                        variant="outlined"
                                        validate={[validString]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TUSSENVOEGSEL</Typography>
                                    <Field
                                        name="prefix"
                                        variant="outlined"
                                        validate={[validString]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>ACHTERNAAM</Typography>
                                    <Field
                                        name="lastName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>EMAIL 1</Typography>
                                    <Field
                                        name="email"
                                        variant="outlined"
                                        validate={[validString, required, isEmail]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>EMAIL 2</Typography>
                                    <Field
                                        name="contactEmail"
                                        variant="outlined"
                                        validate={[validString, isEmail]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TELEFOON</Typography>
                                    <Field
                                        name="workNumber"
                                        variant="outlined"
                                        validate={[validString, required, isPhone]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TELEFOON MOBIEL</Typography>
                                    <Field
                                        name="mobileNumber"
                                        variant="outlined"
                                        validate={[validString, isPhone]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                                <Typography variant="h6">Factuurgegevens</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>FACTURATIE EMAIL</Typography>
                                    <Field
                                        name="invoiceEmail"
                                        variant="outlined"
                                        validate={[validString, isEmail]}
                                        type="email"
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ marginBottom: "auto", marginTop: "auto", paddingLeft: ".5em" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="byMail"
                                            value={byMail}
                                            checked={byMail}
                                            onClick={() => setByMail(!byMail)}
                                        />
                                    }
                                    label={"afwijkend factuuradres"}
                                    classes={{
                                        root: `${textClasses.subLabel}`
                                    }}
                                />
                            </Grid>
                            <Grid container>
                                <Box display={byMail ? "" : "none"} style={{ width: "100%" }}>
                                    <Grid container>
                                        <AutofillComponent
                                            identifier="EDIT_COMPANY_INVOICE"
                                            formValues={formValues}
                                            shouldValidate={byMail}
                                            getAddress={getAddress}
                                            config="invoice"
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid container justify="space-between">
                                <Button
                                    onClick={() => history.push('/bedrijfsgegevens')}
                                    classes={{
                                        root: buttonClasses.primary,
                                    }}
                                >
                                    Annuleren
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!valid}
                                    classes={{
                                        root: buttonClasses.primary,
                                    }}
                                >
                                    Opslaan
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid >
    );
};

const DecoratedInitializeFromStateFormFunction = reduxForm<RelationDetail, EditRelationProps>({
    form: "EditRelationDataForm",
    enableReinitialize: true,
})(EditRelationComponent);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let relationDetail = state.userInformation.userInformation.relation;
        relationDetail = trimFields(relationDetail);

        return ({
            formValues: getFormValues("EditRelationDataForm")(state),
            initialValues: relationDetail,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;