import React from "react";
import { Typography, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { passwordChecker } from "../../helperfunctions/passwordChecker";
import { useTextStyles } from "../../styles/text";

export interface TextFieldPasswordCheckerProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: string;
    };
    custom: any
}

const useStyles = makeStyles(() => ({
    input: {
        backgroundColor: "#fff",
        borderRadius: "4px"
    },
    color: {
        backgroundColor: "#fff"
    },
    veryUnsafe: {
        backgroundColor: "#c0392b"
    },
    unsafe: {
        backgroundColor: "#e74c3c"
    },
    moderate: {
        backgroundColor: "#f39c12"
    },
    safe: {
        backgroundColor: "#f1c40f"
    },
    verySafe: {
        backgroundColor: "#2ecc71"
    }
}));

const RenderTextFieldPasswordChecker = ({
    input,
    label,
    meta: { touched, error },
    ...custom
}: TextFieldPasswordCheckerProps) => {
    const score = passwordChecker(input.value);
    const classes = useStyles();
    const textClasses = useTextStyles();

    const getScoreClass = (): string => {
        switch (true) {
            case (score <= 20):
                return classes.veryUnsafe;
            case (score <= 40):
                return classes.unsafe;
            case (score <= 60):
                return classes.moderate;
            case (score <= 80):
                return classes.safe;
            case (score <= 100):
                return classes.verySafe;
            default:
                return classes.veryUnsafe;
        }
    }

    return (
        <div id="textfield-wrapper">     
            <div id="login-secondary-action-wrapper" className="center-v">
                <Grid item xl={3} xs={4}>
                    {!!score && <Typography className={`${textClasses.subLabel} ${textClasses.light}`}>
                        Wachtwoord sterkte
                    </Typography>}
                </Grid>
                <Grid item xl={9} xs={8}>
                    <LinearProgress
                        classes={{
                            root: classes.color,
                            colorPrimary: classes.color,
                            barColorPrimary: getScoreClass()
                        }}
                        variant="determinate"
                        value={score}
                    />
                </Grid>
            </div>
        </div>
    );
}

export default RenderTextFieldPasswordChecker;
