import { useState } from "react"
import { connect } from "react-redux";
import { Paper, Link, Grid, CardContent, Card, Typography, ListItemAvatar, Avatar, IconButton, makeStyles, Divider, MenuItem, Button, Tooltip, FormControlLabel, Checkbox, Select } from "@material-ui/core";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { FaBook, FaEdit, FaTrash } from "react-icons/fa";

import { useTextStyles } from "../../styles/text";
import { Registration, RegisterPriceResponse } from "../../types/register";
import { validString, isNumber, validLongString } from "../../validators/form";
import RenderTextField from "../TextField";
import { useButtonStyles } from "../../styles/button";
import EditShoppingCartContainer from "../ShoppingCart/EditShoppingCartContainer";
import { isNil, omit } from "lodash";
import { getWebsite } from "../../helperfunctions/getWebsite";
import { Website, Role, PaymentMethod } from "../../types/enum";
import { RegisterCourse } from "../../types/registerCourse";
import { ApplicationState } from "../../store";
import IssuersContainer from "../Issuers/IssuersContainer";
import { getIssuerLogo } from "../../helperfunctions/getIssuerLogo";
import messages from "../../constants/messages";
import { trimFields } from "../../helperfunctions/trimFields";
import { Issuer } from "../../types/payment";
import { parseMoney } from "../../helperfunctions/money";
import { useHistory } from "react-router";
import CustomPrompt from "../notification/CustomPrompt";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { toast } from "react-toastify";

interface CheckoutPaymentProps {
    checkoutItems: Registration[]
    onRemove: (index: number) => void
    handleNext: (paymentData: CheckoutPaymentFormData) => void;
    setCourse: (course: RegisterCourse) => void;
    discountString?: string;
    checkDiscountCode: (discountCode: string) => void;
    prices: RegisterPriceResponse[];
    downloadAgreement: () => void;
    role: string;
    editShoppingCartItemFreelanceBooks: (id: number, freelanceBooks: boolean) => void;
    specialPriceAgreement: boolean;
    setKhnId: (khnId: string) => void;
    paymentMethodsAllowed: string[];
}

export interface CheckoutPaymentFormData {
    paymentMethod: string;
    discountCode?: string;
    discountString?: string;
    note?: string;
    khnId?: string;
    issuerId?: string
    remark?: string;
    paymentFeature?: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        overflowX: "auto",
        backgroundColor: theme.palette.background.paper,
    },
    table: {
        minWidth: 1300
    },
    avatar: {
        margin: 10,
        color: "#fff",
        backgroundColor: theme.palette.secondary.main,
    },
    divider: {
        backgroundColor: "#EDEDED",
        opacity: 0.5,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    listText: {
        maxWidth: "400px"
    },
    studentText: {
        height: "100%",
    },
    issuerLogo: {
        width: theme.spacing(8),
        height: theme.spacing(5),
        borderRadius: 5
    }
}));

const CheckoutPayment = ({ valid, setKhnId, specialPriceAgreement, role, editShoppingCartItemFreelanceBooks, downloadAgreement, checkDiscountCode, discountString, setCourse, checkoutItems, onRemove, handleSubmit, submitting, handleNext, prices, paymentMethodsAllowed }: CheckoutPaymentProps & InjectedFormProps<CheckoutPaymentFormData, CheckoutPaymentProps>) => {
    const textClasses = useTextStyles();
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const [openCourse, setOpenCourse] = useState(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [openIssuerModal, setOpenIssuerModal] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<"A" | "I">(specialPriceAgreement ? 'A' : 'I');
    const [selectedIssuer, setSelectedIssuer] = useState<Issuer | undefined>(undefined);
    const [textColor, setTextColor] = useState("#000000");
    const [openCheckoutItemIndex, setOpenCheckoutItemIndex] = useState<number | undefined>(undefined);
    const [id, setIndex] = useState(0);
    const [discount, setDiscountCode] = useState<string>("");
    const [oldDiscount, setOldDiscountCode] = useState<string>("");
    const history = useHistory();

    const handleBlur = () => {
        if (discount !== oldDiscount) {
            setOldDiscountCode(discount);
            checkDiscountCode(discount);
        }
    };

    const handleChange = (event: any, id: number) => {
        editShoppingCartItemFreelanceBooks(id, event.target.checked)
    };

    const totalPrice = (prices && prices.length > 0 && prices.reduce((a, b) => ({ price: (a.price || 0) + (b.price || 0) })).price) || 0;

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card elevation={2}>
                        <CardContent>
                            {!checkoutItems.length ? <Typography>Uw winkelmandje is leeg!</Typography> :
                                <Paper className={classes.root}>
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>Cursus categorie</Typography>
                                                </TableCell>
                                                {role === Role.COMPANY ? <TableCell>
                                                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>Deelnemers</Typography>
                                                </TableCell> : null}
                                                <TableCell>
                                                    <Typography style={{ fontWeight: 'bold' }} variant='subtitle1'>Prijs</Typography>
                                                </TableCell>
                                                {role !== Role.COMPANY ? <TableCell></TableCell> : null}
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {checkoutItems.map(({ students, course, freelancerBooks }, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <Grid container spacing={2} alignItems="center" >
                                                            <Grid item>
                                                                <ListItemAvatar>
                                                                    <Avatar className={classes.avatar}>
                                                                        <FaBook />
                                                                    </Avatar>
                                                                </ListItemAvatar>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title={<Grid>
                                                                    <Typography variant="body1">{course.category?.category}</Typography>
                                                                    <Typography variant="body1">{course.type?.type}</Typography>
                                                                    <Typography variant="body1">{course.place}</Typography>
                                                                    {
                                                                        course.date?.dates && course.date.dates.map((d, i) => {
                                                                            if (d && Array.isArray(d)) return (
                                                                                d.map((date, i) => <Typography key={i} variant="body1">{`${date}`}</Typography>)
                                                                            )
                                                                            if (d) return <Typography key={i} variant="body1">{`${d}`}</Typography>;
                                                                            return null;
                                                                        })

                                                                    }
                                                                </Grid>}>
                                                                    <Typography variant="body1" style={{ color: '#363636' }}>{course.category?.category}
                                                                        <IconButton onClick={() => {
                                                                            setCourse(course);
                                                                            setIndex(index);
                                                                            setOpenCourse(true);
                                                                        }} edge="end" aria-label="delete">
                                                                            <FaEdit />
                                                                        </IconButton>
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    {role === Role.COMPANY ? <TableCell scope="row">
                                                        <Grid container spacing={2} alignItems="center">
                                                            <Grid item>
                                                                <Tooltip title={<Grid container>
                                                                    {(students || []).map(({ firstName, initials, prefix, lastName }, index) => <Grid item xs={12} key={index}>
                                                                        <Typography variant="body1">{firstName || initials} {prefix ? prefix + " " : ""}{lastName}</Typography>
                                                                    </Grid>)}
                                                                </Grid>}>
                                                                    <Typography display="inline" className={classes.studentText} variant="body1">{`${(students || []).length} ${(students || []).length === 1 ? "deelnemer" : "deelnemers"}`}
                                                                        <IconButton onClick={() => {
                                                                            setIndex(index)
                                                                            setOpenCheckoutItemIndex(index)
                                                                        }} edge="end" aria-label="delete">
                                                                            <FaEdit />
                                                                        </IconButton>
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell> : null}
                                                    <TableCell scope="row">
                                                        {prices && prices.length > index && <Grid container direction="column" alignItems="flex-start" justify="flex-start" spacing={2}>
                                                            <Typography variant="body1"><b>{parseMoney(prices[index].price)}</b></Typography>
                                                            <Typography variant="body1">{prices[index].discountInfo?.toString()}</Typography>
                                                        </Grid>}
                                                    </TableCell>
                                                    {role !== Role.COMPANY ? <TableCell scope="row">
                                                        {course.type?.hasBookDiscount ? <FormControlLabel
                                                            control={<Checkbox
                                                                checked={freelancerBooks}
                                                                onChange={(e) => handleChange(e, index)}
                                                                name="checkedB"
                                                                color="primary"
                                                            />}
                                                            label={course.type?.priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                                                        /> : <Typography variant="body1">Niet van toepassing!</Typography>}
                                                    </TableCell> : null}
                                                    <TableCell scope="row">
                                                        <Tooltip title="Inschrijving verwijderen">
                                                            <IconButton onClick={() => onRemove(index)} edge="end" aria-label="delete">
                                                                <FaTrash />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Paper>}
                            <Grid container style={{ marginTop: '1em' }}>
                                <Grid item xs={2}>
                                    <Typography variant="subtitle1">
                                        {checkoutItems.length !== 0 && `Totaal:  `}
                                        <b>{checkoutItems.length !== 0 && totalPrice && `${parseMoney(totalPrice)}`}</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                {checkoutItems.length !== 0 &&
                    <Grid item xs={12}>
                        <CustomPrompt when={!valid} />
                        <Card elevation={2}>
                            <CardContent>
                                <form onSubmit={handleSubmit((paymentData: CheckoutPaymentFormData): void => {
                                    let issuer: Issuer | undefined = selectedIssuer;

                                    if (paymentMethod === 'I') {
                                        handleNext({ ...trimFields(paymentData), paymentMethod, issuerId: undefined });
                                    } else {
                                        handleNext({ ...trimFields(omit(paymentData, "issuerID")), paymentMethod, issuerId: undefined });
                                    }
                                })}>
                                    <Grid container>
                                        <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                                            <Typography variant="h6">Betaling</Typography>
                                            <Divider classes={{
                                                root: classes.divider
                                            }} />
                                        </Grid>
                                        <Grid item xs={12} md={paymentMethod === 'I' ? 8 : 12}>
                                            <div className="textfield-spacing textfield-spacing-h">
                                                <Typography classes={{
                                                    root: `${textClasses.label}`
                                                }}>BETAALMETHODE</Typography>
                                                {specialPriceAgreement ? "Factuur" : <Select variant="outlined" fullWidth style={{ marginTop: 8 }}
                                                    name="paymentMethod"
                                                    value={paymentMethod}
                                                    onChange={(e: any) => setPaymentMethod(e.target.value)}
                                                >
                                                    {paymentMethodsAllowed.includes(PaymentMethod.Invoice) &&
                                                        <MenuItem value={"A"}>Factuur</MenuItem>

                                                    }
                                                    {paymentMethodsAllowed.includes(PaymentMethod.Ideal) &&
                                                        <MenuItem value={"I"}>iDeal</MenuItem>
                                                    }
                                                </Select>}
                                            </div>
                                        </Grid>
                                        {getWebsite() === Website.HSN &&
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <div className="textfield-spacing textfield-spacing-h">
                                                        <Typography classes={{
                                                            root: `${textClasses.label}`
                                                        }}>KORTINGSCODE</Typography>
                                                        <Field
                                                            name="discountCode"
                                                            variant="outlined"
                                                            validate={[validString]}
                                                            placeholder="Optioneel"
                                                            component={RenderTextField}
                                                            handleChange={setDiscountCode}
                                                            handleBlur={handleBlur}
                                                        />
                                                    </div>
                                                </Grid>
                                                {!isNil(discountString) &&
                                                    <Grid item xs={12} md={4}>
                                                        <div className="textfield-spacing textfield-spacing-h">
                                                            <Typography classes={{
                                                                root: `${textClasses.label}`
                                                            }}>KORTING</Typography>
                                                            <Paper style={{ display: 'table', height: 70 }} elevation={0}>
                                                                <Typography style={{ display: 'table-cell', verticalAlign: 'middle' }} variant='subtitle2'>{discountString}</Typography>
                                                            </Paper>
                                                        </div>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                        {getWebsite() === Website.HOC &&
                                            <Grid item xs={12} md={12}>
                                                <div className="textfield-spacing textfield-spacing-h">
                                                    <Typography classes={{
                                                        root: `${textClasses.label}`
                                                    }}>KHN ACCOUNTNUMMER</Typography>
                                                    <Field
                                                        name="khnId"
                                                        variant="outlined"
                                                        validate={[validString, isNumber]}
                                                        placeholder="Optioneel"
                                                        handleBlur={setKhnId}
                                                        component={RenderTextField}
                                                    />
                                                </div>
                                            </Grid>
                                        }

                                        <Grid item xs={12} style={{ marginTop: "1em" }}>
                                            <Typography variant="h6">Overig</Typography>
                                            <Divider classes={{
                                                root: classes.divider
                                            }} />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: ".5em", marginTop: ".5em" }}>
                                            <Typography>Eventueel betalingskenmerk / ordernummer</Typography>
                                            <Field
                                                name="paymentFeature"
                                                variant="outlined"
                                                validate={[validString]}
                                                placeholder="Optioneel"
                                                component={RenderTextField}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: ".5em", marginTop: ".5em" }}>
                                            <Typography>Eventuele opmerkingen</Typography>
                                            <Field
                                                name="remark"
                                                variant="outlined"
                                                validate={[validLongString]}
                                                placeholder="Optioneel"
                                                component={RenderTextField}
                                                props={{ multiline: true }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: ".5em", marginTop: ".5em" }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checked}
                                                        onChange={(e: any) => setChecked(!checked)}
                                                        name="termsOfService"
                                                        required
                                                    />
                                                }
                                                label={<Typography>Ik ben bekend en ga akkoord met de <Link onClick={() => downloadAgreement()} >Studievoorwaarden</Link></Typography>}
                                                classes={{
                                                    root: `${textClasses.subLabel}`
                                                }}
                                            />
                                        </Grid>
                                        <Grid container justify="space-between">
                                            <Button
                                                onClick={() => history.goBack()}
                                                classes={{
                                                    root: buttonClasses.secondaryApp,
                                                }}>
                                                Terug</Button>
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                disabled={submitting || !valid}
                                                type="submit"
                                                classes={{
                                                    root: buttonClasses.primary,
                                                }}
                                            >
                                                Inschrijven</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>
                }
            </Grid>
            <IssuersContainer setOpen={(open) => setOpenIssuerModal(open)} open={openIssuerModal} modal setSelectedIssuer={(issuer: Issuer) => setSelectedIssuer(issuer)} />
            <EditShoppingCartContainer
                itemIndex={id}
                openCheckoutItemIndex={openCheckoutItemIndex}
                setOpenCheckoutItemIndex={(openCheckoutItemIndex?: number) => setOpenCheckoutItemIndex(openCheckoutItemIndex)}
                setOpenCourse={(openCourse: boolean) => setOpenCourse(openCourse)}
                openCourse={openCourse}
            />
        </div >
    )
}

const DecoratedInitializeFromStateFormFunction = reduxForm<CheckoutPaymentFormData, CheckoutPaymentProps>({
    form: "CheckoutPaymentForm"
})(CheckoutPayment);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let initialValues = {
            discountCode: state.discount.discountCode,
            discountString: state.discount.discountString,
            ...state.register.registerPayment
        }
        initialValues = trimFields(initialValues)

        return ({
            initialValues: initialValues,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;