import { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { deleteMailingLink, getMailingLinks } from "../../../store/mailingLink/actions";
import { setMailingLink } from "../../../store/registerCourse/actions";
import { setDiscountCode, resetDiscountCode } from "../../../store/discount/actions";
import { ApplicationState } from "../../../store";
import { MailingLinkState } from "../../../types/mailingLink";
import MailingLinksComponent from "./MailingLinksComponent";

interface MailingLinksContainerProps {
    setName: (name: string) => void;
    setTargetAudience: (index: number) => void;
    mailingLink: MailingLinkState;
    getMailingLinks: () => void;
    setMailingLink: (categoryId?: number, courseId?: string, location?: string, locationCode?: string) => void;
    deleteMailingLink: (name: string) => void;
    setDiscountCode(discountCode: string):void;
    resetDiscountCode: () => void;
}

const mapStateToProps = ({ mailingLink }: ApplicationState) => {
    return ({
        mailingLink
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getMailingLinks, setDiscountCode, deleteMailingLink, setMailingLink, resetDiscountCode }, dispatch);
};

const MailingLinksContainer = ({ setTargetAudience, setDiscountCode, resetDiscountCode, setName, mailingLink, getMailingLinks, setMailingLink, deleteMailingLink }: MailingLinksContainerProps) => {
    useEffect(() => getMailingLinks(), [getMailingLinks]);

    return <MailingLinksComponent
        setTargetAudience={setTargetAudience}
        resetDiscountCode={resetDiscountCode}
        setDiscountCode={setDiscountCode}
        setName={setName}
        mailingLink={mailingLink}
        setMailingLink={setMailingLink}
        deleteMailingLink={deleteMailingLink}
    />
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MailingLinksContainer);