import { createActionsWithPrefix } from "../../helperfunctions/action";

export const userInformationActions = createActionsWithPrefix("USER_INFORMATION/", {
    GET_USER_INFORMATION_REQUEST: "GET_USER_INFORMATION_REQUEST",
    GET_USER_INFORMATION_SUCCESS: "GET_USER_INFORMATION_SUCCESS",
    GET_USER_INFORMATION_ERROR: "GET_USER_INFORMATION_ERROR",
    GET_REQUEST_EDIT_REQUEST: "GET_REQUEST_EDIT_REQUEST",
    GET_REQUEST_EDIT_SUCCESS: "GET_REQUEST_EDIT_SUCCESS",
    GET_REQUEST_EDIT_ERROR: "GET_REQUEST_EDIT_ERROR",
    SET_EDIT_RELATION_ADDRESS: "SET_EDIT_RELATION_ADDRESS",
    SET_EDIT_VISITING_RELATION_ADDRESS: "SET_EDIT_VISITING_RELATION_ADDRESS",
    SET_EDIT_STUDENT_ADDRESS: "SET_EDIT_STUDENT_ADDRESS",
    SET_EDIT_RELATION_INVOICE_ADDRESS: "SET_EDIT_RELATION_INVOICE_ADDRESS",
});