import React, { useState } from "react"
import { Card, CardActionArea, Grid, Typography, CardContent, makeStyles, useTheme, Theme } from "@material-ui/core";
import { animated, useSpring, config } from "react-spring";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    optionCard: {
        boxShadow: "none",
        width: "100%",
        backgroundColor: "#fff",
    }
}));

interface OptionsProps {
    icon: React.FC;
    text: string;
    path: string;
    delay: number;
    onClickUser?: () => void;
}

const Options = ({ icon, text, path, delay, onClickUser }: OptionsProps) => {
    const classes = useStyles();
    const theme: Theme = useTheme();
    const [hover, setHover] = useState(false);
    const GenericIcon: React.FC<any> = icon as React.FC;
    const propsHover = useSpring({
        to: { transform: hover ? " scale(1.05) translateY(-3px)" : " scale(1) translateY(0px)", color: theme.palette.secondary.main },
        config: config.wobbly
    });

    const cardProps = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: config.default,
        delay,
    });

    return (
        <animated.div style={cardProps}>
            
                <Link style={{ textDecoration: "none" }} to={path} onClick={onClickUser && onClickUser}>
                    <Card onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={classes.optionCard}>
                        <CardActionArea>
                            <CardContent>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <animated.div style={propsHover}><GenericIcon /></animated.div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2">
                                            {text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Link>
            
        </animated.div>
    )
}

export default Options
