import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import { connect } from "react-redux";

import { ApplicationState } from "../../store";
import { AuthenticationState } from "../../types/auth";

interface NotFoundProps {
    auth: AuthenticationState;
}

const mapStateToProps = ({ auth }: ApplicationState) => {
    return {
        auth,
    };
};

const NotFound = ({ auth: { token } }: NotFoundProps) => {
    return (
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
                <Typography variant="h2">404</Typography>
                <Typography variant="subtitle1">Oeps, je hoort hier niet te zijn!</Typography>
                    <Link
                        style={{ textDecoration: "none" }}
                        to={!!token ? "/dashboard" : "/"}
                        component={RouterLink}>
                        <Button
                            color="secondary"
                            style={{ margin: "2em" }}
                            variant="outlined">
                            <Typography variant="overline">TERUG</Typography>
                        </Button>
                    </Link>
            </Grid>
        </Grid>
    )
};

export default connect(
    mapStateToProps
)(NotFound);
