import { FreelancerUserFormData } from "../Freelancer/FreelancerUserData";
import Details from "../../../molecules/Details";

const Freelancer = (registerFreelancerUserFormData: FreelancerUserFormData) => {
    const freelancerData = [
        { title: "Persoonsgegevens", elements: [
            { name: "Geslacht", data: registerFreelancerUserFormData.sex },
            { name: "Voorletters", data: registerFreelancerUserFormData.initials },
            { name: "Voornaam", data: registerFreelancerUserFormData.firstName },
            { name: "Achternaam", data: `${registerFreelancerUserFormData.prefix ? registerFreelancerUserFormData.prefix + " " : ""}${registerFreelancerUserFormData.lastName}` },
            { name: "Geboortedatum", data: registerFreelancerUserFormData.birthDate.toString() },
            { name: "Geboorteplaats", data: registerFreelancerUserFormData.birthPlace },
        ] },
        { title: "Contactgegevens", elements: [
            { name: "Email", data: registerFreelancerUserFormData.email },
            { name: "Telefoon", data: registerFreelancerUserFormData.workNumber },
            { name: "Mobiele telefoon", data: registerFreelancerUserFormData.mobileNumber },
        ] },
        { title: "Adresgegevens", elements: [
            { name: "Straatnaam", data: registerFreelancerUserFormData.address },
            { name: "Postcode", data: registerFreelancerUserFormData.zipCode },
            { name: "Plaats", data: registerFreelancerUserFormData.place },
            { name: "Huisnummer", data: registerFreelancerUserFormData.houseNumber },
        ] },
        { title: "Factuurgegevens", elements: [
            { name: "Email", data: registerFreelancerUserFormData.emailInvoice },
            { name: "Factuur per post", data: registerFreelancerUserFormData.byMail ? "Ja" : "Nee" },
            { name: "Straatnaam", data: registerFreelancerUserFormData.byMail ? registerFreelancerUserFormData.placeInvoice : null },
            { name: "Postcode", data: registerFreelancerUserFormData.byMail ? registerFreelancerUserFormData.zipCodeInvoice : null },
            { name: "Huisnummer", data: registerFreelancerUserFormData.byMail ? registerFreelancerUserFormData.numberInvoice : null },
            { name: "Plaats", data: registerFreelancerUserFormData.byMail ? registerFreelancerUserFormData.placeInvoice : null },
            { name: "Boeken in bezit", data: registerFreelancerUserFormData.freelancerBooks },
        ] },
    ]

    return <Details data={freelancerData} />
};

export default Freelancer;