import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, Card, CardContent, ListItem, ListItemAvatar, ListItemText, List, CardHeader, useTheme } from "@material-ui/core";
import { FaUsers } from "react-icons/fa"
import { useSpring, animated } from "react-spring";
import { useHistory } from "react-router"

import { LoginResponse } from "../../types/auth";
import Avatar from "react-avatar";

const useStyles: any = makeStyles((theme: Theme) => ({
    full: {
        height: "60%"
    },
    divider: {
        height: "3px",
        color: "gray"
    }
}));

interface SubAccountsComponentProps {
    accounts: LoginResponse[];
    loginSubAccount: (account: LoginResponse) => void;
}

const SubAccountsComponent = ({ accounts, loginSubAccount }: SubAccountsComponentProps) => {
    const history = useHistory()
    const theme = useTheme()
    const [hover, set] = useState(false)
    const [accountsetted, setAccount] = useState<LoginResponse | undefined>(undefined)
    const AnimatedFaUsers = animated(FaUsers)
    const classes = useStyles();
    const animationProps = useSpring({
        transform: hover ? "translateY(-0.5rem) scale(1.1)" : "translateY(0rem) scale(1)",
        alignContent: "center",
        onRest: () => {
            hover && accountsetted && loginSubAccount(accountsetted)
        }
    })
    const animationIconProps = useSpring({
        fontSize: "100px",
        color: hover ? theme.palette.primary.main : "#b8b8b8"
    })

    useEffect(() => {
        if (!accounts) history.push("/")
    }, [accounts, history])

    return (
        <Grid style={{ zIndex: -2 }} container justify="center" alignItems="center" className={classes.full}>
            <Grid style={{ textAlign: "center" }} item xs={12}>
                <animated.div style={animationProps}>
                    <AnimatedFaUsers style={animationIconProps} />
                </animated.div>
            </Grid>
            <Grid item xs={10} md={6} lg={4}>
                <Card>
                    <CardHeader title="Kies een sub-account" />
                    <CardContent>
                        {accounts && accounts.length > 0 &&
                            <Grid container>
                                <Grid item xs={12}>
                                    <List className={classes.root}>
                                        {accounts.map((account, index) => {
                                            return (
                                                <>
                                                    <ListItem key={index} onClick={() => {
                                                        set(true)
                                                        if (typeof index === "number") {
                                                            const account: LoginResponse = accounts[index];
                                                            setAccount(account)
                                                        }
                                                    }} style={{ paddingTop: "1em", marginTop: "1em" }} button>
                                                        <Grid container>
                                                            <Grid item xs={2}>
                                                                <ListItemAvatar>
                                                                    <Avatar color={theme.palette.secondary.main} round={true} name={`${account.initials}`} size="30" />
                                                                </ListItemAvatar>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <ListItemText primary={`${account.companyName}`} />
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                </>
                                            );
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
}

export default SubAccountsComponent;