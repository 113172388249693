import React, { useEffect, useState } from "react"
import { useSpring, animated } from "react-spring"
import { RouteComponentProps, withRouter } from "react-router";
import { List, Drawer, makeStyles, useMediaQuery, Theme, useTheme, Button, Typography } from "@material-ui/core/";
import { FaBook, FaHome, FaUsers, FaFileInvoiceDollar, FaUserEdit, FaTags, FaEnvelope, FaList } from "react-icons/fa";

import { AuthenticationState } from "../../types/auth";
import SideMenuItem from "./SideMenuItem";
import "./SideMenu.css"
import { Role } from "../../types/enum";

export interface PageProps {
    title: string;
    path: string;
    icon: () => JSX.Element;
    allowedRoles: Array<string>;
}

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
        paddingTop: "70px"
    },
    toolbar: theme.mixins.toolbar,
    menuItemSelected: {
        minHeight: "75px",
        "&$selected": {
            backgroundColor: "#E8E8E8",
            borderLeft: "4px solid " + theme.palette.secondary.main
        }
    },
    selected: {},
    listItem: {
        fontWeight: 600
    },
}));

interface SideMenuProps {
    auth: AuthenticationState;
    showMenu: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
    resetRegisterState: () => void;
    resetStepper: (reset: boolean) => void;
}

const SideMenu = ({ auth: { role }, history, location, showMenu, open, setOpen, resetRegisterState, resetStepper }: SideMenuProps & RouteComponentProps<{}>) => {
    const classes = useStyles();
    const [scrollY, setScrollY] = useState(0)
    const [props, set] = useSpring(() => ({ height: `64px`, config: { mass: 1, tension: 200, friction: 20 } }));
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

    const pages: PageProps[] = [
        { title: "Dashboard", path: "/dashboard", icon: () => <FaHome size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN, Role.COMPANY, Role.FREELANCER, Role.STUDENT] },
        { title: "Cursussen", path: "/cursussen", icon: () => <FaBook size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.COMPANY, Role.FREELANCER, Role.STUDENT] },
        { title: "Cursussen", path: "/admin/categorieën", icon: () => <FaBook size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        { title: "Medewerkers", path: "/medewerkers", icon: () => <FaUsers size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.COMPANY] },
        { title: "Facturen", path: "/facturen", icon: () => <FaFileInvoiceDollar size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.COMPANY, Role.FREELANCER] },
        { title: "Persoonlijke gegevens", path: "/persoonlijke-gegevens", icon: () => <FaUserEdit size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.FREELANCER, Role.STUDENT] },
        { title: "Bedrijfsgegevens", path: "/bedrijfsgegevens", icon: () => <FaUserEdit size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.COMPANY] },
        { title: "Studenten", path: "/studenten", icon: () => <FaUsers size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        { title: "Kortingscodes", path: "/admin/kortingscodes", icon: () => <FaTags size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        { title: "Mail link-builder", path: "/admin/link-builder", icon: () => <FaEnvelope size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        { title: "KHN klanten", path: "/admin/khn-klanten", icon: () => <FaUsers size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        { title: "Voorwaarden", path: "/admin/voorwaarden", icon: () => <FaBook size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        { title: "Proefexamenlog", path: "/admin/proefexamenlog", icon: () => <FaList size="1.5em" color={theme.palette.secondary.main} />, allowedRoles: [Role.ADMIN] },
        {
            title: "", path: "/inschrijven", icon: () => <Button onClick={() => {
                location.pathname !== "/inschrijven" && resetRegisterState();
                resetStepper(true);
            }} variant="contained" color="secondary" id="login-login-button">
                <Typography variant="button" style={{ color: "#fff" }}>
                    DIRECT INSCHRIJVEN
                </Typography>
            </Button>, allowedRoles: [Role.COMPANY, Role.FREELANCER]
        },
        {
            title: "", path: "/inschrijven/particulier", icon: () => <Button onClick={() => {
                location.pathname !== "/inschrijven/particulier" && resetRegisterState();
                resetStepper(true);
            }} variant="contained" color="secondary" id="login-login-button">
                <Typography variant="button" style={{ color: "#fff" }}>
                    DIRECT INSCHRIJVEN
                </Typography>
            </Button>, allowedRoles: [Role.STUDENT]
        },
    ];

    const navigateToPage = (path: string) => {
        history.push(path);
        if (fullscreen) {
            setOpen(!open)
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollY(Math.round(window.scrollY))
        });
    }, []);

    useEffect(() => {
        set({ height: history.location.pathname === "/dashboard" && !fullscreen && scrollY < 100 ? "300px" : "64px" })
    });

    const drawer = (
        <div>
            <animated.div style={props} />
            <List id="menu-list">
                {pages && pages.map((page: PageProps, index: number) => <SideMenuItem key={index} {...page} selected={page.path === history.location.pathname} role={role} navigateToPage={navigateToPage} />)}
            </List>
        </div>
    );
    return (
        <Drawer
            className={classes.drawer}
            variant={fullscreen ? "temporary" : "persistent"}
            anchor="left"
            open={open}
            onClose={() => setOpen(false)}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            {drawer}
        </Drawer>
    )
}

export default withRouter(SideMenu);