import React from "react"
import ContentLoader from "react-content-loader"

export const LoadingContentCard = () => {
    return (
        <div>
            <ContentLoader
                height={700}
                width={400}
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="3" y="0" rx="5" ry="5" width="400" height="177" />
                <rect x="3" y="203" rx="5" ry="5" width="400" height="177" />
                <rect x="3" y="403" rx="5" ry="5" width="400" height="177" />
            </ContentLoader>
        </div>
    )
}

export const LoadingContentInfoSmall = () => {
    return (
        <div>
            <ContentLoader
                height={160}
                width={400}
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="16" rx="4" ry="4" width="117" height="6" />
                <rect x="0" y="33" rx="3" ry="3" width="85" height="6" />
                <rect x="0" y="80" rx="3" ry="3" width="350" height="6" />
                <rect x="0" y="100" rx="3" ry="3" width="380" height="6" />
                <rect x="0" y="120" rx="3" ry="3" width="201" height="6" />
            </ContentLoader>
        </div>
    )
}

export const LoadingCourses = () => {
    return (
        <div>
            <ContentLoader
                height={100}
                width={400}
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="10" y="13" rx="0" ry="0" width="382" height="89" />
            </ContentLoader>
        </div>
    )
}

export const LoadingTable = () => {
    return (
        <div>
            <ContentLoader
                height={500}
                width={400}
                speed={2}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="-1" y="1" rx="0" ry="0" width="400" height="256" />
            </ContentLoader>
        </div>
    )
}




