import graphqlHelperService from "./graphqlHelper.service";
import { UserInformation } from "../types/userInformation";

export const userInformationService = {
    userInformation: async (): Promise<UserInformation> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            userInformationQuery()
        );
        return apolloResult.userInformation as UserInformation;
    },
    requestEdit: async (userInformation: UserInformation): Promise<UserInformation> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            requestEditQuery(userInformation)
        );
        return apolloResult.userInformation as UserInformation;
    },
};

const requestEditQuery = (userInformation: UserInformation) => `mutation{
    editRequest (
        userInformation: {
        ${userInformation.student ? `
        student: {
            ${userInformation.student.studentId ? `studentId: "${userInformation.student.studentId}",` : ``}
            sex: "${userInformation.student.sex}",
            initials: "${userInformation.student.initials}",
            ${userInformation.student.firstName ? `firstName: "${userInformation.student.firstName}",` : ``}
            ${userInformation.student.prefix ? `prefix: "${userInformation.student.prefix}",` : ``}
            lastName: "${userInformation.student.lastName}",
            address: "${userInformation.student.address}",
            houseNumber: "${userInformation.student.houseNumber}",
            zipCode: "${userInformation.student.zipCode}",
            place: "${userInformation.student.place}",
            birthDate: "${userInformation.student.birthDate}",
            birthPlace: "${userInformation.student.birthPlace}",
            ${userInformation.student.privateNumber ? `privateNumber: "${userInformation.student.privateNumber}",` : ``}
            mobileNumber: "${userInformation.student.mobileNumber}",
            email: "${userInformation.student.email}",
        }` : ``}
        ${userInformation.relation ? `
        relation: {
            sex: "${userInformation.relation.sex}",
            initials: "${userInformation.relation.initials}",
            ${userInformation.relation.firstName ? `firstName: "${userInformation.relation.firstName}",` : ``}
            ${userInformation.relation.prefix ? `prefix: "${userInformation.relation.prefix}",` : ``}
            lastName: "${userInformation.relation.lastName}",
            address: "${userInformation.relation.address}",
            houseNumber: "${userInformation.relation.houseNumber}",
            zipCode: "${userInformation.relation.zipCode}",
            place: "${userInformation.relation.place}",
            workNumber: "${userInformation.relation.workNumber}",
            ${userInformation.relation.mobileNumber ? `mobileNumber: "${userInformation.relation.mobileNumber}",` : ``}
            email: "${userInformation.relation.email}",
            ${userInformation.relation.contactEmail ? `contactEmail: "${userInformation.relation.contactEmail}",` : ``}
            ${userInformation.relation.companyName ? `companyName: "${userInformation.relation.companyName}",` : ``}
            ${userInformation.relation.invoiceEmail ? `invoiceEmail: "${userInformation.relation.invoiceEmail}",` : ``}
            byMail: ${!!userInformation.relation.byMail},
            ${userInformation.relation.byMail ? `
                invoiceAddress: "${userInformation.relation.invoiceAddress}",
                invoiceHouseNumber: "${userInformation.relation.invoiceHouseNumber}",
                invoiceZipCode: "${userInformation.relation.invoiceZipCode}",
                invoicePlace: "${userInformation.relation.invoicePlace}",
            ` : ``}
        },` : ``}
    })
}`

const userInformationQuery = () => `query{
userInformation{
    student {
            sex
            initials
            firstName
            prefix
            lastName
            address
            houseNumber
            zipCode
            place
            birthDate
            birthPlace
            privateNumber
            mobileNumber
            email
            courseResults {
                name
                date
                passed
                failed
                grade
                place
                madeSampleExams
                sampleExamGrade
            }
        }
        relation {
            sex
            initials
            firstName
            prefix
            lastName
            address
            houseNumber
            zipCode
            place
            workNumber
            mobileNumber
            email
            contactEmail
            companyName
            invoiceAddress
            invoiceHouseNumber
            invoiceZipCode
            invoicePlace
            invoiceEmail
            byMail
            khnId
        }
    }
}`
