import graphqlHelperService from "./graphqlHelper.service";
import { Question, QuestionInput, AnswerInput } from "../types/question";

export const questionService = {
    questions: async (categoryId: number): Promise<Question[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            questionsQuery(categoryId)
        );
        return apolloResult.questionsByCategory as Question[];
    },
    createQuestion: async (question: QuestionInput): Promise<Question> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            createQuestionQuery(question)
        );
        return apolloResult.createQuestion as Question;
    },
    updateQuestion: async (questionId: number, question: QuestionInput): Promise<Question> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateQuestionQuery(questionId, question)
        );
        return apolloResult.updateQuestion as Question;
    },
    deleteQuestion: async (questionId: number): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            deleteQuestionQuery(questionId)
        );
        return apolloResult.deleteQuestion as boolean;
    },
    createAnswer: async (answer: AnswerInput): Promise<number> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            createAnswerQuery(answer)
        );
        return apolloResult.createAnswer as number;
    },
    updateAnswer: async (answerId: number, answer: AnswerInput): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateAnswerQuery(answerId, answer)
        );
        return apolloResult.updateAnswer as boolean;
    },
    deleteAnswer: async (answerId: number): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            deleteAnswerQuery(answerId)
        );
        return apolloResult.deleteAnswer as boolean;
    },
}

const questionsQuery = (categoryId: number) => `query {
    questionsByCategory(categoryId: ${categoryId}) {
        categoryId
        questionId
        text
        possibleAnswers {
            answerId
            questionId
            text
            correct
        }
        testjekennis
        active
    }
}`;

const createQuestionQuery = (question: QuestionInput) => `mutation{
    createQuestion(question: {
        text: "${question.text}",
        categoryId: ${question.categoryId},
        testjekennis: ${question.testjekennis ? question.testjekennis : false},
        active: ${question.active ? question.active : false}
    })
}`;

const updateQuestionQuery = (questionId: number, question: QuestionInput) => `mutation{
    updateQuestion(question: {
        questionId: ${questionId},
        text: "${question.text}",
        testjekennis: ${question.testjekennis},
        active: ${question.active}
    })
}`;

const deleteQuestionQuery = (questionId: number) => `mutation{
    deleteQuestion(questionId: ${questionId})
}`;

const createAnswerQuery = (answer: AnswerInput) => `mutation {
    createAnswer(answer: {
        questionId: ${answer.questionId},
        text: "${answer.text}",
        correct: ${answer.correct ? answer.correct : false}
    })
}`;

const updateAnswerQuery = (answerId: number, answer: AnswerInput) => `mutation {
    updateAnswer(answer: {
        answerId: ${answerId},
        text: "${answer.text}",
        correct: ${answer.correct}
    })
}`;

const deleteAnswerQuery = (answerId: number) => `mutation {
    deleteAnswer(answerId: ${answerId})
}`;
