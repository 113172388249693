import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, Stepper, Step, StepLabel, Card, CardContent, useMediaQuery, useTheme, Typography } from "@material-ui/core";
import RegisterAppStepperContainer from "./RegisterAppStepperContainer";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import { useStepperIconStyles, StepperConnector } from "../../../styles/stepper";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { getRole } from "../../../helperfunctions/localstorage";
import { Role } from "../../../types/enum";
import { RouteComponentProps, withRouter } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { resetStepper } from "../../../store/register/actions";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        marginTop: theme.spacing(2),
    },
    stepper: {
        backgroundColor: theme.palette.primary.main,
    },
    steplabel: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        color: "black"
    }
}));

const mapStateToProps = ({ register: { resetStep } }: ApplicationState) => {
    return ({
        resetStep,
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        resetStepper,
    }, dispatch);
}

interface RegisterAppProps {
    resetStep: boolean;
    resetStepper: (reset: boolean) => void;
};

const RegisterApp = ({ location, resetStepper, resetStep }: RegisterAppProps & RouteComponentProps) => {
    const role = getRole()
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const steps = role === Role.COMPANY ? ["CURSUS SELECTEREN", "DEELNEMERS", "OVERZICHT"] : ["CURSUS SELECTEREN", "OVERZICHT"];

    const urlParams = new URLSearchParams(location.search);
    let categoryId: string | number | undefined = urlParams.get("categoryId") || undefined;
    categoryId = categoryId ? parseInt(categoryId) : undefined;
    const courseId = urlParams.get("courseId") || undefined;
    const place = urlParams.get("place") || undefined;
    const locationCode = urlParams.get("locationCode") || undefined;
    const discountCode = urlParams.get("discountCode") || undefined;

    useEffect(() => {
        resetStep && resetStepper(false) && setActiveStep(0);
    }, [resetStep, resetStepper]);

    function isStepSkipped(step: number) {
        return skipped.has(step);
    }

    function handleNext() {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function StepIcon(props: StepIconProps) {
        const classes = useStepperIconStyles();
        const { active, completed } = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ? <div className={classes.circleCompleted}><Check className={classes.completed} /></div> : <div className={classes.circle}>{active && <div className={classes.innerCircle} />}</div>}
            </div>
        );
    }

    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Typography variant="h5">Inschrijven</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.card} elevation={2}>
                    <CardContent>
                        {!fullScreen &&
                            <Stepper activeStep={activeStep} connector={<StepperConnector />}>
                                {steps && steps.map((label: string, index: number) => {
                                    const stepProps: { completed?: boolean } = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel StepIconComponent={StepIcon} classes={{
                                                label: `${classes.steplabel}`,
                                                root: `${classes.steplabel}`,
                                                active: `${classes.steplabel}`
                                            }}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        }
                        {fullScreen && <Typography style={{ marginBottom: "1em" }} variant="h5">{steps[activeStep]}</Typography>}
                        <div>
                            <RegisterAppStepperContainer
                                setActiveStep={setActiveStep}
                                categoryId={categoryId}
                                courseId={courseId}
                                place={place}
                                locationCode={locationCode}
                                discountCode={discountCode}
                                currentStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterApp));
