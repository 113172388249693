import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import CategoryComponent from "./CategoryComponent";
import { getCategories } from "../../../store/registerCourse/actions";
import { ApplicationState } from "../../../store";
import { CourseCategory } from "../../../types/registerCourse";
import { RegisterUserType } from "../../../types/enum";

interface CategoryContainerProps {
    categories: CourseCategory[];
    getCategories: (userType: RegisterUserType) => void;
}

const mapStateToProps = ({ registerCourse: { categories } }: ApplicationState) => {
    return ({
        categories
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getCategories }, dispatch);
};

const CategoryContainer = ({ categories, getCategories }: CategoryContainerProps) => {
    useEffect(() => {
        getCategories(RegisterUserType.Everyone);
    }, [getCategories]);

    return (
        <CategoryComponent
            categories={categories}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoryContainer);