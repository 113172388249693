import React from "react";
import { FormHelperText, Checkbox, FormControlLabel } from "@material-ui/core";
import { useTextStyles } from "../../styles/text";

export interface RenderCheckBoxProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: string;
    };
    light: boolean;
    textarea: boolean;
    custom: any
}

const RenderCheckBox = ({
    input,
    label,
    meta: { touched, error },
    light,
    ...custom
}: RenderCheckBoxProps) => {
    const textClasses = useTextStyles();

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!input.value}
                        {...input}
                        {...custom}
                    />
                }
                label={label}
                classes={{
                    root: `${textClasses.subLabel} ${light ? textClasses.light : textClasses.main}`
                }}
            />
            {(touched && !!error) && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
    );
}

export default RenderCheckBox;