import { Prompt } from 'react-router-dom';
import { useEffect } from 'react';
import { getWebsiteStopRegisterWarning } from '../../helperfunctions/getWebsite';

interface CustomPromptProps {
    when: boolean;
}

const CustomPrompt = ({ when }: CustomPromptProps) => {
    useEffect(() => {
        if (when) window.onbeforeunload = () => true;
        return () => { window.onbeforeunload = null; }
    }, [when]);

    return <Prompt
        when={when}
        message={getWebsiteStopRegisterWarning}
    />;
}

export default CustomPrompt;
