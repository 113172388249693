import React, { useState } from "react";
import { Grid, ImageListItem, Card, Button, makeStyles, AccordionDetails, Typography, withStyles, Theme, useTheme } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion"
import { useSpring, animated, config } from "react-spring";
import { FaCertificate, FaBook, FaGlobe } from "react-icons/fa";

import { CourseDetail } from "../../types/course";
import CourseDetailItem from "./CourseDetailItem";
import { SampleExamState } from "../../types/sampleExam";
import CourseParticipant from "./CourseParticipant";
import "./Course.css";
import { AuthenticationState } from "../../types/auth";
import HomeworkModal from "./HomeworkModal";
import TestExamModal from "./TestExamModal";
import Loading from "../FeedBack/Loading";
import { AssignmentState } from "../../types/assignment";
import { Role } from "../../types/enum";

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: "#f7f8f9",
        width: "100%",
        textAlign: "center",
        textTransform: "none",
        height: "100px"
    },
    card: {
        padding: "20px",
        borderRadius: 0
    }
}))

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "rgba(0, 0, 0, .03)",
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

interface CourseItemProps {
    auth: AuthenticationState;
    courseDetail: CourseDetail;
    loadingParticipatingStudents: boolean;
    testExams: SampleExamState;
    assignment: AssignmentState;
    downloadAssignment: (assignmentId: number, name: string) => void;
    getTestExamsByCategoryId: (categoryId: number, projectId?: number) => void;
    getAssignmentsByCourseId: (courseId: string) => void;
    getParticipatingStudents: (projectId: number) => void;
}

const CourseItem: React.FC<CourseItemProps> = ({ auth, courseDetail, downloadAssignment, loadingParticipatingStudents, getTestExamsByCategoryId, getAssignmentsByCourseId, testExams, getParticipatingStudents, assignment }: CourseItemProps) => {
    const classes = useStyles();
    const { role } = auth;
    const [hoverH, setHoverH] = useState(false);
    const [hoverP, setHoverP] = useState(false);
    const [hoverE, setHoverE] = useState(false);
    const [expansionOpen, setExpansionOpen] = useState<boolean>(false);
    const [openHomeworkModal, setOpenHomeworkModal] = useState<boolean>(false);
    const [openTestExamModal, setOpenTestExamModal] = useState<boolean>(false);
    const theme: Theme = useTheme();
    const propsHoverH = useSpring({
        to: { transform: hoverH ? " translateY(-3px)" : " translateY(0px)" },
        config: config.wobbly
    });

    const propsHoverP = useSpring({
        to: { transform: hoverP ? " translateY(-3px)" : " translateY(0px)" },
        config: config.wobbly
    });

    const propsHoverE = useSpring({
        to: { transform: hoverE ? " translateY(-3px)" : " translateY(0px)" },
        config: config.wobbly
    });

    return (
        <ImageListItem className="full-w">
            <Card elevation={2} className="course-paper">
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={role === Role.COMPANY ? 7 : 4} lg={role === Role.COMPANY ? 7 : 5}>
                        <Grid container spacing={2}>
                            <Grid item xs={5} md={4} className="course-first-column">Cursus</Grid> <Grid item xs={7} md={6} className="course-second-column">{courseDetail.category}</Grid>
                            <Grid item xs={5} md={4} className="course-first-column">Cursustype</Grid> <Grid item xs={7} md={6} className="course-second-column">{courseDetail.type}</Grid>
                            {courseDetail.address && courseDetail.place && <>
                                <Grid item xs={5} md={4} className="course-first-column">Locatie</Grid> <Grid item xs={7} md={6} className="course-second-column">{courseDetail.place}</Grid>
                            </>}
                            {courseDetail.dates && courseDetail.dates.length > 0 &&
                                <>
                                    <Grid item xs={5} md={4} className="course-first-column">Cursusplanning</Grid> <Grid item xs={7} md={6} className="course-second-column">
                                        {courseDetail.dates.map((date, i) => {
                                            if (date) return (
                                                <Grid key={i} container>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2">{date}</Typography>
                                                    </Grid>
                                                </Grid>
                                            );
                                            return null;
                                        })}
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CourseDetailItem role={role} courseDetail={courseDetail} />
                    </Grid>
                    {role === Role.COMPANY ?
                        <Grid item xs={12} md={12} lg={12} className="center-v">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Accordion expanded={expansionOpen} onChange={() => {
                                        setExpansionOpen(!expansionOpen);
                                    }} onClick={() => !courseDetail.participatingStudents && courseDetail.projectId && getParticipatingStudents(courseDetail.projectId)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>Deelnemers</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ padding: "8px" }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {loadingParticipatingStudents && <Loading />}
                                                </Grid>
                                                {courseDetail.participatingStudents && courseDetail.participatingStudents.map((participatingStudent) => {
                                                    return <CourseParticipant xs={12} lg={6} {...participatingStudent} done={courseDetail.dateDone ? true : false} key={participatingStudent.studentId} />
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={12} md={3} lg={2} className="center-v">
                            {!courseDetail.expired ?
                                <Grid container spacing={2}>
                                    {courseDetail.showSampleExam === true ?
                                        <Grid item xs={12}>
                                            <Button onClick={() => {
                                                courseDetail.categoryId && getTestExamsByCategoryId(courseDetail.categoryId, courseDetail.projectId)
                                                courseDetail.showSampleExam && setOpenTestExamModal(!openTestExamModal)
                                            }} onMouseEnter={() => setHoverP(true)} onMouseLeave={() => setHoverP(false)} fullWidth={true} variant="outlined" className={classes.button}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <animated.div style={propsHoverP}>
                                                            <FaCertificate size="1.5em" color={theme.palette.secondary.main} /> <br />
                                                        </animated.div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">Proefexamens</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                        : undefined}
                                    {courseDetail.showHomework === true ?
                                        <Grid item xs={12}>
                                            <Button onClick={() => {
                                                courseDetail.courseId && getAssignmentsByCourseId(courseDetail.courseId)
                                                setOpenHomeworkModal(!openHomeworkModal)
                                            }
                                            } onMouseEnter={() => setHoverH(true)} onMouseLeave={() => setHoverH(false)} fullWidth={true} variant="outlined" className={classes.button}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <animated.div style={propsHoverH}>
                                                            <FaBook size="1.5em" color={theme.palette.secondary.main} /> <br />
                                                        </animated.div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">Huiswerk</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                        : undefined}
                                    {courseDetail.eLearning && courseDetail.eLearningLink ?
                                        <Grid item xs={12}>
                                            <Button onClick={() => { window.open(courseDetail.eLearningLink); }
                                            } onMouseEnter={() => setHoverE(true)} onMouseLeave={() => setHoverE(false)} fullWidth={true} variant="outlined" className={classes.button}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <animated.div style={propsHoverE}>
                                                            <FaGlobe size="1.5em" color={theme.palette.secondary.main} /> <br />
                                                        </animated.div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">E-Learning</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                        : undefined}
                                </Grid>
                                :
                                undefined
                            }
                        </Grid>
                    }
                </Grid>
            </Card>
            {courseDetail.category && <TestExamModal testExams={testExams} course={courseDetail.category} open={openTestExamModal} handleClose={() => setOpenTestExamModal(false)} />}
            <HomeworkModal downloadAssignment={downloadAssignment} open={openHomeworkModal} assignment={assignment} handleClose={() => setOpenHomeworkModal(false)} />
        </ImageListItem>
    )
};

export default CourseItem;
