import { Dispatch } from "redux";

import { InvoicesActions } from "./types";
import { InvoiceService } from "../../services/invoice.service";
import { openFileInNewWindow } from "../../helperfunctions/files";
import { InvoicesObject, Invoice } from "../../types/invoice";
import messages from "../../constants/messages";
import { toast } from "react-toastify";

export const getInvoices = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: InvoicesActions.GET_INVOICES_REQUEST });
        const response: InvoicesObject = await InvoiceService.invoices();
        dispatch({ type: InvoicesActions.GET_INVOICES_SUCCESS, invoices: response.invoices, outstandingInvoices: response.outstandingInvoices });
    } catch (error) {
        dispatch({ type: InvoicesActions.GET_INVOICES_ERROR, error });
    }
};

export const getInvoicePDF = (invoiceId: string) => async (dispatch: Dispatch) => {
    try {
        toast.info(messages.DOWNLOAD_START)
        dispatch({ type: InvoicesActions.GET_INVOICESPDF_REQUEST });
        const b64data = await InvoiceService.invoicePDF(invoiceId);
        openFileInNewWindow(b64data, invoiceId, "pdf");
        dispatch({ type: InvoicesActions.GET_INVOICESPDF_SUCCESS });
    } catch (error) {
        toast.error(messages.COULD_NOT_DOWNLOAD)
        dispatch({ type: InvoicesActions.GET_INVOICESPDF_ERROR, error });
    }
};

export const setSelectedInvoice = (invoice: Invoice) => async (dispatch: Dispatch) => {
    dispatch({ type: InvoicesActions.SET_INVOICE, invoice });
};
