import { userInformationActions } from "./types";
import { UserInformationState } from "../../types/userInformation";

const initialState: UserInformationState = {
    userInformation: {
        student: undefined,
        relation: undefined,
    },
    loading: false,
    error: undefined
};

export function userInformationReducer(state = initialState, action: typeof userInformationActions): UserInformationState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case userInformationActions.GET_USER_INFORMATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case userInformationActions.GET_USER_INFORMATION_SUCCESS:
            return {
                ...state,
                userInformation: action.response,
                loading: false
            };
        case userInformationActions.GET_USER_INFORMATION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case userInformationActions.GET_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case userInformationActions.GET_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userInformationActions.GET_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case userInformationActions.SET_EDIT_RELATION_ADDRESS:
            return {
                ...state,
                userInformation: {
                    ...state.userInformation,
                    relation: {
                        ...action.formValues,
                        place: action.address.place,
                        address: action.address.address,
                    }
                }
            };
        case userInformationActions.SET_EDIT_RELATION_INVOICE_ADDRESS:
            return {
                ...state,
                userInformation: {
                    ...state.userInformation,
                    relation: {
                        ...action.formValues,
                        invoicePlace: action.address.place,
                        invoiceAddress: action.address.address,
                    }
                }
            };
        case userInformationActions.SET_EDIT_VISITING_RELATION_ADDRESS:
                return {
                    ...state,
                    userInformation: {
                        ...state.userInformation,
                        relation: {
                            ...action.formValues,
                            visitingPlace: action.address.place,
                            visitingAddress: action.address.address,
                        }
                    }
                };
        case userInformationActions.SET_EDIT_STUDENT_ADDRESS:
                return {
                    ...state,
                    userInformation: {
                        ...state.userInformation,
                        student: {
                            ...action.formValues,
                            place: action.address.place,
                            address: action.address.address,
                        }
                    }
                };
        default:
            return state;
    }
}