import React, { useState } from 'react'
import { FaTimes, FaCheck, FaTrash, FaPen } from 'react-icons/fa';
import { Grid, Typography, IconButton, TextField, Checkbox } from '@material-ui/core';
import { Answer, AnswerInput } from '../../../types/question';

interface AnswerComponentProps {
    answer: Answer;
    updateAnswer: (answerId: number, answer: AnswerInput) => void;
    deleteAnswer: (answerId: number, questionId: number) => void;
}

const AnswerComponent = ({ answer, updateAnswer, deleteAnswer }: AnswerComponentProps) => {
    const [correct, setCorrect] = useState<boolean>(answer.correct);
    const [text, setText] = useState<string>(answer.text);
    const [editting, setEditting] = useState<boolean>(false);

    return (
        <Grid container justify="space-between" alignItems="center" key={answer.answerId}>
            <Grid item xs={10}>
                <Grid container alignItems="center" style={{ padding: "1em" }}>
                    <Grid item xs={1}>
                        {editting ?
                            <Checkbox
                                size="small"
                                name="correct"
                                checked={correct}
                                value={correct}
                                onChange={() => setCorrect(!correct)}
                            />
                            :
                            answer.correct ? <FaCheck size="1.3em" color={"#008000"} /> : <FaTimes size="1.3em" color={"#DC143C"} />
                        }
                    </Grid>
                    <Grid item xs={11}>
                        {editting ?
                            <TextField
                                name="text"
                                value={text}
                                onChange={({ target }) => setText(target.value)}
                                fullWidth
                            />
                            :
                            <Typography variant="body2">{answer.text}</Typography>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                {editting &&
                    <IconButton size="small" onClick={() => {
                        updateAnswer(answer.answerId, { correct, text, questionId: answer.questionId });
                        setEditting(false);
                    }}>
                        <FaCheck />
                    </IconButton>
                }
                <IconButton size="small" onClick={() => setEditting(!editting)} style={{ marginLeft: "1em" }}>
                    <FaPen />
                </IconButton>
                <IconButton size="small" onClick={() => deleteAnswer(answer.answerId, answer.questionId)} style={{ marginLeft: "1em", marginRight: "1em" }}>
                    <FaTrash />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default AnswerComponent;
