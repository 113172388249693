import React, { useState } from 'react';
import { makeStyles, Theme, useTheme, useMediaQuery, Grid, Typography, Paper, Card, CardContent, FormControl, Select, MenuItem, TextField, Button } from '@material-ui/core';
import { config, useSpring, animated } from "react-spring"

import { useTextStyles } from "../../../styles/text";
import { getWebsiteDomain } from '../../../helperfunctions/getWebsiteDomain';
import CourseContainer from '../../Register/Register/Course/CourseContainer';
import MailingLinksContainer from './MailingLinksContainer';
import { MailingLink } from '../../../types/mailingLink';
import { copyStringToClipboard } from '../../../helperfunctions/clipboard';
import { RegisterCourse } from '../../../types/registerCourse';
import { useInputStyles } from '../../../styles/input';
import { Discount } from '../../../types/discount';
import { toast } from 'react-toastify';
import { RegisterUserType } from '../../../types/enum';

const useStyles: any = makeStyles((theme: Theme) => ({
    linkContainer: {
        display: "flex",
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        cursor: "pointer",
        border: `1px solid ${theme.palette.text.disabled}`,
        background: theme.palette.background.default
    },
    resultContainer: {
        padding: theme.spacing(1),
    },
    margin: {
        margin: theme.spacing(1),
    },
    label: {
        marginBottom: "0.5em",
    },
    select: {
        backgroundColor: "#EDEDED"
    },
    card: {
        backgroundColor: "#EDEDED"
    },
    linkButton: {
        "&:hover": {
            textDecoration: "underline",
        },
    }
}));

interface LinkBuilderComponentProps {
    course: RegisterCourse;
    createOrUpdateMailingLink: (mailingLink: MailingLink) => void;
    discounts: Discount[];
    setDiscountCode: (discountCode: string) => void;
    discountCode?: string;
}

const LinkBuilderComponent = ({ course, setDiscountCode, discountCode, createOrUpdateMailingLink, discounts }: LinkBuilderComponentProps) => {
    const classes = useStyles();
    const inputClasses = useInputStyles();
    const textClasses = useTextStyles();

    const [hover, setHover] = useState<boolean>(false);
    const [linkName, setName] = useState<string>("");
    const targetAudiences = ["Iedereen", "Nieuwe bedrijven", "Nieuwe particulieren", "Bestaande klanten"]
    const [targetAudienceIndex, setTargetAudience] = useState<number>(0);

    const discountIndex: number = discounts ? discounts.findIndex(discount => discount.discountCode === discountCode) : -1;

    let path;
    switch (targetAudiences[targetAudienceIndex]) {
        case "Nieuwe bedrijven":
            path = "inschrijven/bedrijf"
            break;
        case "Nieuwe particulieren":
            path = "inschrijven/particulier"
            break;
        case "Bestaande klanten":
            path = "inschrijven";
            break;
        default:
            path = "inschrijven-als"
    }

    const categoryQuery = course.category ? `?categoryId=${course.category.categoryId}` : "";
    const courseQuery = course.type ? `&courseId=${course.type.courseId}` : "";
    const placeQuery = course.place ? `&place=${course.place}` : "";
    const locationCodeQuery = course.date ? `&locationCode=${course.date.locationCode}` : "";
    const discountCodeQuery = discountCode ? `&discountCode=${discountCode}` : "";
    const baseUrl = `${getWebsiteDomain()}/${path}`;

    const urlToCopy = (baseUrl + categoryQuery + courseQuery + placeQuery + locationCodeQuery + discountCodeQuery).replace(" ", "%20");
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const linkProps = useSpring({ config: config.stiff })

    return (
        <Grid container >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">Mail link-builder</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper style={{ marginTop: "1em" }}>
                    <CardContent>
                        <Grid item xs={12}>
                            <Grid container >
                                <Grid item xs={12} lg={6}>
                                    <CourseContainer userType={RegisterUserType.Everyone} isMailLinkBuilder={true}/>
                                    <FormControl className={classes.margin} style={{ width: "98%" }}>
                                        <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>KORTINGSCODE</Typography>
                                        <Select
                                            className={classes.select}
                                            fullWidth
                                            variant="outlined"
                                            value={discountIndex >= 0 ? discountIndex : ""}
                                            onChange={(e) => {
                                                const index = e.target.value;
                                                if (typeof index === "number") {
                                                    const discount: Discount = discounts[index];
                                                    setDiscountCode(discount.discountCode);
                                                }
                                            }}
                                        >
                                            {discounts && discounts.map((discount, index) => <MenuItem value={index} key={discount.discountCode}>{discount.discountCode}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6} className={classes.resultContainer}>
                                    <Grid container direction="column" justify="space-between">
                                        <Grid item>
                                            <animated.div style={!fullscreen ? linkProps : {}}>
                                                <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>GEGENEREERDE LINK</Typography>
                                                <Card className={classes.card}>
                                                    <CardContent>
                                                        <Typography variant="caption">Klik de link om te kopiëren</Typography>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <div className={classes.linkContainer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => {
                                                                    copyStringToClipboard(urlToCopy);
                                                                    toast.info("Link gekopieerd!")
                                                                }}>
                                                                    <Typography display="block" variant="subtitle2" style={{ textDecoration: hover ? "underline" : "none", wordBreak: "break-word" }}>{urlToCopy}</Typography>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </animated.div>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <FormControl style={{ marginTop: "1em", width: "100%" }}>
                                                    <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>Vul een naam in als je de link wilt opslaan</Typography>
                                                    <TextField
                                                        fullWidth
                                                        classes={{
                                                            root: inputClasses.root
                                                        }}
                                                        variant="outlined"
                                                        value={linkName}
                                                        placeholder="Verplicht"
                                                        onChange={(e: any) => setName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <FormControl style={{ marginTop: "1em", marginBottom: '1em' }}>
                                            <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>Doelgroep</Typography>
                                            <Select
                                                className={classes.select}
                                                variant="outlined"
                                                value={targetAudienceIndex}
                                                onChange={(e) => {
                                                    const index = e.target.value;
                                                    if (typeof index === "number") {
                                                        setTargetAudience(index);
                                                    }
                                                }}
                                            >
                                                {targetAudiences && targetAudiences.map((targetAudience, index) => <MenuItem value={index} key={targetAudience}>{targetAudience}</MenuItem>)}
                                            </Select>
                                        </FormControl>

                                        <Button onClick={() => linkName !== "" && createOrUpdateMailingLink({ name: linkName, link: urlToCopy })} variant="contained" color="secondary">
                                            <Typography>OPSLAAN</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Paper>
            </Grid>
            <MailingLinksContainer setName={(name) => setName(name)} setTargetAudience={(index) => setTargetAudience(index)}/>
        </Grid>
    );
};


export default LinkBuilderComponent;