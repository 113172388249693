import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useSpring, animated, config } from "react-spring";
import { Grid, Card, Theme, Typography, CardContent, CardHeader, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RenderTextFieldSearch from "../../TextField/TextFieldSearch";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { AdminStudentsComponentForm } from "../../Admin/Students/AdminStudentsComponent";

interface AdminStudentsType {
    title: string;
    description: string;
    image: string;
    path: string;
    index: number;
}

interface AdminStudentsProps {
    scrollY: number
}

const useStyles = makeStyles((theme: Theme) => ({
    itemIndex: {
        zIndex: theme.zIndex.drawer + 2,
        [theme.breakpoints.down("sm")]: {
            zIndex: 0,
        },
    },
    action: {
        padding: theme.spacing(1)
    },
    card: {
        borderRadius: "12px",
        maxWidth: "fit-content",
        boxShadow: "2px 10px 50px rgba(0, 0, 0, 0.16)",
        margin: theme.spacing(2.5),
        [theme.breakpoints.up("xs")]: {
            maxWidth: "100%",
        },
        [theme.breakpoints.down("lg")]: {
            margin: theme.spacing(1),
        },
        padding: theme.spacing(1),
    },
    data: {
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down("lg")]: {
            marginLeft: theme.spacing(1),
        },
    },
    image: {
        width: "100px"
    }
}));

type Props = RouteComponentProps<{}> & AdminStudentsType & AdminStudentsProps;

const AdminStudents = ({ history, title, description, image, index, handleSubmit }: Props & InjectedFormProps<AdminStudentsComponentForm, Props>) => {
    const classes = useStyles();

    const props = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: (index * 200) + 200,
        config: config.default
    });

    return (
        <Grid item xs={12} lg={4} className={classes.itemIndex}>
            <animated.div style={props}>
                <Card className={classes.card}>
                    <CardHeader title={title} />
                    <Divider />
                    <CardContent>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <img src={`../assets/img/card-icons/${image}.png`} alt={`${image}`} className={classes.image} />
                                    </Grid>
                                    <Grid item className={classes.data}>
                                        <Grid container direction="column">
                                            <Typography variant="subtitle1">{description}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <form style={{ marginTop: "-8px" }} onSubmit={handleSubmit(({ search }: AdminStudentsComponentForm): void => 
                        history.push(`/studenten${search ? `?studentId=${search}` : ``}`)
                    )}
                    >
                        <Field
                            number={true}
                            name="search"
                            placeholder="Zoek op Cursist ID"
                            component={RenderTextFieldSearch}
                        />
                    </form>
                </Card>
            </animated.div>
        </Grid>
    );
}

export default withRouter(reduxForm<AdminStudentsComponentForm, Props>({ form: 'adminStudentComponentForm' })(AdminStudents));

