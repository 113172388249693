import React from "react"
import { Grid, Button, Typography } from "@material-ui/core";
import { useDropzone } from 'react-dropzone';


interface KHNCustomersComponentProps {
    uploadFile: (file?: File[]) => void;
}

const KHNCustomersComponent = ({ uploadFile }: KHNCustomersComponentProps) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const lastFile = acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[acceptedFiles.length - 1]
    const file = lastFile && <div key={lastFile.name}>{lastFile.name}</div>

    return (
        <div>
            <Grid alignItems="center" container>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5">Upload hier het KHN klanten bestand</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1em" }}>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ border: "2px dotted #e3e3e3", backgroundColor: "#f7f7f7", cursor: "pointer" }}
                    {...getRootProps({ className: 'dropzone' })}
                >
                    <input {...getInputProps()} />
                    <p style={{ margin: "3em" }}>Sleep een bestand, of klik op een bestand te selecteren</p>
                </Grid>
            </Grid>
            {file && <aside style={{ marginTop: "1em" }}>
                <Typography variant="overline">Bestand:</Typography>
                {file}
            </aside>}
            <Button onClick={(e) => uploadFile(acceptedFiles)} variant="contained" color="secondary" id="login-login-button">
                <Typography variant="button" style={{ color: "#fff" }}>
                    UPLOADEN
                </Typography>
            </Button>
        </div>
    );
};

export default KHNCustomersComponent;
