import graphqlHelperService from "./graphqlHelper.service";

export const courseService = {
    getCourseGoogleAnalyticsUrl: async (courseId: string) => {
        const apolloResult = await graphqlHelperService.sendQuery(
            getCourseGoogleAnalyticsUrlQuery(courseId)
        );
        return apolloResult.googleAnalyticsUrl as string;
    },
    editCourseGoogleAnalyticsUrl: async (courseId: string, url: string) => {
        const apolloResult = await graphqlHelperService.sendMutate(
            editCourseGoogleAnalyticsUrlMutation(courseId, url)
        );
        return apolloResult.addGoogleAnalyticsUrl as boolean;
    },
    deleteCourseGoogleAnalyticsUrl: async (courseId: string) => {
        const apolloResult = await graphqlHelperService.sendMutate(
            deleteCourseGoogleAnalyticsUrlMutation(courseId)
        );
        return apolloResult.deleteGoogleAnalyticsUrl as boolean;
    },
}

const getCourseGoogleAnalyticsUrlQuery = (courseId: string) => `query{
    googleAnalyticsUrl(courseId:"${courseId}")
}`

const editCourseGoogleAnalyticsUrlMutation = (courseId: string, url: string) => `mutation {
    addGoogleAnalyticsUrl(courseId: "${courseId}", googleAnalyticsUrl: "${url}")
}`;

const deleteCourseGoogleAnalyticsUrlMutation = (courseId: string) => `mutation{
    deleteGoogleAnalyticsUrl(courseId: "${courseId}")
}`;


