import { useEffect, useState } from "react";
import { Grid, Theme, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { animated, useSpring, config } from "react-spring";

import { getDashboardCards } from "../../helperfunctions/getDashboardCards"
import DashboardCard from "./DashboardCard";
import LatestResults from "./DashboardItems/LatestResults";
import { useTextStyles } from "../../styles/text";
import { AuthenticationState } from "../../types/auth";
import UpcomingCourses from "./DashboardItems/UpcomingCourses";
import { InvoiceState } from "../../types/invoice";
import { DashboardState } from "../../types/dashboard";
import "./Dashboard.css"
import AdminStudents from "./DashboardItems/AdminStudents";
import { SampleExamState } from "../../types/sampleExam";
import { AssignmentState } from "../../types/assignment";
import { Role } from "../../types/enum";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme: Theme) => ({
    itemIndex: {
        zIndex: theme.zIndex.drawer + 3,
        padding: "0px",
        [theme.breakpoints.down("sm")]: {
            zIndex: 0,
        },
    },
}));

interface DashboardProps {
    auth: AuthenticationState;
    dashboard: DashboardState;
    testExams: SampleExamState
    invoice: InvoiceState;
    assignment: AssignmentState;
    getTestExamsByCategoryId: (categoryId: number, projectId?: number) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
    getAssignments: (variables: any) => void;
}

const Dashboard = ({ auth, dashboard, invoice, getAssignments, testExams, getTestExamsByCategoryId, assignment, downloadAssignment }: DashboardProps) => {
    const classes = useStyles();
    const textClasses = useTextStyles();
    const [props, set] = useSpring(() => ({ opacity: 1, config: config.gentle }));
    const [scrollY, setScrollY] = useState(0)
    const { role } = auth;
    const openInvoices = invoice.outstandingInvoices;
    const dashboardCard = getDashboardCards(role);
    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollY(Math.round(window.scrollY))
        });
    }, []);

    useEffect(() => {
        set({ opacity: scrollY > 50 ? 0 : 1 })
    });

    useEffect(() => {
        if ((role === Role.FREELANCER || role === Role.COMPANY) && openInvoices > 0) {
            toast.info(`U heeft ${openInvoices} openstaande factu${openInvoices > 1 ? `ren` : `ur`}.`)
        }
    }, [role, openInvoices]);

    return (
        <Grid container>
            <Grid item xs={4} className={classes.itemIndex}>
                <animated.div style={props}>
                    <span style={{ color: !fullScreen ? "#fff" : "gray" }} className={`${textClasses.pageTitle} ${textClasses.dark}`}>Welkom</span>
                </animated.div>
            </Grid>
            <Grid container spacing={3} className="dashboard-card-list-container">
                {dashboardCard && dashboardCard.map((dashboardCard, index) => {
                    return <DashboardCard {...dashboardCard} key={index} index={index} scrollY={scrollY} />
                })}
                {(role === Role.STUDENT || role === Role.FREELANCER || role === Role.COMPANY) &&
                    <>
                        <Grid className={classes.itemIndex} item xs={12} md={8}>
                            <LatestResults role={auth.role} dashboard={dashboard} />
                        </Grid>
                    </>
                }
                {(role === Role.STUDENT || role === Role.FREELANCER) &&
                    <>
                        <Grid className={classes.itemIndex} item xs={12} md={6} lg={4}>
                            <UpcomingCourses
                                assignment={assignment}
                                dashboard={dashboard}
                                testExams={testExams}
                                getTestExamsByCategoryId={getTestExamsByCategoryId}
                                getAssignments={getAssignments}
                                downloadAssignment={downloadAssignment}
                            />
                        </Grid>
                    </>
                }
                {(role === Role.ADMIN) &&
                    <>
                        <AdminStudents index={2} scrollY={scrollY} title="Studenten" path="/studenten" image="student" description="Zoek een student op met zijn cursisten ID" />
                    </>
                }
            </Grid>
        </Grid>
    );
};

export default Dashboard;