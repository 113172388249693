import { CompanyDataFormData } from "../Company/CompanyData";
import Details from "../../../molecules/Details";

const Company = (registerCompanyData: CompanyDataFormData) => {
    const companyData = [
        { title: "Bedrijfsgegevens", elements: [
            { name: "Bedrijfsnaam", data: registerCompanyData.nameCompany },
            { name: "Straatnaam", data: registerCompanyData.adresCompany },
            { name: "Postcode", data: registerCompanyData.zipCodeCompany },
            { name: "Plaats", data: registerCompanyData.placeCompany },
            { name: "Huisnummer", data: registerCompanyData.houseNumberCompany },
        ] },
        { title: "Contactgegevens", elements: [
            { name: "Geslacht", data: registerCompanyData.sexContact },
            { name: "Voorletters", data: registerCompanyData.initialsContact },
            { name: "Voornaam", data: registerCompanyData.firstNameContact },
            { name: "Achternaam", data: `${registerCompanyData.infixContact ? registerCompanyData.infixContact + ' ' : ""}${registerCompanyData.lastNameContact}` },
            { name: "Email", data: registerCompanyData.emailCompany },
            { name: "Telefoon", data: registerCompanyData.phoneCompany },
            { name: "Mobiele telefoon", data: registerCompanyData.phoneMobileContact },
        ] },
        { title: "Factuurgegevens", elements: [
            { name: "Email", data: registerCompanyData.emailInvoice },
            { name: "Factuur per post", data: registerCompanyData.byMail ? "Ja" : "Nee" },
            { name: "Straatnaam", data: registerCompanyData.byMail ? registerCompanyData.invoiceAddress : null },
            { name: "Postcode", data: registerCompanyData.byMail ? registerCompanyData.invoiceZipCode : null },
            { name: "Huisnummer", data: registerCompanyData.byMail ? registerCompanyData.invoiceHouseNumber : null },
            { name: "Plaats", data: registerCompanyData.byMail ? registerCompanyData.invoicePlace : null },
        ] },
    ]

    return <Details data={companyData} />
};

export default Company;