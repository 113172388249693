import graphqlHelperService from "./graphqlHelper.service";
import { Registration, RegistrationPriceInfo, RegisterKHN, RegisterResponse, RegisterPriceResponse } from "../types/register";
import { capitalizeFirstLetter } from "../helperfunctions/data";
import { CheckoutPaymentFormData } from "../components/Checkout/CheckoutPayment";

export const registerService = {
    register: async (shoppingCart: Registration[], paymentData: CheckoutPaymentFormData): Promise<RegisterResponse> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            registerMutation(shoppingCart, paymentData)
        );
        return apolloResult.register as RegisterResponse;
    },
    registerInformationKHN: async (khnId: number, zipCode: string): Promise<RegisterKHN> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            registerInformationKHN(khnId, zipCode)
        );
        return apolloResult.dataByKhn as RegisterKHN;
    },
    uploadKHNData: async (content: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            importKhnData(content)
        );
        return apolloResult.importKhnData as boolean;
    },
    uploadAgreement: async (content: string, fileName: string): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            uploadAgreementQuery(content, fileName)
        );
        return apolloResult.setUserAgreement as boolean;
    },
    getUserAgreementFileName: async (): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            getUserAgreementFileNameQuery()
        );
        return apolloResult.getUserAgreementFileName as string;
    },
    calculatePrice: async (priceItems: RegistrationPriceInfo[]): Promise<RegisterPriceResponse[]> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            calculatePriceMutation(priceItems)
        );
        return apolloResult.calculatePrices as RegisterPriceResponse[];
    },
    getAgreement: async (): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            downloadAgreementQuery()
        );
        return apolloResult.getUserAgreement as string;
    },
};

const getUserAgreementFileNameQuery = () => `query {
    getUserAgreementFileName
}`

const downloadAgreementQuery = () => `query {
    getUserAgreement
}`

const uploadAgreementQuery = (content: string, fileName: string) => `mutation {
    setUserAgreement(file: "${content}" fileName: "${fileName}")
}`

const importKhnData = (content: string) => `mutation {
    importKhnData(file: "${content}")
}`

const registerInformationKHN = (khnId: number, zipCode: string) => `query {
    dataByKhn(khnId: ${khnId}, zipCode: "${zipCode}") {
        nameCompany
        emailCompany
        zipCodeCompany
        houseNumberCompany
        placeCompany
        adresCompany
        phoneCompany
        phoneMobileContact
        initialsContact
        infixContact
        firstNameContact
        lastNameContact
        emailContact
        byMail
        adresInvoice
        numberInvoice
        zipCodeInvoice
        placeInvoice
        emailInvoice
    }
}`

const registerMutation = (shoppingCart: Registration[], paymentData: CheckoutPaymentFormData) => `mutation{
    register(registration: {shoppingCart: [
        ${shoppingCart && shoppingCart.map((registration) => `{
            course: {
                courseId:"${registration.course.type?.courseId}"
                paymentMethod: "${paymentData.paymentMethod}"
                ${registration.course.date?.locationCode ? `locationCode: "${registration.course.date?.locationCode}"` : ``}
            }
            ${registration.relation ? `relation: {
                contact: {
                    sex: "${registration.relation.contact.sex}"
                    ${registration.relation.contact.initials ? `initials: "${registration.relation.contact.initials.toUpperCase()}"` : ``}
                    ${registration.relation.contact.prefix ? `prefix: "${registration.relation.contact.prefix}"` : ``}
                    ${registration.relation.contact.firstName ? `firstName: "${capitalizeFirstLetter(registration.relation.contact.firstName)}"` : ``}
                    ${registration.relation.contact.lastName ? `lastName: "${capitalizeFirstLetter(registration.relation.contact.lastName)}"` : ``}
                    ${registration.relation.contact.birthDate ? `birthDate: "${registration.relation.contact.birthDate}"` : ``}
                    ${registration.relation.contact.birthPlace ? `birthPlace: "${registration.relation.contact.birthPlace}"` : ``}
                    ${registration.relation.contact.email ? `email: "${registration.relation.contact.email}"` : ``}
                }
                ${registration.relation.invoice ? `invoice: {
                    ${registration.relation.invoice.email ? `email: "${registration.relation.invoice.email}"` : ``}
                    byMail: ${!!registration.relation.invoice.byMail}
                    ${registration.relation.invoice.byMail ? `
                        address: "${capitalizeFirstLetter(registration.relation.invoice.address)}"
                        houseNumber: "${registration.relation.invoice.houseNumber}"
                        zipCode: "${registration.relation.invoice.zipCode}"
                        place: "${capitalizeFirstLetter(registration.relation.invoice.place)}"
                    ` : ``}
                }` : ``}
                ${registration.relation.companyName ? `companyName: "${registration.relation.companyName}"` : ``}
                zipCode: "${registration.relation.zipCode}"
                houseNumber: "${registration.relation.houseNumber}"
                address: "${capitalizeFirstLetter(registration.relation.address)}"
                place: "${capitalizeFirstLetter(registration.relation.place)}"
                ${registration.relation.workNumber ? `workNumber: "${registration.relation.workNumber}"` : ``}
                ${registration.relation.mobileNumber ? `mobileNumber: "${registration.relation.mobileNumber}"` : ``}
                email: "${registration.relation.email}"
                password: "${registration.relation.password}"
                ${registration.relation.studentId ? `studentId: "${registration.relation.studentId}"` : ``}
                ${registration.relation.autoFilled ? `autoFilled: ${registration.relation.autoFilled}` : ``}
            }`: ``}
            ${registration.students ? `students: [
                ${registration.students.map((student) => `{
                    ${student.studentId ? `studentId: "${student.studentId}"` : ``}
                    ${student.birthDate ? `birthDate: "${student.birthDate}"` : ``}
                    ${student.birthPlace ? `birthPlace: "${student.birthPlace}"` : ``}
                    ${student.place ? `place: "${capitalizeFirstLetter(student.place)}"` : ``}
                    ${student.sex ? `sex: "${student.sex}"` : ``}
                    ${student.initials ? `initials: "${student.initials.toUpperCase()}"` : ``}
                    ${student.firstName ? `firstName: "${capitalizeFirstLetter(student.firstName)}"` : ``}
                    ${student.lastName ? `lastName: "${capitalizeFirstLetter(student.lastName)}"` : ``}
                    ${student.prefix ? `prefix: "${student.prefix}"` : ``}
                    ${student.address ? `address: "${capitalizeFirstLetter(student.address)}"` : ``}
                    ${student.houseNumber ? `houseNumber: "${student.houseNumber}"` : ``}
                    ${student.zipCode ? `zipCode: "${student.zipCode}"` : ``}
                    ${student.privateNumber ? `privateNumber: "${student.privateNumber}"` : ``}
                    ${student.mobileNumber ? `mobileNumber: "${student.mobileNumber}"` : ``}
                    ${student.email ? `email: "${student.email}"` : ``}
                    books: ${!!student.books}
                }`)}
            ]` : ``}
            ${ registration.freelancerBooks ? `freelancerBooks: ${!!registration.freelancerBooks}` : `` }
        }`)}
    ]
    ${ paymentData.discountCode ? `discountCode: "${paymentData.discountCode}"` : `` }
    ${ paymentData.note ? `note: "${paymentData.note}"` : `` }
    ${ paymentData.remark ? `remark: "${paymentData.remark}"` : `` }
    ${ paymentData.paymentFeature ? `paymentFeature: "${paymentData.paymentFeature}"` : `` }
    ${ paymentData.issuerId ? `issuerId: "${paymentData.issuerId}"` : `` }
    ${ `paymentMethod: "${paymentData.paymentMethod}"` }

    ${ paymentData.khnId ? `khnId: "${paymentData.khnId}"` : `` }
    }) {
        successful
        idealLink
    }
}`;

const calculatePriceMutation = (priceItems: RegistrationPriceInfo[]) => `mutation{
    calculatePrices(priceItems: [
        ${
            priceItems && priceItems.map((item, index) => {
                return `{courseId: "${item.courseId}"
            studentBooks: ${JSON.stringify(item.studentBooks)}
            ${item.discountCode ? `discountCode: "${item.discountCode}"` : ``}
            ${item.khnId ? `khnId: "${item.khnId}",` : ``}
            ${index < priceItems.length - 1 ? `},` : `}`}`
            })
        }
    ]) {
        price
        discountInfo
        portalPaymentMethod
    }
} `