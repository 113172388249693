import React, { useState } from "react"
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { StudentDetail } from "../../../types/student";
import { materialTableLocalization } from "../../../constants/materialTableLocalization";

interface AddStudentDialogProps {
    selectedIds?: string[];
    students: StudentDetail[];
    open: boolean;
    handleSelect: (student: StudentDetail[]) => void;
    handleClose: () => void;
}

const AddStudentDialog = ({ handleSelect, open, handleClose, students, selectedIds }: AddStudentDialogProps) => {
    const [selectedStudents, setStudents] = useState<StudentDetail[]>([]);
    const handleSelecting = (selectedStudents: any[]) => {
        for (let index = 0; index < selectedStudents.length; index++) {
            const rowData = selectedStudents[index];
            rowData.tableData.checked = false;
            const student: StudentDetail = {
                sex: rowData.sex,
                studentId: rowData.studentId,
                initials: rowData.initials,
                firstName: rowData.firstName,
                prefix: rowData.prefix,
                lastName: rowData.lastName,
                address: rowData.address,
                place: rowData.place,
                houseNumber: rowData.houseNumber,
                zipCode: rowData.zipCode,
                birthDate: rowData.birthDate,
                birthPlace: rowData.birthPlace,
                privateNumber: rowData.privateNumber,
                mobileNumber: rowData.mobileNumber,
                email: rowData.email,
                books: rowData.books,
            }
            selectedStudents[index] = student;
        }

        const emptyArray: StudentDetail[] = [];
        setStudents(emptyArray);
        handleSelect(selectedStudents);
        handleClose();
    };

    let studentData: StudentDetail[] = [];
    for (const student of students) {
        if (!(student.studentId && selectedIds && selectedIds.includes(student.studentId))) {
            student.fullName = `${student.firstName} ${student.prefix ? student.prefix : ""} ${student.lastName}`;
            student.email = student.email ? `${student.email}` : `Geen e-mailadres bekend`;
            student.fullAddress = student.address ? `${student.address} ${student.houseNumber}, ${student.place}` : `Geen adres bekend`;
            studentData.push(student);
        }
    }

    return (
        <div>
            <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <Grid container justify="space-between">
                        <Grid item>
                            Voeg Medewerker(s) toe
                        </Grid>
                        <Grid item>
                            <Button disabled={!Array.isArray(selectedStudents)} onClick={() => handleSelecting(selectedStudents)} variant="contained" color="secondary">Toevoegen</Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <MaterialTable
                        style={{ boxShadow: "none", marginBottom: "2em" }}
                        options={{
                            search: true,
                            sorting: true,
                            selection: true,
                            pageSize: 5,
                            showTitle: false,
                            searchFieldAlignment: "left",
                            headerStyle: {
                                color: "gray"
                            }
                        }}
                        localization={materialTableLocalization}
                        columns={[
                            { title: "lastName", field: "lastName", defaultSort: "asc", hidden: true },
                            { title: "Naam", field: "fullName" },
                            { title: "Email", field: "email" },
                            { title: "Adres", field: "fullAddress" },
                        ]}
                        data={studentData}
                        onSelectionChange={(data: any[]) => setStudents(data)}
                    />
                    <DialogActions>
                        <Grid container justify="space-between">
                            <Button onClick={handleClose}>Annuleren</Button>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default AddStudentDialog;
