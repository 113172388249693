import React from "react"
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemIcon, DialogActions, Button, CardActionArea, Typography } from "@material-ui/core";
import { FaFileWord } from "react-icons/fa";
import { AssignmentState } from "../../types/assignment";

interface HomeworkModalProps {
    open: boolean;
    downloadAssignment: (assignmentId: number, name: string) => void;
    assignment: AssignmentState;
    handleClose: () => void;
}

const HomeworkModal = ({ open, handleClose, assignment, downloadAssignment }: HomeworkModalProps) => {
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Huiswerkopdrachten</DialogTitle>
            <DialogContent >
                {!assignment.assignments?.length && <Typography variant="body1" style={{ color: '#000' }}>Geen huiswerk beschikbaar voor deze cursus.</Typography>}
                <List>
                    {assignment.assignments.map((assignment) => {
                        return (
                            <CardActionArea key={assignment.assignmentId} onClick={() => downloadAssignment(assignment.assignmentId, assignment.name)}>
                                <ListItem style={{ width: "500px" }}>
                                    <ListItemIcon>
                                        <FaFileWord size="1.5em" color="#2a5699" />
                                    </ListItemIcon>
                                </ListItem>
                            </CardActionArea>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Annuleren</Button>
            </DialogActions>
        </Dialog>
    )
}

export default HomeworkModal
