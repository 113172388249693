import React, { useEffect } from 'react';

interface ScrollToTopControllerProps {
    path: string;
}

export const ScrollToTopController = ({ path }: ScrollToTopControllerProps) => {
    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }, [path]);

    return <></>;
};