import React, { useEffect } from "react"
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import UserInformationComponent from "./UserInformationComponent";
import { UserInformationState } from "../../types/userInformation";
import { getUserInformation } from "../../store/userInformation/actions";
import { AuthenticationState } from "../../types/auth";

interface UserInformationContainerProps {
    auth: AuthenticationState;
    userInformation: UserInformationState;
    getUserInformation: () => void;
};

const mapStateToProps = ({ userInformation, auth }: UserInformationContainerProps) => {
    return ({
        userInformation,
        auth
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getUserInformation }, dispatch);
};

const UserInformationContainer = ({ userInformation, auth, getUserInformation }: UserInformationContainerProps) => {
    useEffect(() => {
        getUserInformation();
    }, [getUserInformation]);

    return (
        <div>
            <UserInformationComponent
                role={auth.role}
                userInformation={ userInformation.userInformation }
                loading={userInformation.loading}
                error={userInformation.error}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInformationContainer);