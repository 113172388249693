import React, { useState } from "react";
import MaterialTable from "material-table";
import { Grid, Card, useTheme, Theme, useMediaQuery } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FaTimes, FaUserPlus, FaCartPlus, FaCheck } from "react-icons/fa";

import { LoadingTable } from "../FeedBack/LoadingContent";
import Error from "../FeedBack/Error";
import { StudentDetail } from "../../types/student";
import { materialTableLocalization } from "../../constants/materialTableLocalization";

interface StudentsComponentProps {
	students: StudentDetail[];
	loading: boolean;
	error?: any;
	handleSelect: (students: StudentDetail[]) => void;
	emptyStudentDetail: () => void;
	resetRegisterState: () => void;
}

const StudentsComponent = ({ history, students, loading, error, handleSelect, emptyStudentDetail, resetRegisterState }: StudentsComponentProps & RouteComponentProps<{}>) => {
	const [selection, setSelection] = useState(false);
	const [selectedStudents, setSelectedStudents] = useState<any[]>([]);
	const [disabled, setDisabled] = useState(true)
	const theme: Theme = useTheme();
	const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

	const navigateToPage = (studentId?: any): void => {
		history.push({
			pathname: `/deelnemer-overzicht/${studentId}`,
		});
	};

	students && students.forEach((student) => {
		student.fullName = `${student.firstName && student.firstName !== "undefined" ? student.firstName : null || student.initials || ""} ${student.prefix ? student.prefix : ""} ${student.lastName}`;
		student.email = student.email ? `${student.email}` : `Geen e-mailadres bekend`;
		student.fullAddress = student.address ? `${student.address} ${student.houseNumber}, ${student.place}` : `Geen adres bekend`;
	})
     

	return (
		<Grid container direction="column">
			{loading && <Grid item xs={12}> <LoadingTable /></Grid>}
			{error && <Error />}
			{(!loading && !error && students) &&
				<div className="table">
					<Card>
						<MaterialTable
							style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "350px" : "none" }}
							onRowClick={(event, rowData) => !selection && navigateToPage(rowData && rowData.studentId)}
							title="Medewerkers"
							columns={[
								{ title: "LastName", field: "lastName", defaultSort: "asc", hidden: true},
								{ title: "Naam", field: "fullName" },
								{ title: "Email", field: "email" },
								{ title: "Adres", field: "fullAddress" },
							]}
							data={students}
							options={{
								sorting: true,
								selection: selection,
								pageSize: 10,
								searchFieldStyle: {
									marginRight: "1em"
								},
								headerStyle: {
									color: "gray"
								},
							}}
							actions={selection ? [
								{
									icon: () => <FaCheck color={theme.palette.action.active} />,
									tooltip: 'Aanvragen',
									isFreeAction: false,
									disabled,
									onClick: (event: any) => {
										resetRegisterState();
										handleSelect(selectedStudents);
										history.push("/inschrijven");
									}
								},
								{
									icon: () => <FaTimes color={theme.palette.action.active} />,
									tooltip: 'Annuleren',
									isFreeAction: false,
									onClick: (event: any, rowData: any) => {
										for (let index = 0; index < rowData.length; index++) rowData[index].tableData.checked = false;
										setSelection(false);
									}
								}
							] : [
								{
									icon: () => <FaUserPlus color={theme.palette.action.active} />,
									tooltip: 'Medewerker toevoegen',
									isFreeAction: true,
									onClick: (event: any) => {
										history.push("/nieuwe-medewerker");
										emptyStudentDetail();
									}
								},
								{
									icon: () => <FaCartPlus color={theme.palette.action.active} />,
									tooltip: 'Cursus aanvragen',
									isFreeAction: true,
									onClick: (event: any) => setSelection(true)
								}
							]}
							localization={materialTableLocalization}
							onSelectionChange={(data: any[]) => {
								setSelectedStudents(data)
								if (data.length !== 0) {
									setDisabled(false)
								} else {
									setDisabled(true)
								}
							}}
						/>
					</Card>
				</div>}
		</Grid >
	);
};

export default withRouter(StudentsComponent);