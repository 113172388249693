import React from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { animated, useSpring, config } from "react-spring";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";

import Loading from "./Loading";
import { withRouter, RouteComponentProps } from "react-router";

const useStyles = makeStyles((theme) => ({
    check: {
        verticalAlign: "middle",
        fontSize: "100px",
        color: theme.palette.primary.main,
    },
    subtitle: {
        marginTop: theme.spacing(2),
    },
    loading: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    }
}));

interface CompleteProps {
    error?: any;
    loading: boolean;
    toDashboard: boolean;
}

const Complete = ({ error, loading, toDashboard, history }: CompleteProps & RouteComponentProps) => {
    const classes = useStyles();
    const checkProps = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        config: config.default
    });

    return (
        <>
            <Grid container justify="center" alignItems="center" style={{ padding: "2em" }} direction="column">
                <Grid item xs={12} md={6}>
                    {loading && !error &&
                        <Grid container justify="center" alignItems="center" direction="column" className={classes.loading}>
                            <animated.div style={checkProps}>
                                <Loading />
                            </animated.div>
                            <Grid container justify="center" alignItems="center" direction="column" className={classes.subtitle}>
                                <Typography variant="h5">Verzoek aan het indienen</Typography>
                            </Grid>
                        </Grid>
                    }
                    {error &&
                        <Grid container justify="center" alignItems="center" direction="column">
                            <animated.div style={checkProps}>
                                <FaExclamationTriangle className={classes.check} />
                            </animated.div>
                            <Grid container justify="center" alignItems="center" direction="column">
                                <Typography variant="h5">Oeps er is iets misgegaan</Typography>
                                <Typography variant="subtitle2">Probeer het later opnieuw</Typography>
                            </Grid>
                            {toDashboard ?
                                <Button onClick={() => history.push("/dashboard")} variant="contained" color="secondary" id="login-login-button">
                                    <Typography variant="button" style={{ color: "#fff" }}>
                                        TERUG NAAR DASHBOARD
                                    </Typography>
                                </Button>
                                :
                                <Button onClick={() => history.push("/inschrijven-als")} variant="contained" color="secondary" id="login-login-button">
                                    <Typography variant="button" style={{ color: "#fff" }}>
                                        TERUG NAAR INSCHRIJVEN
                                    </Typography>
                                </Button>
                            }
                        </Grid>
                    }
                    {!error && !loading &&
                        <Grid container justify="center" alignItems="center" direction="column">
                            <animated.div style={checkProps}>
                                <FaCheck className={classes.check} />
                            </animated.div>
                            <Grid container justify="center" alignItems="center" direction="column">
                                <Typography variant="h5">Aanvraag succesvol</Typography>
                                <Typography variant="subtitle2">Uw inschrijving wordt zo snel mogelijk verwerkt.</Typography>
                                <Typography variant="subtitle2">Zodra deze door ons verwerkt is, vindt u de cursus in uw portal terug</Typography>
                            </Grid>
                            {toDashboard ?
                                <Button onClick={() => history.push("/dashboard")} variant="contained" color="secondary" id="login-login-button">
                                    <Typography variant="button" style={{ color: "#fff" }}>
                                        TERUG NAAR DASHBOARD
                                    </Typography>
                                </Button>
                                :
                                <Button onClick={() => history.push("/inschrijven-als")} variant="contained" color="secondary" id="login-login-button">
                                    <Typography variant="button" style={{ color: "#fff" }}>
                                        TERUG NAAR INSCHRIJVEN
                                    </Typography>
                                </Button>
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(Complete);