import { Dispatch } from "redux";

import { userInformationActions } from "./types";
import { userInformationService } from "../../services/userInfomation.service";
import { StudentDetail } from "../../types/student";
import { UserInformation } from "../../types/userInformation";

export const getUserInformation = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: userInformationActions.GET_USER_INFORMATION_REQUEST });
        const response = await userInformationService.userInformation();
        dispatch({ type: userInformationActions.GET_USER_INFORMATION_SUCCESS, response });
    } catch (error) {
        dispatch({ type: userInformationActions.GET_USER_INFORMATION_ERROR, error });
    }
};

export const requestEdit = (userInformation: UserInformation) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: userInformationActions.GET_REQUEST_EDIT_REQUEST });
        await userInformationService.requestEdit(userInformation);
        dispatch({ type: userInformationActions.GET_REQUEST_EDIT_SUCCESS });
    } catch (error) {
        dispatch({ type: userInformationActions.GET_REQUEST_EDIT_ERROR, error });
    }
}; 

export const setUserInformationStudent = (studentDetail: StudentDetail | undefined) => async (dispatch: Dispatch) => {
    const response: UserInformation = {student: studentDetail}
    dispatch({ type: userInformationActions.GET_USER_INFORMATION_SUCCESS, response })
};
