import { AuthActions } from "./types";
import { AuthenticationState } from "../../types/auth";
import { loadState } from "../../helperfunctions/localstorage";

const persistentState = loadState().auth;

const initialState: AuthenticationState = {
    accounts: [],
    token: persistentState?.token,
    refreshToken: persistentState?.refreshToken,
    role: persistentState?.role,
    lastName: persistentState?.lastName,
    prefix: persistentState?.prefix,
    firstName: persistentState?.firstName,
    initials: persistentState?.initials,
    companyName: persistentState?.companyName,
    destination: undefined,
    specialPriceAgreement: false,
    loading: false,
    error: undefined
};

export function authReducer(state = initialState, action: typeof AuthActions): AuthenticationState {
    switch (action.type) {
        case AuthActions.SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.accounts
            }
        case AuthActions.RESET_ACCOUNTS:
            return {
                ...state,
                accounts: []
            }
        case AuthActions.SET_DESTINATION:
            return {
                ...state,
                destination: action.destination
            }
        case AuthActions.RESET_DESTINATION:
            return {
                ...state,
                destination: undefined
            }
        case AuthActions.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case AuthActions.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.account.token,
                refreshToken: action.account.refreshToken,
                role: action.account.role,
                firstName: action.account.firstName,
                lastName: action.account.lastName,
                prefix: action.account.prefix,
                initials: action.account.initials,
                companyName: action.account.companyName,
                specialPriceAgreement: action.account.specialPriceAgreement,
                loading: false,
                error: undefined
            };
        case AuthActions.CHANGE_REFRESHTOKEN:
            return {
                ...state,
                refreshToken: action.newToken
            }
        case AuthActions.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AuthActions.LOGOUT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AuthActions.LOGOUT_SUCCESS:
            return {
                ...initialState,
                token: "",
                refreshToken: "",
                role: "",
                lastName: "",
                initials: "",
                prefix: "",
                firstName: "",
                companyName: "",
            };
        case AuthActions.LOGOUT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AuthActions.SEND_EMAIL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case AuthActions.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AuthActions.SEND_EMAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AuthActions.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AuthActions.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AuthActions.RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AuthActions.SET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AuthActions.SET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case AuthActions.SET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AuthActions.REFRESH_TOKEN:
            return {
                ...state,
                token: action.newToken,
            };
        case AuthActions.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case AuthActions.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state
            };
        case AuthActions.CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}