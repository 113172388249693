import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles, withStyles } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";

const styles = (theme: Theme) => ({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)",
    },
    active: {
        "& $line": {
            borderColor: theme.palette.secondary.main,
        },
    },
    completed: {
        "& $line": {
            borderColor: theme.palette.secondary.main,
        },
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1,
    },
})

export const StepperConnector = withStyles(styles)(StepConnector)


export const useStepperIconStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: "#EDEDED",
            display: "flex",
            height: 22,
            alignItems: "center",
        },
        active: {
            color: theme.palette.secondary.main,
        },
        circle: {
            width: 28,
            height: 28,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: "currentColor",
        },
        circleCompleted: {
            width: 28,
            height: 28,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: theme.palette.secondary.main,
        },
        innerCircle: {
            width: 14,
            height: 14,
            borderRadius: "50%",
            backgroundColor: "#EDEDED",
        },
        completed: {
            color: "#EDEDED",
            zIndex: 1,
            fontSize: 18,
        },
    })
);