import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";

export const useInputStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: "8px !important",
            minHeight: "56px !important",
            backgroundColor: "#EDEDED !important",
            borderRadius: "4px",
            color: "#757575 !important",
            outline: "none",
            "&$cssFocused $notchedOutline": {
                borderColor: "#fff !important",
            }
        },
        rootSelect: {
            marginTop: "8px !important",
        },
        cssOutlinedInputSelect: {
            paddingLeft: "14px !important",
            minHeight: "56px !important",
            color: "#757575 !important",
            backgroundColor: "#EDEDED !important",
            borderRadius: "4px",
            "&$cssFocused $notchedOutline": {
                borderColor: "#fff !important",
            }
        },
        cssFocused: {},
        notchedOutline: {},
        focused: {},
        label: {
            color: "black"
        },
        formHelperText: {
            color: "#fff !important"
        },
        icon: {
            color: "#8f8f8f !important"
        },
        maskedInput: {
            paddingLeft: theme.spacing(2),
            fontSize: "18px",
            width: "100%",
            '&::placeholder ': {
                fontWeight: "lighter",
                color: theme.palette.text.disabled,
            },
            border: "1px solid rgba(0, 0, 0, 0.23)",
            '&:focus ': {
                border: "2px solid #000",
            },
        },
    })
);