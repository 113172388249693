import React, { useState } from "react";
import { Grid, makeStyles, Theme, Card, CardContent, ListItem, ListItemAvatar, ListItemText, List, Button, Typography, Avatar } from "@material-ui/core";

import { Issuer } from "../../types/payment";
import { FaCheck } from "react-icons/fa";
import { getIssuerLogo } from "../../helperfunctions/getIssuerLogo";
import Loading from "../FeedBack/Loading";

const useStyles: any = makeStyles((theme: Theme) => ({
    full: {
        height: "60%",
        marginTop: '2em'
    },
    divider: {
        height: "3px",
        color: "gray"
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(7),
        borderRadius: 5
    }
}));

interface IssuersComponentProps {
    issuers: Issuer[];
    loading: boolean;
    handleNext: (issuerId: string | undefined) => void;
}

const IssuersComponent = ({ issuers, handleNext, loading }: IssuersComponentProps) => {
    const classes = useStyles();
    const [selected, setSelected] = useState(-1);
    const [issuerId, setIssuerId] = useState<string | undefined>(undefined);

    return (
        <Grid style={{ zIndex: -2 }} container justify="center" alignItems="center" className={classes.full}>
            <Grid item xs={10} md={6} lg={6}>
                <Grid container style={{ marginLeft: '32px' }} justify='space-between' alignItems="center">
                    <Grid item xs={6} sm={3}>
                        <Button onClick={(e) => {
                            handleNext(issuerId)
                        }} variant="contained" color="secondary" id="login-login-button">
                            <Typography variant="button" style={{ color: "#fff" }}>
                                VOLGENDE
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
}

export default IssuersComponent;