import React, { useEffect } from 'react'
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import AdminStudentsComponent from './AdminStudentsComponent'
import { getStudentDetail } from '../../../store/student/actions';
import { StudentState } from '../../../types/student';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';

interface AdminStudentsContainerProps {
    role?: string;
    student: StudentState;
    getCourseDetailsAdmin: () => void;
    getCourseAssignments: (courseId: string) => void;
    getStudentDetail: (search: string) => void;
}

const mapStateToProps = ({ student, auth: { role } }: ApplicationState) => {
    return ({
        student, role
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getStudentDetail }, dispatch);
};

const StudentsContainer = ({ role, student, getStudentDetail, location }: AdminStudentsContainerProps & RouteComponentProps) => {
    const urlParams = new URLSearchParams(location.search);
    const studentId = urlParams.get("studentId") || undefined;

    useEffect(() => {
        if (studentId) {
            getStudentDetail(studentId);
        }
    }, [studentId, getStudentDetail]);

    return (
        <AdminStudentsComponent
            studentDetail={student.studentDetail}
            searchStudentDetails={(search) => getStudentDetail(search)}
            loading={student.loading}
            error={student.error}
            role={role}
        />
    )
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(StudentsContainer);
