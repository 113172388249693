import React from "react";
import { FormHelperText, makeStyles, createStyles, InputBase, Paper, IconButton, Divider } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export interface TextFieldProps {
    input: string;
    label: string;
    number?: boolean;
    meta: {
        touched: boolean;
        error: string;
    };
    custom: any
}

const useStyles = makeStyles(
    createStyles({
        root: {
            paddingLeft: "1em",
            display: "flex",
            alignItems: "center",
            height: "48px"
        },
        input: {
            marginLeft: 8,
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
        divider: {
            width: 1,
            height: 28,
            margin: 4,
        },
    }),
);

const RenderTextFieldSearch = ({
    input,
    label,
    number,
    meta: { touched, error },
    ...custom
}: TextFieldProps) => {
    const classes = useStyles();

    return (
        <>
            <Paper className={classes.root}>
                <InputBase
                    fullWidth
                    label={label}
                    type={number ? "number" : "text"}
                    error={touched && !!error}
                    {...input}
                    {...custom}
                />
                <Divider className={classes.divider} />
                <IconButton type="submit" className={classes.iconButton} aria-label="Search">
                    <SearchIcon />
                </IconButton>
            </Paper>
            {(touched && !!error) && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </>
    );
}

export default RenderTextFieldSearch;