import graphqlHelperService from "./graphqlHelper.service";
import { StudentDetail } from "../types/student";
import { capitalizeFirstLetter } from "../helperfunctions/data";

export const studentService = {
    students: async (): Promise<StudentDetail[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            studentsQuery()
        );
        return apolloResult.students as StudentDetail[];
    },
    studentDetail: async (studentId?: string): Promise<StudentDetail> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            studentDetailQuery(studentId)
        );
        return apolloResult.studentDetail as StudentDetail;
    },
    createNewStudent: async (newStudent: StudentDetail): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            createNewStudentQuery(newStudent)
        );
        return apolloResult.createNewStudent as boolean;
    },
};

const studentsQuery = () => `query{
    students {
        studentId
        sex
        initials
        firstName
        prefix
        lastName
        address
        houseNumber
        place
        zipCode
        birthDate
        birthPlace
        mobileNumber
        privateNumber
        email
    }
}`;

const studentDetailQuery = (studentId?: string) => `query{
    studentDetail${studentId ? `(studentId: "${studentId}")` : ``}{
        studentId
        sex
        initials
        firstName
        prefix
        lastName
        address
        houseNumber
        zipCode
        place
        birthDate
        birthPlace
        privateNumber
        mobileNumber
        email
        courseResults {
            name
            date
            passed
            failed
            grade
            theoreticGrade
            portfolioGrade
            coachingGrade
            place
            madeSampleExams
            sampleExamGrade
            presentAtExam
        }
    }
}`

const createNewStudentQuery = (newStudent: StudentDetail) => `mutation{
    createNewStudent(newStudent: {
            sex: "${newStudent.sex}",
            initials: "${newStudent.initials}",
            birthDate: "${newStudent.birthDate}",
            birthPlace: "${newStudent.birthPlace}",
            ${newStudent.firstName ? `firstName: "${capitalizeFirstLetter(newStudent.firstName)}",` : ``}
            ${newStudent.prefix ? `prefix: "${newStudent.prefix}",` : ``}
            lastName: "${capitalizeFirstLetter(newStudent.lastName)}",
            zipCode: "${newStudent.zipCode}",
            houseNumber: "${newStudent.houseNumber}",
            address: "${capitalizeFirstLetter(newStudent.address)}",
            place: "${capitalizeFirstLetter(newStudent.place)}",
            ${newStudent.privateNumber ? `privateNumber: "${newStudent.privateNumber}",` : ``}
            mobileNumber: "${newStudent.mobileNumber}",
            email: "${newStudent.email}",
        }
    )
}`;
