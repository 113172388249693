import { SampleExamLogState } from "../../types/sampleExamLog";
import { SampleExamLogActions } from "./types";

const initialState: SampleExamLogState = {
    sampleExamLogs: [],
    loading: false,
    error: undefined
};

export function sampleExamLogsReducer(state = initialState, action: typeof SampleExamLogActions): SampleExamLogState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case SampleExamLogActions.GET_SAMPLEEXAMLOG_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case SampleExamLogActions.GET_SAMPLEEXAMLOG_SUCCESS:
            return {
                ...state,
                sampleExamLogs: action.response,
                loading: false
            };
        case SampleExamLogActions.GET_SAMPLEEXAMLOG_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
