import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

const Loading = () => {
    return (
        <Grid container justify="center" alignItems="center">
            <Grid item>
                <CircularProgress size={64} color="secondary" />
            </Grid>
        </Grid>
    );
}

export default Loading;