import React from "react";
import { Grid, makeStyles, Theme, Card, CardContent, Button, Typography, Dialog } from "@material-ui/core";

import { Issuer } from "../../types/payment";

const useStyles: any = makeStyles((theme: Theme) => ({
    full: {
        height: "60%",
        marginTop: '2em'
    },
    divider: {
        height: "3px",
        color: "gray"
    },
    avatar: {
        width: theme.spacing(12),
        height: theme.spacing(7),
        borderRadius: 5
    }
}));

interface IssuersComponentProps {
    issuers: Issuer[];
    loading: boolean;
    open?: boolean;
    setOpen: (open: boolean) => void;
    setSelectedIssuer: (issuer: Issuer) => void;
}

const IssuersModal = ({ issuers, loading, setSelectedIssuer, open, setOpen }: IssuersComponentProps) => {
    const classes = useStyles();
    return (
        <Dialog maxWidth='md' fullWidth open={open ? open : false} onClose={() => setOpen(false)}>
            <Grid container justify="center" alignItems="center" className={classes.full}>
                <Grid item xs={12} md={12} lg={12}>
                    <Card>
                        <Grid container style={{ marginLeft: '32px' }} justify='space-between' alignItems="center">
                            <Grid item xs={6} sm={3}>
                                <Button onClick={(e) => {
                                    setOpen(false)
                                }} variant="contained" color="secondary" id="login-login-button">
                                    <Typography variant="button" style={{ color: "#fff" }}>
                                        VOLGENDE
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                        <CardContent>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </Dialog>
    );
}

export default IssuersModal;