import { Localization } from "material-table";

export const materialTableLocalization: Localization = {
    body: {
        emptyDataSourceMessage: "Geen data gevonden",
        addTooltip: "Toevoegen",
        editTooltip: "Aanpassen",
        deleteTooltip: "Verwijderen",
        editRow: {
            saveTooltip: "Opslaan",
            cancelTooltip: "Annuleren",
            deleteText: "Weet u zeker dat u dit veld wil verwijderen?"
        },
    },
    toolbar: {
        searchTooltip: "Zoeken",
        searchPlaceholder: "Zoeken",
        nRowsSelected: "{0} rij(en) geselecteerd"
    },
    header: {
        actions: ""
    },
    pagination: {
        firstTooltip: "Eerste pagina",
        previousTooltip: "Vorige pagina",
        nextTooltip: "Volgende pagina",
        lastTooltip: "Laatste pagina",
        labelRowsSelect: "Rijen"
    }
};

export const materialTableLocalizationAction: Localization = {
    body: {
        emptyDataSourceMessage: "Geen data gevonden",
        addTooltip: "Toevoegen",
        editTooltip: "Aanpassen",
        deleteTooltip: "Verwijderen",
        editRow: {
            saveTooltip: "Opslaan",
            cancelTooltip: "Annuleren",
            deleteText: "Weet u zeker dat u dit veld wil verwijderen?"
        },
    },
    toolbar: {
        searchTooltip: "Zoeken",
        searchPlaceholder: "Zoeken",
        nRowsSelected: "{0} rij(en) geselecteerd"
    },
    header: {
        actions: "Download factuur"
    },
    pagination: {
        firstTooltip: "Eerste pagina",
        previousTooltip: "Vorige pagina",
        nextTooltip: "Volgende pagina",
        lastTooltip: "Laatste pagina",
        labelRowsSelect: "Rijen"
    }
};