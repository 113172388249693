import React from "react";
import { connect } from "react-redux";
import { Grid, Typography, Button, Card, makeStyles, CardContent } from "@material-ui/core";
import { Field, reduxForm, InjectedFormProps, getFormValues } from "redux-form";

import TextFieldBar from "../TextField/TextFieldBar";
import { required, passwordStrongEnough, validString } from "../../validators/form";
import RenderTextFieldLogin from "../TextField/TextFieldLogin";
import { useTextStyles } from "../../styles/text";
import "./Login.css";
import { ApplicationState } from "../../store";
import Loading from "../FeedBack/Loading";
import { getPortalName } from "../../helperfunctions/getPortalName";
import PasswordTooltip from "../../helperfunctions/PasswordTooltip";

interface ConfigurePasswordFormData {
    password: string;
    repeatedPassword: string;
}

interface ConfigurePasswordProps {
    formValues: any;
    title: string
    loginError: string
    loading: boolean
    handleChangePassword: (newPassword?: string) => void;
}

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: "#fff",
        borderRadius: "0",
        [theme.breakpoints.down("sm")]: {
            boxShadow: "none",
        },
    },
    button: {
        marginTop: "1em"
    },
    title: {
        fontSize: "25pt",
        color: theme.palette.primary.main
    },
    cardContent: {
        marginTop: "6em",
        marginBottom: "6em"
    },
    full: {
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: theme.palette.primary.main,
        },
    }
}));

const ConfigurePassword = ({ handleSubmit, handleChangePassword, title, loading, reset, formValues }: ConfigurePasswordProps & InjectedFormProps<ConfigurePasswordFormData, ConfigurePasswordProps>) => {
    const disabled: boolean = !formValues || !formValues.password || formValues.password !== formValues.repeatedPassword || passwordStrongEnough(formValues.password) !== undefined;

    const alertData = (data: ConfigurePasswordFormData) => handleChangePassword(data.password);
    const classes = useStyles();
    const textClasses = useTextStyles();

    return (
        <Grid className={classes.full} container alignItems="center" justify="center">
            <Grid item xs={12} md={6}>
                <Card elevation={8} className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Grid container alignItems="center" justify="center">
                            <Grid item xs={12} sm={8} xl={8}>
                                <Typography className={classes.title}>{title}</Typography>
                                {loading && <Loading />}
                                {(!loading) &&
                                    <form id="login-input-form" onSubmit={handleSubmit(alertData)}>
                                        <Field
                                            name="password"
                                            type="password"
                                            component={RenderTextFieldLogin}
                                            placeholder={`Wachtwoord ${getPortalName()}`}
                                            validate={[validString, required, passwordStrongEnough]}
                                            InputProps={{
                                                endAdornment: <PasswordTooltip />,
                                            }}
                                        />
                                        <Field
                                            name="repeatedPassword"
                                            type="password"
                                            component={RenderTextFieldLogin}
                                            placeholder="Wachtwoord opnieuw"
                                            validate={[validString, required, passwordStrongEnough]}
                                        />
                                        <Field
                                            name="password"
                                            validate={[validString, required, passwordStrongEnough]}
                                            component={TextFieldBar}
                                        />
                                        <Button className={classes.button} disabled={disabled} type="submit" variant="contained" color="secondary">
                                            <Typography classes={{
                                                root: `${textClasses.button}`
                                            }}>Instellen</Typography>
                                        </Button>
                                    </form>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

const DecoratedInitializeFromStateFormFunction = reduxForm<ConfigurePasswordFormData, ConfigurePasswordProps>({
    form: "configurePassword",
    enableReinitialize: true,
})(ConfigurePassword);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        return ({
            formValues: getFormValues("configurePassword")(state),
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;