import React from "react"
import { Grid, makeStyles, CardContent, Card } from "@material-ui/core";
import { FaCheck, FaTimes } from "react-icons/fa";
import { parseGrade } from "../../helperfunctions/grade";
import { isNil } from "lodash";

const useStyles = makeStyles(theme => ({
    text: {
        fontWeight: "bold",
    },
    card: {
        boxShadow: "none",
        width: "100%",
        backgroundColor: "#F8F8F8"
    }
}))

interface StudentDetailResultsItemProps {
    passed?: boolean;
    failed?: boolean;
    name: string;
    madeSampleExams?: boolean;
    grade?: number;
    theoreticGrade?: number,
    portfolioGrade?: number,
    coachingGrade?: number,
    date?: string;
    place?: string;
    showSampleExam?: boolean;
    sampleExamGrade?: number;
    presentAtExam?: boolean;
}

const StudentDetailResultsItem = ({ passed, failed, name, madeSampleExams, sampleExamGrade, grade, theoreticGrade, portfolioGrade, coachingGrade, showSampleExam, date, place, presentAtExam }: StudentDetailResultsItemProps) => {
    const classes = useStyles()

    return (
        <div>
            <Grid style={{ marginBottom: "20px" }} container>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={8}>
                                <p className={classes.text}>Cursus</p>
                            </Grid>
                            <Grid item xs={4}>
                                <p>{name}</p>
                            </Grid>
                            <Grid item xs={8}>
                                <p className={classes.text}>Cursus data</p>
                            </Grid>
                            <Grid item xs={3}>
                                <p>Afgerond op: {date}</p>
                            </Grid>
                            {place ? <><Grid item xs={8}>
                                <p className={classes.text}>Cursus plaats</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p>{place}</p>
                                </Grid></> : null}
                            {!isNil(madeSampleExams) ? <><Grid item xs={8}>
                                <p className={classes.text}>Proefexamen {sampleExamGrade ? "cijfer" : "gemaakt"}</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p>{madeSampleExams ? sampleExamGrade ? parseGrade(sampleExamGrade) : "Ja" : "Nee"}</p>
                                </Grid></> : null}
                            {(passed === true || failed === true) ? <><Grid item xs={8}>
                                <p className={classes.text}>Resultaat</p>
                            </Grid>
                                <Grid item xs={3}>
                                    {(passed === true) &&
                                        <Grid container alignItems="center">
                                            <Grid item xs={4}>
                                                <FaCheck style={{ marginLeft: "5px", color: "green" }} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <p className={classes.text}>Geslaagd</p>
                                            </Grid>
                                        </Grid>
                                    }
                                    {(failed === true) &&
                                        <Grid container alignItems="center">
                                            <Grid item xs={4}>
                                                <FaTimes style={{ marginLeft: "5px", color: "red" }} />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <p className={classes.text}>Afgewezen</p>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </> : null}
                            {presentAtExam === false ? <><Grid item xs={8}>
                                <p className={classes.text}>Aanwezigheid</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p className={classes.text}>Afwezig</p>
                                </Grid></> : null}
                            {grade ? <><Grid item xs={8}>
                                <p className={classes.text}>Cijfer</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p className={classes.text}>{parseGrade(grade)}</p>
                                </Grid></> : null}
                            {theoreticGrade ? <><Grid item xs={8}>
                                <p className={classes.text}>Theorie cijfer</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p className={classes.text}>{parseGrade(theoreticGrade)}</p>
                                </Grid></> : null}
                            {portfolioGrade ? <><Grid item xs={8}>
                                <p className={classes.text}>Portfolio cijfer</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p className={classes.text}>{parseGrade(portfolioGrade)}</p>
                                </Grid></> : null}
                            {coachingGrade ? <><Grid item xs={8}>
                                <p className={classes.text}>Coachen & Begeleiden cijfer</p>
                            </Grid>
                                <Grid item xs={3}>
                                    <p className={classes.text}>{parseGrade(coachingGrade)}</p>
                                </Grid></> : null}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    )
}

export default StudentDetailResultsItem
