import React from "react"
import { UserInformation } from "../../types/userInformation";
import StudentDetailComponent from "../StudentDetail/StudentDetailComponent";
import RelationComponent from "../relationDetail/RelationDetailComponent";
import { Role } from "../../types/enum";

interface UserInformationProps {
    role: string;
    userInformation: UserInformation;
    loading: boolean;
    error?: any;
}

const UserInformationComponent = ({ role, userInformation, loading, error }: UserInformationProps) => {
    return (
        <div>
            {(role === Role.COMPANY || role === Role.FREELANCER) &&
                <RelationComponent
                    role={role}
                    relationDetail={userInformation.relation}
                    loading={loading}
                    error={error}
                />
            }
            {(role === Role.STUDENT || role === Role.FREELANCER) &&
                <StudentDetailComponent
                    title={"Persoonlijke gegevens"}
                    studentDetail={userInformation.student}
                    loading={loading}
                    error={error}
                />
            }
        </div>
    );
};

export default UserInformationComponent;
