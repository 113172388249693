import { createActionsWithPrefix } from "../../helperfunctions/action";

export const RegisterActions = createActionsWithPrefix("REGISTER/", {
    SET_AGREEMENT_FILENAME: "SET_AGREEMENT_FILENAME",
    RESET_STEPPER: "RESET_STEPPER",
    RESET_REGISTER_STATE: "RESET_REGISTER_STATE",
    RESET_REGISTER_STATE_COMPANY: "RESET_REGISTER_STATE_COMPANY",
    RESET_REGISTER_STATE_FREELANCER: "RESET_REGISTER_STATE_FREELANCER",
    SET_PRICES: "SET_PRICES",
    SET_FREELANCER_DATA: "SET_FREELANCER_DATA",
    SET_COMPANY_DATA: "SET_COMPANY_DATA",
    SET_PAYMENT: "SET_PAYMENT",
    ADD_STUDENT: "ADD_STUDENT",
    EDIT_STUDENT: "EDIT_STUDENT",
    REMOVE_STUDENT: "REMOVE_STUDENT",
    UPDATE_BOOKS: "UPDATE_BOOKS",
    REMOVE_ALL_STUDENTS: "REMOVE_ALL_STUDENTS",
    SET_REGISTER_STUDENT: "SET_REGISTER_STUDENT",
    EMPTY_REGISTER_STUDENT: "EMPTY_REGISTER_STUDENT",
    ADD_TO_SHOPPINGCART: "ADD_TO_SHOPPINGCART",
    DELETE_FROM_SHOPPINGCART: "DELETE_FROM_SHOPPINGCART",
    SHOPPINGCART_ADD_STUDENT: "SHOPPINGCART_ADD_STUDENT",
    SHOPPINGCART_EDIT_STUDENT: "SHOPPINGCART_EDIT_STUDENT",
    SHOPPINGCART_REMOVE_STUDENT: "SHOPPINGCART_REMOVE_STUDENT",
    SHOPPINGCART_REMOVE_ALL_STUDENTS: "SHOPPINGCART_REMOVE_ALL_STUDENTS",
    REGISTER_REQUEST: "REGISTER_REQUEST",
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_ERROR: "REGISTER_ERROR",
    SET_SHOPPINGCART_PAYMENT: "SET_SHOPPINGCART_PAYMENT",
    EMPTY_SHOPPINGCART: "EMPTY_SHOPPINGCART",
    SET_SHOPPINGCART_COURSE: "SET_SHOPPINGCART_COURSE",
    EDIT_SHOPPINGCART_FREELANCEBOOKS: "EDIT_SHOPPINGCART_FREELANCEBOOKS",
    SET_COMPANY_KHN: "SET_COMPANY_KHN",
    SET_FREELANCER_KHN: "SET_FREELANCER_KHN",
    SET_COMPANY_ADDRESS: "SET_COMPANY_ADDRESS",
    SET_COMPANY_INVOICE_ADDRESS: "SET_COMPANY_INVOICE_ADDRESS",
    SET_FREELANCER_ADDRESS: "SET_FREELANCER_ADDRESS",
    SET_FREELANCER_INVOICE_ADDRESS: "SET_FREELANCER_INVOICE_ADDRESS",
    SET_STUDENT_ADDRESS: "SET_STUDENT_ADDRESS",
});