import { ReactElement } from "react";
import { FaCalendar, FaCalendarDay, FaChartLine, FaCheck, FaClock, FaEuroSign, FaFile, FaGraduationCap, FaHourglassHalf, FaInfo, FaLocationArrow, FaQuestion, FaStar, FaTag, FaTimes, FaUser, FaUserCheck, FaUserTimes } from "react-icons/fa";

export type IconOption = "FaQuestion" | "FaEuroSign" | "FaChartLine" | "FaUser" | "FaTag" | "FaLocationArrow"
    | "FaFile" | "FaCalendar" | "FaClock" | "FaCalendarDay" | "FaGraduationCap" | "FaCheck" | "FaTimes"
 | "FaInfo" | "FaUserCheck" | "FaStar" | "FaUserTimes" | "FaHourglassHalf";
export type SelectIconOption = {
    value: IconOption;
    label: ReactElement<any, any>;
}

export const iconOptions: SelectIconOption[] = [
    { value: "FaQuestion", label: <FaQuestion /> },
    { value: "FaEuroSign", label: <FaEuroSign /> },
    { value: "FaChartLine", label: <FaChartLine /> },
    { value: "FaUser", label: <FaUser /> },
    { value: "FaTag", label: <FaTag /> },
    { value: "FaLocationArrow", label: <FaLocationArrow /> },
    { value: "FaFile", label: <FaFile /> },
    { value: "FaCalendar", label: <FaCalendar /> },
    { value: "FaClock", label: <FaClock /> },
    { value: "FaCalendarDay", label: <FaCalendarDay /> },
    { value: "FaGraduationCap", label: <FaGraduationCap /> },
    { value: "FaCheck", label: <FaCheck /> },
    { value: "FaTimes", label: <FaTimes /> },
    { value: "FaInfo", label: <FaInfo /> },
    { value: "FaUserCheck", label: <FaUserCheck /> },
    { value: "FaStar", label: <FaStar /> },
    { value: "FaUserTimes", label: <FaUserTimes /> },
    { value: "FaHourglassHalf", label: <FaHourglassHalf /> }
];

export const getIconOptionByValue = (value: IconOption): SelectIconOption => {
    for (let index = 0; index < iconOptions.length; index++) {
        const option = iconOptions[index];
        if (option.value === value) {
            return option;
        }
    }
    return iconOptions[0];
}