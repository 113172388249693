import React, { useState, useEffect } from "react"
import { Grid, Card, CardContent, Typography, LinearProgress, makeStyles } from "@material-ui/core";

import { useTextStyles } from "../../styles/text";
import TestExamStart from "./TestExamStart";
import TestExamModal from "./TestExamModal";
import TestExamResult from "./TestExamResult";
import TestExam from "./TestExam";
import { SampleExam, ExamResult } from "../../types/sampleExam";
import Loading from "../FeedBack/Loading";
import { QuestionFilledInput } from "../../types/question";

interface TestExamComponentProps {
    sampleExam?: SampleExam
    course?: string
    testExamResult?: ExamResult
    loading?: boolean
    error?: string
    examAnswers: Array<QuestionFilledInput>;
	addTestExamAnswer: (givenAnswerId: number, questionId: number) => void;
    resetAnswers: () => void;
    getTestExamResult: () => void;
}

const useStyles = makeStyles(() => ({
    bar: {
        height: "100%"
    }
}))

const TestExamComponent = ({ sampleExam, course, addTestExamAnswer, resetAnswers, examAnswers, testExamResult, getTestExamResult, loading, error }: TestExamComponentProps) => {
    const textClasses = useTextStyles();
    const classes = useStyles();
    const [started, setStarted] = useState(false)
    const [result, setResult] = useState(false)
    const [open, setOpen] = useState(false)
    const [barValue, setBarValue] = useState(100)


    const getInterval = (duration: number) => {
        let durationSeconds = duration * 60
        let interval = durationSeconds / 100 * 1000
        return interval;
    }

    useEffect(() => {
        if (started && sampleExam) {
            var timerID = setInterval(() => tick(), getInterval(sampleExam.duration));
            return function cleanup() {
                clearInterval(timerID);
            };
        }
    });

    function tick() {
        setBarValue(barValue - 1);
        if (barValue === 0) {
            setResult(true)
            getTestExamResult()
        }
    }
    return (
        <div>
            {loading && <Loading />}
            {!loading && !error && sampleExam &&
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <span className={`${textClasses.pageTitle}`}>Proefexamen</span>
                    </Grid>
                    <Grid item xs={12}>
                        <Card style={{ marginTop: "1em" }} elevation={2}>
                            <CardContent>
                                {!started && !result && <TestExamStart questions={sampleExam.questions} started={started} setStarted={setStarted} course={course} duration={sampleExam.duration} amountOfQuestions={sampleExam.amountOfQuestions} />}
                                {started && !result && <TestExam examAnswers={examAnswers} setOpen={() => setOpen(true)} addTestExamAnswer={addTestExamAnswer} course={course} questions={sampleExam.questions} />}
                                {started && result && <TestExamResult testExamResult={testExamResult} loading={loading} error={error} />}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        {started && !result &&
                            <Card elevation={2}>
                                <CardContent style={{ paddingBottom: "16px" }}>
                                    <Grid container justify="center">
                                        <Grid item xs={4}>
                                            <Typography variant="h5">Tijd over</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <LinearProgress className={classes.bar} variant="determinate" color="secondary" value={barValue} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        }
                    </Grid>
                    <TestExamModal setOpen={() => setOpen(!open)} getTestExamResult={getTestExamResult} result={result} setResult={setResult} open={open} />
                </Grid>
            }
        </div>
    )
}

export default TestExamComponent
