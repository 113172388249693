import { useState } from "react"
import MaterialTable from "material-table";
import { Grid, Card, CardContent, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { FaPen, FaTrash, FaPlus, FaDownload } from "react-icons/fa";

import { renderSkeleton } from "../../FeedBack/Skeleton";
import Error from "../../FeedBack/Error";
import { Assignment, InputAssignment } from "../../../types/assignment";
import EditAssignmentDialog, { EditAssignmentDialogForm } from "./EditAssignmentDialog";
import { materialTableLocalization } from "../../../constants/materialTableLocalization";
import messages from "../../../constants/messages";
import { toast } from "react-toastify";

interface AssignmentComponentProps {
    courseId: string;
    assignments: Assignment[];
    fileUrl?: string;
    loading: boolean;
    error: any;
    uploadAssignment: (assignment: InputAssignment) => void;
    editAssignment: (assignmentId: number, assignment: InputAssignment) => void;
    getAssignmentFile: (assignmentId: number) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
    deleteAssignment: (assignmentId: number) => void;
}

const AssignmentComponent = ({ assignments, loading, error, courseId, fileUrl, uploadAssignment, editAssignment, getAssignmentFile, downloadAssignment, deleteAssignment }: AssignmentComponentProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>("");
    const [openEditId, setOpenEditId] = useState<number | undefined>(undefined);
    const initialAssignment: InputAssignment = { courseId, name: "", showOnHOC: false, showOnHSN: false, showOnSH: false, content: undefined };
    const [initialValues, setInitialValues] = useState<InputAssignment>(initialAssignment);
    const theme: Theme = useTheme();

    const handleEditAssignment = (assignmentId: number) => {
        const editAssignment: Assignment | undefined = assignments ? assignments.find(assignment => assignment.assignmentId === assignmentId) : undefined;

        if (editAssignment) {
            setFileName(editAssignment.name);
            const inputEditAssignment: InputAssignment = {
                courseId,
                name: editAssignment.name,
                showOnHOC: editAssignment.showOnHOC,
                showOnHSN: editAssignment.showOnHSN,
                showOnSH: editAssignment.showOnSH,
            };

            getAssignmentFile(assignmentId);
            setOpenEditId(assignmentId);
            setInitialValues(inputEditAssignment);
            setOpen(true);
        }
    };

    return <>
        <EditAssignmentDialog 
            fileName={fileName}
            initialValues={initialValues} 
            fileUrl={fileUrl} 
            open={open} 
            setOpen={setOpen} 
            doSubmit={({ name, showOnHOC, showOnHSN, showOnSH }: EditAssignmentDialogForm, file: File | string) => {
                let assignment: InputAssignment = { courseId, name, showOnSH, showOnHSN, showOnHOC, content: file };

                openEditId ? editAssignment(openEditId, assignment) : uploadAssignment(assignment);
                setOpen(false);
                setOpenEditId(undefined);
            }} 
        />
        <Grid container>
            {loading ?
                <Grid container>
                    {renderSkeleton(1, { xs: 12 }, 400, "rect")}
                </Grid>
                :
                <>
                    {error && <Error />}
                    <Card style={{ width: "100%", marginBottom: "1em" }}>
                        <CardContent>
                            <MaterialTable
                                title="Huiswerk"
                                style={{ boxShadow: "none", width: "100%" }}
                                columns={[
                                    { title: "ID", field: "assignmentId" },
                                    { title: "Naam", field: "name", defaultSort: "asc" },
                                    { title: "HSN", field: "showOnHSN", type: "boolean" },
                                    { title: "HOC", field: "showOnHOC", type: "boolean" },
                                    { title: "SH", field: "showOnSH", type: "boolean" },
                                ]}
                                data={assignments}
                                actions={[
                                    {
                                        icon: () => <FaDownload size="0.8em" color={theme.palette.action.active} />,
                                        tooltip: 'Downloaden',
                                        onClick: (event: any, rowData: Assignment | Assignment[]) => {
                                            if (!Array.isArray(rowData)) {
                                                downloadAssignment(rowData.assignmentId, rowData.name);
                                            }
                                        }
                                    },
                                    {
                                        icon: () => <FaPen size="0.8em" color={theme.palette.action.active} />,
                                        tooltip: 'Aanpassen',
                                        onClick: (_: any, rowData: Assignment | Assignment[]) => {
                                            if (!Array.isArray(rowData)) {
                                                toast.info(messages.UPDATE_STARTED)
                                                handleEditAssignment(rowData.assignmentId);
                                            }
                                        }
                                    },
                                    {
                                        icon: () => <FaTrash size="0.8em" color={theme.palette.action.active} />,
                                        tooltip: 'Verwijderen',
                                        onClick: (_: any, rowData: Assignment | Assignment[]) => {
                                            if (!Array.isArray(rowData)) {
                                                toast.info(messages.DELETE_STARTED)
                                                deleteAssignment(rowData.assignmentId);
                                            }
                                        }
                                    },
                                    {
                                        icon: () => <FaPlus color={theme.palette.action.active} />,
                                        tooltip: 'Huiswerk toevoegen',
                                        isFreeAction: true,
                                        onClick: (_: any) => setOpen(true)
                                    }
                                ]}
                                options={{
                                    sorting: true,
                                    pageSize: 5,
                                    headerStyle: {
                                        color: "gray"
                                    },
                                    actionsColumnIndex: -1,
                                }}
                                localization={materialTableLocalization}
                            />
                        </CardContent>
                    </Card>
                </>
            }
        </Grid>
    </>
};

export default AssignmentComponent;