import { isNil } from "lodash";
import { PaymentMethod, PortalPaymentMethod } from "../types/enum";
import { RegisterPriceResponse } from "../types/register";


export const getPaymentMethodsAllowed = (prices: RegisterPriceResponse[]): string[] => {
    let portalPaymentMethod: PortalPaymentMethod = PortalPaymentMethod.All;
    if (!isNil(prices)) {
        for (let index = 0; index < prices.length; index++) {
            const price: RegisterPriceResponse = prices[index];

            if (portalPaymentMethod === PortalPaymentMethod.All) {
                portalPaymentMethod = price.portalPaymentMethod || PortalPaymentMethod.All;
            }
            else {
                if (price.portalPaymentMethod === PortalPaymentMethod.InvoiceOnly && portalPaymentMethod === PortalPaymentMethod.IdealOnly) {
                    portalPaymentMethod = PortalPaymentMethod.All;
                    break;
                }
                else if (price.portalPaymentMethod === PortalPaymentMethod.IdealOnly && portalPaymentMethod === PortalPaymentMethod.InvoiceOnly) {
                    portalPaymentMethod = PortalPaymentMethod.All;
                    break;
                }
            }
        }
    }

    if (portalPaymentMethod === PortalPaymentMethod.InvoiceOnly) {
        return [PaymentMethod.Invoice];
    }
    else if (portalPaymentMethod === PortalPaymentMethod.IdealOnly) {
        return [PaymentMethod.Ideal];
    }
    else {
        return [PaymentMethod.Ideal, PaymentMethod.Invoice];
    }
}