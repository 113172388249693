import { connect } from "react-redux";
import { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";

import { ApplicationState } from "../../../store";
import { editMetaData, getMetaData } from "../../../store/metadata/actions";
import { CourseMetaData } from "../../../types/metaData";
import CourseMetaDataComponent from "./CourseMetaData";

interface CourseMetaDataContainerProps {
    courseId: string;
    metaData?: CourseMetaData;
    loading: boolean;

    editMetaData: (metaData: CourseMetaData) => void;
    getMetaData: (courseId: string) => void;
}

const mapStateToProps = ({ metaData: { loading, metaData } }: ApplicationState) => {
    return ({ loading, metaData });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        editMetaData,
        getMetaData,
    }, dispatch);
};

const MetaDataContainer = ({
    courseId,
    metaData,
    loading,
    editMetaData,
    getMetaData,
}: CourseMetaDataContainerProps) => {
    useEffect(() => {
        !loading && (!metaData || metaData?.courseId !== courseId) && getMetaData(courseId);
    }, [loading, metaData, getMetaData, courseId]);

    return (
        <CourseMetaDataComponent
            metaData={metaData}
            loading={loading}
            editMetaData={editMetaData}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MetaDataContainer);