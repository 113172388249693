import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { getIdealLink } from "../../store/payment/actions";
import InvoicePaymentComponent from "./InvoicePaymentComponent";
import { ApplicationState } from "../../store";
import { Invoice } from "../../types/invoice";
import messages from "../../constants/messages";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";

interface InvoicePaymentContainerProps {
    getIdealLink: (invoiceId: string, description: string, issuerId: string, returnUrl: string) => void;
    invoice?: Invoice;
}

const mapStateToProps = ({ invoice: { invoice } }: ApplicationState) => ({
    invoice, 
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ 
    getIdealLink 
}, dispatch);

const InvoicePaymentContainer = ({ getIdealLink, invoice, history }: InvoicePaymentContainerProps & RouteComponentProps) => <div>
    <InvoicePaymentComponent 
        handleNext={(issuerId: string) => {
            if (!invoice) {
                toast.error(messages.SOMETHING_WENT_WRONG)
                history.push("/facturen");
            } else {
                getIdealLink(invoice.invoiceId, "(factuur) " + invoice.invoiceId, issuerId, "factuur-betaald");
            }
        }}
    />
</div>

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentContainer);