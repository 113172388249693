import { createActionsWithPrefix } from "../../helperfunctions/action";

export const QuestionActions = createActionsWithPrefix("QUESTION/", {
    QUESTIONS_BY_CATEGORY_REQUEST: "QUESTIONS_BY_CATEGORY_REQUEST",
    QUESTIONS_BY_CATEGORY_SUCCESS: "QUESTIONS_BY_CATEGORY_SUCCESS",
    QUESTIONS_BY_CATEGORY_ERROR: "QUESTIONS_BY_CATEGORY_ERROR",
    CREATE_QUESTION_REQUEST: "CREATE_QUESTION_REQUEST",
    CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
    CREATE_QUESTION_ERROR: "CREATE_QUESTION_ERROR",
    UPDATE_QUESTION_REQUEST: "UPDATE_QUESTION_REQUEST",
    UPDATE_QUESTION_SUCCESS: "UPDATE_QUESTION_SUCCESS",
    UPDATE_QUESTION_ERROR: "UPDATE_QUESTION_ERROR",
    DELETE_QUESTION_REQUEST: "DELETE_QUESTION_REQUEST",
    DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
    DELETE_QUESTION_ERROR: "DELETE_QUESTION_ERROR",
    CREATE_ANSWER_REQUEST: "CREATE_ANSWER_REQUEST",
    CREATE_ANSWER_SUCCESS: "CREATE_ANSWER_SUCCESS",
    CREATE_ANSWER_ERROR: "CREATE_ANSWER_ERROR",
    UPDATE_ANSWER_REQUEST: "UPDATE_ANSWER_REQUEST",
    UPDATE_ANSWER_SUCCESS: "UPDATE_ANSWER_SUCCESS",
    UPDATE_ANSWER_ERROR: "UPDATE_ANSWER_ERROR",
    DELETE_ANSWER_REQUEST: "DELETE_ANSWER_REQUEST",
    DELETE_ANSWER_SUCCESS: "DELETE_ANSWER_SUCCESS",
    DELETE_ANSWER_ERROR: "DELETE_ANSWER_ERROR",
});