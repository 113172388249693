import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import DiscountCodesComponent from './DiscountCodesComponent';
import { ApplicationState } from '../../../store';
import { getDiscounts, createDiscount, updateDiscount, deleteDiscount } from '../../../store/discount/actions';
import { DiscountState, Discount } from '../../../types/discount';

interface DiscountCodesContainerProps {
    discount: DiscountState;
    getDiscounts: () => void;
    createDiscount: (discount: Discount) => void;
    updateDiscount: (discount: Discount) => void;
    deleteDiscount: (discountCode: string) => void;
}

const mapStateToProps = ({ discount }: ApplicationState) => {
    return ({
        discount
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getDiscounts, createDiscount, updateDiscount, deleteDiscount }, dispatch);
};

const DiscountCodesContainer = ({ discount, getDiscounts, createDiscount, updateDiscount, deleteDiscount }: DiscountCodesContainerProps) => {

    useEffect(() => {
        getDiscounts();
    }, [getDiscounts]);

    return (
        <DiscountCodesComponent
            discount={discount}
            createDiscount={createDiscount}
            updateDiscount={updateDiscount}
            deleteDiscount={deleteDiscount}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscountCodesContainer);