import { Dispatch } from "redux";

import { RegisterCourseActions } from "./types";
import { registerCourseService } from "../../services/registerCourse.service";
import { courseDetailService } from "../../services/courseDetail.service";
import { CourseDetail } from "../../types/course";
import { CourseType, AvailableDate, CourseCategory, RegisterCourse } from "../../types/registerCourse";
import { MailingLinkService } from "../../services/mailingLink.service";
import { RegisterUserType } from "../../types/enum";
import messages from "../../constants/messages";
import { toast } from "react-toastify";

export const getCategories = (userType: RegisterUserType) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.GET_CATEGORIES_REQUEST });
        const categories = await registerCourseService.categories(userType);
        dispatch({ type: RegisterCourseActions.GET_CATEGORIES_SUCCESS, categories });
    } catch (error) {
        dispatch({ type: RegisterCourseActions.GET_CATEGORIES_ERROR, error });
    }
};

export const getTypes = (categoryId: number, userType: RegisterUserType) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.GET_TYPES_REQUEST });
        const types = await registerCourseService.courseTypes(categoryId, userType);
        dispatch({ type: RegisterCourseActions.GET_TYPES_SUCCESS, types });
    } catch (error) {
        dispatch({ type: RegisterCourseActions.GET_TYPES_ERROR, error });
    }
};

export const getPlaces = (courseId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.GET_PLACES_REQUEST });
        const places = await registerCourseService.courseLocations(courseId);
        dispatch({ type: RegisterCourseActions.GET_PLACES_SUCCESS, places });
    } catch (error) {
        dispatch({ type: RegisterCourseActions.GET_PLACES_ERROR, error });
    }
};

export const getAvailableDates = (courseId: string, place: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.GET_AVAILABLE_DATES_REQUEST });
        const availableDates = await registerCourseService.locationOptions(courseId, place);
        dispatch({ type: RegisterCourseActions.GET_AVAILABLE_DATES_SUCCESS, availableDates });
    } catch (error) {
        dispatch({ type: RegisterCourseActions.GET_AVAILABLE_DATES_ERROR, error });
    }
};

export const setCategory = (category: CourseCategory) => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.SET_CATEGORY, category });
};

export const setType = (courseType: CourseType) => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.SET_TYPE, courseType });
};

export const setPlace = (place: string) => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.SET_PLACE, place });
};

export const setDate = (availableDate: AvailableDate) => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.SET_DATE, availableDate });
};

export const setMailingLink = (categoryId?: number, courseId?: string, place?: string, locationCode?: string) => async (dispatch: Dispatch) => {
    const course: RegisterCourse = await MailingLinkService.checkMailingLink(categoryId, courseId, place, locationCode);
    dispatch({ type: RegisterCourseActions.SET_COURSE, course });
}

export const setCourse = (course: RegisterCourse) => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.SET_COURSE, course });
}

export const setFreelanceBook = (freelancebook: boolean) => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.SET_FREELANCEBOOK, freelancebook });
}

export const resetCourseRegister = () => (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.RESET_COURSE })
}

export const getCourseDetail = (course: RegisterCourse, discountCode?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.GET_COURSE_DETAIL_REQUEST });
        const courseDetail = await courseDetailService.courseDetailRegister(course, discountCode);
        dispatch({ type: RegisterCourseActions.GET_COURSE_DETAIL_SUCCESS, courseDetail });
    } catch (error) {
        dispatch({ type: RegisterCourseActions.GET_COURSE_DETAIL_ERROR, error });
    }
};

export const setCourseDetail = (courseDetail: CourseDetail) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.GET_COURSE_DETAIL_SUCCESS, courseDetail })
};

export const resetCourseDetail = () => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterCourseActions.RESET_COURSE_DETAIL })
};

export const getCategoryRegisterViaLinkOnly = (categoryId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.GET_CATEGORYREGISTERVIALINKONLY_REQUEST });
        const categoryRegisterViaLinkOnly = await registerCourseService.getCategoryRegisterViaLinkOnly(categoryId);
        dispatch({ type: RegisterCourseActions.GET_CATEGORYREGISTERVIALINKONLY_SUCCESS, categoryRegisterViaLinkOnly });
    } catch (error) {
        dispatch({ type: RegisterCourseActions.GET_CATEGORYREGISTERVIALINKONLY_ERROR, error});
    }
};

export const updateCategoryRegisterViaLinkOnly = (categoryId: number, categoryRegisterViaLinkOnly: boolean) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: RegisterCourseActions.UPDATE_CATEGORYREGISTERVIALINKONLY_REQUEST });
        const updated = await registerCourseService.updateCategoryRegisterViaLinkOnly(categoryId, categoryRegisterViaLinkOnly);

        if (updated) dispatch({ type: RegisterCourseActions.UPDATE_CATEGORYREGISTERVIALINKONLY_SUCCESS, categoryId, categoryRegisterViaLinkOnly });
        else {
            dispatch({ type: RegisterCourseActions.UPDATE_CATEGORYREGISTERVIALINKONLY_ERROR });
            toast.error(messages.UPDATE_ERROR)
        }
    } catch (error) {
        dispatch({ type: RegisterCourseActions.UPDATE_CATEGORYREGISTERVIALINKONLY_ERROR });
        toast.error(messages.UPDATE_ERROR)
    }
};