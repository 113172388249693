import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme, Grid, Typography, List, ListItem, Button, IconButton, ListItemSecondaryAction, Paper, Divider, FormControlLabel, Checkbox, MenuItem } from "@material-ui/core";
import { reduxForm, Field, InjectedFormProps, getFormValues } from "redux-form";
import { animated, useSpring, config } from "react-spring";
import { connect } from "react-redux";
import { FaPlusCircle, FaTrash, FaEdit } from "react-icons/fa";

import { RegisterState } from "../../../types/register";
import RenderTextField from "../../TextField";
import { required, isEmail, isPhone, validString, validDate } from "../../../validators/form";
import RenderDatePicker from "../../TextField/RenderDatePicker";
import { fillEmptyStudent } from "../../../helperfunctions/student";
import { useButtonStyles } from "../../../styles/button";
import { useTextStyles } from "../../../styles/text";
import RenderCheckBox from "../../TextField/RenderCheckBox";
import { ApplicationState } from "../../../store";
import AutofillComponent from "../../../helperfunctions/AutofillComponent";
import { trimFields } from "../../../helperfunctions/trimFields";
import { StudentDetail, emptyStdDetail } from "../../../types/student";
import CustomPrompt from "../../notification/CustomPrompt";
import UserDataTooltip from "../../../helperfunctions/UserDataTooltip";
import RenderTextFieldDropdown from "../../TextField/TextFieldDropdown";

const useStyles = makeStyles((theme: Theme) => ({
    addStudentListItem: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(2)
    },
    addStudentListItemIconWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: theme.spacing(2)
    },
    studentListItem: {
        padding: theme.spacing(2)
    },
    studentListWrapper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    noStudentListItem: {
        padding: theme.spacing(2)
    }
}));

interface StudentDataProps {
    register: RegisterState;
    formValues: any;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    editRegisteringStudent: (student: StudentDetail, id: number) => void;
    setRegisterStudent: (student: StudentDetail) => void;
    updateRegisterBooks: (studentIndex: number) => void;
    onAdd: (newRegisterStudent: StudentDetail) => void;
    onRemove: (selectedStudentIndex: number) => void;
    handleNext: () => void;
    handleBack: () => void;
    hasBookDiscount: boolean;
    priceIncludesBook: boolean;
}

const StudentData = ({
    reset,
    handleSubmit,
    setRegisterStudent,
    editRegisteringStudent,
    submitting,
    handleNext,
    handleBack,
    register,
    onAdd,
    onRemove,
    formValues,
    hasBookDiscount,
    priceIncludesBook,
    updateRegisterBooks,
    valid,
    getAddress }: StudentDataProps & InjectedFormProps<StudentDetail, StudentDataProps>) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const textClasses = useTextStyles();
    const [edit, setEdit] = useState<boolean>(false)
    const [studentIndex, setStudentIndex] = useState<number>(0)
    const [open, setOpen] = useState<boolean>(false);
    const [noStudent, setNoStudent] = useState<boolean>(false);
    const studentDataProps = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0, display: open ? "block" : "none" },
        config: config.stiff
    });
    const studentProps = useSpring({
        from: { opacity: 0, transform: "translateX(100px)" },
        to: { opacity: 1, transform: "translateX(-0px)" },
        config: config.stiff
    });
    const noStudentProps = useSpring({
        from: { color: "black" },
        to: { color: noStudent ? "red" : "black" },
        config: config.stiff
    });

    return <form onSubmit={handleSubmit((data: StudentDetail): void => {
        let newStudent = fillEmptyStudent(data);
        newStudent = trimFields(newStudent);

        if (edit) {
            editRegisteringStudent(newStudent, studentIndex)
        } else {
            onAdd(newStudent);
        }
        reset();
        setRegisterStudent(emptyStdDetail);
        setEdit(false);
        setOpen(false);
    })}>
        <CustomPrompt when={!valid} />
        <animated.div style={studentDataProps}>
            <Grid container alignItems="center">
                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                    <Typography variant="h6">Persoonsgegevens  <UserDataTooltip /></Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{ root: `${textClasses.label}` }}>GESLACHT</Typography>
                        <Field
                            name="sex"
                            validate={[validString, required]}
                            component={RenderTextFieldDropdown}
                        >
                            <MenuItem value={"H"} >De heer</MenuItem>
                            <MenuItem value={"M"} >Mevrouw</MenuItem>
                        </Field>
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>VOORLETTERS</Typography>
                        <Field
                            name="initials"
                            variant="outlined"
                            validate={[validString, required]}
                            placeholder="Verplicht"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>VOORNAAM</Typography>
                        <Field
                            name="firstName"
                            variant="outlined"
                            validate={[validString, required]}
                            placeholder="Verplicht"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>TUSSENVOEGSEL</Typography>
                        <Field
                            name="prefix"
                            variant="outlined"
                            validate={[validString]}
                            placeholder="Optioneel"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>ACHTERNAAM</Typography>
                        <Field
                            name="lastName"
                            variant="outlined"
                            validate={[validString, required]}
                            placeholder="Verplicht"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>GEBOORTEDATUM</Typography>
                        <Field
                            name="birthDate"
                            placeholder="Verplicht"
                            validate={[validString, required, validDate]}
                            component={RenderDatePicker}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>GEBOORTEPLAATS</Typography>
                        <Field
                            name="birthPlace"
                            variant="outlined"
                            validate={[validString, required]}
                            placeholder="Verplicht"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                    <Typography variant="h6">Contactgegevens</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>EMAIL</Typography>
                        <Field
                            name="email"
                            variant="outlined"
                            validate={[validString, required, isEmail]}
                            placeholder="Verplicht"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>TELEFOON MOBIEL</Typography>
                        <Field
                            name="mobileNumber"
                            variant="outlined"
                            validate={[validString, required, isPhone]}
                            placeholder="Verplicht"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Typography classes={{
                            root: `${textClasses.label}`
                        }}>TELEFOON PRIVÉ</Typography>
                        <Field
                            name="privateNumber"
                            variant="outlined"
                            validate={[validString, isPhone]}
                            placeholder="Optioneel"
                            component={RenderTextField}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                    <Typography variant="h6">Adresgegevens</Typography>
                    <Divider />
                </Grid>
                <AutofillComponent identifier="STUDENT" formValues={formValues} getAddress={getAddress}/>
                {hasBookDiscount && <Grid item xs={12} md={6}>
                    <div className="textfield-spacing textfield-spacing-h">
                        <Field
                            name="books"
                            label={priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                            component={RenderCheckBox}
                        />
                    </div>
                </Grid>}
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Button
                            onClick={() => {
                                setOpen(false)
                                reset()
                            }}
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            annuleren
                        </Button>
                        <Button
                            color="secondary"
                            variant="outlined"
                            type="submit"
                            disabled={submitting}
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            {edit ? "Nieuwe medewerker wijzigen" : "Nieuwe medewerker opslaan"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </animated.div>
        {!open && <>
            <animated.div style={studentProps}>
                <Grid container direction="column">
                    <Grid item xs={12}>
                        <Paper className={classes.studentListWrapper}>
                            <List disablePadding>
                                {(!register.students || register.students.length < 1) &&
                                    <>
                                        <ListItem dense className={classes.noStudentListItem}>
                                            <animated.div style={noStudentProps}>
                                                <Typography>U heeft nog geen medewerkers toegevoegd</Typography>
                                            </animated.div>
                                        </ListItem>
                                        <Divider />
                                    </>
                                }
                                {register.students && register.students.length > 0 && register.students.map((student: StudentDetail, index: number) => {
                                    return (
                                        <div key={index}>
                                            <ListItem dense className={classes.studentListItem}>
                                                <Grid container>
                                                    <Grid item xs={12}><Typography variant="subtitle1">{`${student.firstName} ${student.prefix ? student.prefix : ""} ${student.lastName}`}</Typography></Grid>
                                                    <Grid item md={3} sm={3} xs={12} lg={2}><Typography variant="subtitle2">E-mailadres: </Typography></Grid><Grid item md={9} sm={9} xs={12} lg={10}><Typography variant="subtitle2">{student.email ? `${student.email}` : `Geen e-mailadres bekend`}</Typography></Grid>
                                                    <Grid item md={3} sm={3} xs={12} lg={2}><Typography variant="subtitle2">Adres: </Typography></Grid><Grid item md={9} sm={9} xs={12} lg={10}><Typography variant="subtitle2">{student.address ? `${student.address} ${student.houseNumber}, ${student.place}` : `Geen adres bekend`}</Typography></Grid>
                                                    {hasBookDiscount && <Grid xs={12}>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                checked={student.books || false}
                                                                onChange={(e: any) => updateRegisterBooks(index)}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />}
                                                            label={priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                                                        />
                                                    </Grid>}
                                                </Grid>
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" onClick={() => {
                                                        setRegisterStudent(student)
                                                        setEdit(true)
                                                        setStudentIndex(index)
                                                        setOpen(true)
                                                    }}>
                                                        <FaEdit />
                                                    </IconButton>
                                                    <IconButton edge="end" onClick={() => onRemove(index)}>
                                                        <FaTrash />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider />
                                        </div>
                                    );
                                })}
                                <ListItem dense button onClick={() => {
                                    setEdit(false);
                                    setOpen(true);
                                    setRegisterStudent(emptyStdDetail);
                                }} className={classes.addStudentListItem}>
                                    <div className={classes.addStudentListItemIconWrapper}><FaPlusCircle /></div>
                                    <Typography classes={{
                                        root: `${textClasses.button}`
                                    }}>MEDEWERKER TOEVOEGEN</Typography>
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </animated.div>
            <Grid container justify="space-between">
                <Button
                    onClick={handleBack}
                    classes={{
                        root: buttonClasses.secondaryApp,
                    }}
                >
                    Terug
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={register?.students?.length < 1}
                    onClick={() => register.students.length < 1 ? setNoStudent(true) : handleNext()}
                    classes={{
                        root: buttonClasses.primary,
                    }}
                >
                    Volgende
                </Button>
            </Grid>
        </>}
    </form>
}

const studentDataForm = reduxForm<StudentDetail, StudentDataProps>({
    form: "studentDataForm",
    enableReinitialize: true,
})(StudentData);

const ConnectedstudentDataForm = connect(
    (state: ApplicationState) => {
        let initialValues = state.register.registerStudent;
        initialValues = trimFields(initialValues);

        return ({
            formValues: getFormValues("studentDataForm")(state),
            initialValues: initialValues,
        })
    },
)(studentDataForm);

export default ConnectedstudentDataForm;