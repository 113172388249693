import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { LoginResponse } from "../../types/auth";
import SubAccountsComponent from "./SubAccountsComponent";
import { ApplicationState } from "../../store";
import { loginSubAccount } from "../../store/auth/actions";

interface SubAccountsContainerProps {
    accounts: LoginResponse[];
    destination: string | undefined;
    loginSubAccount: (account: LoginResponse, destination?: string) => void;
}

const mapStateToProps = ({ auth: { accounts, destination } }: ApplicationState) => {
    return ({
        accounts,
        destination
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loginSubAccount
    }, dispatch);
}

const SubAccountsContainer = ({ accounts, destination, loginSubAccount }: SubAccountsContainerProps) => {
    return (
        <SubAccountsComponent
            accounts={accounts}
            loginSubAccount={(account: LoginResponse) => loginSubAccount(account, destination)}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubAccountsContainer);