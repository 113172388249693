import React, { useEffect } from "react"
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import StudentDetailComponent from "./StudentDetailComponent";
import { StudentState, StudentDetail } from "../../types/student";
import { addRegisterStudent, resetRegisterState } from "../../store/register/actions";
import { getStudentDetail } from "../../store/student/actions";
import { ApplicationState } from "../../store";

interface MatchParams {
    studentId: string;
}

interface StudentContainerProps {
    student: StudentState;
    role?: string;
    getStudentDetail: (studentId?: string) => void;
    addRegisterStudent: (student: StudentDetail) => void;
    resetRegisterState: () => void;
};

const mapStateToProps = ({ student, auth: { role } }: ApplicationState) => {
    return ({
        student,
        role
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getStudentDetail, addRegisterStudent, resetRegisterState }, dispatch);
};

const StudentContainer = ({ match, history, student, getStudentDetail, role, addRegisterStudent, resetRegisterState }: StudentContainerProps & RouteComponentProps<MatchParams>) => {
    if (!match) {
        history.push("/dashboard")
    }

    useEffect(() => {
        getStudentDetail(match.params.studentId);
    }, [getStudentDetail, match.params.studentId]);

    return (
        <div>
            <StudentDetailComponent
                resetRegisterState={resetRegisterState}
                studentDetail={student.studentDetail}
                loading={student.loading}
                error={student.error}
                role={role}
                addRegisterStudent={addRegisterStudent}
            />
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentContainer);