import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";

import { addRegisterStudent, resetRegisterState } from "../../store/register/actions";
import { withRouter, RouteComponentProps } from "react-router";
import StudentsComponent from "./StudentsComponent";
import { StudentState, StudentDetail } from "../../types/student";
import { getStudents, emptyStudentDetail } from "../../store/student/actions";

interface StudentsContainerProps {
    student: StudentState;
    addRegisterStudent: (student: StudentDetail) => void;
    emptyStudentDetail: () => void;
    getStudents: () => void;
    resetRegisterState: () => void;
}

const mapStateToProps = ({ student }: StudentsContainerProps) => {
    return ({
        student
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getStudents, addRegisterStudent, emptyStudentDetail, resetRegisterState }, dispatch);
};

const StudentsContainer = ({ student, getStudents, addRegisterStudent, emptyStudentDetail, resetRegisterState }: StudentsContainerProps & RouteComponentProps) => {
    useEffect(() => {
        getStudents();
    }, [getStudents]);

    const addExistingStudents = (students: StudentDetail[]) => {
        students && students.map((student) => addRegisterStudent(student));
    };

    return <StudentsComponent
        resetRegisterState={resetRegisterState}
        emptyStudentDetail={() => emptyStudentDetail()}
        handleSelect={(students) => addExistingStudents(students)}
        students={student.students}
        loading={student.loading}
        error={student.error}
    />;
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentsContainer));