import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { useDropzone } from 'react-dropzone';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, Typography } from "@material-ui/core";

import RenderTextField from "../../TextField";
import RenderSwitch from "../../TextField/RenderSwitch";
import { required, validString } from "../../../validators/form";
import messages from "../../../constants/messages";
import { toast } from "react-toastify";

export interface EditAssignmentDialogForm {
    name: string;
    showOnHSN: boolean;
    showOnHOC: boolean;
    showOnSH: boolean;
}

interface EditAssignmentDialogProps {
    open: boolean;
    fileUrl?: string;
    fileName?: string;
    setOpen: (open: boolean) => void;
    doSubmit: (values: EditAssignmentDialogForm, files: File | string) => void;
}

const EditAssignmentDialog = ({ open, fileUrl, fileName, setOpen, handleSubmit, doSubmit }: EditAssignmentDialogProps & InjectedFormProps<EditAssignmentDialogForm, EditAssignmentDialogProps>) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const files = acceptedFiles && acceptedFiles.map((file: File) => (
        <li key={file.name}>
            {file.name}
        </li>
    ));

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <form onSubmit={handleSubmit((values: EditAssignmentDialogForm) => {
                let file = acceptedFiles && acceptedFiles.length > 0 ? acceptedFiles[acceptedFiles.length - 1] : fileUrl ? fileUrl : undefined;
                file ? doSubmit(values, file) : toast.error(messages.MISSING_FILE);
            })}>
                <DialogTitle>
                    Huiswerk toevoegen
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Typography variant="overline">Naam</Typography>
                            <Field
                                name="name"
                                variant="outlined"
                                validate={[validString, required]}
                                component={RenderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "1em" }}>
                            <Grid container justify="space-between">
                                <Grid item>
                                    <Typography variant="overline">HSN</Typography>
                                    <Field
                                        name="showOnHSN"
                                        component={RenderSwitch}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="overline">HOC</Typography>
                                    <Field
                                        name="showOnHOC"
                                        component={RenderSwitch}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography variant="overline">SH</Typography>
                                    <Field
                                        name="showOnSH"
                                        component={RenderSwitch}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "1em" }}>
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{ border: "2px dotted #e3e3e3", backgroundColor: "#f7f7f7", cursor: "pointer" }}
                                {...getRootProps({ className: 'dropzone' })}
                            >
                                <input {...getInputProps()} />
                                <p style={{ margin: "3em" }}>Sleep een bestand, of klik op een bestand te selecteren</p>
                            </Grid>
                            {(files.length > 0 || fileUrl) && <aside style={{ marginTop: "1em" }}>
                                <Typography variant="overline">Bestanden</Typography>
                                <ul>{files.length > 0 ? files : fileName + ".docx"}</ul>
                            </aside>}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify="space-between">
                        <Button color="secondary" onClick={() => setOpen(false)}>Annuleren</Button>
                        <Button type="submit" variant="contained" color="secondary">Bevestigen</Button>
                    </Grid>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default reduxForm<EditAssignmentDialogForm, any>({
    form: "editAssignmentDialogForm",
    enableReinitialize: true
})(EditAssignmentDialog);