// Er is een probleem met de cors, daar moeten we in de volgende sprint naar kijken

import { useState, useEffect } from "react";
import { Helmet } from "react-helmet"
import { connect } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Theme, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import { HashRouter } from "react-router-dom";

import { resetRegisterState, resetStepper } from "../store/register/actions";
import Main from "../routes/Main";
import Header from "./Header/Header";
import SideMenu from "./SideMenu/SideMenu";
import { ApplicationState } from "../store";
import { AuthenticationState } from "../types/auth";
import { getTitle } from "../helperfunctions/getTitle";
import { bindActionCreators, Dispatch } from "redux";
import { getWebsite } from "../helperfunctions/getWebsite";
import { tagmanager } from "./tagmanager";

interface AppProps {
	auth: AuthenticationState;
	resetRegisterState: () => void;
	resetStepper: (reset: boolean) => void;
};

const mapStateToProps = ({ auth }: ApplicationState) => ({ auth });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
	resetRegisterState,
	resetStepper,
}, dispatch);

const App = ({ auth, resetRegisterState, resetStepper }: AppProps) => {
	const theme: Theme = useTheme();
	const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [open, setOpen] = useState<boolean>(true);
	const [showMenu, setShowMenu] = useState<boolean>(true);


	useEffect(() => {
		const favicon = document.getElementById("favicon") as HTMLLinkElement;
		favicon.href = `${getWebsite()}.png`

		if (!fullscreen) setOpen(true)
	}, [setOpen, fullscreen]);

	useEffect(() => {
		auth.token ? setShowMenu(false) : setShowMenu(true)
	}, [auth, showMenu])

	return <HashRouter>
		<div style={{ display: "flex", height: "100%" }}>
			<Helmet>
				<title>{getTitle()}</title>
				{tagmanager(window, document, 'script', 'dataLayer', 'GTM-WHSQP4G4')}
			</Helmet>
			<CssBaseline />
			<noscript>
				<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHSQP4G4" height="0" width="0" style="display:none;visibility:hidden"></iframe>
			</noscript>
			<Header showMenu={showMenu} setOpen={setOpen} open={open} />
			{!showMenu && <SideMenu resetStepper={resetStepper} resetRegisterState={resetRegisterState} showMenu={showMenu} setOpen={setOpen} open={open} auth={auth} />}
			<Main showMenu={showMenu} />
		</div>
	</HashRouter>;
}

export default connect(mapStateToProps, mapDispatchToProps)(App);