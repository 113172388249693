import { withRouter, RouteComponentProps } from "react-router";
import { Grid, Typography } from "@material-ui/core";
import { FaArrowLeft } from "react-icons/fa";

import { AssignmentState, InputAssignment } from "../../../types/assignment";
import { SampleExamState } from "../../../types/sampleExam";
import AssignmentComponent from "../Assignment/AssignmentComponent";
import GoogleAnalytics from "./GoogleAnalytics";
import CourseMetaDataContainer from "./CourseMetaDataContainer";

interface CourseComponentProps {
    courseId: string;
    assignment: AssignmentState;
    testExams: SampleExamState;
    analytic: {
        analyticLink?: string;
        loadingAnalyticLink: boolean;
        errorAnalyticLink?: any;
    }

    uploadAssignment: (assignment: InputAssignment) => void;
    editAssignment: (assignmentId: number, assignment: InputAssignment) => void;
    getAssignmentFile: (assignmentId: number) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
    deleteAssignment: (assignmentId: number) => void;
    editCourseGoogleAnalytics: (courseId: string, url: string) => void;
    deleteCourseGoogleAnalytics: (courseId: string) => void;
}

const CourseComponent = ({
    courseId,
    assignment,
    analytic,
    history,
    uploadAssignment,
    editAssignment,
    getAssignmentFile,
    downloadAssignment,
    deleteAssignment,
    editCourseGoogleAnalytics,
    deleteCourseGoogleAnalytics,
}: CourseComponentProps & RouteComponentProps) => (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid alignItems="center" container>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <FaArrowLeft size="1.3em" style={{ marginRight: ".5em", cursor: "pointer" }} onClick={() => history.goBack()} />
                                <Typography variant="h5">Cursus</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <GoogleAnalytics
                    {...analytic}
                    courseId={courseId}
                    editCourseGoogleAnalytics={editCourseGoogleAnalytics}
                    deleteCourseGoogleAnalytics={deleteCourseGoogleAnalytics}
                />
                <AssignmentComponent
                    {...assignment}
                    courseId={courseId}
                    uploadAssignment={uploadAssignment}
                    editAssignment={editAssignment}
                    getAssignmentFile={getAssignmentFile}
                    downloadAssignment={downloadAssignment}
                    deleteAssignment={deleteAssignment}
                />
                <CourseMetaDataContainer courseId={courseId} />
            </Grid>
        </>
    );

export default withRouter(CourseComponent);
