import React, { useEffect } from "react"
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { loginByEntranceCode } from "../../store/auth/actions";
import { RouteComponentProps } from "react-router";
import PaymentComplete from "../FeedBack/PaymentComplete";
import { ApplicationState } from "../../store";

interface PayedContainerProps {
    token: string;
    refreshToken: string;
    loginByEntranceCode: (token?: string, refreshToken?: string, entranceCode?: string) => void;
}

const mapStateToProps = ({ auth: { token, refreshToken } }: ApplicationState) => {
    return ({
        token,
        refreshToken 
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loginByEntranceCode,
    }, dispatch);
};

const PayedContainer = ({ loginByEntranceCode, location, token, refreshToken }: PayedContainerProps & RouteComponentProps) => {
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const entranceCode = urlParams.get("ec") || undefined;
        loginByEntranceCode(token, refreshToken, entranceCode);
    }, [location.search, loginByEntranceCode, token, refreshToken]);

    return (
        <PaymentComplete />
    );
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(PayedContainer);