import { getWebsite } from "./getWebsite";
import { Website } from "../types/enum"

export const getPortalName = (): string => {
    switch (getWebsite()) {
        case Website.SH:
            return "MijnSH";
        case Website.HOC:
            return "MijnHOC";
        default:
            return "MijnHSN";
    }
}