import { Dispatch } from "redux";
import { isNil } from "lodash";

import { DiscountActions } from "./types";
import { discountService } from "../../services/discount.service";
import { Discount } from "../../types/discount";
import messages from "../../constants/messages";
import { toast } from "react-toastify";

export const resetDiscountCode = () => async (dispatch: Dispatch) => {
    const discountCode = undefined;
    dispatch({ type: DiscountActions.CHECK_SUCCESS, discountCode });
};

export const setDiscountCode = (discountCode: string) => async (dispatch: Dispatch) => {
    dispatch({ type: DiscountActions.CHECK_SUCCESS, discountCode });
};

export const checkDiscountCode = (discountCode: string) => async (dispatch: Dispatch) => {
    if (!discountCode || discountCode.length < 1) {
        dispatch({ type: DiscountActions.CHECK_SUCCESS, discountCode, discountString: "" });
        return;
    }
    try {
        const discountString = await discountService.checkDiscount(discountCode);
        if (!isNil(discountString)) {
            dispatch({ type: DiscountActions.CHECK_SUCCESS, discountCode, discountString });
        } else {
            toast.error(messages.INVALID_DISCOUNT)
            dispatch({ type: DiscountActions.CHECK_ERROR, discountCode });
        }
    } catch (error) {
        toast.error(messages.INVALID_DISCOUNT)
        dispatch({ type: DiscountActions.CHECK_ERROR, discountCode });
    }
};

export const getDiscounts = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: DiscountActions.GET_REQUEST });
        const response = await discountService.discounts();
        dispatch({ type: DiscountActions.GET_SUCCESS, response });
    } catch (error) {
        dispatch({ type: DiscountActions.GET_ERROR, error });
    }
};

export const createDiscount = (discount: Discount) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: DiscountActions.CREATE_REQUEST });
        const created = await discountService.createDiscount(discount);

        if (created) {
            dispatch({ type: DiscountActions.CREATE_SUCCESS, discount });
        } else {
            dispatch({ type: DiscountActions.CREATE_ERROR });
        }
    } catch (error) {
        dispatch({ type: DiscountActions.CREATE_ERROR, error });
    }
};

export const updateDiscount = (discount: Discount) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: DiscountActions.UPDATE_REQUEST });
        const updated = await discountService.updateDiscount(discount);

        if (updated) {
            dispatch({ type: DiscountActions.UPDATE_SUCCESS, discount });
        } else {
            dispatch({ type: DiscountActions.UPDATE_ERROR });
        }
    } catch (error) {
        dispatch({ type: DiscountActions.UPDATE_ERROR, error });
    }
};

export const deleteDiscount = (discountCode: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: DiscountActions.DELETE_REQUEST });
        const deleted = await discountService.deleteDiscount(discountCode);

        if (deleted) {
            dispatch({ type: DiscountActions.DELETE_SUCCESS, discountCode });
        } else {
            dispatch({ type: DiscountActions.DELETE_ERROR });
        }
    } catch (error) {
        dispatch({ type: DiscountActions.DELETE_ERROR, error });
    }
};