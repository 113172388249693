import { AssignmentActions } from "./types";
import { AssignmentState } from "../../types/assignment";

const initialState: AssignmentState = {
    assignments: [],
    fileUrl: undefined,
    loading: false,
    error: undefined
};

export function assignmentReducer(state = initialState, action: typeof AssignmentActions): AssignmentState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case AssignmentActions.ASSIGNMENTS_BY_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case AssignmentActions.ASSIGNMENTS_BY_COURSE_SUCCESS:
            return {
                ...state,
                assignments: action.response,
                loading: false,
                error: undefined
            };
        case AssignmentActions.ASSIGNMENTS_BY_COURSE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case AssignmentActions.DELETE_SUCCESS:
            return {
                ...state,
                assignments: state.assignments ? state.assignments.filter(assignment => assignment.assignmentId !== action.assignmentId) : [],
            };
        case AssignmentActions.UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AssignmentActions.UPLOAD_SUCCESS:
            let newAssignments = state.assignments;
            newAssignments.push(action.assignment);

            return {
                ...state,
                loading: false,
                assignments: newAssignments,
            };
        case AssignmentActions.UPLOAD_ERROR:
            return {
                ...state,
                loading: false,
            };
        case AssignmentActions.EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AssignmentActions.EDIT_SUCCESS:
            const edittedAssignments = state.assignments ? state.assignments.map((item) => item.assignmentId === action.assignment.assignmentId ? action.assignment : item) : [];

            return {
                ...state,
                loading: false,
                assignments: edittedAssignments,
            };
        case AssignmentActions.EDIT_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
