import { StudentDetail } from "../types/student";

export const fillEmptyStudent = (data: StudentDetail): StudentDetail => {
    return {
        address: data.address,
        email: data.email,
        birthDate: data.birthDate.toString(),
        birthPlace: data.birthPlace,
        books: data.books,
        houseNumber: data.houseNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        zipCode: data.zipCode,
        sex: data.sex,
        place: data.place,
        initials: data.initials,
        mobileNumber: data.mobileNumber,
        prefix: data.prefix,
        privateNumber: data.privateNumber,
    };
};