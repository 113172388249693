import MaterialTable from "material-table";
import { Grid, Card, CardContent, useTheme, Theme, useMediaQuery } from "@material-ui/core";

import { materialTableLocalization } from "../../../constants/materialTableLocalization";
import { LoadingTable } from "../../FeedBack/LoadingContent";
import { CourseMetaData } from "../../../types/metaData";
import { toast } from "react-toastify";
import Select from "react-select";
import { IconOption, getIconOptionByValue, iconOptions } from "../../../types/iconSelection";

interface CourseMetaDataProps {
    metaData?: CourseMetaData;
    loading: boolean;

    editMetaData: (metaData: CourseMetaData) => void;
}

const CourseMetaDataComponent = ({ metaData, loading, editMetaData }: CourseMetaDataProps) => {
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container style={{ marginTop: "2em" }}>
            <Grid item xs={12}>
                {loading && <Grid item xs={12}> <LoadingTable /></Grid>}
                {(!loading && metaData) &&
                    <Card elevation={2}>
                        <CardContent>
                            <MaterialTable
                                style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "300px" : "none" }}
                                columns={[
                                    { title: "Tijdspan", field: "timeSpan", type: "string" },
                                    { title: "Frequentie", field: "frequency", type: "string" },
                                    { title: "Locaties", field: "locations", type: "string" },
                                    { title: "Examen", field: "exam", type: "string" },
                                    { title: "Diploma", field: "certificate", type: "string" },
                                    { title: "Rating", field: "rating", type: "string" },
                                    { title: "Huiswerkbegeleiding", field: "homeworkSupport", type: "boolean" },
                                    { title: "Vrije invoer 1 label", field: "freeField1Caption", type: "string" },
                                    { title: "Vrije invoer 1", field: "freeField1", type: "string" },
                                    {
                                        title: "Vrije invoer 1 Icoon",
                                        field: "freeField1Icon",
                                        type: "numeric",
                                        render(data) {
                                            return getIconOptionByValue(data.freeField1Icon).label;
                                        },
                                        editComponent: props => <Select onChange={selectedValue => {
                                            if (selectedValue) {
                                                props.onRowDataChange({
                                                    ...props.rowData,
                                                    freeField1Icon: selectedValue.value as IconOption
                                                })
                                            }
                                        }} menuPosition="fixed" options={iconOptions} optionId="key" optionName="value" />
                                    },
                                    { title: "Vrije invoer 2 label", field: "freeField2Caption", type: "string" },
                                    { title: "Vrije invoer 2", field: "freeField2", type: "string" },
                                    {
                                        title: "Vrije invoer 2 Icoon",
                                        field: "freeField2Icon",
                                        type: "numeric",
                                        render(data) {
                                            return getIconOptionByValue(data.freeField2Icon).label;
                                        },
                                        editComponent: props => <Select onChange={selectedValue => {
                                            if (selectedValue) {
                                                props.onRowDataChange({
                                                    ...props.rowData,
                                                    freeField2Icon: selectedValue.value as IconOption
                                                })
                                            }
                                        }} menuPosition="fixed" options={iconOptions} optionId="key" optionName="value" />
                                    }
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    search: false,
                                    paging: false,
                                    headerStyle: {
                                        zIndex: 0
                                    }
                                }}
                                editable={{
                                    onRowUpdate: (metaData: CourseMetaData) => {
                                        for (let [key, value] of Object.entries(metaData)) {
                                            if (value && typeof value === "string" && value.length > 256) {
                                                toast.error(`${key} is langer dan 256 characters`);
                                                return Promise.reject();
                                            }
                                        }
                                        editMetaData(metaData);
                                        return Promise.resolve();
                                    },
                                }}
                                localization={materialTableLocalization}
                                data={[metaData]}
                                title="Meta data"
                            />
                        </CardContent>
                    </Card>
                }
            </Grid>
        </Grid>
    );
};

export default CourseMetaDataComponent;