import { Dispatch } from "redux";

import { MailingLinkActions } from "./types";
import { MailingLink } from "../../types/mailingLink";
import { MailingLinkService } from "../../services/mailingLink.service";

export const getMailingLinks = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: MailingLinkActions.GET_MAILING_LINKS_REQUEST });
        const mailingLinks: MailingLink[] = await MailingLinkService.mailingLinks();
        dispatch({ type: MailingLinkActions.GET_MAILING_LINKS_SUCCESS, mailingLinks });
    } catch (error) {
        dispatch({ type: MailingLinkActions.GET_MAILING_LINKS_ERROR, error });
    }
};

export const createOrUpdateMailingLink = (mailingLink: MailingLink) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_REQUEST });
        const success = await MailingLinkService.createOrUpdateMailingLink(mailingLink);

        if (success) {
            dispatch({ type: MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_SUCCESS, mailingLink });
        } else {
            dispatch({ type: MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_ERROR });
        }
    } catch (error) {
        dispatch({ type: MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_ERROR, error });
    }
};

export const deleteMailingLink = (name: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: MailingLinkActions.DELETE_MAILING_LINK_REQUEST });
        const deleted = await MailingLinkService.deleteMailingLink(name);

        if (deleted) {
            dispatch({ type: MailingLinkActions.DELETE_MAILING_LINK_SUCCESS, name });
        } else {
            dispatch({ type: MailingLinkActions.DELETE_MAILING_LINK_ERROR });
        }
    } catch (error) {
        dispatch({ type: MailingLinkActions.DELETE_MAILING_LINK_ERROR, error });
    }
};