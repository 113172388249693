import { Dispatch } from "redux";

import { CompanyDataFormData } from "../../components/Register/Register/Company/CompanyData";
import { PaymentFormData } from "../../components/Register/Register/Payment";
import { FreelancerUserFormData } from "../../components/Register/Register/Freelancer/FreelancerUserData";
import { RegisterActions } from "./types";
import { registerService } from "../../services/register.service";
import { Registration, Address, RegistrationPriceInfo, RegisterKHN } from "../../types/register";
import { StudentDetail } from "../../types/student";
import { CheckoutPaymentFormData } from "../../components/Checkout/CheckoutPayment";
import { zipCodeAPIService } from "../../services/zipCodeAPI.service";
import { userInformationActions } from "../userInformation/types";
import { getRole } from "../../helperfunctions/localstorage";
import { StudentActions } from "../student/types";
import { isNull, isUndefined } from "lodash";
import { toast } from "react-toastify";
import messages from "../../constants/messages";
import { Role } from "../../types/enum";
import { RegisterCourse } from "../../types/registerCourse";
import { toBase64, openFileInNewWindow } from "../../helperfunctions/files";
import { SentryCapture } from "../../sentry";

let addressCache: Map<string, Address>;

const combineZipCodeAndHouseNumber = (zipCode: string, houseNumber: string): string => {
    return (zipCode + ':' + houseNumber).toLowerCase();
}

export const resetStepper = (reset: boolean) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.RESET_STEPPER, reset });
};

export const resetRegisterStateFreelancer = () => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.RESET_REGISTER_STATE_FREELANCER });
};

export const resetRegisterStateCompany = () => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.RESET_REGISTER_STATE_COMPANY });
};

export const resetRegisterState = () => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.RESET_REGISTER_STATE });
};

export const getPrices = (priceItems: RegistrationPriceInfo[]) => async (dispatch: Dispatch) => {
    try {
        const prices = await registerService.calculatePrice(priceItems);
        dispatch({ type: RegisterActions.SET_PRICES, prices });
    } catch (error) {
        SentryCapture(error);
        return;
    }
};

export const setFreelancerFormData = (freelancerData: FreelancerUserFormData) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SET_FREELANCER_DATA, freelancerData });
};

export const setCompanyFormData = (companyData: CompanyDataFormData) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SET_COMPANY_DATA,  companyData });
};

export const setRegisterPayment = (registerPayment: PaymentFormData) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SET_PAYMENT, registerPayment });
};

export const addRegisterStudent = (student: StudentDetail) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.ADD_STUDENT, student });
};

export const editRegisterStudent = (student: StudentDetail, index: number) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.EDIT_STUDENT, student, index });
};

export const removeRegisterStudent = (index: number) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.REMOVE_STUDENT, index });
};

export const updateRegisterBooks = (index: number) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.UPDATE_BOOKS, index })
}

export const removeAllRegisterStudents = () => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.REMOVE_ALL_STUDENTS });
};

export const setRegisterStudent = (student: StudentDetail) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SET_REGISTER_STUDENT, student });
};

export const emptyRegisterStudent = () => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.EMPTY_REGISTER_STUDENT });
};

export const addToShoppingCart = (newShoppingCartItem: Registration) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.ADD_TO_SHOPPINGCART, newShoppingCartItem });
};

export const removeFromShoppingCart = (itemIndex: number) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.DELETE_FROM_SHOPPINGCART, itemIndex });
};

export const addShoppingCartStudent = (itemIndex: number, student: StudentDetail) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SHOPPINGCART_ADD_STUDENT, itemIndex, student });
};

export const editShoppingCartStudent = (itemIndex: number, studentIndex: number, student: StudentDetail) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SHOPPINGCART_EDIT_STUDENT, itemIndex, studentIndex, student });
};

export const removeShoppingCartStudent = (itemIndex: number, studentIndex: number) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SHOPPINGCART_REMOVE_STUDENT, itemIndex, studentIndex });
};

export const removeShoppingCartStudents = (itemIndex: number) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SHOPPINGCART_REMOVE_ALL_STUDENTS, itemIndex });
};

export const doRegister = (shoppingCart: Registration[], paymentData: CheckoutPaymentFormData) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.REGISTER_REQUEST });
    try {
        const registerResponse = await registerService.register(shoppingCart, paymentData);
        if (registerResponse.successful) {
            dispatch({ type: RegisterActions.REGISTER_SUCCESS, registerResponse });

            if (registerResponse.idealLink) {
                window.location.assign(registerResponse.idealLink);
            }
        } else {
            const registerError: any = "Er is iets mis gegaan.";
            dispatch({ type: RegisterActions.REGISTER_ERROR, registerError });
        }
    } catch (registerError) {
        dispatch({ type: RegisterActions.REGISTER_ERROR, registerError });
    }
};

export const editShoppingCartItemCourse = (index: number, course: RegisterCourse) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.SET_SHOPPINGCART_COURSE, index, course })
}
    ;
export const editShoppingCartItemFreelanceBooks = (index: number, freelanceBooks: boolean) => async (dispatch: Dispatch) => {
    dispatch({ type: RegisterActions.EDIT_SHOPPINGCART_FREELANCEBOOKS, index, freelanceBooks })
};

export const registerInformationKHN = (khnId: number, zipCode: string, identifier: string, formValues: any) => async (dispatch: Dispatch) => {
    try {
        const khnFormValues: RegisterKHN = await registerService.registerInformationKHN(khnId, zipCode);
        switch (identifier) {
            case "COMPANY": {
                dispatch({ type: RegisterActions.SET_COMPANY_KHN, khnFormValues, formValues });
                break;
            }
            case "FREELANCER": {
                dispatch({ type: RegisterActions.SET_FREELANCER_KHN, khnFormValues, formValues });
                break;
            }
        }
    } catch (error) {
        toast.error(messages.KHN_ID_UNKNOWN)
    }
}

export const uploadKHNFile = (file?: File[]) => async (dispatch: Dispatch) => {
    if (!file || file.length <= 0) {
        toast.error(messages.MISSING_FILE)
        return;
    }

    try {
        toast.info(messages.UPLOAD_STARTED)
        let content: string = file ? await toBase64(file[file.length - 1]) : "";

        const success = await registerService.uploadKHNData(content);
        if (success) toast.success(messages.KHN_DATA_RECEIVED)
        else toast.error(messages.UPLOAD_ERROR)
    } catch (error) {
        toast.error(messages.UPLOAD_ERROR)
    }
}

export const getUserAgreementFileName = () => async (dispatch: Dispatch) => {
    try {
        const fileName = await registerService.getUserAgreementFileName();
        dispatch({ type: RegisterActions.SET_AGREEMENT_FILENAME, fileName });
    } catch (error) {
        return;
    }
}

export const uploadAgreementFile = (file?: File[]) => async (dispatch: Dispatch) => {
    if (!file || file.length <= 0) {
        toast.error(messages.MISSING_FILE)
        return;
    }

    try {
        const uploadFile: File = file[file.length - 1];
        toast.info(messages.UPLOAD_STARTED);
        let content: string = uploadFile ? await toBase64(uploadFile) : "";
        const fileName = uploadFile ? uploadFile.name.endsWith(".pdf") ? uploadFile.name.slice(0, -4) : uploadFile.name : "Studievoorwaarden"

        const success = await registerService.uploadAgreement(content, fileName);
        if (success) toast.success(messages.UPLOAD_SUCCESSFULL);
        else toast.error(messages.UPLOAD_ERROR);
        getUserAgreementFileName()(dispatch);
    } catch (error) {
        toast.error(messages.UPLOAD_ERROR);
    }
}

export const downloadAgreement = () => async (dispatch: Dispatch) => {
    try {
        toast.info(messages.DOWNLOAD_START);
        const fileName = await registerService.getUserAgreementFileName();
        const response = await registerService.getAgreement();

        if (response) openFileInNewWindow(response, fileName, "pdf");
        else toast.error(messages.COULD_NOT_DOWNLOAD);
    } catch (error) {
        toast.error(messages.COULD_NOT_DOWNLOAD);
    }
};

export const getAddress = (houseNumber: string, zipCode: string, identifier: string, formValues: any) => async (dispatch: Dispatch) => {
    if (addressCache === undefined) {
        addressCache = new Map<string, Address>();
    }
    const cacheKey = combineZipCodeAndHouseNumber(zipCode, houseNumber);

    const cacheHasKey = addressCache.has(cacheKey);
    
    let address: Address | undefined;
    if (cacheHasKey) {
        address = addressCache.get(cacheKey);
    }
    else {
        address = await zipCodeAPIService.getAddress(houseNumber, zipCode);
    }

    if (isNull(address) || isUndefined(address)) {
        toast.error(messages.ADDRESS_WRONG_INPUT);
        return;
    }

    if (!cacheHasKey) {
        addressCache.set(cacheKey, address);
    }

    switch (identifier) {
        case "COMPANY": {
            dispatch({ type: RegisterActions.SET_COMPANY_ADDRESS, address, formValues });
            break;
        }
        case "COMPANY_INVOICE": {
            dispatch({ type: RegisterActions.SET_COMPANY_INVOICE_ADDRESS, address, formValues });
            break;
        }
        case "FREELANCER": {
            dispatch({ type: RegisterActions.SET_FREELANCER_ADDRESS, address, formValues });
            break;
        }
        case "FREELANCER_INVOICE": {
            dispatch({ type: RegisterActions.SET_FREELANCER_INVOICE_ADDRESS, address, formValues });
            break;
        }
        case "STUDENT": {
            dispatch({ type: RegisterActions.SET_STUDENT_ADDRESS, address, formValues });
            break;
        }
        case "EDIT_RELATION": {
            dispatch({ type: userInformationActions.SET_EDIT_RELATION_ADDRESS, address, formValues });
            break;
        }
        case "EDIT_COMPANY_INVOICE": {
            dispatch({ type: userInformationActions.SET_EDIT_RELATION_INVOICE_ADDRESS, address, formValues });
            break;
        }
        case "EDIT_VISITING_RELATION": {
            dispatch({ type: userInformationActions.SET_EDIT_VISITING_RELATION_ADDRESS, address, formValues });
            break;
        }
        case "EDIT_STUDENT": {
            const role = getRole();
            if (role === Role.COMPANY) {
                dispatch({ type: StudentActions.SET_EDIT_STUDENT_ADDRESS, address, formValues });
            }
            if (role === Role.STUDENT || role === Role.FREELANCER) {
                dispatch({ type: userInformationActions.SET_EDIT_STUDENT_ADDRESS, address, formValues });
            }
            break;
        }
        default: {
            break;
        }
    }
};
