import React from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import { changePassword } from "../../store/auth/actions"
import SettingsComponent from './SettingsComponent';


interface SettingsContainerProps {
    changePassword: (newPassword: string, oldPassword: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ changePassword }, dispatch);
}

const SettingsContainer = ({ changePassword }: SettingsContainerProps) => {
    return <SettingsComponent changePassword={(newPassword: string, oldPassword: string) => changePassword(newPassword, oldPassword)} />

};

export default connect(null, mapDispatchToProps)(SettingsContainer);
