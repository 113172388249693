import { createActionsWithPrefix } from "../../helperfunctions/action";

export const StudentActions = createActionsWithPrefix("STUDENT/", {
    GET_STUDENTS_REQUEST: "GET_STUDENTS_REQUEST",
    GET_STUDENTS_SUCCESS: "GET_STUDENTS_SUCCESS",
    GET_STUDENTS_ERROR: "GET_STUDENTS_ERROR",
    CREATE_NEW_STUDENT_REQUEST: "CREATE_NEW_STUDENT_REQUEST",
    CREATE_NEW_STUDENT_SUCCESS: "CREATE_NEW_STUDENT_SUCCESS",
    CREATE_NEW_STUDENT_ERROR: "CREATE_NEW_STUDENT_ERROR",
    GET_STUDENTDETAIL_REQUEST: "GET_STUDENTDETAIL_REQUEST",
    GET_STUDENTDETAIL_SUCCESS: "GET_STUDENTDETAIL_SUCCESS",
    GET_STUDENTDETAIL_ERROR: "GET_STUDENTDETAIL_ERROR",
    SET_EDIT_STUDENT_ADDRESS: "SET_EDIT_STUDENT_ADDRESS",
});