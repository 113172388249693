import { RegisterActions } from "./types";
import { RegisterState } from "../../types/register";

const initialState: RegisterState = {
    registerCompanyData: undefined,
    registerStudent: undefined,
    students: [],
    registerFreelancerUserFormData: undefined,
    registerPayment: undefined,
    registerResponse: undefined,
    registerLoading: false,
    registerError: undefined,
    resetStep: false,
    userAgreementFileName: undefined,
    shoppingCart: [],
    prices: []
};

export function registerReducer(state = initialState, action: typeof RegisterActions): RegisterState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
        case "AUTH/LOGIN_SUCCESS":
            return initialState
        case RegisterActions.SET_AGREEMENT_FILENAME:
            return {
                ...state,
                userAgreementFileName: action.fileName,
            }
        case RegisterActions.RESET_STEPPER:
            return {
                ...state,
                resetStep: !!action.reset,
            }
        case RegisterActions.RESET_REGISTER_STATE_COMPANY:
            return {
                ...state,
                registerFreelancerUserFormData: undefined,
            }
        case RegisterActions.RESET_REGISTER_STATE_FREELANCER: 
            return {
                ...state,
                registerCompanyData: undefined,
                students: [],
            }
        case RegisterActions.RESET_REGISTER_STATE:
            return {
                ...initialState,
                shoppingCart: state.shoppingCart,
            }
        case RegisterActions.SET_PRICES:
            return {
                ...state,
                prices: action.prices
            }
        case RegisterActions.SET_COMPANY_ADDRESS:
            return {
                ...state,
                registerCompanyData: {
                    ...action.formValues,
                    placeCompany: action.address.place,
                    adresCompany: action.address.address,
                }
            };
        case RegisterActions.SET_COMPANY_INVOICE_ADDRESS:
            return {
                ...state,
                registerCompanyData: {
                    ...action.formValues,
                    invoicePlace: action.address.place,
                    invoiceAddress: action.address.address,
                }
            };
        case RegisterActions.SET_FREELANCER_ADDRESS:
            return {
                ...state,
                registerFreelancerUserFormData: {
                    ...action.formValues,
                    place: action.address.place,
                    address: action.address.address,
                }
            };
        case RegisterActions.SET_FREELANCER_INVOICE_ADDRESS:
            return {
                ...state,
                registerFreelancerUserFormData: {
                    ...action.formValues,
                    placeInvoice: action.address.place,
                    adresInvoice: action.address.address,
                }
            };
        case RegisterActions.SET_COMPANY_DATA:
            return {
                ...state,
                registerCompanyData: action.companyData
            };
        case RegisterActions.SET_FREELANCER_DATA:
            return {
                ...state,
                registerFreelancerUserFormData: action.freelancerData
            };
        case RegisterActions.SET_PAYMENT:
            return {
                ...state,
                registerPayment: action.registerPayment
            };
        case RegisterActions.ADD_STUDENT:
            return {
                ...state,
                students: [...state.students, action.student]
            };
        case RegisterActions.EDIT_STUDENT:
            return {
                ...state,
                students: state.students ? state.students.map((student, index) => index === action.index ? action.student : student) : []
            };
        case RegisterActions.REMOVE_STUDENT:
            return {
                ...state,
                students: state.students ? state.students.filter((student, index) => index !== action.index) : []
            };
        case RegisterActions.UPDATE_BOOKS:
            return {
                ...state,
                students: state.students ? state.students.map((student, index) => index === action.index ? { ...student, books: student.books ? !student.books : true } : student) : []
            }
        case RegisterActions.REMOVE_ALL_STUDENTS:
            return {
                ...state,
                students: [],
            }
        case RegisterActions.SET_FREELANCER_KHN:
            return {
                ...state,
                registerFreelancerUserFormData: {
                    initials: action.khnFormValues.initialsContact,
                    address: action.khnFormValues.adresCompany,
                    firstName: action.khnFormValues.firstNameContact,
                    prefix: action.khnFormValues.infixContact,
                    lastName: action.khnFormValues.lastNameContact,
                    email: action.khnFormValues.emailContact,
                    nameCompany: action.khnFormValues.nameCompany,
                    place: action.khnFormValues.placeCompany,
                    workNumber: action.khnFormValues.phoneCompany,
                    mobileNumber: action.khnFormValues.phoneMobileContact,
                    zipCode: action.khnFormValues.zipCodeCompany,
                    houseNumber: action.khnFormValues.houseNumberCompany,
                    byMail: action.khnFormValues.byMail,
                    adresInvoice: action.khnFormValues.adresInvoice,
                    numberInvoice: action.khnFormValues.numberInvoice,
                    zipCodeInvoice: action.khnFormValues.zipCodeInvoice,
                    placeInvoice: action.khnFormValues.placeInvoice,
                    emailInvoice: action.khnFormValues.emailInvoice,
                    ...action.formValues
                }
            }
        case RegisterActions.SET_COMPANY_KHN:
            return {
                ...state,
                registerCompanyData: { ...action.khnFormValues, ...action.formValues }
            }
        case RegisterActions.REGISTER_REQUEST:
            return {
                ...state,
                registerLoading: true,
            };
        case RegisterActions.REGISTER_SUCCESS:
            return {
                ...initialState,
                registerLoading: false,
                registerResponse: action.registerResponse
            };
        case RegisterActions.REGISTER_ERROR:
            return {
                ...state,
                registerLoading: false,
                registerError: action.registerError
            };
        case RegisterActions.ADD_TO_SHOPPINGCART:
            return {
                ...state,
                shoppingCart: [...state.shoppingCart, action.newShoppingCartItem]
            };
        case RegisterActions.DELETE_FROM_SHOPPINGCART:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.filter((item, itemIndex) => itemIndex !== action.itemIndex) : []
            };
        case RegisterActions.SHOPPINGCART_ADD_STUDENT:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.map((item, itemIndex) => itemIndex === action.itemIndex ? 
                    { ...item, students: [...item.students, action.student] } 
                    : item) : []
            };
        case RegisterActions.SHOPPINGCART_EDIT_STUDENT:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.map((item, itemIndex) => itemIndex === action.itemIndex ? 
                    { ...item, students: item.students ? item.students.map((student, studentIndex) => studentIndex === action.studentIndex ? action.student : student) : []} 
                    : item) : []
            }
        case RegisterActions.SHOPPINGCART_REMOVE_STUDENT:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.map((item, itemIndex) => itemIndex === action.itemIndex ? 
                    { ...item, students: item.students ? item.students.filter((student, studentIndex) => studentIndex !== action.studentIndex) : [] }
                    : item
                ) : []
            };
        case RegisterActions.SHOPPINGCART_REMOVE_ALL_STUDENTS:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.map((item, itemIndex) => itemIndex === action.itemIndex ? { ...item, students: [] } : item) : []
            };
        case RegisterActions.SET_SHOPPINGCART_COURSE:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.map((item, index) => 
                    index === action.index ? { ...item, course: action.course } : item
                ) : []
            };
        case RegisterActions.SET_SHOPPINGCART_FREELANCEBOOKS:
            return {
                ...state,
                shoppingCart: state.shoppingCart ? state.shoppingCart.map((item, index) => 
                    index === action.index ? { ...item, freelancerBooks: action.freelanceBooks } : item
                ) : []
            };
        case RegisterActions.SET_REGISTER_STUDENT:
            return {
                ...state,
                registerStudent: action.student
            };
        case RegisterActions.EMPTY_REGISTER_STUDENT:
            return {
                ...state,
                registerStudent: undefined
            };
        case RegisterActions.SET_STUDENT_ADDRESS:
            return {
                ...state,
                registerStudent: {
                    ...action.formValues,
                    place: action.address.place,
                    address: action.address.address,
                }
            };
        default:
            return state;
    }
}