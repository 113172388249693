import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, Typography, Card, CardContent } from "@material-ui/core";
import { FaBuilding, FaUserTie, FaSignInAlt } from "react-icons/fa";

import Options from "./Options";
import { RouteComponentProps, withRouter } from "react-router";
import { getLogo } from "../../../../helperfunctions/getLogo";
import LogoBox from "../../../Login/LogoBox";

const useStyles = makeStyles((theme: Theme) => ({
    full: {
        height: "100%",
        alignItems: "center",
    },
    cardContent: {
        marginTop: "2em",
        marginBottom: "1em"
    },
    cardLogo: {
        maxWidth: "500px",
        height: "550px",
        borderRadius: "0",
        backgroundColor: theme.palette.primary.main,
    },
    cardLogin: {
        maxWidth: "500px",
        height: "550px",
        borderRadius: "0",
        backgroundColor: "#fff",
    },
    optionCard: {
        boxShadow: "none",
        width: "100%",
        backgroundColor: theme.palette.primary.main
    },
    background: {
        width: "30%",
        top: "100px",
        right: "0",
        height: "auto",
        position: "absolute",
        zIndex: -1
    },
}));

interface RegisterAsComponentProps {
    company: string;
    setDestination: (destination: string) => void;
    resetRegisterState: () => void;
}

const RegisterAsComponent = ({ location, company, setDestination, resetRegisterState }: RegisterAsComponentProps & RouteComponentProps) => {
    const classes = useStyles();
    const logo = getLogo();
    const params = location.search;
    const companyPath = `inschrijven/bedrijf${params}`;
    const freelancerPath = `inschrijven/particulier${params}`;
    const existingCustomerPath = `inschrijven${params}`;

    return (
        <Grid style={{ zIndex: -2 }} container justify="center" className={classes.full}>
            <Grid style={{ zIndex: 1 }} item>
                <Card className={classes.cardLogo}>
                    <CardContent className={classes.cardContent}>
                        <Grid container alignItems="flex-start" justify="center">
                            <Grid item lg={10} >
                                <LogoBox logo={logo} type="small-margin"/>
                                <Typography style={{ marginBottom: "0.5em" }} variant="h2" color="textSecondary">INSCHRIJVEN</Typography>
                                <Typography style={{ marginBottom: "3em", color: "#fff" }} variant="body1">Schrijf u in als bedrijf of particulier.</Typography>
                                <br />
                                <Typography style={{ color: "#fff" }} variant="caption">{`Heeft u al een bestaand account bij ${company}? Log dan in met uw bestaande account.`}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid style={{ zIndex: 3 }} item>
                <Card className={classes.cardLogin}>
                    <CardContent style={{ height: "100%" }}>
                        <Grid style={{ height: "100%" }} container justify="center" alignItems="center">
                            <Grid item xs={10}>
                                <Options onClickUser={() => resetRegisterState()} path={companyPath} icon={() => <FaBuilding size="5em" />} text="Inschrijven als bedrijf" delay={100} />
                            </Grid>
                            <Grid item xs={10}>
                                <Options onClickUser={() => resetRegisterState()} path={freelancerPath} icon={() => <FaUserTie size="5em" />} text="Inschrijven als particulier" delay={250} />
                            </Grid>
                            <Grid item xs={10}>
                                <Options path={existingCustomerPath} onClickUser={() => setDestination(existingCustomerPath)} icon={() => <FaSignInAlt size="5em" />} text="Inloggen met een bestaand account" delay={400} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
}

export default withRouter(RegisterAsComponent);