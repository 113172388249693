import { Dispatch } from "redux";

import { TestExamActions } from "./types";
import { sampleExamService } from "../../services/sampleExam.service";
import { SampleExamFilledInput, SampleExamAdminInput, SampleExamAdmin } from "../../types/sampleExam";

export const getTestExamsByCategoryId = (categoryId: number, projectId?: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TestExamActions.GET_TESTEXAMS_BY_CATEGORY_REQUEST });
        const response = await sampleExamService.sampleExamsByCategoryId(categoryId);
        dispatch({ type: TestExamActions.GET_TESTEXAMS_BY_CATEGORY_SUCCESS, response, projectId });
    } catch (error) {
        dispatch({ type: TestExamActions.GET_TESTEXAMS_BY_CATEGORY_ERROR, error });
    }
};

export const getTestExamById = (sampleExamId: number, projectId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TestExamActions.GET_TESTEXAM_BY_ID_REQUEST });
        const response = await sampleExamService.sampleExam(sampleExamId, projectId);

        dispatch({ type: TestExamActions.GET_TESTEXAM_BY_ID_SUCCESS, response });
    } catch (error) {
        dispatch({ type: TestExamActions.GET_TESTEXAM_BY_ID_ERROR, error });
    }
};

export const addTestExamAnswer = (givenAnswerId: number, questionId: number) => async (dispatch: Dispatch) => {
    dispatch({
        type: TestExamActions.SET_TESTEXAM_ANSWER,
        givenAnswerId,
        questionId
    });
};

export const resetTestExamAnswers = () => async (dispatch: Dispatch) => {
    dispatch({
        type: TestExamActions.RESET_TESTEXAM_ANSWERS,
    });
};

export const checkTestExam = (sampleExam: SampleExamFilledInput) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TestExamActions.CHECK_TESTEXAM_REQUEST });
        const response = await sampleExamService.checkSampleExam(sampleExam);
        dispatch({ type: TestExamActions.CHECK_TESTEXAM_SUCCESS, response });
    } catch (error) {
        dispatch({ type: TestExamActions.CHECK_TESTEXAM_ERROR, error });
    }
};

export const createTestExam = (sampleExam: SampleExamAdminInput) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TestExamActions.CREATE_TESTEXAM_REQUEST });
        const newSampleExamId = await sampleExamService.createSampleExam(sampleExam);
        const createdTestExam: SampleExamAdmin = {
            ...sampleExam,
            sampleExamId: newSampleExamId,
        };
        dispatch({ type: TestExamActions.CREATE_TESTEXAM_SUCCESS, createdTestExam });
    } catch (error) {
        dispatch({ type: TestExamActions.CREATE_TESTEXAM_ERROR, error });
    }
};

export const updateTestExam = (sampleExam: SampleExamAdmin) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TestExamActions.UPDATE_TESTEXAM_REQUEST });
        await sampleExamService.updateSampleExam(sampleExam);
        dispatch({ type: TestExamActions.UPDATE_TESTEXAM_SUCCESS, sampleExam });
    } catch (error) {
        dispatch({ type: TestExamActions.UPDATE_TESTEXAM_ERROR, error });
    }
};

export const deleteTestExam = (sampleExamId: number) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: TestExamActions.DELETE_TESTEXAM_REQUEST });
        const deleted = await sampleExamService.deleteSampleExam(sampleExamId);

        if (deleted) {
            dispatch({ type: TestExamActions.DELETE_TESTEXAM_SUCCESS, sampleExamId });
        } else {
            dispatch({ type: TestExamActions.DELETE_TESTEXAM_ERROR });
        }
    } catch (error) {
        dispatch({ type: TestExamActions.DELETE_TESTEXAM_ERROR, error });
    }
};