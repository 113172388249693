import React, { useState } from "react"
import { Stepper, Step, StepLabel, Theme, useTheme, useMediaQuery, makeStyles, Grid, Typography } from "@material-ui/core";
import Check from "@material-ui/icons/Check";

import { Registration } from "../../types/register";
import { StepperConnector, useStepperIconStyles } from "../../styles/stepper";
import { StepIconProps } from "@material-ui/core/StepIcon";
import clsx from "clsx";
import CheckoutStepperContainer from "./CheckoutStepperContainer";

interface CheckoutComponentProps {
    checkoutItems: Registration[]
    onRemove: (index: number) => void
    role: string
}

const useStyles = makeStyles((theme: Theme) => ({
    stepper: {
        backgroundColor: theme.palette.primary.main,
    },
    stepperBackground: {
        backgroundColor: "#fafafa"
    },
    steplabel: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        color: "black"
    }
}));

const CheckoutComponent = ({ checkoutItems, onRemove, role }: CheckoutComponentProps) => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const classes = useStyles();
    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const steps = ["OVERZICHT", "BEVESTIGING"];

    function isStepSkipped(step: number) {
        return skipped.has(step);
    }

    function handleNext() {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    function StepIcon(props: StepIconProps) {
        const classes = useStepperIconStyles();
        const { active, completed } = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ? <div className={classes.circleCompleted}><Check className={classes.completed} /></div> : <div className={classes.circle}>{active && <div className={classes.innerCircle} />}</div>}
            </div>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">Afrekenen</Typography>
            </Grid>
            <Grid item xs={12}>
                {!fullScreen &&
                    <Stepper className={classes.stepperBackground} activeStep={activeStep} connector={<StepperConnector />}>
                        {steps && steps.map((label: string, index: number) => {
                            const stepProps: { completed?: boolean } = {};
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel StepIconComponent={StepIcon} classes={{
                                        label: `${classes.steplabel}`,
                                        root: `${classes.steplabel}`,
                                        active: `${classes.steplabel}`
                                    }}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                }
            </Grid>
            <Grid item xs={12}>
                <CheckoutStepperContainer
                    onRemove={(index: number) => onRemove(index)}
                    role={role}
                    checkoutItems={checkoutItems}
                    setActiveStep={(step: number) => setActiveStep(step)}
                    currentStep={activeStep}
                    handleNext={handleNext}
                />
            </Grid>
        </Grid>

    )
}

export default CheckoutComponent;
