import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { ApplicationState } from "../../../store";
import TestExamAdminComponent from "../SampleExam/TestExamAdminComponent";
import { createTestExam, updateTestExam, deleteTestExam, getTestExamsByCategoryId } from "../../../store/testExams/actions";
import { SampleExamAdminInput, SampleExamAdmin, SampleExam } from "../../../types/sampleExam";

interface CourseTypesContainerState {
    getTestExamsByCategoryId: (categoryId: number) => void;
    createTestExam: (sampleExam: SampleExamAdminInput) => void;
    updateTestExam: (sampleExam: SampleExamAdmin) => void;
    deleteTestExam: (sampleExamId: number) => void;
    exams: SampleExam[];
    loading: boolean;
    error?: any; 
}

interface CourseTypesContainerProps {
    categoryId: number;
}

const mapStateToProps = ({ testExams: { exams, loading, error } }: ApplicationState) => {
    return ({
        exams,
        loading, 
        error,
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ createTestExam, updateTestExam, deleteTestExam, getTestExamsByCategoryId }, dispatch);
};

const CourseTypesContainer = ({ createTestExam, updateTestExam, deleteTestExam, getTestExamsByCategoryId, exams, loading, error, categoryId }: CourseTypesContainerProps & CourseTypesContainerState) => {
    useEffect(() => {
        getTestExamsByCategoryId(categoryId);
    }, [categoryId, getTestExamsByCategoryId]);

    return (
        <TestExamAdminComponent
            categoryId={categoryId}
            exams={exams}
            error={error}
            loading={loading}
            createTestExam={createTestExam}
            updateTestExam={updateTestExam}
            deleteTestExam={deleteTestExam}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseTypesContainer);