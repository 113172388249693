import graphqlHelperService from "./graphqlHelper.service";
import { CourseDetail, ParticipatingStudent } from "../types/course";
import { RegisterCourse } from "../types/registerCourse";

export const courseDetailService = {
    courseDetailRegister: async (course: RegisterCourse, discountCode?: string): Promise<CourseDetail> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            courseDetailRegisterQuery(course, discountCode)
        );
        return apolloResult.courseDetail as CourseDetail;
    },
    courseDetails: async (studentId?: string): Promise<CourseDetail[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            courseDetailsQuery(studentId)
        );
        return apolloResult.courseDetails as CourseDetail[];
    },
    courseDetailsAdmin: async (): Promise<CourseDetail[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            courseDetailsAdminQuery()
        );
        return apolloResult.courseDetails as CourseDetail[];
    },
    participatingStudents: async (projectId: number): Promise<ParticipatingStudent[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            participatingStudentsQuery(projectId)
        );
        return apolloResult.participatingStudents as ParticipatingStudent[];
    }
}

const courseDetailRegisterQuery = (course: RegisterCourse, discountCode?: string) => `query{
    courseDetail ${course.category || course.type || course.place || course.date ? `(
        ${course.category?.categoryId ? `categoryId: ${course.category.categoryId}` : ``} 
        ${course.type?.courseId ? `courseId: "${course.type.courseId}"` : ``} 
        ${course.place ? `place: "${course.place}"` : ``} 
        ${course.date?.locationCode ? `locationCode: "${course.date.locationCode}"` : ``}
        ${discountCode ? `discountCode: "${discountCode}"` : ``})`: ``} 
    {
        category
        type
        address
        zipCode
        place
        dates 
        examDate 
        eLearning
        price
        isHomeStudy
        showHomework
        showSampleExam
        btw
        certificate
        exam
        timeSpan
        frequency
        locations
        rating
        homeworkSupport
        locationName
        freeField1
        freeField1Caption
        freeField1Icon
        freeField2
        freeField2Caption
        freeField2Icon
    }
}`;

const courseDetailsQuery = (studentId?: string) => `query{
    courseDetails ${studentId ? `(studentId: ${studentId})` : ``}  {
        categoryId
        courseId
        projectId
        category
        type
        address
        zipCode
        place
        locationName
        dates 
        examDate 
        exam
        certificate
        timeSpan
        homeworkSupport
        eLearning
        eLearningLink
        passed
        failed
        isHomeStudy
        amountOfParticipants
        showHomework
        showSampleExam
        madeHomeWork
        madeSampleExams
        sampleExamGrade
        dateDone
        grade
        presentAtExam
        theoreticGrade
        portfolioGrade
        coachingGrade
        expired
        freeField1
        freeField1Caption
        freeField1Icon
        freeField2
        freeField2Caption
        freeField2Icon
    }
}`;

const courseDetailsAdminQuery = () => `query{
    courseDetails {
        courseId
        course
        googleAnalyticsUrl
    }
}`;

const participatingStudentsQuery = (projectId: number) => `query{
    participatingStudents(projectId: ${projectId}) {
        studentId
        firstName
        prefix
        lastName
        passed
        failed
        madeHomeWork
        madeSampleExams
        sampleExamGrade
        presentAtExam
    }
}`;
