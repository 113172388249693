import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { StudentActions } from "./types";
import { studentService } from "../../services/student.service";
import { StudentDetail, emptyStdDetail } from "../../types/student";
import { toast } from "react-toastify";
import { getRole } from "../../helperfunctions/localstorage";
import { Role } from "../../types/enum";

export const getStudents = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: StudentActions.GET_STUDENTS_REQUEST });
        const role = getRole();
        if (role !== Role.COMPANY) {
            const response: StudentDetail[] = [];
            dispatch({ type: StudentActions.GET_STUDENTS_SUCCESS, response });
        } else {
            const response: StudentDetail[] = await studentService.students();
            dispatch({ type: StudentActions.GET_STUDENTS_SUCCESS, response });
        }
    } catch (error) {
        dispatch({ type: StudentActions.GET_STUDENTS_ERROR, error });
    }
};

export const getStudentDetail = (studentId?: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: StudentActions.GET_STUDENTDETAIL_SUCCESS, emptyStdDetail });
        dispatch({ type: StudentActions.GET_STUDENTDETAIL_REQUEST });
        const response = await studentService.studentDetail(studentId);
        if (!response) {
            toast.error("Student niet gevonden")
            dispatch({ type: StudentActions.GET_STUDENTDETAIL_SUCCESS, response });
        } else {
            dispatch({ type: StudentActions.GET_STUDENTDETAIL_SUCCESS, response });
        }
    } catch (error) {
        dispatch({ type: StudentActions.GET_STUDENTDETAIL_ERROR, error });
    }
};

export const emptyStudentDetail = () => async (dispatch: Dispatch) => {
    dispatch({ type: StudentActions.GET_STUDENTDETAIL_SUCCESS, emptyStdDetail });
};

export const createNewStudent = (newStudent: StudentDetail) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: StudentActions.CREATE_NEW_STUDENT_REQUEST });
        const response = await studentService.createNewStudent(newStudent);
        dispatch({ type: StudentActions.CREATE_NEW_STUDENT_SUCCESS, response });
        dispatch(push("/medewerkers"));
        toast.error("Medewerker toegevoegd")
    } catch (error) {
        dispatch({ type: StudentActions.CREATE_NEW_STUDENT_ERROR, error });
    }
};