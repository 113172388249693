import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Box, IconButton, Menu, MenuItem, Grid, Link, useMediaQuery, Theme, useTheme, Badge } from "@material-ui/core/";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { doLogout } from "../../store/auth/actions";
import { connect } from "react-redux";
import { useSpring, animated, config } from "react-spring";
import Avatar from "react-avatar";
import { FaUser, FaShoppingCart, FaCaretDown, FaCog, FaSignOutAlt, FaBars, FaPhone } from "react-icons/fa";

import { ApplicationState } from "../../store";
import Profile from "./Profile";
import "./Header.css"
import { getLogo } from "../../helperfunctions/getLogo";
import { AuthenticationState } from "../../types/auth";
import ShoppingCartContainer from "../ShoppingCart/ShoppingCartContainer";
import { Role } from "../../types/enum";
import { getPhone } from "../../helperfunctions/getPhone";
import { getContactPage } from "../../helperfunctions/getContactPage";

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1)
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  name: {
    opacity: 0.5,
    marginTop: "14px"
  }
}));

interface HeaderProps {
  shoppingCartAmount: number;
  auth: AuthenticationState;
  showMenu: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  doLogout: () => void;
}

interface IPathname {
  pathname: string;
}

const mapStateToProps = ({ auth, router, register }: ApplicationState) => {
  return {
    auth,
    pathname: router.location.pathname,
    shoppingCartAmount: register.shoppingCart.length
  }
};

const Header = ({ auth, showMenu, setOpen, open, doLogout, pathname, shoppingCartAmount }: HeaderProps & IPathname) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [shoppingCartOpen, setShoppingCartOpen] = useState<boolean>(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [props, set] = useSpring(() => ({ height: `64px`, config: config.stiff }));
  const { initials, lastName, firstName, prefix } = auth;
  const theme: Theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollY, setScrollY] = useState(0);
  const logo = getLogo();
  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollY(Math.round(window.scrollY))
    });
  }, []);

  useEffect(() => {
    set({ height: pathname === "/dashboard" && !fullScreen && scrollY < 100 ? "300px" : "64px" })
  });

  const logout = () => {
    if (shoppingCartAmount < 1) doLogout(); 
    else if (window.confirm('Let op, door uit te loggen vervalt de inhoud van de winkelwagen.')) doLogout();
  }

  const getName = (auth: AuthenticationState) => {
    if (auth.role === "ADMIN") {
      return 'Administrator'
    } else if (auth.role === 'STUDENT') {
      return auth.lastName
    } else {
      return auth.companyName ? auth.companyName : auth.lastName
    }
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!(auth.role === Role.ADMIN) &&
        <Link style={{ textDecoration: "none", color: "#363636" }} component={RouterLink} to="/persoonlijke-gegevens">
          <MenuItem onClick={() => { handleMenuClose(); }}>
            <FaUser className={classes.icon} />
            <p>Mijn gegevens</p>
          </MenuItem>
        </Link>
      }
      <Link style={{ textDecoration: "none", color: "#363636" }} component={RouterLink} to="/instellingen">
        <MenuItem onClick={() => { handleMenuClose(); }}>
          <FaCog className={classes.icon} />
          <p>Instellingen</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={() => { handleMenuClose(); logout() }}>
        <FaSignOutAlt className={classes.icon} />
        <p>Uitloggen</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      id={mobileMenuId}
      anchorEl={mobileMoreAnchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link style={{ textDecoration: "none", color: "#363636" }} component={RouterLink} to="/instellingen">
        <MenuItem
          onClick={() => { handleMobileMenuClose(); }}>
          <FaCog className={classes.icon} />
          <p>Instellingen</p>
        </MenuItem>
      </Link>
      <MenuItem
        onClick={() => { handleMobileMenuClose(); logout() }}>
        <FaSignOutAlt className={classes.icon} />
        <p>Uitloggen</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box display={showMenu ? "none" : ""}>
      <AppBar className={classes.appBar} position="fixed">
        <Grid container justify="flex-end" alignItems="center">
            <Grid item style={{ textAlign: "end", padding: "15px", fontSize: "large", cursor: "pointer" }} onClick={() => { window.open(getContactPage()); }}>CONTACT</Grid>
            <Grid item style={{ textAlign: "end", padding: "15px", fontSize: "large", paddingRight: "25px" }}><FaPhone style={{ marginRight: "5px" }} />{getPhone()}</Grid>
        </Grid>
        <animated.div style={props}>
          <Toolbar>
            <Grid container justify="center" alignItems="center">
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-controls={mobileMenuId}
                  onClick={() => setOpen(!open)}
                  color="inherit"
                  edge="start"
                >
                  <FaBars />
                </IconButton>
              </div>
              <div>
                <Link component={RouterLink} to="/dashboard">
                  <img className="hsn-logo" src={logo ? logo : ""} alt="hsn logo" />
                </Link>
              </div>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {auth.role !== Role.ADMIN &&
                  <IconButton
                    style={{ marginRight: "6px" }}
                    edge="end"
                    aria-controls={menuId}
                    onClick={() => setShoppingCartOpen(true)}
                    color="inherit"
                  >
                    <Badge color="secondary" badgeContent={shoppingCartAmount} >
                      <FaShoppingCart color="#fff" />
                    </Badge>
                  </IconButton>
                }
                <IconButton
                  edge="end"
                  aria-controls={menuId}
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar color={theme.palette.secondary.main} round={true} name={auth.role === Role.ADMIN ? "Administrator" : initials || lastName} size="30" />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                {!(auth.role === Role.STUDENT || auth.role === Role.ADMIN) &&
                  <IconButton
                    style={{ marginRight: "6px" }}
                    edge="end"
                    aria-controls={menuId}
                    onClick={() => setShoppingCartOpen(true)}
                    color="inherit"
                  >
                    <Badge color="secondary" badgeContent={shoppingCartAmount} >
                      <FaShoppingCart color="#fff" />
                    </Badge>
                  </IconButton>
                }
                <IconButton
                  aria-controls={mobileMenuId}
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <FaCaretDown color="#fff" />
                </IconButton>
              </div>
              <Grid item xs={12}>
                <Box className="profile" display={pathname === "/dashboard" && !fullScreen && scrollY < 100 ? "block" : "none"}>
                  <Profile prefix={prefix} firstName={firstName} lastName={lastName} name={getName(auth)} role={auth.role} />
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </animated.div>
      </AppBar>
      <ShoppingCartContainer open={shoppingCartOpen} handleClose={() => setShoppingCartOpen(false)} />
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};


export default connect(
  mapStateToProps,
  { doLogout }
)(Header);
