import React from 'react'
import MaterialTable from 'material-table';
import { Grid, Card, CardContent, useMediaQuery, useTheme, Theme, Typography, TextField } from '@material-ui/core';

import { LoadingTable } from '../../FeedBack/LoadingContent';
import Error from '../../FeedBack/Error';
import { Question, QuestionInput, AnswerInput } from '../../../types/question';
import AnswerComponent from './AnswerComponent';
import { materialTableLocalization } from '../../../constants/materialTableLocalization';
import NewAnswerComponent from './NewAnswerComponent';
import { renderSkeleton } from '../../FeedBack/Skeleton';

interface QuestionComponentProps {
    categoryId: number;
    questions: Question[];
    loading: boolean;
    error?: any;
    createQuestion: (question: QuestionInput) => void;
    updateQuestion: (questionId: number, question: QuestionInput) => void;
    deleteQuestion: (questionId: number) => void;
    createAnswer: (answer: AnswerInput) => void;
    updateAnswer: (answerId: number, answer: AnswerInput) => void;
    deleteAnswer: (answerId: number, questionId: number) => void;
}

const QuestionComponent = ({ categoryId, questions, loading, error, createQuestion, updateQuestion, deleteQuestion, createAnswer, updateAnswer, deleteAnswer }: QuestionComponentProps) => {
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

    questions && questions.map(question => {
        question.possibleAnswers && question.possibleAnswers.forEach(answer => {
            question.answers += answer.text;
        });
        return question;
    })

    return (
        <Grid container style={{ marginTop: "2em" }}>
            <Grid item xs={12}>
                {loading && <Grid item xs={12}> <LoadingTable /></Grid>}
                {error && <Error />}
                {(!loading && !error && questions) &&
                    <Card elevation={2}>
                        <CardContent>
                            <MaterialTable
                                style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "300px" : "none" }}
                                columns={[
                                    { title: "ID", field: "questionId", defaultSort: "asc", editable: 'never' },
                                    {
                                        title: "Vraag", field: "text", editComponent: props => (
                                            <TextField
                                                value={props.value}
                                                fullWidth
                                                onChange={e => props.onChange(e.target.value)}
                                            />
                                        )
                                    },
                                    { title: "Actief", field: "active", type: "boolean" },
                                    { title: "Test je kennis", field: "testjekennis", type: "boolean" },
                                    { field: "answers", hidden: true, searchable: true }
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                }}
                                editable={{
                                    onRowAdd: (newData: Question) =>
                                        new Promise(() => {
                                            createQuestion({ ...newData, categoryId });
                                        }),
                                    onRowUpdate: (newData: Question) =>
                                        new Promise(() => {
                                            updateQuestion(newData.questionId, { ...newData });
                                        }),
                                    onRowDelete: (oldData: Question) =>
                                        new Promise(() => {
                                            deleteQuestion(oldData.questionId);
                                        }),
                                }}
                                data={questions}
                                title="Vragen en antwoorden"
                                localization={materialTableLocalization}
                                detailPanel={({ possibleAnswers, questionId, loading }: Question) =>
                                    <Grid container direction="column">
                                        <Grid container justify="space-between" alignItems="center">
                                            <Grid item xs={10}>
                                                <Grid container alignItems="center" style={{ padding: "1em" }}>
                                                    <Grid item xs={1}>
                                                        <Typography variant="overline">Correct</Typography>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography variant="overline">Antwoord</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {loading ?
                                            renderSkeleton(5, { xs: 12 }, 50, "rect")
                                            :
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    {possibleAnswers && possibleAnswers.map(answer => <AnswerComponent
                                                        answer={answer}
                                                        updateAnswer={updateAnswer}
                                                        deleteAnswer={deleteAnswer}
                                                        key={answer.answerId}
                                                    />)}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <NewAnswerComponent
                                                        questionId={questionId}
                                                        createAnswer={createAnswer}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                                onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
                            />
                        </CardContent>
                    </Card>
                }
            </Grid>
        </Grid>
    )
}

export default QuestionComponent;
