import React from "react";
import { Grid, Typography, makeStyles, Button } from "@material-ui/core";
import { animated, useSpring, config } from "react-spring";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    check: {
        verticalAlign: "middle",
        fontSize: "100px",
        color: theme.palette.primary.main,
    },
}));

interface RegisterAppCompleteProps {
    setActiveStep: (step: number) => void;
}

const RegisterAppComplete = ({ setActiveStep }: RegisterAppCompleteProps) => {
    const classes = useStyles()
    const checkProps = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        config: config.default
    })

    return (
        <>
            <Grid container justify="center" alignItems="center" style={{ padding: "2em" }} direction="column">
                <Grid item xs={12} md={6}>
                    <Grid container justify="center" alignItems="center" direction="column">
                        <animated.div style={checkProps}>
                            <FaCheck className={classes.check} />
                        </animated.div>
                        <Grid container spacing={4} justify="center" alignItems="center" direction="column">
                            <Grid item xs={12}>
                                <Typography style={{ textAlign: "center" }} variant="h5">Toegevoegd aan uw winkelmandje</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="center" spacing={1}>
                                    <Grid item>
                                        <Button onClick={() => setActiveStep(0)} variant="contained" color="secondary">nog een inschrijving doen</Button>
                                    </Grid>
                                    <Grid item>
                                        <Link style={{ textDecoration: "none" }} to="/bevestigen">
                                            <Button variant="contained" color="secondary">inschrijving bevestigen</Button>
                                        </Link>     
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default RegisterAppComplete;