import { Dispatch } from "redux";
import { push } from "connected-react-router";

import { authenticationService } from "../../services/auth.service";
import { LoginData, LoginResponse } from "../../types/auth";
import { AuthActions } from "./types";
import messages from "../../constants/messages";
import { Role } from "../../types/enum";
import { toast } from "react-toastify";

export const setDestination = (destination: string) => async (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.SET_DESTINATION, destination });
};

export const loginSubAccount = (account: LoginResponse, destination?: string) => async (dispatch: Dispatch) => {
    if (!destination) {
        destination = "/dashboard";
    }

    dispatch({ type: AuthActions.LOGIN_SUCCESS, account });
    dispatch({ type: AuthActions.RESET_ACCOUNTS });
    dispatch({ type: AuthActions.RESET_DESTINATION })
    dispatch(push(destination));
}

export const doLogin = (loginData: LoginData, destination?: string) => async (dispatch: Dispatch) => {
    if (!destination) {
        destination = "/dashboard";
    }

    dispatch({ type: AuthActions.LOGIN_REQUEST });
    try {
        const accounts: LoginResponse[] = await authenticationService.login(loginData.username, loginData.password);
        if (accounts.length === 1) {
            const account: LoginResponse = accounts[0];
            dispatch({ type: AuthActions.LOGIN_SUCCESS, account });
            dispatch({ type: AuthActions.RESET_DESTINATION })
            if (account.role === Role.STUDENT && destination === 'inschrijven') destination = "/dashboard";
            dispatch(push(destination));
        } else {
            dispatch({ type: AuthActions.SET_DESTINATION, destination });
            dispatch({ type: AuthActions.SET_ACCOUNTS, accounts })
            dispatch(push("/sub-accounts"))
        }
    } catch (error) {
        toast.error(error === "Invalid credentials" ? "Onjuiste inloggegevens" : messages.CONNECTION_TIMED_OUT_LOGIN)
        dispatch({ type: AuthActions.LOGIN_ERROR, error });
    }
};

export const doLogout = () => async (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.LOGOUT_REQUEST });
    try {
        localStorage.setItem('remember', 'false');
        dispatch({ type: AuthActions.LOGOUT_SUCCESS });
        dispatch(push("/"));
    } catch (error) {
        dispatch({ type: AuthActions.LOGOUT_ERROR, error });
    }
};

export const forgotPasswordSendEmail = (username: string) => async (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.SEND_EMAIL_REQUEST });
    try {
        const response = await authenticationService.forgotPasswordRequest(username);
        if (response) {
            dispatch({ type: AuthActions.SEND_EMAIL_SUCCESS, response });
            toast.success(messages.FORGOT_PASSWORD_REQUEST_SUCCESS)
        } else {
            toast.error(messages.INVALID_USERNAME)
        }
    } catch (error) {
        dispatch({ type: AuthActions.SEND_EMAIL_ERROR, error });
        toast.error(messages.INVALID_USERNAME)
    }
};

export const forgotPasswordResetPassword = (inputToken?: string, newPassword?: string) => async (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.RESET_PASSWORD_REQUEST });
    try {
        const response = await authenticationService.resetPassword(inputToken, newPassword);

        if (response) {
            dispatch({ type: AuthActions.RESET_PASSWORD_SUCCESS, response });
            toast.success(messages.SET_PASSWORD)
        } else {
            toast.error(messages.SOMETHING_WENT_WRONG)
        }
    } catch (error) {
        toast.error(messages.SOMETHING_WENT_WRONG)
        dispatch({ type: AuthActions.RESET_PASSWORD_ERROR, error });
    }
    dispatch(push("/login"));
};

export const changePassword = (newPassword: string, oldPassword: string) => async (dispatch: Dispatch) => {
    dispatch({ type: AuthActions.CHANGE_PASSWORD_REQUEST });
    try {
        const response = await authenticationService.changePassword(newPassword, oldPassword);
        toast.success(messages.SET_PASSWORD)
        dispatch({ type: AuthActions.CHANGE_PASSWORD_SUCCESS, response });
        dispatch(push("/dashboard"));
    } catch (error) {
        if (error === "Invalid input") {
            toast.error(messages.WRONG_PASSWORD)
        } else {
            toast.error(messages.SOMETHING_WENT_WRONG)
        }
        dispatch({ type: AuthActions.CHANGE_PASSWORD_ERROR, error });
    }
};

export const loginRegistrationEntranceCode = (token?: string, refreshToken?: string, entranceCode?: string) => async (dispatch: Dispatch) => {
    let tokenValid = token && await authenticationService.checkToken(token);
    if (!tokenValid) tokenValid = refreshToken && await authenticationService.checkRefreshToken(refreshToken);
    if (tokenValid && entranceCode && entranceCode !== "undefined") await authenticationService.deleteEntranceCode(entranceCode);
    if (tokenValid || !entranceCode || entranceCode === "undefined") return;

    try {
        const account = await authenticationService.loginByEntranceCode(entranceCode);
        dispatch({ type: AuthActions.LOGIN_SUCCESS, account });
        dispatch({ type: AuthActions.RESET_DESTINATION })
        dispatch(push("/inschrijving-betaald"));
    } catch (error) {
        dispatch(push("/inschrijving-betaald"));
    }
}

export const loginByEntranceCode = (token?: string, refreshToken?: string, entranceCode?: string) => async (dispatch: Dispatch) => {
    let tokenValid = token && await authenticationService.checkToken(token);
    if (!tokenValid) tokenValid = refreshToken && await authenticationService.checkRefreshToken(refreshToken);
    if (tokenValid && entranceCode && entranceCode !== "undefined") await authenticationService.deleteEntranceCode(entranceCode);
    if (tokenValid || !entranceCode || entranceCode === "undefined") return;

    try {
        const account = await authenticationService.loginByEntranceCode(entranceCode);
        dispatch({ type: AuthActions.LOGIN_SUCCESS, account });
        dispatch({ type: AuthActions.RESET_DESTINATION })
        dispatch(push("/factuur-betaald"));
    } catch (error) {
        dispatch(push("/login"));
    }
} 
