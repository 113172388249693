//gives a number between 0 and 100 as password strength
export const passwordChecker = (password: string) => {
    //define checkers and set score to default: 0
    let score = 0;
    if(!password) return score;
    
    const upperCaseCheck = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowerCaseCheck = "abcdefghijklmnopqrstuvwxyz";
    const numberCheck = "0123456789";
    const characterCheck = "!@#$%^&*?_~";

    //assign points for the length
    if (password.length > 0 && password.length < 5) score += 5;
    else if (password.length > 4 && password.length < 8) score += 15;
    else if (password.length > 7) score += 25;

    //assign points for the usage of lower and or uppercase letters
    const upperCount = countContain(password, upperCaseCheck);
    const lowerCount = countContain(password, lowerCaseCheck);
    if (upperCount === 0 && lowerCount !== 0) score += 10;
    else if (upperCount !== 0 && lowerCount !== 0) score += 20;

    //assign points for the usage of numbers
    const numberCount = countContain(password, numberCheck);
    if (numberCount === 1) score += 10;
    if (numberCount > 1) score += 20;

    //assign points for the usage of special characters
    const characterCount = countContain(password, characterCheck);
    if (characterCount === 1) score += 10;
    if (characterCount > 1) score += 20;

    //assign bonus points for combinations
    const lowerUpperCount = upperCount + lowerCount;
    if (numberCount !== 0 && lowerUpperCount !== 0) score += 5;
    if (numberCount !== 0 && lowerUpperCount !== 0 && characterCount !== 0) score += 5;
    if (numberCount !== 0 && upperCount !== 0 && lowerCount !== 0 && characterCount !== 0) score += 5;

    return score;
}

const countContain = (usePassword: string, check: string) => {
    let count = 0;

    for (let i = 0; i < usePassword.length; i++) {
        if (check.indexOf(usePassword.charAt(i)) > -1) count++;
    }

    return count;
}

export enum PasswordStrength {
    VERYUNSAFE = 20,
    UNSAFE = 40,
    MODERATE = 60,
    SAFE = 80,
    VERYSAFE = 100,
}
