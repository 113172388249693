import { IconOption } from "./iconSelection";

export interface MetaDataState {
    metaData?: CourseMetaData;
    loading: boolean;
};

export interface CourseMetaData {
    courseId: string,
    timeSpan?: string,
    frequency?: string,
    locations?: string,
    exam?: string,
    certificate?: string,
    rating?: string,
    freeField1?: string;
    freeField1Caption?: string;
    freeField1Icon: IconOption;
    freeField2?: string;
    freeField2Caption?: string;
    freeField2Icon: IconOption;
    homeworkSupport: boolean,
}

export const emptyCourseMetaData: CourseMetaData = {
    courseId: "0",
    timeSpan: "",
    frequency: "",
    locations: "",
    exam: "",
    certificate: "",
    rating: "",
    homeworkSupport: false,
    freeField1: "",
    freeField1Caption: "",
    freeField1Icon: "FaQuestion",
    freeField2: "",
    freeField2Caption: "",
    freeField2Icon: "FaQuestion"
}