import React, { useEffect } from "react";
import { Grid, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import CourseDetailItem from "../../../Courses/CourseDetailItem";
import { CourseDetail } from "../../../../types/course";
import CourseContainer from "./CourseContainer";
import { RegisterUserType, Role } from "../../../../types/enum";
import { useHistory } from "react-router"

interface RegisterCourseComponentProps {
    completed: boolean;
    handleNext?: () => void;
    hasBook?: boolean;
    courseDetail: CourseDetail;
    setFreelanceBook: (freelancebook: boolean) => void;
    role: string;
    freelanceBooks: boolean;
    userType: RegisterUserType;
    priceIncludesBook: boolean;
}

const RegisterCourseComponent = ({ courseDetail, handleNext, completed, hasBook, setFreelanceBook, role, freelanceBooks, userType, priceIncludesBook }: RegisterCourseComponentProps) => {
    const [checked, setChecked] = React.useState(freelanceBooks);
    const history = useHistory()
    const handleChange = (event: any) => {
        setChecked(event.target.checked);
    };

    useEffect(() => setFreelanceBook(checked), [checked, setFreelanceBook])

    return (
        <Grid container direction='row'>
            <Grid item xs={12} md={6}>
                <CourseContainer userType={userType} isMailLinkBuilder={false}/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Grid container direction='row'>
                    <Grid item xs={12}>
                        <CourseDetailItem role={role} courseDetail={courseDetail} />
                    </Grid>
                    {hasBook && role === Role.FREELANCER &&
                        <Grid item xs={12}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label={priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                                />
                            </div>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                handleNext && <Grid container justify="space-between">
                    <Button
                        style={{ marginTop: "2em" }}
                        type="submit"
                        onClick={() => role !== Role.COMPANY && role !== Role.FREELANCER ? history.push("/inschrijven-als") : history.push("/dashboard")}>
                        Terug
                    </Button>
                    <Button
                        color="secondary"
                        style={{ marginTop: "2em" }}
                        variant="contained"
                        type="submit"
                        disabled={!completed}
                        onClick={handleNext}>
                        Volgende
                    </Button>
                </Grid>
            }
        </Grid>
    )
}

export default RegisterCourseComponent;
