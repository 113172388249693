import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from "react-dom";

import Root from "./components/Root";
import { SetupSentry } from './sentry';

SetupSentry();

ReactDOM.render(
    <Root />
    , document.getElementById("root")
);