import { MuiThemeProvider } from "@material-ui/core/styles";
import { ConnectedRouter } from "connected-react-router";
import { getTheme } from "../helperfunctions/getTheme";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createHashHistory } from "history";
import { configureStore } from "../store";
import { Provider } from "react-redux";
import App from "../components/App";
import "./Root.css";

const theme = getTheme();
const history = createHashHistory();
export const store = configureStore(history);

const Root = () => <Provider store={store}>
    <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
            <ToastContainer limit={3} position="bottom-center" />
            <App />
        </MuiThemeProvider>
    </ConnectedRouter>
</Provider>

export default Root;