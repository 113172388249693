import { getWebsite } from "./getWebsite";
import { Website } from "../types/enum"

export const getPhone = (): string => {
    switch (getWebsite()) {
        case Website.SH:
            return "076-5138045";
        case Website.HOC:
            return "085-2026666";
        default:
            return "076-5233666";
    }
}