import MaterialTable from 'material-table';
import { Grid, Card, CardContent, Theme, useTheme, useMediaQuery } from '@material-ui/core';

import { LoadingTable } from '../../FeedBack/LoadingContent';
import { materialTableLocalization } from '../../../constants/materialTableLocalization';
import { MailingLinkState, MailingLink } from '../../../types/mailingLink';
import { getAllUrlParams } from "../../../helperfunctions/urlReader"
import { copyStringToClipboard } from '../../../helperfunctions/clipboard';
import { toast } from 'react-toastify';

interface MailingLinksComponentProps {
    setName: (name: string) => void;
    setTargetAudience: (index: number) => void;
    mailingLink: MailingLinkState;
    setMailingLink: (categoryId?: number, courseId?: string, location?: string, locationCode?: string) => void;
    deleteMailingLink: (name: string) => void;
    setDiscountCode: (discountCode: string) => void;
    resetDiscountCode: () => void;
}

const MailingLinksComponent = ({ setName, mailingLink, deleteMailingLink, setTargetAudience, setDiscountCode, resetDiscountCode, setMailingLink }: MailingLinksComponentProps) => {
    const theme: Theme = useTheme();
    const fullscreen = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Grid container style={{ marginTop: "2em" }}>
            <Grid item xs={12}>
                {mailingLink.loading && <Grid item xs={12}> <LoadingTable /></Grid>}
                {(!mailingLink.loading && mailingLink.mailingLinks) &&
                    <Card elevation={2}>
                        <CardContent>
                            <MaterialTable
                                style={{ boxShadow: "none", marginBottom: "2em", maxWidth: fullscreen ? "300px" : "none" }}
                                columns={[
                                    { title: "Naam", field: "name", type: "string", defaultSort: "asc", editable: "onAdd" },
                                    { title: "Link", field: "link", type: "string" },
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                }}
                                editable={{
                                    onRowDelete: (oldData: MailingLink) =>
                                        new Promise(() => {
                                            deleteMailingLink(oldData.name);
                                        }),
                                }}
                                onRowClick={(e, rowData) => {
                                    if (rowData && rowData.link && rowData.name) {
                                        copyStringToClipboard(rowData.link);
                                        toast.info("Link gekopieerd!");

                                        if (rowData.link.includes("inschrijven/bedrijf")) { 
                                            setTargetAudience(1);
                                        } else if (rowData.link.includes("inschrijven/particulier")) { 
                                            setTargetAudience(2);
                                        } else if (rowData.link.includes("inschrijven-als")) { 
                                            setTargetAudience(0);
                                        } else {
                                            setTargetAudience(3);
                                        }

                                        const urlParams: any = getAllUrlParams(rowData.link);
                                        urlParams && setMailingLink(parseInt(urlParams.categoryId), urlParams.courseId, urlParams.place, urlParams.locationCode);
                                        urlParams && urlParams.discountCode && setDiscountCode(urlParams.discountCode);
                                        urlParams && !urlParams.discountCode && resetDiscountCode();

                                        setName(rowData.name);
                                    }
                                }}
                                localization={materialTableLocalization}
                                data={mailingLink.mailingLinks}
                                title="Mailing links"
                            />
                        </CardContent>
                    </Card>
                }
            </Grid>
        </Grid>
    );
};

export default MailingLinksComponent;
