import { MetaDataActions } from "./types";
import { MetaDataState, emptyCourseMetaData } from "../../types/metaData";

const initialState: MetaDataState = {
    metaData: emptyCourseMetaData,
    loading: false,
};

export function metaDataReducer(state = initialState, action: typeof MetaDataActions): MetaDataState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case MetaDataActions.REQUEST:
            return {
                metaData: emptyCourseMetaData,
                loading: true,
            };
        case MetaDataActions.SUCCESS:
        case MetaDataActions.FAILURE:
            return {
                metaData: { ...emptyCourseMetaData, ...action.metaData },
                loading: false,
            };
        default:
            return state;
    }
}