import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, InjectedFormProps, getFormValues } from "redux-form";
import { animated, useSpring, config } from "react-spring";
import { Grid, Typography, Button, Divider, Box, Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";

import RenderTextField from "../../../TextField";
import { required, isEmail, isPhone, passwordStrongEnough, validString } from "../../../../validators/form";
import { ApplicationState } from "../../../../store";
import { useButtonStyles } from "../../../../styles/button";
import { useTextStyles } from "../../../../styles/text";
import AutofillComponent from "../../../../helperfunctions/AutofillComponent";
import { getWebsite } from "../../../../helperfunctions/getWebsite";
import { Website } from "../../../../types/enum";
import AutofillKHNComponent from "../../../../helperfunctions/AutofillKHNComponent";
import RenderTextFieldPasswordChecker from "../../../TextField/TextFieldBar";
import { trimFields } from "../../../../helperfunctions/trimFields";
import PasswordTooltip from "../../../../helperfunctions/PasswordTooltip";
import { getPortalName } from "../../../../helperfunctions/getPortalName";
import CustomPrompt from "../../../notification/CustomPrompt";
import RenderTextFieldLogin from "../../../TextField/TextFieldLogin";
import RenderTextFieldDropdown from "../../../TextField/TextFieldDropdown";

export interface CompanyDataFormData {
    khnId?: string;
    nameCompany: string;
    emailCompany: string;
    passwordCompany: string;
    houseNumberCompany: string;
    placeCompany: string;
    zipCodeCompany: string;
    adresCompany: string;
    phoneCompany: string;
    phoneMobileContact: string;
    sexContact: string;
    initialsContact: string;
    infixContact: string;
    firstNameContact: string;
    lastNameContact: string;
    emailContact: string;
    byMail: boolean;
    invoiceAddress: string;
    invoiceHouseNumber: string;
    invoiceZipCode: string;
    invoicePlace: string;
    emailInvoice: string;
}

interface CompanyDataProps {
    formValues: any;
    registerInformationKHN: (khnId: number, zipCode: string, identifier: string, formValues: any) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    setRegisterCompanyData: (registerCompanyData: CompanyDataFormData) => void;
    handleNext: () => void;
    handleBack: () => void;
}

const CompanyData = ({ handleSubmit, valid, submitting, handleNext, handleBack, setRegisterCompanyData, formValues, getAddress, registerInformationKHN }: CompanyDataProps & InjectedFormProps<CompanyDataFormData, CompanyDataProps>) => {
    const buttonClasses = useButtonStyles();
    const textClasses = useTextStyles();
    const [byMail, setByMail] = useState<boolean>(false);
    const dataProps = useSpring({
        from: { opacity: 0, transform: "translateX(200px)" },
        to: { opacity: 1, transform: "translateX(-0px)" },
        config: config.stiff
    });

    const initialMail = !!formValues?.byMail;
    useEffect(() => {
        setByMail(initialMail);
    }, [initialMail, setByMail])

    return (
        <form onSubmit={handleSubmit((data: CompanyDataFormData): void => {
            data = trimFields(data)
            setRegisterCompanyData({ ...data, byMail });
            handleNext();
        })}>
            <CustomPrompt when={!valid} />
            <animated.div style={dataProps}>
                <Grid container>
                    {getWebsite() === Website.HOC &&
                    <>
                        <Grid item xs={12} style={{ marginBottom: "1em" }}>
                            <Typography variant="h6">KHN lidmaatschap</Typography>
                            <Divider />
                        </Grid>
                        <AutofillKHNComponent
                            identifier="COMPANY"
                            formValues={formValues}
                            registerInformationKHN={registerInformationKHN}
                        />
                    </>}
                    <Grid item xs={12} style={{ marginBottom: "1em" }}>
                        <Typography variant="h6">Bedrijfsgegevens</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>BEDRIJFSNAAM</Typography>
                            <Field
                                name="nameCompany"
                                variant="outlined"
                                validate={[validString, required]}
                                placeholder="Verplicht"
                                component={RenderTextField}
                            />
                        </div>
                    </Grid>
                    
                    <Grid item xs={12} style={{ marginBottom: "1em" }}>
                        <Typography variant="h6">Contactgegevens</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{ root: `${textClasses.label}` }}>GESLACHT</Typography>
                            <Field
                                name="sexContact"
                                validate={[validString, required]}
                                component={RenderTextFieldDropdown}
                            >
                                <MenuItem value={"H"} >De heer</MenuItem>
                                <MenuItem value={"M"} >Mevrouw</MenuItem>
                            </Field>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>VOORLETTERS</Typography>
                            <Field
                                name="initialsContact"
                                variant="outlined"
                                validate={[validString, required]}
                                placeholder="Verplicht"
                                component={RenderTextField}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>VOORNAAM</Typography>
                            <Field
                                name="firstNameContact"
                                variant="outlined"
                                validate={[validString]}
                                placeholder="Optioneel"
                                component={RenderTextField}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>TUSSENVOEGSEL</Typography>
                            <Field
                                name="infixContact"
                                variant="outlined"
                                validate={[validString]}
                                placeholder="Optioneel"
                                component={RenderTextField}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="textfield-spacing textfield-spacing-h">
                            <Typography classes={{
                                root: `${textClasses.label}`
                            }}>ACHTERNAAM</Typography>
                            <Field
                                name="lastNameContact"
                                variant="outlined"
                                validate={[validString, required]}
                                placeholder="Verplicht"
                                component={RenderTextField}
                            />
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>EMAIL 1</Typography>
                                <Field
                                    name="emailCompany"
                                    variant="outlined"
                                    validate={[validString, required, isEmail]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>WACHTWOORD {getPortalName()}</Typography>
                                <Field
                                    name="passwordCompany"
                                    variant="outlined"
                                    type="password"
                                    validate={[validString, required, passwordStrongEnough]}
                                    placeholder="Verplicht"
                                    component={RenderTextFieldLogin}
                                    InputProps={{
                                        endAdornment: <PasswordTooltip />,
                                    }}
                                />
                                <Field
                                    name="passwordCompany"
                                    validate={[validString, required, passwordStrongEnough]}
                                    component={RenderTextFieldPasswordChecker}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>EMAIL 2</Typography>
                                <Field
                                    name="emailContact"
                                    variant="outlined"
                                    validate={[validString, isEmail]}
                                    placeholder="Optioneel"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>BEDRIJFS TELEFOON</Typography>
                                <Field
                                    name="phoneCompany"
                                    variant="outlined"
                                    validate={[validString, isPhone, required]}
                                    placeholder="Verplicht"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>MOBIELE TELEFOON</Typography>
                                <Field
                                    name="phoneMobileContact"
                                    variant="outlined"
                                    validate={[validString, isPhone]}
                                    placeholder="Optioneel"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "1em" }}>
                            <Typography variant="h6">Adresgegevens</Typography>
                            <Divider />
                        </Grid>
                        <AutofillComponent identifier="COMPANY" formValues={formValues} getAddress={getAddress} />
                        <Grid item xs={12} style={{ marginBottom: "1em", marginTop: "1em" }}>
                            <Typography variant="h6">Factuurgegevens</Typography>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="textfield-spacing textfield-spacing-h">
                                <Typography classes={{
                                    root: `${textClasses.label}`
                                }}>FACTURATIE EMAIL</Typography>
                                <Field
                                    name="emailInvoice"
                                    variant="outlined"
                                    validate={[validString, isEmail]}
                                    type="email"
                                    placeholder="Optioneel"
                                    component={RenderTextField}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ marginBottom: "auto", marginTop: "auto", paddingLeft: ".5em" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={byMail}
                                        onClick={() => setByMail(!byMail)}
                                    />
                                }
                                label={"afwijkend factuuradres"}
                                classes={{
                                    root: `${textClasses.subLabel}`
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Box display={byMail ? "" : "none"} style={{ width: "100%" }}>
                            <Grid container>
                                <AutofillComponent
                                    identifier="COMPANY_INVOICE"
                                    formValues={formValues}
                                    shouldValidate={byMail}
                                    getAddress={getAddress}
                                    config="invoice"
                                />
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid container justify="space-between">
                        <Button
                            onClick={handleBack}
                            classes={{
                                root: buttonClasses.secondaryApp,
                            }}
                        >
                            Terug
                    </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={submitting || !valid}
                            type="submit"
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            Volgende
                    </Button>
                    </Grid>
                </Grid>
            </animated.div >
        </form >
    );
}

const DecoratedInitializeFromStateFormFunction = reduxForm<CompanyDataFormData, CompanyDataProps>({
    form: "companyDataForm",
    enableReinitialize: true,
})(CompanyData);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let initialValues = state.register.registerCompanyData;
        initialValues = trimFields(initialValues)

        return ({
            formValues: getFormValues("companyDataForm")(state),
            initialValues: initialValues,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;