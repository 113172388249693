import React, { useState, useEffect } from "react";
import { connect } from "react-redux"
import { Link as RouterLink } from "react-router-dom";
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { Grid, Typography, Button, Link, Card, CardContent, makeStyles, Box, Theme, Switch } from "@material-ui/core";
import { withTheme } from "@material-ui/styles";
import { FaUser, FaLock } from "react-icons/fa";

import { doLogin, forgotPasswordSendEmail } from "../../store/auth/actions";
import RenderTextFieldLogin from "../TextField/TextFieldLogin";
import { ApplicationState } from "../../store";
import { LoginData } from "../../types/auth";
import Loading from "../FeedBack/Loading";
import LogoBox from "./LogoBox"
import { getLogo } from "../../helperfunctions/getLogo";
import ForgotPassword from "./ForgotPassword";
import { required, validString } from "../../validators/form";
import "./Login.css";
import { getPortalName } from "../../helperfunctions/getPortalName";

const useStyles = makeStyles((theme: Theme) => ({
    cardLogin: {
        height: "550px",
        borderRadius: "0",
        backgroundColor: "#fff",
        [theme.breakpoints.down("sm")]: {
            boxShadow: "none",
            height: "100%",

        },
    },
    background: {
        width: "30%",
        top: "100px",
        right: "0",
        height: "auto",
        position: "absolute",
        zIndex: -1
    },
    full: {
        height: "100%",
    },
    colorContrast: {
        color: theme.palette.primary.main
    },
    logo: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        marginBottom: "50px",
        padding: "1em"
    },
    loginWrapper: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "0",
        },
        marginTop: "6em",
    },
    linkButton: {
        "&:hover": {
            textDecoration: "underline",
        },
    }
}));

interface LoginProps {
    theme: any;
    email: string;
    password: string;
    remember: boolean;
    doLogin: (loginData: LoginData, destination?: string) => void;
    forgotPasswordSendEmail: (username: string) => void;
    loading: boolean;
    destination?: string;
}

const mapStateToProps = ({ auth }: ApplicationState) => {
    const loading = auth.loading;
    const destination = auth.destination;
    return { loading, destination };
};

const Login = ({ handleSubmit, doLogin, loading, destination, forgotPasswordSendEmail }: LoginProps & InjectedFormProps<LoginData, LoginProps>) => {
    const classes = useStyles();
    const logo = getLogo();
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState<boolean>(false);
    const [remember, setRemember] = useState<boolean>(localStorage.getItem('remember') === 'true' || false);

    useEffect(() => localStorage.setItem('remember', remember.toString()), [remember]);

    return (
        <Grid container className={classes.full} alignItems="center" justify="center" >
            <Grid container alignItems="center" justify="center">
                <Grid item xs={4}>
                    <Box style={{ zIndex: -1 }} display={{ xs: "none", sm: "none", md: "block" }}>
                        <LogoBox logo={logo} />
                    </Box>
                </Grid>
                <Grid style={{ zIndex: 3 }} className={classes.full} item lg={4} md={4} xs={12}>
                    <Card className={classes.cardLogin}>
                        <Grid container>
                            <Link
                                to="/inschrijven-als"
                                component={RouterLink}
                                style={{ width: "100%" }}>
                                <Button fullWidth variant="contained" color="primary" style={{ borderRadius: 0 }}>
                                    <Typography variant="button" color="textSecondary" style={{ fontSize: "1em" }}>KLIK OM DIRECT IN TE SCHRIJVEN</Typography>
                                </Button>
                            </Link>
                        </Grid>
                        <CardContent>
                            <Grid container justify="center" className={classes.loginWrapper}>
                                <Box display={{ xs: "block", md: "none" }}>
                                    <Grid style={{ textAlign: "center" }} className={classes.logo} item xs={12}>
                                        <img style={{ width: "80%", height: "auto" }} src={logo} alt="logo" />
                                    </Grid>
                                </Box>
                                <Grid item lg={8} xs={10} sm={10}>
                                    <Typography variant="h3" className={classes.colorContrast} style={{ marginBottom: ".5em" }}>LOGIN</Typography>
                                </Grid>
                                {loading && <Loading />}
                                {(!loading) &&
                                    <Grid item lg={8} xs={10} sm={10}>
                                        <form id="login-input-form" onSubmit={handleSubmit((data: LoginData & any) => doLogin(data, destination))}>
                                            <Field
                                                icon={<FaUser />}
                                                name="username"
                                                validate={[validString, required]}
                                                component={RenderTextFieldLogin}
                                                placeholder="Email of cursistnummer"
                                            />
                                            <Field
                                                icon={<FaLock />}
                                                name="password"
                                                type="password"
                                                validate={[validString, required]}
                                                component={RenderTextFieldLogin}
                                                placeholder={`Wachtwoord ${getPortalName()}`}
                                            />
                                            <div className="textfield-spacing">
                                                <Grid container alignItems="center">
                                                    <Switch
                                                        name="remember"
                                                        checked={remember}
                                                        onChange={() => setRemember(!remember)}
                                                    />
                                                    <Typography variant="overline">Ingelogd blijven</Typography>
                                                </Grid>
                                            </div>
                                            <Button type="submit" variant="contained" color="secondary" id="login-login-button">
                                                <Typography variant="button" style={{ color: "#fff" }}>
                                                    INLOGGEN
                                            </Typography>
                                            </Button>
                                        </form>
                                        <div id="login-secondary-action-wrapper">
                                            <Button onClick={() => setOpenForgotPasswordModal(!openForgotPasswordModal)} className={classes.colorContrast}>
                                                <Typography variant="overline" className={classes.linkButton}>WACHTWOORD VERGETEN</Typography>
                                            </Button>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <ForgotPassword open={openForgotPasswordModal} handleClose={() => setOpenForgotPasswordModal(false)} handleSend={(username: string) => {
                forgotPasswordSendEmail(username)
                setOpenForgotPasswordModal(false);
            }} />
        </Grid>
    );
}

const Decorator = connect(
    mapStateToProps,
    { doLogin, forgotPasswordSendEmail }
)(
    reduxForm<LoginData, LoginProps>({
        form: "loginForm"
    })(Login)
);

export default withTheme(Decorator);