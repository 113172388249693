import { createActionsWithPrefix } from "../../helperfunctions/action";

export const InvoicesActions = createActionsWithPrefix("INVOICES/", {
    GET_INVOICES_REQUEST: "GET_INVOICES_REQUEST",
    GET_INVOICES_SUCCESS: "GET_INVOICES_SUCCESS",
    GET_INVOICES_ERROR: "GET_INVOICES_ERROR",
    GET_INVOICESPDF_REQUEST: "GET_INVOICESPDF_REQUEST",
    GET_INVOICESPDF_SUCCESS: "GET_INVOICESPDF_SUCCESS",
    GET_INVOICESPDF_ERROR: "GET_INVOICESPDF_ERROR",
    SET_INVOICE: "SET_INVOICE",
});