import React from "react"
import { Grid, Card, CardContent, Divider, makeStyles } from "@material-ui/core";

import CardHeader from "../UI/CardHeader";
import { RelationDetail } from "../../types/userInformation";
import { renderSkeleton } from "../FeedBack/Skeleton";

interface RelationDetailProps {
    relationDetail?: RelationDetail;
    loading: boolean;
    error?: any;
    role: string;
}

const useStyles = makeStyles(theme => ({
    subtitle: {
        color: "gray",
        fontSize: "14pt",
        marginBottom: "5px",
        marginTop: "5px"
    },
    divider: {
        marginTop: "25px",
        marginBottom: "20px"
    },
    buttonMobile: {
        marginBottom: "1em",
    }
}))

const RelationDetailComponent = ({ relationDetail, loading, role }: RelationDetailProps) => {
    const classes = useStyles()
    return (
        <div style={{ marginBottom: "10px" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card elevation={2} >
                        <CardHeader button buttonLink='/wijzig-bedrijfs-gegevens' buttonText='BEDRIJFSGEGEVENS WIJZIGEN' text={role === "COMPANY" ? "Bedrijfsinformatie" : "Particulier informatie"} />
                        <CardContent>
                            {loading &&
                                renderSkeleton(12, { xs: 5 }, 10, "text")
                            }
                            {(!loading && relationDetail) &&
                                role === "COMPANY" ?
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Bedrijfsgegevens</p></Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Bedrijfsnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.companyName}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Straatnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.address}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Huisnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.houseNumber}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Postcode</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.zipCode}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Plaats</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.place}</Grid>
                                    </Grid>
                                    <Divider className={classes.divider} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Contactgegevens</p></Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Geslacht</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.sex === 'M' ? "Vrouw" : "Man"}</Grid>
                                        {relationDetail.initials && <><Grid item xs={5} md={4} className="course-first-column">Voorletters</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.initials}</Grid></>}
                                        <Grid item xs={5} md={4} className="course-first-column">Voornaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.firstName}</Grid>
                                        {relationDetail.prefix && <><Grid item xs={5} md={4} className="course-first-column">Tussenvoegsel</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.prefix}</Grid></>}
                                        <Grid item xs={5} md={4} className="course-first-column">Achternaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.lastName}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">E-mail 1</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.email}</Grid>
                                        {relationDetail.contactEmail && <><Grid item xs={5} md={4} className="course-first-column">E-mail 2</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.contactEmail}</Grid></>}
                                        <Grid item xs={5} md={4} className="course-first-column">Telefoon</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.workNumber}</Grid>
                                        {relationDetail.mobileNumber && <><Grid item xs={5} md={4} className="course-first-column">Telefoon mobiel</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.mobileNumber}</Grid></>}
                                    </Grid>
                                    <Divider className={classes.divider} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Facturatiegegevens </p></Grid>
                                        {relationDetail.khnId && <><Grid item xs={5} md={4} className="course-first-column">KHN accountnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.khnId}</Grid></>}
                                        {relationDetail.invoiceEmail && <><Grid item xs={5} md={4} className="course-first-column">E-mail</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceEmail}</Grid></>}
                                        <Grid item xs={5} md={4} className="course-first-column">Factuur per post</Grid> <Grid item xs={7} md={6} className="course-second-column">{!!relationDetail.byMail ? "Ja" : "Nee"}</Grid>
                                        {relationDetail.byMail && <>
                                            <Grid item xs={5} md={4} className="course-first-column">Straatnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceAddress}</Grid>
                                            <Grid item xs={5} md={4} className="course-first-column">Huisnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceHouseNumber}</Grid>
                                            <Grid item xs={5} md={4} className="course-first-column">Postcode</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceZipCode}</Grid>
                                            <Grid item xs={5} md={4} className="course-first-column">Plaats</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoicePlace}</Grid>
                                        </>}
                                    </Grid>
                                </div>
                                : (!loading && relationDetail) &&
                                <div>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Contactgegevens</p></Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Bedrijfsnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.companyName}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Geslacht</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.sex === 'M' ? "Vrouw" : "Man"}</Grid>
                                        {relationDetail.initials && <><Grid item xs={5} md={4} className="course-first-column">Voorletters</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.initials}</Grid></>}
                                        <Grid item xs={5} md={4} className="course-first-column">Voornaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.firstName}</Grid>
                                        {relationDetail.prefix && <><Grid item xs={5} md={4} className="course-first-column">Tussenvoegsel</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.prefix}</Grid></>}                                        
                                        <Grid item xs={5} md={4} className="course-first-column">Achternaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.lastName}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">E-mail 1</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.email}</Grid>
                                        {relationDetail.contactEmail && <><Grid item xs={5} md={4} className="course-first-column">E-mail 2</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.contactEmail}</Grid></>}                                        
                                        <Grid item xs={5} md={4} className="course-first-column">Telefoon</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.workNumber}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Telefoon mobiel</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.mobileNumber}</Grid>
                                    </Grid>
                                    <Divider className={classes.divider} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Adresgegevens</p></Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Straatnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.address}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Huisnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.houseNumber}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Postcode</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.zipCode}</Grid>
                                        <Grid item xs={5} md={4} className="course-first-column">Plaats</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.place}</Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Facturatiegegevens </p></Grid>
                                        {relationDetail.khnId && <><Grid item xs={5} md={4} className="course-first-column">KHN accountnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.khnId}</Grid></>}
                                        {relationDetail.invoiceEmail && <><Grid item xs={5} md={4} className="course-first-column">E-mail</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceEmail}</Grid></>}
                                        <Grid item xs={5} md={4} className="course-first-column">Factuur per post</Grid> <Grid item xs={7} md={6} className="course-second-column">{!!relationDetail.byMail ? "Ja" : "Nee"}</Grid>
                                        {relationDetail.byMail && <>
                                            <Grid item xs={5} md={4} className="course-first-column">Straatnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceAddress}</Grid>
                                            <Grid item xs={5} md={4} className="course-first-column">Huisnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceHouseNumber}</Grid>
                                            <Grid item xs={5} md={4} className="course-first-column">Postcode</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoiceZipCode}</Grid>
                                            <Grid item xs={5} md={4} className="course-first-column">Plaats</Grid> <Grid item xs={7} md={6} className="course-second-column">{relationDetail.invoicePlace}</Grid>
                                        </>}
                                    </Grid>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div >
    );
};

export default RelationDetailComponent;
