import { PaymentActions } from "./types";
import { PaymentState } from "../../types/payment";

const initialState: PaymentState = {
    issuers: [],
    loading: false,
    error: undefined,
};

export function paymentReducer(state = initialState, action: typeof PaymentActions): PaymentState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case PaymentActions.GET_ISSUERS_REQUEST:
            return {
                ...state,
                loading: true,
                issuers: []
            };
        case PaymentActions.GET_ISSUERS_SUCCESS:
            return {
                ...state,
                loading: false,
                issuers: action.issuersList
            };
        case PaymentActions.GET_ISSUERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}