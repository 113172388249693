import { getWebsite } from "./getWebsite";
import { Website } from "../types/enum";

export const getLogo = (): string => {
    switch (getWebsite()) {
        case Website.HSN:
            return "../assets/img/hsn/hsn.png";
        case Website.HOC:
            return "../assets/img/hoc/hoc.png";
        case Website.SH:
            return "../assets/img/sh/sh.png";
        default:
            return "../assets/img/hsn/hsn.png";
    }
}