import React, { useEffect } from "react"
import { connect } from "react-redux";
import { RouterState } from "connected-react-router";
import { bindActionCreators, Dispatch } from "redux";

import { setRegisterStudent, addToShoppingCart, editShoppingCartItemCourse, getAddress, emptyRegisterStudent, addShoppingCartStudent, editShoppingCartStudent, removeShoppingCartStudent, removeShoppingCartStudents } from "../../store/register/actions";
import { RegisterState } from "../../types/register";
import { RegisterCourseState, RegisterCourse } from "../../types/registerCourse";
import EditShoppingcartComponent from "./EditShoppingcartComponent";
import { AuthenticationState } from "../../types/auth";
import { getStudents } from "../../store/student/actions";
import { StudentState, StudentDetail } from "../../types/student";
import { isNil } from "lodash";

interface EditShoppingcartContainerProps {
    registerCourse: RegisterCourseState;
    register: RegisterState;
    auth: AuthenticationState;
    student: StudentState;
    router: RouterState;
    openCourse: boolean;
    openCheckoutItemIndex: number | undefined;
    itemIndex: number

    getStudents: () => void;
    setOpenCourse: (openCourse: boolean) => void;
    setOpenCheckoutItemIndex: (openCheckoutItemIndex: number | undefined) => void;

    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    emptyRegisterStudent: () => void;
    setRegisterStudent: (student: StudentDetail) => void;

    addShoppingCartStudent: (itemIndex: number, student: StudentDetail) => void;
    editShoppingCartStudent: (itemIndex: number, studentIndex: number, student: StudentDetail) => void;
    removeShoppingCartStudent: (itemIndex: number, studentIndex: number) => void;
    removeShoppingCartStudents: (itemIndex: number) => void;
    editShoppingCartItemCourse: (index: number, course: RegisterCourse) => void;
}

const mapStateToProps = ({ registerCourse, register, auth, student, router }: EditShoppingcartContainerProps) => {
    return ({
        registerCourse,
        register,
        auth,
        student,
        router
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getAddress,
        emptyRegisterStudent,
        getStudents,
        addToShoppingCart,
        setRegisterStudent,
        editShoppingCartItemCourse,
        addShoppingCartStudent,
        editShoppingCartStudent,
        removeShoppingCartStudent,
        removeShoppingCartStudents,
    }, dispatch);
};

const EditShoppingcartContainer = ({
    getAddress,
    emptyRegisterStudent,
    getStudents,
    registerCourse,
    register,
    student,
    router,
    openCourse,
    openCheckoutItemIndex,
    setOpenCheckoutItemIndex,
    setOpenCourse,
    setRegisterStudent,
    itemIndex,
    addShoppingCartStudent,
    editShoppingCartStudent,
    removeShoppingCartStudent,
    editShoppingCartItemCourse,
    auth,
}: EditShoppingcartContainerProps) => {
    useEffect(() => {
        getStudents();
    }, [getStudents]);

    return (
        <div>
            <EditShoppingcartComponent
                hasBookDiscount={registerCourse.course.type?.hasBookDiscount || false}
                priceIncludesBook={!isNil(registerCourse.course.type?.priceIncludesBook) ? registerCourse.course.type?.priceIncludesBook : true}
                getAddress= {getAddress}
                setRegisterStudent={setRegisterStudent}
                emptyRegisterStudent={emptyRegisterStudent}
                itemIndex={itemIndex}
                checkoutItems={register.shoppingCart}
                student={student}
                loading={false}
                pathname={router.location.pathname}
                registerCourse={registerCourse}
                openCourse={openCourse}
                openCheckoutItemIndex={openCheckoutItemIndex}
                setOpenCheckoutItemIndex={setOpenCheckoutItemIndex}
                setOpenCourse={(openCourse) => {
                    editShoppingCartItemCourse(itemIndex, registerCourse.course);
                    setOpenCourse(openCourse);
                }}
                addShoppingCartStudent={addShoppingCartStudent}
                editShoppingCartStudent={editShoppingCartStudent}
                removeShoppingCartStudent={removeShoppingCartStudent}
                auth={auth}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditShoppingcartContainer)
