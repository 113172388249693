import { QuestionState, Question } from "../../types/question";
import { QuestionActions } from "./types";

const initialState: QuestionState = {
    questions: [],
    loading: false,
    error: undefined
};

export function questionReducer(state = initialState, action: typeof QuestionActions): QuestionState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case QuestionActions.QUESTIONS_BY_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case QuestionActions.QUESTIONS_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                questions: action.response ? action.response.map((question: Question) => {
                    return { ...question, loading: false }
                }) : [],
                loading: false,
                error: undefined
            };
        case QuestionActions.QUESTIONS_BY_CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case QuestionActions.CREATE_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case QuestionActions.CREATE_QUESTION_SUCCESS:
            return {
                ...state,
                questions: [...state.questions, action.response],
                loading: false,
                error: undefined
            };
        case QuestionActions.CREATE_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case QuestionActions.UPDATE_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case QuestionActions.UPDATE_QUESTION_SUCCESS:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.question.questionId ? action.question : question) : [],
                loading: false,
                error: undefined
            };
        case QuestionActions.UPDATE_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case QuestionActions.DELETE_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case QuestionActions.DELETE_QUESTION_SUCCESS:
            return {
                ...state,
                questions: state.questions ? state.questions.filter(question => question.questionId !== action.questionId) : [],
                loading: false,
                error: undefined
            };
        case QuestionActions.DELETE_QUESTION_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case QuestionActions.CREATE_ANSWER_REQUEST:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.questionId ? { ...question, loading: true } : question) : [],
                error: undefined
            };
        case QuestionActions.CREATE_ANSWER_SUCCESS:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.response?.questionId ? { ...question, loading: false, possibleAnswers: [...question.possibleAnswers, action.response] } : question) : [],
                error: undefined
            };
        case QuestionActions.CREATE_ANSWER_ERROR:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.questionId ? { ...question, loading: false } : question) : [],
                error: action.error
            };
        case QuestionActions.UPDATE_ANSWER_REQUEST:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.questionId ? { ...question, loading: true } : question) : [],
                error: undefined
            };
        case QuestionActions.UPDATE_ANSWER_SUCCESS:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.response?.questionId ? { ...question, loading: false, possibleAnswers: question.possibleAnswers ? question.possibleAnswers.map(answer => answer.answerId === action.response?.answerId ? action.response : answer) : [] } : question) : [],
                error: undefined
            };
        case QuestionActions.UPDATE_ANSWER_ERROR:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.questionId ? { ...question, loading: false } : question) : [],
                error: action.error
            };
        case QuestionActions.DELETE_ANSWER_REQUEST:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.questionId ? { ...question, loading: true } : question) : [],
                error: undefined
            };
        case QuestionActions.DELETE_ANSWER_SUCCESS:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.response?.questionId ? { ...question, loading: false, possibleAnswers: question.possibleAnswers ? question.possibleAnswers.filter(answer => answer.answerId !== action.response?.answerId) : [] } : question) : [],
                error: undefined
            };
        case QuestionActions.DELETE_ANSWER_ERROR:
            return {
                ...state,
                questions: state.questions ? state.questions.map(question => question.questionId === action.questionId ? { ...question, loading: false } : question) : [],
                error: action.error
            };
        default:
            return state;
    }
}
