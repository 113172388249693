import React from "react";
import { InjectedFormProps, reduxForm, Field, getFormValues } from "redux-form";
import { Grid, Paper, Typography, Button, makeStyles, Theme, Divider, MenuItem } from "@material-ui/core";

import { useButtonStyles } from "../../styles/button";
import { useTextStyles } from "../../styles/text";
import { required, isEmail, isPhone, validString, validDate } from "../../validators/form";
import RenderDatePicker from "../TextField/RenderDatePicker";
import RenderTextField from "../TextField";
import { StudentState, StudentDetail } from "../../types/student";
import Error from "../FeedBack/Error";
import { LoadingContentInfoSmall } from "../FeedBack/LoadingContent";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import AutofillComponent from "../../helperfunctions/AutofillComponent";
import { trimFields } from "../../helperfunctions/trimFields";
import { useHistory } from "react-router";
import CustomPrompt from "../notification/CustomPrompt";
import UserDataTooltip from "../../helperfunctions/UserDataTooltip";
import RenderTextFieldDropdown from "../TextField/TextFieldDropdown";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    }
}));

interface StudentAddComponentProps {
    formValues: any;
    student: StudentState;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    createNewStudent: (newStudent: StudentDetail) => void;
}

const StudentAddComponent = ({ valid, formValues, getAddress, reset, handleSubmit, student, createNewStudent }: StudentAddComponentProps & InjectedFormProps<StudentDetail, StudentAddComponentProps>) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const textClasses = useTextStyles();
    const history = useHistory();

    return (
        <form onSubmit={handleSubmit((studentDetail: StudentDetail): void => {
            studentDetail = trimFields(studentDetail)
            createNewStudent(studentDetail);
            reset();
        })}>
            <CustomPrompt when={!valid} />
            <Grid container direction="column">
                <Grid item xs={12} className={classes.title}>
                    <Typography classes={{
                        root: `${textClasses.pageTitle}`
                    }}>Nieuwe medewerker</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            {!student.error && <>
                                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                    <Typography variant="h6">Persoonsgegevens  <UserDataTooltip /></Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Grid container direction="column" justify="center">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>GESLACHT</Typography>
                                        <Field
                                            name="sex"
                                            validate={[validString, required]}
                                            component={RenderTextFieldDropdown}
                                        >
                                            <MenuItem value={"H"} >De heer</MenuItem>
                                            <MenuItem value={"M"} >Mevrouw</MenuItem>
                                        </Field>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORLETTERS</Typography>
                                    <Field
                                        name="initials"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORNAAM</Typography>
                                    <Field
                                        name="firstName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TUSSENVOEGSEL</Typography>
                                    <Field
                                        name="prefix"
                                        variant="outlined"
                                        placeholder="Optioneel"
                                        validate={[validString]}
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>ACHTERNAAM</Typography>
                                    <Field
                                        name="lastName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>GEBOORTEDATUM</Typography>
                                    <Field
                                        name="birthDate"
                                        placeholder="Verplicht"
                                        validate={[validString, required, validDate]}
                                        component={RenderDatePicker}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>GEBOORTEPLAATS</Typography>
                                    <Field
                                        name="birthPlace"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                    <Typography variant="h6">Contactgegevens</Typography>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="textfield-spacing textfield-spacing-h">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>EMAIL</Typography>
                                        <Field
                                            name="email"
                                            variant="outlined"
                                            validate={[validString, required, isEmail]}
                                            placeholder="Verplicht"
                                            component={RenderTextField}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="textfield-spacing textfield-spacing-h">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>MOBIEL</Typography>
                                        <Field
                                            name="mobileNumber"
                                            variant="outlined"
                                            validate={[validString, isPhone, required]}
                                            placeholder="Verplicht"
                                            component={RenderTextField}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <div className="textfield-spacing textfield-spacing-h">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>TELEFOON PRIVÉ</Typography>
                                        <Field
                                            name="privateNumber"
                                            variant="outlined"
                                            validate={[validString, isPhone]}
                                            placeholder="Optioneel"
                                            component={RenderTextField}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                    <Typography variant="h6">Adresgegevens</Typography>
                                    <Divider />
                                </Grid>
                                <AutofillComponent identifier="EDIT_STUDENT" formValues={formValues} getAddress={getAddress} />
                                <Grid container justify="space-between">
                                    <Button
                                        onClick={() => history.push('/medewerkers')}
                                        classes={{
                                            root: buttonClasses.primary,
                                        }}
                                    >
                                        Annuleren</Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!valid}
                                        classes={{
                                            root: buttonClasses.primary,
                                        }}
                                    >
                                        Nieuwe medewerker opslaan</Button>
                                </Grid>
                            </>}
                            {(student.loading) &&
                                <LoadingContentInfoSmall />
                            }
                            {student.error && <Grid container>
                                <Error />
                            </Grid>}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </form>
    );
};

const DecoratedInitializeFromStateFormFunction = reduxForm<StudentDetail, StudentAddComponentProps>({
    form: "studentAddDataForm",
    enableReinitialize: true,
})(StudentAddComponent);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let studentDetail = state.student.studentDetail;
        trimFields(studentDetail);

        return ({
            formValues: getFormValues("studentAddDataForm")(state),
            initialValues: studentDetail,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;
