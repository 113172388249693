import React, { useEffect } from "react"
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RouteComponentProps } from "react-router";

import { getTestExamById, addTestExamAnswer, resetTestExamAnswers, checkTestExam } from "../../store/testExams/actions";
import { ApplicationState } from "../../store";
import TestExamComponent from "./TestExamComponent";
import { SampleExamState, SampleExamFilledInput } from "../../types/sampleExam";
import { QuestionFilledInput } from "../../types/question";

interface TestExamContainerProps {
	testExams: SampleExamState;
	getTestExamById: (sampleExamId: number, projectId: number) => void;
	addTestExamAnswer: (givenAnswerId: number, questionId: number) => void;
	resetTestExamAnswers: () => void;
	checkTestExam: (sampleExam: SampleExamFilledInput) => void;
};

const mapStateToProps = ({ testExams }: ApplicationState) => {
	return {
		testExams
	}
};

const mapDispatchToProps = (dispatch: Dispatch) => {
	return bindActionCreators({ getTestExamById, addTestExamAnswer, resetTestExamAnswers, checkTestExam }, dispatch);
};

interface MatchParams {
	course?: string
	sampleExamId?: string
}

const TestExamContainer = ({ match, testExams, getTestExamById, addTestExamAnswer, resetTestExamAnswers, checkTestExam }: TestExamContainerProps & RouteComponentProps<MatchParams>) => {
	const sampleExamId = match.params.sampleExamId;
	const projectId = testExams.projectId;
	const course = match.params.course;

	useEffect(() => {
		sampleExamId && projectId && getTestExamById(parseInt(sampleExamId), projectId);
	}, [getTestExamById, sampleExamId, projectId]);

	const getTestExamResult = (sampleExamId: number, answers: QuestionFilledInput[]) => {
		const curinfoId = testExams.examDetail?.curinfoId;
		if (!curinfoId) return;

		const sampleExam: SampleExamFilledInput = {
			sampleExamId,
			curinfoId,
			questions: answers
		}
		checkTestExam(sampleExam)
	}

	return (
		<TestExamComponent
			addTestExamAnswer={addTestExamAnswer}
			getTestExamResult={() => sampleExamId && getTestExamResult(parseInt(sampleExamId), testExams.examAnswers)}
			testExamResult={testExams.examResult}
			resetAnswers={() => resetTestExamAnswers()}
			course={course}
			examAnswers={testExams.examAnswers}
			sampleExam={testExams.examDetail}
			loading={testExams.loading}
			error={testExams.error}
		/>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(TestExamContainer);