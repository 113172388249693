import { createActionsWithPrefix } from "../../helperfunctions/action";

export const CourseActions = createActionsWithPrefix("COURSE/", {
    GET_COURSE_DETAIL_REQUEST: "GET_COURSE_DETAIL_REQUEST",
    GET_COURSE_DETAIL_SUCCESS: "GET_COURSE_DETAIL_SUCCESS",
    GET_COURSE_DETAIL_ERROR: "GET_COURSE_DETAIL_ERROR",
    SET_CURRENT_USER: "SET_CURRENT_USER",
    GET_PARTICIPATINGSTUDENTS_REQUEST: "GET_PARTICIPATINGSTUDENTS_REQUEST",
    GET_PARTICIPATINGSTUDENTS_SUCCESS: "GET_PARTICIPATINGSTUDENTS_SUCCESS",
    GET_PARTICIPATINGSTUDENTS_ERROR: "GET_PARTICIPATINGSTUDENTS_ERROR",
    GET_COURSE_ASSIGNMENTS_REQUEST: "GET_COURSE_ASSIGNMENTS_REQUEST",
    GET_COURSE_ASSIGNMENTS_SUCCESS: "GET_COURSE_ASSIGNMENTS_SUCCESS",
    GET_COURSE_ASSIGNMENTS_ERROR: "GET_COURSE_ASSIGNMENTS_ERROR",
    GET_COURSE_GOOGLE_ANALYTICS_REQUEST: "GET_COURSE_GOOGLE_ANALYTICS_REQUEST",
    GET_COURSE_GOOGLE_ANALYTICS_SUCCESS: "GET_COURSE_GOOGLE_ANALYTICS_SUCCESS",
    GET_COURSE_GOOGLE_ANALYTICS_ERROR: "GET_COURSE_GOOGLE_ANALYTICS_ERROR",
    EDIT_COURSE_GOOGLE_ANALYTICS_REQUEST: "EDIT_COURSE_GOOGLE_ANALYTICS_REQUEST",
    EDIT_COURSE_GOOGLE_ANALYTICS_SUCCESS: "EDIT_COURSE_GOOGLE_ANALYTICS_SUCCESS",
    EDIT_COURSE_GOOGLE_ANALYTICS_ERROR: "EDIT_COURSE_GOOGLE_ANALYTICS_ERROR",
    DELETE_COURSE_GOOGLE_ANALYTICS_REQUEST: "DELETE_COURSE_GOOGLE_ANALYTICS_REQUEST",
    DELETE_COURSE_GOOGLE_ANALYTICS_SUCCESS: "DELETE_COURSE_GOOGLE_ANALYTICS_SUCCESS",
    DELETE_COURSE_GOOGLE_ANALYTICS_ERROR: "DELETE_COURSE_GOOGLE_ANALYTICS_ERROR"
});