import React, { useState } from "react"
import { Grid, Typography, AccordionDetails, withStyles, Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion"
import { Field, reduxForm, InjectedFormProps, getFormValues } from "redux-form";
import { connect } from "react-redux";

import TextFieldBar from "../TextField/TextFieldBar";
import { PASSWORD_DIFFERENT, REQUIRED_FIELD, PASSWORD_TOO_WEAK } from "../../constants/errors";
import { passwordChecker } from "../../helperfunctions/passwordChecker";
import RenderTextFieldLogin from "../TextField/TextFieldLogin";
import { required, passwordStrongEnough, validString } from "../../validators/form";
import { ApplicationState } from "../../store";
import { getPortalName } from "../../helperfunctions/getPortalName";
import CustomPrompt from "../notification/CustomPrompt";

interface ChangePasswordFormData {
    oldPassword: string
    newPassword: string;
    repeatedPassword: string;
}

interface ChangePasswordProps {
    formValues: any;
    handleChangePassword: (newPassword: string, oldPassword: string) => void;
}

const validate = (values: any) => {
    const errors: any = {};
    const requiredFields = [
        "oldPassword",
        "newPassword",
        "repeatedPassword"
    ];
    if (values.newPassword && passwordChecker(values.newPassword) < 30) errors.repeatedPassword = PASSWORD_TOO_WEAK;
    if (
        values.newPassword !== values.repeatedPassword
    ) {
        errors.repeatedPassword = PASSWORD_DIFFERENT;
    }
    requiredFields && requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = REQUIRED_FIELD;
        }
    })

    return errors;
}

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .125)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, .125)",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
            minHeight: 56,
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const SettingsChangePassword = ({ valid, handleSubmit, handleChangePassword, formValues }: ChangePasswordProps & InjectedFormProps<ChangePasswordFormData, ChangePasswordProps>) => {
    const disabled: boolean = !valid || formValues?.newPassword !== formValues?.repeatedPassword;
    
    const alertData = (data: ChangePasswordFormData) => handleChangePassword(data.newPassword, data.oldPassword);
    const [ExpansionOpen, setExpansionOpen] = useState(false)

    return (
        <Grid style={{ marginTop: "2em" }} item xs={12}>
            <Accordion onChange={() => setExpansionOpen(ExpansionOpen ? false : true)} expanded={ExpansionOpen}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>Wachtwoord {getPortalName()} Veranderen</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form style={{ width: "100%" }} onSubmit={handleSubmit(alertData)}>
                        <CustomPrompt when={ExpansionOpen && disabled} />
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={2} className="course-first-column">Huidig Wachtwoord</Grid>
                                            <Grid item xs={12} md={10}>
                                                <Field
                                                    name="oldPassword"
                                                    type="password"
                                                    validate={[validString, required, passwordStrongEnough]}
                                                    component={RenderTextFieldLogin}
                                                    placeholder="Huidig wachtwoord"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={2} className="course-first-column">Nieuw Wachtwoord</Grid>
                                            <Grid item xs={12} md={10}>
                                                <Field
                                                    name="newPassword"
                                                    type="password"
                                                    component={RenderTextFieldLogin}
                                                    placeholder="Nieuw wachtwoord"
                                                    validate={[validString, required, passwordStrongEnough]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={2} className="course-first-column">Herhaal nieuw wachtwoord</Grid>
                                            <Grid item xs={12} md={10}>
                                                <Field
                                                    name="repeatedPassword"
                                                    type="password"
                                                    component={RenderTextFieldLogin}
                                                    placeholder="Herhaal nieuw wachtwoord"
                                                    validate={[validString, required, passwordStrongEnough]}
                                                />
                                                <Field
                                                    name="newPassword"
                                                    validate={[validString, required, passwordStrongEnough]}
                                                    component={TextFieldBar}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-end">
                                            <Button type="submit" variant="contained" color="secondary" disabled={disabled} >
                                                WIJZIGINGEN OPSLAAN
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

const DecoratedInitializeFromStateFormFunction = reduxForm<ChangePasswordFormData, ChangePasswordProps>({
    form: "loginForm",
    enableReinitialize: true,
    validate
})(SettingsChangePassword);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        return ({
            formValues: getFormValues("loginForm")(state),
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;