import { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import CourseComponent from "./CourseComponent";
import { ApplicationState } from "../../../store";
import { RouteComponentProps } from "react-router";
import { getAssignmentsByCourseId, uploadAssignment, editAssignment, getAssignmentFile, downloadAssignment, deleteAssignment } from "../../../store/assignment/actions";
import { AssignmentState, InputAssignment } from "../../../types/assignment";
import { SampleExamState } from "../../../types/sampleExam";
import { getCourseGoogleAnalytics, editCourseGoogleAnalytics, deleteCourseGoogleAnalytics } from "../../../store/course/actions";

interface CourseContainerProps {
    assignment: AssignmentState;
    testExams: SampleExamState;
    analytic: {
        analyticLink?: string;
        loadingAnalyticLink: boolean;
        errorAnalyticLink?: any;
    }
    getAssignmentsByCourseId: (courseId: string) => void;
    uploadAssignment: (assignment: InputAssignment) => void;
    editAssignment: (assignmentId: number, assignment: InputAssignment) => void;
    getAssignmentFile: (assignmentId: number) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
    deleteAssignment: (assignmentId: number) => void;
    getCourseGoogleAnalytics: (courseId: string) => void;
    editCourseGoogleAnalytics: (courseId: string, url: string) => void;
    deleteCourseGoogleAnalytics: (courseId: string) => void;
}

interface MatchParams {
    courseId: string;
}

const mapStateToProps = ({ assignment, testExams, course }: ApplicationState) => {
    return ({
        assignment,
        testExams,
        analytic: {
            analyticLink: course.analyticLink,
            loadingAnalyticLink: course.loadingAnalyticLink,
            errorAnalyticLink: course.error
        }
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getAssignmentsByCourseId,
        uploadAssignment,
        editAssignment,
        getAssignmentFile,
        downloadAssignment,
        deleteAssignment,
        getCourseGoogleAnalytics,
        editCourseGoogleAnalytics,
        deleteCourseGoogleAnalytics,
    }, dispatch);
};

const CourseContainer = ({
    assignment,
    testExams,
    analytic,
    match,
    getAssignmentsByCourseId,
    uploadAssignment,
    editAssignment,
    getAssignmentFile,
    downloadAssignment,
    deleteAssignment,
    getCourseGoogleAnalytics,
    editCourseGoogleAnalytics,
    deleteCourseGoogleAnalytics,
}: CourseContainerProps & RouteComponentProps<MatchParams>) => {
    const { courseId } = match.params;
    useEffect(() => {
        getAssignmentsByCourseId(courseId);
        getCourseGoogleAnalytics(courseId);
    }, [getAssignmentsByCourseId, getCourseGoogleAnalytics, courseId]);

    return (
        <CourseComponent
            courseId={courseId}
            analytic={analytic}
            assignment={assignment}
            testExams={testExams}
            uploadAssignment={uploadAssignment}
            editAssignment={editAssignment}
            getAssignmentFile={getAssignmentFile}
            downloadAssignment={downloadAssignment}
            deleteAssignment={deleteAssignment}
            editCourseGoogleAnalytics={editCourseGoogleAnalytics}
            deleteCourseGoogleAnalytics={deleteCourseGoogleAnalytics}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseContainer);