import React from "react";
import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";

import { getPayment } from "../../../helperfunctions/getPaymentMethod";
import { parseMoney } from "../../../helperfunctions/money";
import { Issuer } from "../../../types/payment";
import { RegisterPriceResponse } from "../../../types/register";
import Details from "../../molecules/Details";

const useStyles = makeStyles((theme: Theme) => ({
    data: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        backgroundColor: "#f0f0f0"
    },
    dataText: {
        display: "flex",
        alignItems: "center"
    },
    row: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    subtitle: {
        marginTop: theme.spacing(2),
    }
}));

interface ConfirmationPaymentProps {
    prices: RegisterPriceResponse[];
    paymentMethod: string;
    discountString?: string;
    issuer?: Issuer;
    note: string;
    remark?: string;
    paymentFeature?: string;
}

const ConfirmationPayment = ({ prices, paymentMethod, discountString, note, remark, paymentFeature, issuer }: ConfirmationPaymentProps ) => {
    const classes = useStyles();
    const totalPrice = (prices && prices.length > 0 && prices.reduce((a, b) => ({ price: (a.price || 0) + (b.price || 0) })).price) || 0;
    const discountInfo = (prices && prices.length > 0 && prices.map(p => p.discountInfo?.toString() || "").reduce((a, b) => a + b)) || "";

    const paymentData = [
        { title: "Overig", elements: [
            { name: "Gevonden via", data: note },
            { name: "Opmerking(en)", data: remark },
            { name: "Betalingskenmerk / ordernummer", data: paymentFeature },
        ] }
    ]

    return <>
        <Grid container className={classes.data}>
            <Grid item xs={12} className={classes.subtitle}>
                <Typography variant="h6">Betalingsgegevens</Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <Typography variant="body2" style={{ fontWeight: "bold" }}>Betaalmethode</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.dataText}>
                        <Typography variant="body2">{getPayment(paymentMethod)}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {paymentMethod === "I" && issuer && issuer.issuerName &&
                <Grid item xs={12} className={classes.row}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Typography variant="body2" style={{ fontWeight: "bold" }}>Bank</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.dataText}>
                            <Typography variant="body2">{issuer.issuerName}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {discountString &&
                <Grid item xs={12} className={classes.row}>
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Typography variant="body2" style={{ fontWeight: "bold" }}>Korting</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.dataText}>
                            <Typography variant="body2">{discountString}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid container direction="column" justify="center" alignItems="flex-end" style={{ marginTop: '1em' }}>
                <Typography variant="subtitle1">Totaal: <b>{parseMoney(totalPrice)}</b></Typography>
                <Typography variant="body1">{discountInfo}</Typography>                
            </Grid>
        </Grid>
        <Details data={paymentData} />
    </>
};

export default ConfirmationPayment;