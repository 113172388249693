import { makeStyles } from "@material-ui/styles";
import { Theme, createStyles } from "@material-ui/core";

export const useTextStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageTitle: {
            fontSize: "30pt",
            fontWeight: "bolder",
            color: "gray",
            [theme.breakpoints.up('sm')]:{
                fontSize: "36pt"
            }
        },
        label: {
            fontSize: "12px",
            fontWeight: "bold",
            "& $label": {
                color: "#fff"
            },
        },
        subLabel: {
            fontSize: "11px",
            fontWeight: "lighter",
        },
        text: {
            fontSize: "12x",
            fontWeight: "lighter",
        },
        button: {
            fontSize: "12px",
            fontWeight: "bold"
        },
        light: {
            color: theme.palette.text.secondary
        },
        main: {
            color: theme.palette.primary.main
        },
        medium: {
            color: "#949494"
        },
        dark: {
            color: theme.palette.text.primary
        }
    })
);