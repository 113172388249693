import { authenticationService } from "./auth.service";
import { getToken, getRefreshToken } from "../helperfunctions/localstorage";
import environment from "../config/environment"
import { store } from "../components/Root";
import { AuthActions } from "../store/auth/types";

const graphqlHelperService = {
    sendMutate: async function (mutation: string, ignoreRefreshToken?: boolean): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const refreshToken = getRefreshToken();

            const success = async (response: any, secondTime: boolean) => {
                if (!secondTime && !ignoreRefreshToken) {
                    if (response.errors && response.errors.some((error: { extensions: { code: string } }) => error.extensions.code === "UNAUTHENTICATED")) {
                        const valid = refreshToken ? await authenticationService.checkRefreshToken(refreshToken) : false;
                        if ((localStorage.getItem("remember") === 'true') && refreshToken && valid) {
                            await authenticationService.refreshToken(refreshToken);
                            fetchUrl(true);
                        } else {
                            localStorage.setItem('remember', 'false');
                            store.dispatch({ type: AuthActions.LOGOUT_SUCCESS });
                        }
                    } else if (response.errors) {
                        reject(new Error(response.errors[0].message));
                    } else {
                        resolve(response.data);
                    }
                } else if (response.errors) {
                    reject(new Error(response.errors[0].message));
                } else {
                    resolve(response.data);
                }
            };

            const failure = async (error: any) => {
                reject(new Error(error));
            };

            const fetchUrl = (secondTime=false) => {
                const token = getToken();

                fetch(environment.url, {
                    method: 'POST',
                    headers: new Headers({
                        "authorization": authenticationService.getAuthHeader(token ? token : ""),
                        "Content-Type": "application/json"
                    }),
                    body: JSON.stringify({ query: mutation })
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((response) => success(response, secondTime))
                    .catch(failure)
            };

            fetchUrl();
        });
    },

    sendQuery: function (query: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const refreshToken = getRefreshToken();
            
            const success = async (response: any, secondTime: boolean) => {
                if (!secondTime && response.errors && response.errors.some((error: { extensions: { code: string } }) => error.extensions.code === "UNAUTHENTICATED")) {
                    const valid = refreshToken ? await authenticationService.checkRefreshToken(refreshToken) : false;
                    if ((localStorage.getItem("remember") === 'true') && refreshToken && valid) {
                        await authenticationService.refreshToken(refreshToken);
                        fetchUrl(true);
                    } else {
                        localStorage.setItem('remember', 'false');
                        store.dispatch({ type: AuthActions.LOGOUT_SUCCESS });
                    }
                } else if (response.errors) {
                    reject(new Error(response.errors[0].message));
                } else {
                    resolve(response.data);
                }
            };

            const failure = async (error: any) => {
                reject(new Error(error));
            };

            const fetchUrl = (secondTime=false) => {
                const token = getToken();

                fetch(environment.url, {
                    method: 'POST',
                    headers: new Headers({
                        "authorization": authenticationService.getAuthHeader(token ? token : ""),
                        "Content-Type": "application/json"
                    }),
                    body: JSON.stringify({ query })
                })
                    .then((response) => {
                        return response.json()
                    })
                    .then((response) => success(response, secondTime))
                    .catch(failure)
            };

            fetchUrl();
        });
    }
}

Object.freeze(graphqlHelperService);
export default graphqlHelperService;