import { createActionsWithPrefix } from "../../helperfunctions/action";

export const AssignmentActions = createActionsWithPrefix("ASSIGNMENT/", {
    ASSIGNMENTS_BY_COURSE_REQUEST: "ASSIGNMENTS_BY_COURSE_REQUEST",
    ASSIGNMENTS_BY_COURSE_SUCCESS: "ASSIGNMENTS_BY_COURSE_SUCCESS",
    ASSIGNMENTS_BY_COURSE_ERROR: "ASSIGNMENTS_BY_COURSE_ERROR",
    DELETE_SUCCESS: "DELETE_SUCCESS",
    UPLOAD_REQUEST: "UPLOAD_REQUEST",
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
    UPLOAD_ERROR: "UPLOAD_ERROR",
    EDIT_REQUEST: "EDIT_REQUEST",
    EDIT_SUCCESS: "EDIT_SUCCESS",
    EDIT_ERROR: "EDIT_ERROR",
});