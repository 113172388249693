import React from "react"
import { Typography, Grid, Theme, useTheme, Button } from "@material-ui/core";
import { FaQuestion, FaClock } from "react-icons/fa";
import { Question } from "../../types/question";

interface TestExamStartProps {
    started: boolean
    setStarted: (started: boolean) => void;
    course?: string
    amountOfQuestions: number
    duration: number
    questions: Question[]
}

const TestExamStart = ({ amountOfQuestions, course, duration, setStarted, started, questions }: TestExamStartProps) => {
    const theme: Theme = useTheme();
    const startExam = () => {
        setStarted(!started)
    };

    return (
        <div>
            <Grid container justify="center" style={{ textAlign: "center" }}>
                <Grid style={{ marginTop: "2em" }} item xs={12}>
                    <Typography variant="h5">{course}</Typography>
                </Grid>
                {amountOfQuestions && duration &&
                    <Grid item xs={6}>
                        <Grid container spacing={2} style={{ marginTop: "2em" }} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body1"><FaQuestion color={theme.palette.secondary.main} style={{ marginRight: "1em" }} />{`${amountOfQuestions} vragen`} </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1"><FaClock color={theme.palette.secondary.main} style={{ marginRight: "1em" }} />{`${duration} minuten`}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {questions.length === 0 ? <Typography variant="body1">De vragen zijn niet goed opgehaald :(</Typography> :
                                    <Button onClick={() => startExam()} variant="contained" color="secondary">Starten</Button>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div>
    )
}

export default TestExamStart
