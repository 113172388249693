import graphqlHelperService from "./graphqlHelper.service";
import { CourseMetaData } from "../types/metaData";

export const metaDataService = {
    metaData: async (courseId: string): Promise<CourseMetaData> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            metaDataQuery(courseId)
        )
        return apolloResult.metaData as CourseMetaData;
    },
    updateMetaData: async (metaData: CourseMetaData): Promise<boolean> => {
        const apolloResult = await graphqlHelperService.sendMutate(
            updateMetaDataMutation(metaData)
        );
        return apolloResult.updateMetaData as boolean;
    },
}

const metaDataQuery = (courseId: string) => `query {
    metaData (courseId: "${courseId}") {
        courseId
        timeSpan
        frequency
        locations
        exam
        certificate
        rating
        homeworkSupport
        freeField1
        freeField1Caption
        freeField1Icon
        freeField2
        freeField2Caption
        freeField2Icon
    }
}`

const updateMetaDataMutation = (metaData: CourseMetaData) => `mutation{
    updateMetaData (
        metaData: {
            courseId: "${metaData.courseId}"
            ${metaData.timeSpan && metaData.timeSpan.trim() !== "" ? `timeSpan: "${metaData.timeSpan}"` : ``}
            ${metaData.frequency && metaData.frequency.trim() !== "" ? `frequency: "${metaData.frequency}"` : ``}
            ${metaData.locations && metaData.locations.trim() !== "" ? `locations: "${metaData.locations}"` : ``}
            ${metaData.exam && metaData.exam.trim() !== "" ? `exam: "${metaData.exam}"` : ``}
            ${metaData.certificate && metaData.certificate.trim() !== "" ? `certificate: "${metaData.certificate}"` : ``}
            ${metaData.rating && metaData.rating.trim() !== "" ? `rating: "${metaData.rating}"` : ``}
            ${metaData.freeField1 && metaData.freeField1.trim() !== "" ? `freeField1: "${metaData.freeField1}"` : ``}
            ${metaData.freeField1Caption && metaData.freeField1Caption.trim() !== "" ? `freeField1Caption: "${metaData.freeField1Caption}"` : ``}
            freeField1Icon: "${metaData.freeField1Icon}"
            ${metaData.freeField2 && metaData.freeField2.trim() !== "" ? `freeField2: "${metaData.freeField2}"` : ``}
            ${metaData.freeField2Caption && metaData.freeField2Caption.trim() !== "" ? `freeField2Caption: "${metaData.freeField2Caption}"` : ``}
            freeField2Icon: "${metaData.freeField2Icon}"
            homeworkSupport: ${metaData.homeworkSupport}
        }
    )
}`
