import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { CourseDetail } from "../../../../types/course";
import "../../../Courses/Course.css"

const useStyles = makeStyles((theme: Theme) => ({
    data: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        backgroundColor: "#f0f0f0"
    },
    dataText: {
        display: "flex",
        alignItems: "center"
    },
    row: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
}));

interface CourseProps {
    courseDetail: CourseDetail,
}

const Course = ({ courseDetail }: CourseProps) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.data}>
            <Grid item xs={12}>
                <Typography variant="h6">Cursusgegevens</Typography>
            </Grid>
            <Grid item xs={12} className={classes.row}>
                <Grid container direction="row">
                    <Grid item xs={12} md={3} className={classes.row}>
                        <Typography className={classes.row} style={{ fontWeight: "bold" }} variant="body2">Cursus</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.dataText}>
                        <Typography variant="body2">{courseDetail.category}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.row}>
                        <Typography className={classes.row} style={{ fontWeight: "bold" }} variant="body2">Type</Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.dataText}>
                        <Typography variant="body2">{courseDetail.type}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.row}>
                {courseDetail.address && courseDetail.place &&
                    <Grid container direction="row">
                        <Grid item xs={12} md={3}>
                            <Typography className={classes.row} style={{ fontWeight: "bold" }} variant="body2">Plaats</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.dataText}>
                            <Typography variant="body2">{courseDetail.address + " " + courseDetail.place}</Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {courseDetail.dates && courseDetail.dates.length > 0 &&
                <Grid item xs={12} className={classes.row}>
                    <Grid container>
                        <Grid xs={12} md={3}>
                            <Typography className={classes.row} style={{ fontWeight: "bold" }} variant="body2">Cursusplanning</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className={classes.dataText}>
                            <Grid container>
                                {courseDetail.dates.map((date) => (<Grid item xs={12} md={6} key={date}>
                                    <Typography variant="body2">Datum: {date}</Typography>
                                </Grid>))}
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

export default Course;