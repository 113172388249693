import React from "react"
import { CardContent, Card, Grid, Divider, makeStyles } from "@material-ui/core";
import CardHeader from "../UI/CardHeader";
import { LoadingContentInfoSmall } from "../FeedBack/LoadingContent";
import { StudentDetail } from "../../types/student";
import { Role } from "../../types/enum";

const useStyles = makeStyles(theme => ({
    subtitle: {
        color: "gray",
        fontSize: "14pt",
        marginBottom: "5px",
        marginTop: "5px"
    },
    divider: {
        marginTop: "25px",
        marginBottom: "20px"
    }
}))

interface StudentPersonalProps {
    studentDetail?: StudentDetail;
    loading: boolean;
    error?: any;
    role?: string;
}

const StudentPersonal = ({ studentDetail, loading, error, role }: StudentPersonalProps) => {
    const classes = useStyles()

    return (
        <div>
            <Card elevation={2} >
                {!(role === Role.ADMIN) ? <CardHeader button buttonLink='/wijzig-persoons-gegevens' buttonText='GEGEVENS WIJZIGEN' text="Persoonlijke informatie" /> :
                <CardHeader button buttonLink='/cursussen' buttonText='CURSUSSEN' text="Persoonlijke informatie" />}
                <CardContent>
                    {loading &&
                        Array(5)
                            .fill("")
                            .map((e, i) => (
                                <LoadingContentInfoSmall key={i}
                                />
                            ))
                    }
                    {(!loading && studentDetail) &&
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Persoonsgegevens</p></Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Geslacht</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.sex === 'M' ? "Vrouw" : "Man"}</Grid>
                                {studentDetail.initials && <><Grid item xs={5} md={4} className="course-first-column">Voorletters</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.initials}</Grid></>}
                                <Grid item xs={5} md={4} className="course-first-column">Naam</Grid> <Grid item xs={7} md={6} className="course-second-column">{`${studentDetail.firstName || studentDetail.initials} ${studentDetail.prefix ? studentDetail.prefix : ""} ${studentDetail.lastName}`}</Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Geboortedatum</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.birthDate}</Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Geboorteplaats</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.birthPlace}</Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Contactgegevens</p></Grid>
                                <Grid item xs={5} md={4} className="course-first-column">E-mailadres</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.email}</Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Telefoon mobiel</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.mobileNumber}</Grid>
                                {studentDetail.privateNumber && <><Grid item xs={5} md={4} className="course-first-column">Telefoon privé</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.privateNumber}</Grid></>}
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} className="course-first-column"><p className={classes.subtitle}>Adresgegevens</p></Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Straatnaam</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.address}</Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Huisnummer</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.houseNumber}</Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Postcode</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.zipCode}</Grid>
                                <Grid item xs={5} md={4} className="course-first-column">Plaats</Grid> <Grid item xs={7} md={6} className="course-second-column">{studentDetail.place}</Grid>
                            </Grid>
                        </div>
                    }
                </CardContent>
            </Card>
        </div>
    )
}

export default StudentPersonal
