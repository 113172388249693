import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { FormHelperText, Grid } from "@material-ui/core";
import { useInputStyles } from "../../styles/input";
import DateFnsUtils from '@date-io/date-fns';
import subYears from "date-fns/subYears";
import isValid from "date-fns/isValid";
import nl from "date-fns/locale/nl";
import parse from "date-fns/parse";
import { useState } from "react";

export interface TextFieldProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: any;
        warning: any;
    };
    custom: any
}

const getDate = (dateString: string) => {
    const parsedDate = parse(dateString, "dd-MM-yyyy", new Date());
    return isValid(parsedDate) ? parsedDate : null;
}

const RenderDatePicker = ({
    input: { value, onChange },
    label,
    meta: { error },
    ...custom
}: TextFieldProps) => {
    const root = useInputStyles().root;
    const [blurred, setBlurred] = useState<boolean>(false);
    const [inputError, setInputError] = useState<any>(null);
    error = inputError || error;

    return <Grid container justify="space-around">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nl}>
            <KeyboardDatePicker
                autoOk
                helperText=""
                error={(blurred && !!error)}
                onError={newError => setInputError(newError)}
                fullWidth
                className={root}
                style={{ borderRadius: 0, backgroundColor: "#fff" }}
                variant="inline"
                inputVariant="outlined"
                format="dd-MM-yyyy"
                invalidDateMessage="Ongeldige datum"
                minDate={subYears(new Date(), 90)}
                minDateMessage="Datum te lang geleden"
                maxDate={subYears(new Date(), 10)}
                maxDateMessage="Datum te recent"
                value={getDate(value)}
                inputValue={value}
                onBlur={() => !blurred && setBlurred(true)}
                onChange={(_, value) => onChange(value)}
                {...custom}
            />
        </MuiPickersUtilsProvider>
        {(blurred && !!error) && <FormHelperText error={!!error} style={{ marginRight: "auto" }}>{error}</FormHelperText>}
    </Grid>
}

export default RenderDatePicker;
