import React from "react"
import { Card, CardContent, Grid, makeStyles, Theme } from "@material-ui/core";

interface LogoBoxProps {
    logo: string;
    type?: "default" | "small-margin" | "top-right" | "fullscreen";
}

const useStylesDefault = makeStyles((theme: Theme) => ({
    cardContent: {
        marginTop: "8em",
        marginBottom: "8em"
    },
    cardLogo: {
        borderRadius: "0",
        backgroundColor: theme.palette.primary.main,
    },
    imgLogo: {
        width: "100%",
        height: "auto" 
    }
}));

const useStyleSmallMargins = makeStyles((theme: Theme) => ({
    cardContent: {
        marginTop: "1em",
        marginBottom: "3em"
    },
    cardLogo: {
        borderRadius: "0",
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none"
    },
    imgLogo: {
        width: "100%",
        height: "auto" 
    }
}));

const useStyleTopRight = makeStyles((theme: Theme) => ({
    cardContent: {
        marginTop: "0",
        marginBottom: "0"
    },
    cardLogo: {
        borderRadius: "0",
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none"
    },
    imgLogo: {
        width: "auto",
        height: "auto",
        maxHeight: "36pt"
    }
}));

const useStyleFullscreen = makeStyles((theme: Theme) => ({
    cardContent: {
        marginTop: "0",
        marginBottom: "0"
    },
    cardLogo: {
        borderRadius: "0",
        backgroundColor: theme.palette.primary.main,
        boxShadow: "none"
    },
    imgLogo: {
        width: "auto",
        height: "auto",
        maxWidth: "100%"
    }
}));

const LogoBox = ({ logo, type }: LogoBoxProps) => {
    const classesDefault = useStylesDefault();
    const classesSmallMargins = useStyleSmallMargins();
    const classesTopRight = useStyleTopRight();
    const classesFullscreen = useStyleFullscreen();

    let classes = classesDefault;
    if (type === "small-margin") {
        classes = classesSmallMargins;
    }
    else if (type === "top-right") {
        classes = classesTopRight;
    }
    else if (type === "fullscreen") {
        classes = classesFullscreen;
    }

    return (
        <div>
            <Card className={classes.cardLogo}>
                <CardContent className={classes.cardContent}>
                    <Grid container justify-content="center">
                        <Grid item lg={10} >
                            <img className={classes.imgLogo} src={logo} alt="logo" />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );
};

export default LogoBox;
