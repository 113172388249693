import React from "react";
import { Grid, Typography, Button, Dialog, DialogContent, DialogTitle, Divider, MenuItem } from "@material-ui/core";
import { reduxForm, Field, InjectedFormProps, getFormValues } from "redux-form";

import RenderTextField from "../../TextField";
import { required, isEmail, isPhone, validString, validDate } from "../../../validators/form";
import RenderDatePicker from "../../TextField/RenderDatePicker";
import { fillEmptyStudent } from "../../../helperfunctions/student";
import { useButtonStyles } from "../../../styles/button";
import { useTextStyles } from "../../../styles/text";
import RenderCheckBox from "../../TextField/RenderCheckBox";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import AutofillComponent from "../../../helperfunctions/AutofillComponent";
import { trimFields } from "../../../helperfunctions/trimFields";
import { StudentDetail } from "../../../types/student";
import CustomPrompt from "../../notification/CustomPrompt";
import UserDataTooltip from "../../../helperfunctions/UserDataTooltip";
import RenderTextFieldDropdown from "../../TextField/TextFieldDropdown";

interface AddNewStudentDialogProps {
    formValues: any;
    open: boolean;
    studentIndex: number;
    edit: boolean;
    onEdit: (student: StudentDetail, studentIndex: number) => void;
    getAddress: (houseNumber: string, zipCode: string, identifier: string, formValues: any) => void;
    handleClose: () => void;
    onAdd: (newRegisterStudent: StudentDetail) => void;
    hasBookDiscount: boolean;
    priceIncludesBook: boolean;
}

const AddNewStudentDialog = ({ valid, hasBookDiscount, open, handleClose, onAdd, handleSubmit, reset, submitting, formValues, getAddress, studentIndex, onEdit, edit, priceIncludesBook }: AddNewStudentDialogProps & InjectedFormProps<StudentDetail, AddNewStudentDialogProps>) => {
    const buttonClasses = useButtonStyles();
    const textClasses = useTextStyles();

    return (
        <div>
            <form>
                <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{edit ? "Wijzig nieuwe medewerker" : "Voeg een nieuwe medewerker toe"}</DialogTitle>
                    <DialogContent>
                        <CustomPrompt when={(open && !valid)} />
                        <Grid container>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Persoonsgegevens  <UserDataTooltip /></Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Grid container direction="column" justify="center">
                                        <Typography classes={{
                                            root: `${textClasses.label}`
                                        }}>GESLACHT</Typography>
                                        <Field
                                            name="sex"
                                            validate={[validString, required]}
                                            component={RenderTextFieldDropdown}
                                        >
                                            <MenuItem value={"H"} >De heer</MenuItem>
                                            <MenuItem value={"M"} >Mevrouw</MenuItem>
                                        </Field>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORLETTERS</Typography>
                                    <Field
                                        name="initials"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>VOORNAAM</Typography>
                                    <Field
                                        name="firstName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TUSSENVOEGSEL</Typography>
                                    <Field
                                        name="prefix"
                                        variant="outlined"
                                        placeholder="Optioneel"
                                        validate={[validString]}
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>ACHTERNAAM</Typography>
                                    <Field
                                        name="lastName"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>GEBOORTEDATUM</Typography>
                                    <Field
                                        name="birthDate"
                                        placeholder="Verplicht"
                                        validate={[validString, required, validDate]}
                                        component={RenderDatePicker}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>GEBOORTEPLAATS</Typography>
                                    <Field
                                        name="birthPlace"
                                        variant="outlined"
                                        validate={[validString, required]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Contactgegevens</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>EMAIL</Typography>
                                    <Field
                                        name="email"
                                        variant="outlined"
                                        validate={[validString, required, isEmail]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TELEFOON MOBIEL</Typography>
                                    <Field
                                        name="mobileNumber"
                                        variant="outlined"
                                        validate={[validString, required, isPhone]}
                                        placeholder="Verplicht"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Typography classes={{
                                        root: `${textClasses.label}`
                                    }}>TELEFOON PRIVÉ</Typography>
                                    <Field
                                        name="privateNumber"
                                        variant="outlined"
                                        validate={[validString, isPhone]}
                                        placeholder="Optioneel"
                                        component={RenderTextField}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "1em" }}>
                                <Typography variant="h6">Adresgegevens</Typography>
                                <Divider />
                            </Grid>
                            <AutofillComponent identifier="STUDENT" formValues={formValues} getAddress={getAddress} />
                            {hasBookDiscount && <Grid item xs={12} md={6}>
                                <div className="textfield-spacing textfield-spacing-h">
                                    <Field
                                        name="books"
                                        label={priceIncludesBook ? "Lesboek al in bezit?" : "Lesboek toevoegen"}
                                        component={RenderCheckBox}
                                    />
                                </div>
                            </Grid>}
                        </Grid>

                    </DialogContent>
                    <DialogTitle>
                    <Grid item xs={12}>
                        <Grid container justify="space-between">
                            <Button onClick={handleClose}>Annuleren</Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                type="submit"
                                disabled={submitting || !valid}
                                classes={{
                                    root: buttonClasses.primary,
                                }}
                                onClick={handleSubmit((data: StudentDetail): void => {
                                    let newStudent = fillEmptyStudent(data);
                                    newStudent = trimFields(newStudent);

                                    if (edit) {
                                        onEdit(newStudent, studentIndex);
                                    } else {
                                        onAdd(newStudent);
                                    }
                                    reset();
                                    handleClose();
                                })}
                            >
                                {edit ? "medewerker wijzigen" : "medewerker toevoegen"}
                            </Button>
                        </Grid>
                    </Grid>
                    </DialogTitle>
                </Dialog>
            </form>
        </div>
    );
};

const DecoratedInitializeFromStateFormFunction = reduxForm<StudentDetail, AddNewStudentDialogProps>({
    form: "studentDataForm",
    enableReinitialize: true,
})(AddNewStudentDialog);

const ConnectedDecoratedInitializeFromStateFormFunction = connect(
    (state: ApplicationState) => {
        let initialValues = state.register.registerStudent;
        initialValues = trimFields(initialValues);

        return ({
            formValues: getFormValues("studentDataForm")(state),
            initialValues: initialValues,
        })
    },
)(DecoratedInitializeFromStateFormFunction);

export default ConnectedDecoratedInitializeFromStateFormFunction;