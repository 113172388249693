export enum Role {
    ADMIN = "ADMIN",
    COMPANY = "COMPANY",
    FREELANCER = "FREELANCER",
    STUDENT = "STUDENT",
    NO_ROLE = "NO_ROLE",
};

export enum Website {
    HSN = "HSN",
    HOC = "HOC",
    SH = "SH",
}

export enum PortalPaymentMethod {
    All = "ALL",
    InvoiceOnly = "INVOICEONLY",
    IdealOnly = "IDEALONLY",
}

export enum PaymentMethod {
    Invoice = "INVOICE",
    Ideal = "IDEAL",
}

export enum RegisterUserType {
    Everyone = "Everyone",
    Company = "Company",
    Freelancer = "Freelancer"
}
