export const openFileInNewWindow = (b64data: string, title: string, extension: string) => {
    const blob = b64toBlob(b64data, extension);
    const url = window.URL.createObjectURL(blob);
    window.open(url);
}

export const downloadFile = (b64data: string, title: string, extension: string) => {
    const blob = b64toBlob(b64data, extension);
    if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        (window.navigator as any).msSaveBlob(blob, title + "." + extension);
    } else {
        const url = window.URL.createObjectURL(blob);
        let downloadHelper = document.createElement("a");
        downloadHelper.href = url;
        downloadHelper.download = title + "." + extension;
        document.body.appendChild(downloadHelper);
        downloadHelper.click();

        window.URL.revokeObjectURL(url);
        downloadHelper.remove();
    }
}

const b64toBlob = (b64data: string, extension: string) => {
    const sliceSize: number = 512;

    const byteCharacters = window.atob(b64data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const type = extension === "docx" ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf";
    const blob = new Blob(byteArrays, {type: type});
    return blob;
}

export const toBase64 = async (file: File): Promise<string> => {
    let content: string = await readFile(file);
    content = content.split(";base64,").pop() as string;
    return content;
}

export const readFile = (file: File) => new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});