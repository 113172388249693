import { getWebsite } from "./getWebsite";
import { Website } from "../types/enum";

export const getTitle = () => {
    switch (getWebsite()) {
        case Website.HSN:
            return "Horeca Stichting Nederland";
        case Website.SH:
            return "Sociale Hygiene";
        case Website.HOC:
            return "Horeca Opleidings Centrum";
        default:
            return "Horeca Stichting Nederland";
    }
}