import { StudentDetail } from "../../../../types/student";
import Details from "../../../molecules/Details";

interface StudentsProps {
    students: StudentDetail[]
}

const Students = ({ students }: StudentsProps) => {
    const header = { title: "Deelnemers", elements: [] };
    const studentData = students.map(({ firstName, initials, prefix, lastName, email, address, houseNumber, place }) => ({ title: "", elements: [
        { name: "Voornaam", data: firstName || initials },
        { name: "Achternaam", data: `${prefix ? prefix + " " : ""}${lastName}` },
        { name: "Email", data: email },
        { name: "adres", data: `${address} ${houseNumber}, ${place}` },
    ]}));

    return <Details data={[header, ...studentData]} />
};

export default Students;