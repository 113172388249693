import React, { useState, useEffect } from 'react';
import { Paper, Divider, IconButton, InputBase, Theme, useTheme } from '@material-ui/core';
import { FaTimes, FaGoogle } from 'react-icons/fa';
import { renderSkeleton } from '../../FeedBack/Skeleton';

interface GoogleAnalyticsProps {
    courseId: string;
    analyticLink?: string;
    loadingAnalyticLink: boolean;
    errorAnalyticLink?: any;
    editCourseGoogleAnalytics: (courseId: string, url: string) => void;
    deleteCourseGoogleAnalytics: (courseId: string) => void;
}

const GoogleAnalytics = ({ analyticLink, loadingAnalyticLink, errorAnalyticLink, courseId, editCourseGoogleAnalytics, deleteCourseGoogleAnalytics }: GoogleAnalyticsProps) => {
    const [link, setLink] = useState<string>(analyticLink || "");
    const theme: Theme = useTheme();

    useEffect(() => setLink(analyticLink || ""), [analyticLink]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        editCourseGoogleAnalytics(courseId, link);
    };

    const handleRemove = () => {
        deleteCourseGoogleAnalytics(courseId);
        setLink("");
    };

    return (
        <>
            {!errorAnalyticLink &&
                loadingAnalyticLink ?
                renderSkeleton(1, { xs: 12 }, 64, "rect")
                :
                <Paper style={{ display: "flex", width: "100%", padding: "1em", marginBottom: "1em", alignItems: "center" }}>
                    <FaGoogle style={{ marginLeft: ".5em", marginRight: "1em" }} />
                    <form onSubmit={(e) => handleSubmit(e)} style={{ width: "100%" }}>
                        <InputBase
                            name="link"
                            placeholder="Google Analytics link"
                            value={link}
                            onChange={({ target }) => setLink(target.value)}
                            style={{ color: theme.palette.primary.main }}
                            fullWidth
                        />
                    </form>
                    <Divider orientation="vertical" style={{ marginLeft: ".5em", marginRight: "1em" }} />
                    <IconButton size="small" color="primary" onClick={handleRemove}>
                        <FaTimes />
                    </IconButton>
                </Paper>
            }
        </>
    );
};

export default GoogleAnalytics;