import React, { useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import { Field } from "redux-form";
import RenderTextField from "../components/TextField";
import { useTextStyles } from "../styles/text";
import { isZipCode, isNumber, validString } from "../validators/form";

interface AutofillKHNComponentProps {
    identifier: string;
    formValues: any;
    registerInformationKHN: (khnId: number, zipCode: string, identifier: string, formValues: any) => void;
}

const AutofillKHNComponent = (props: AutofillKHNComponentProps) => {
    const textClasses = useTextStyles();
    const [zipCode, setZipCode] = useState<string>("");
    const [khnId, setKHNId] = useState<number | undefined>(undefined);

    const [oldZipCode, setOldZipCode] = useState<string>("");
    const [oldKHNId, setOldKHNId] = useState<number | undefined>(undefined);

    const handleBlur = () => {
        if (khnId && zipCode && isZipCode(zipCode) === undefined && isNumber(khnId) === undefined && (zipCode !== oldZipCode || khnId !== oldKHNId)) {
            setOldZipCode(zipCode);
            setOldKHNId(khnId);
            props.registerInformationKHN(khnId, zipCode, props.identifier, props.formValues);
        }
    };

    return (
        <>
            <Grid item xs={12} md={6}>
                <div className="textfield-spacing textfield-spacing-h">
                    <Typography classes={{
                        root: `${textClasses.label}`
                    }}>KHN ACCOUNTNUMMER</Typography>
                    <Field
                        variant="outlined"
                        name="khnId"
                        placeholder="Optioneel"
                        component={RenderTextField}
                        validate={[validString, isNumber]}
                        handleChange={(value: number) => setKHNId(value)}
                        handleBlur={handleBlur}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <div className="textfield-spacing textfield-spacing-h">
                    <Typography classes={{
                        root: `${textClasses.label}`
                    }}>BEDRIJF POSTCODE</Typography>
                    <Field
                        variant="outlined"
                        name="khnZipcode"
                        placeholder="Optioneel"
                        component={RenderTextField}
                        validate={[validString, isZipCode]}
                        handleChange={(value: string) => setZipCode(value)}
                        handleBlur={handleBlur}
                    />
                </div>
            </Grid>
        </>
    );
};

export default AutofillKHNComponent;