import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router";

const PrivateRoute: any = ({
    component: Component,
    token,
    access,
    role,
    setDestination,
    ...rest
}: {
    component: React.ComponentType<RouteComponentProps<any>>;
    token: string;
    access: string[];
    role: string;
    setDestination: (destination: string) => void;
}) => {
    const check: any = rest;
    if (!(!!token && access.includes(role))) setDestination(check['path']);

    return (
        <Route
            {...rest}
            render={props =>
                (!!token && access.includes(role))
                    ? <Component {...props} />
                    : <Redirect to="/" />
            }
        />
    );
};

export default PrivateRoute;