import graphqlHelperService from "./graphqlHelper.service";
import { LatestResult, UpcomingCourse } from "../types/dashboard";

export const DashboardService = {
    latestResults: async (): Promise<LatestResult[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            latestResultsQuery()
        );
        return apolloResult.latestResults as LatestResult[];
    },
    upcomingCourses: async (): Promise<UpcomingCourse[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            upcomingCoursesQuery()
        );
        return apolloResult.upcomingCourses as UpcomingCourse[];
    },
}

const latestResultsQuery = () => `query{
    latestResults {
        studentId
        firstName
        prefix
        lastName
        courseName
        date
        passed
        failed
        grade
    }
}`;

const upcomingCoursesQuery = () => `query{
    upcomingCourses{
        studentId
        projectId
        firstName
        prefix
        showHomework
        showSampleExam
        lastName
        categoryId
        courseId
        courseName
        date
        eLearning
        eLearningLink
      }
}`;