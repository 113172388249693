import React from "react";
import { Grid, Typography, makeStyles, Theme } from "@material-ui/core";
import { StudentDetail } from "../../../types/student";
import "../../Courses/Course.css"

const useStyles = makeStyles((theme: Theme) => ({
    data: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        backgroundColor: "#f0f0f0"
    },
    dataText: {
        display: "flex",
        alignItems: "center"
    },
    row: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
}));

interface RegisterAppConfirmationStudentsProps {
    students?: StudentDetail[];
}

const RegisterAppConfirmationStudents = ({ students }: RegisterAppConfirmationStudentsProps) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.data}>
            <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h6">Deelnemers</Typography>
                </Grid>
                <Grid item xs={12} className={classes.row}>
                    <> {
                        students && students.map((student: StudentDetail, index: number) => {
                            return (
                                <Grid style={{ marginTop: "1em" }} key={index} container>
                                    <Grid item xs={12} className={classes.row}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <Typography variant="body2" style={{ fontWeight: "bold" }}>Voornaam</Typography>
                                            </Grid>
                                            <Grid item xs={10} className={classes.dataText}>
                                                <Typography variant="body2" className="course-second-column">{student.firstName}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.row}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <Typography variant="body2" style={{ fontWeight: "bold" }}>Achternaam</Typography>
                                            </Grid>
                                            <Grid item xs={10} className={classes.dataText}>
                                                <Typography variant="body2" className="course-second-column">
                                                    {student.prefix ? student.prefix + " " : ""}
                                                    {student.lastName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            )
                        })
                    } </>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default RegisterAppConfirmationStudents;