import { StudentDetail } from "../types/student";
import { Registration } from "../types/register";
import { Role } from "../types/enum";
import { CompanyDataFormData } from "../components/Register/Register/Company/CompanyData";
import { FreelancerUserFormData } from "../components/Register/Register/Freelancer/FreelancerUserData";
import { PaymentFormData } from "../components/Register/Register/Payment";
import { RegisterCourse } from "../types/registerCourse";
import { SentryCapture } from "../sentry";

const initState = { auth: {} };

interface State {
    auth: {
        token: string;
        refreshToken: string;
        role: string;
        companyName: string;
        lastName: string;
        prefix: string;
        firstName?: string;
        initials?: string;
    },
    register: {
        registerCompanyData?: CompanyDataFormData;
        registerFreelancerUserFormData?: FreelancerUserFormData;
        registerPayment?: PaymentFormData;
        shoppingCart: Registration[];
        students: StudentDetail[];
    }, 
    registerCourse: {
        course: RegisterCourse;
        freelanceBooks: boolean;
    },
}

export const loadState = () => {
    try {
        if ((localStorage.getItem("remember") === 'true')) {
            const serializedState = localStorage.getItem("state");

            if (serializedState === null) {
                localStorage.setItem("state", JSON.stringify(initState));
                return initState;
            }

            return JSON.parse(serializedState);
        } else {
            const serializedState = localStorage.getItem("state");
            let state = {};

            if (serializedState === null) {
                localStorage.setItem("state", JSON.stringify(initState));
            } else state = JSON.parse(serializedState);

            const sessionState = sessionStorage.getItem("state");
            if (sessionState === null) {
                sessionStorage.setItem("state", JSON.stringify(initState));
            } else state = { ...state, ...JSON.parse(sessionState) };

            return state;
        }
    } catch (error) {
        SentryCapture(error);
        return undefined;
    }
};

export const saveState = (state: State): void => {
    try {
        const authSerializedState = JSON.stringify({ auth: state.auth })
        const registerSerializedState = JSON.stringify({ register: state.register, registerCourse: state.registerCourse })
        const serializedState = JSON.stringify(state);

        if ((localStorage.getItem("remember") === 'true')) {
            localStorage.setItem("state", serializedState);
        } else {
            localStorage.setItem("state", registerSerializedState);
            sessionStorage.setItem("state", authSerializedState);
        }
    } catch (error) {
        SentryCapture(error);
        return;
    }
};

export const getToken = (): string | undefined => {
    try {
        if ((localStorage.getItem("remember") === 'true')) {
            const serializedState = localStorage.getItem("state");
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState).auth.token;
        } else {
            const serializedState = sessionStorage.getItem("state");

            if (serializedState === null) {
                return undefined;
            }

            return JSON.parse(serializedState).auth.token;
        }
    } catch (error) {
        SentryCapture(error);
        return undefined;
    }
};

export const getRefreshToken = (): string | undefined => {
    try {
        if ((localStorage.getItem("remember") === 'true')) {
            const serializedState = localStorage.getItem("state");
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState).auth.refreshToken;
        } else {
            const serializedState = sessionStorage.getItem("state");

            if (serializedState === null) {
                return undefined;
            }

            return JSON.parse(serializedState).auth.refreshToken;
        }
    } catch (error) {
        SentryCapture(error);
        return undefined;
    }
};

export const getRole = (): Role | undefined => {
    try {
        if ((localStorage.getItem("remember") === 'true')) {
            const serializedState = localStorage.getItem("state");
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState).auth.role;
        } else {
            const serializedState = sessionStorage.getItem("state");

            if (serializedState === null) {
                return undefined;
            }

            return JSON.parse(serializedState).auth.role;
        }
    } catch (error) {
        SentryCapture(error);
        return undefined;
    }
};
