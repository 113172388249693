import React from 'react';
import { isNil, isNull } from "lodash";
import Select from "react-select";
import { Grid, FormControl, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { CourseType, RegisterCourse, CourseCategory, AvailableDate } from '../../../../types/registerCourse';
import { useTextStyles } from '../../../../styles/text';
import CustomPrompt from '../../../notification/CustomPrompt';

const useStyles: any = makeStyles((theme: Theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    select: {
        fontSize: "16px !important",
        menuPortal: (base: any) => ({ ...base, zIndex: "9999 !important"}),
    },
    info: {
        flex: 1,
        overflow: "auto",
        margin: ".5em",
        backgroundColor: "#EDEDED"
    },
    label: {
        marginBottom: "0.5em",
    },
    noOptions: {
        fontFamily: 'Arial,medium-content-sans-serif-font,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;',
        fontSize: "16px",
        paddingLeft: 8,
        fontStyle: "normal",
        lineHeight: 1.5,
        color: "#000",
    },
    infoWrapper: {
        display: "flex",
        flexFlow: "column",
        marginTop: "1em",
    },
}));

interface CourseComponentProps {
    course: RegisterCourse;

    categories: CourseCategory[];
    types: CourseType[];
    places: string[];
    availableDates: AvailableDate[];
    loading: boolean;

    isMailLinkBuilder: boolean;

    setCategory: (category: CourseCategory) => void;
    setType: (type: CourseType) => void;
    setPlace: (place: string) => void;
    setDate: (date: AvailableDate) => void;
}

const CourseComponent = ({ course, categories, types, places, availableDates, setCategory, setType, setPlace, setDate, loading, isMailLinkBuilder }: CourseComponentProps) => {
    const classes = useStyles();
    const textClasses = useTextStyles();
    const selectedColor = useTheme().palette.primary.main;
    const selectThemeProps = (theme: any) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: `${selectedColor}22`, // very transparent
            primary50: `${selectedColor}44`, // partially transparent
            primary: selectedColor
        }
    });
    
    var selectablecategories = (isNil(categories) || isMailLinkBuilder) ? categories : categories.filter((value: CourseCategory) => value.registerViaLinkOnly === false)
    
    return (
        <Grid container direction="column">
            <CustomPrompt when={!course.completed} />
            <FormControl className={classes.margin}>
                <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>CURSUS</Typography>
                <Select
                    className={classes.select}
                    variant="outlined"
                    placeholder="Cursus"
                    value={course.category || null}
                    noOptionsMessage={() => "Geen opties"}
                    getOptionLabel={({ category }) => category}
                    isOptionSelected={(option, options) => options.filter(o => o?.categoryId === option.categoryId).length > 0}
                    onChange={(category) => category && setCategory(category)}
                    options={selectablecategories}
                    menuPortalTarget={document.body}
                    theme={selectThemeProps}
                />
                {selectablecategories && selectablecategories.length === 0 && !loading && !course.completed && <Typography color='error' variant='caption'>Er is iets fout gegaan</Typography>}
            </FormControl>
            <FormControl className={classes.margin}>
                <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>TYPE</Typography>
                <Select
                    className={classes.select}
                    value={course.type || null}
                    getOptionLabel={({ type }) => type}
                    isOptionSelected={(option, options) => options.filter(o => o?.courseId === option.courseId).length > 0}
                    variant="outlined"
                    placeholder="Type"
                    noOptionsMessage={() => "Geen opties"}
                    onChange={type => type && setType(type)}
                    options={types}
                    menuPortalTarget={document.body}
                    theme={selectThemeProps}
                />
                {types && types.length === 0 && !loading && !isNil(course.category) && !course.completed && <Typography color='error' variant='caption'>Er is iets fout gegaan</Typography>}
            </FormControl>
            {
                course.type?.eLearning && !(isNull(course.type?.eLearningHybrid) ? false : course.type.eLearningHybrid) ?
                    <Typography className={classes.noOptions}>Locaties door heel Nederland (keuze na ontvangst code).</Typography>
                    :
                    course.completed && !course.place && (isNull(course.type?.eLearningHybrid) ? false : course.type.eLearningHybrid) ?
                        <Typography className={classes.noOptions}>Locatie niet van toepassing, dit is een thuiscursus.</Typography>
                        :
                        <FormControl className={classes.margin}>
                            <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>LOCATIE</Typography>
                            <Select
                                className={classes.select}
                                value={course.place ? { place: course.place } : null}
                                getOptionLabel={({ place }) => place}
                                isOptionSelected={(option, options) => options.filter(o => o?.place === option.place).length > 0}
                                variant="outlined"
                                placeholder="Locatie"
                                menuPortalTarget={document.body}
                                noOptionsMessage={() => "Geen opties"}
                                theme={selectThemeProps}
                                onChange={place => place?.place && setPlace(place.place)}
                                options={(places || []).map(place => ({ place }))}
                            />
                            {places && places.length === 0 && !loading && !isNil(course.type) && !course.completed && <Typography color='error' variant='caption'>Er is iets fout gegaan</Typography>}
                        </FormControl>
            }
            {
                course.type?.eLearning && !(isNull(course.type?.eLearningHybrid) ? false : course.type.eLearningHybrid) ?
                    <Typography className={classes.noOptions}>Dagelijks beschikbaar (keuze na ontvangst code).</Typography>
                    :
                    course.completed && (!course.date && (isNull(course.type?.eLearningHybrid) ? false : course.type.eLearningHybrid)) ?
                        <Typography className={classes.noOptions}>Datum niet van toepassing, dit is een thuiscursus.</Typography>
                        :
                        <FormControl className={classes.margin}>
                            <Typography className={classes.label} classes={{ root: `${textClasses.label}` }}>DATUM</Typography>
                            <Select
                                className={classes.select}
                                value={course.date || null}
                                isOptionSelected={(option, options) => options.filter(o => o?.locationCode === option.locationCode).length > 0}
                                variant="outlined"
                                menuPortalTarget={document.body}
                                theme={selectThemeProps}
                                placeholder="Datum"
                                onChange={availableDate => availableDate && setDate(availableDate)}
                                noOptionsMessage={() => "Geen opties"}
                                formatOptionLabel={(availableDate, { context }) => context === "menu" ?
                                    availableDate.dates && availableDate.dates.map((date, index) => (
                                        <Grid item xs={12}>
                                            {availableDate.dates.length !== 1 ?
                                                `Lesdag ${index + 1}: ${date}`
                                                : date
                                            }
                                        </Grid>
                                    )) : <Grid item xs={12}>{`${availableDate.dates[0]}${availableDate.dates.length > 1 ? "..." : ''}`}</Grid>
                                }
                                options={availableDates}
                            />
                            {availableDates && availableDates.length === 0 && !loading && !isNil(course.place) && !course.completed && <Typography color='error' variant='caption'>Er is iets fout gegaan</Typography>}
                        </FormControl>
            }
        </Grid>
    );
};

export default CourseComponent;