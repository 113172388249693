import graphqlHelperService from "./graphqlHelper.service";
import { Issuer } from "../types/payment";

export const PaymentService = {
    issuersList: async (): Promise<Issuer[]> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            issuersListQuery()
        );
        return apolloResult.getIssuerList as Issuer[];
    },
    invoiceLink: async (invoiceId: string, description: string, issuerId: string, returnUrl: string): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            invoiceLinkQuery(invoiceId, description, issuerId, returnUrl)
        );
        return apolloResult.generateInvoiceIdealLink as string;
    },
    paymentReturned: async (transactionId: string): Promise<void> => {
        await graphqlHelperService.sendMutate(
            paymentReturnedMutation(transactionId)
        );
        return;
    }
}

const invoiceLinkQuery = (invoiceId: string, description: string, issuerId: string, returnUrl: string) => `query {
    generateInvoiceIdealLink(
        paymentInput: {
            paymentId: "${invoiceId}",
            description: "${description}",
            issuerId: "${issuerId}",
            returnUrl: "${returnUrl}",
        }
    )
}`

const issuersListQuery = () => `query {
    getIssuerList {
        issuerId
        issuerName
    }
}`

const paymentReturnedMutation = (transactionId: string) => `mutation {
    paymentReturned(transactionId: "${transactionId}")
}`