export const MAX_LENGTH: (max: number) => string | undefined = (max: number): string | undefined => `Dit veld mag uit maximaal ${max} tekens bestaan`;
export const MIN_LENGTH: (min: number) => string | undefined = (min: number): string | undefined => `Dit veld moet minimaal uit ${min} tekens bestaan`;
export const INVALID_EMAIL: string = "Ongeldig email adres";
export const INVALID_PHONE: string = "Ongeldig telefoonnummer";
export const INVALID_ZIPCODE: string = "Ongeldige postcode";
export const INVALID_DATE: string = "Ongeldige datum";
export const INVALID_STRING_SPECIAL_CHARS: string = "Mag geen speciale tekens bevatten";
export const PASSWORD_DIFFERENT: string = "Wachtwoorden moeten hetzelfde zijn";
export const PASSWORD_TOO_WEAK: string = "Wachtwoord is te makkelijk";
export const REQUIRED_FIELD: string = "Dit veld is vereist";
export const TYPE_NUMBER: string = "Dit veld moet een nummer zijn";
export const STRING_TOO_LONG: string = "Deze zin is te lang";
export const HOUSENUMBER_INVALID: string = "Ongeldig huisnummer";