import React from "react";
import { isNil } from "lodash";

import { ParticipatingStudent } from "../../types/course";
import { Grid, CardActionArea, Typography, Tooltip, Divider } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router";
import { FaCheck, FaTimes, FaUser, FaClipboardCheck, FaClipboard, FaQuestion, FaUserTimes } from "react-icons/fa";
import { parseGrade } from "../../helperfunctions/grade";

interface breakPointsProps {
    lg?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
    xs?: boolean | "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined
}

interface doneProps {
    done: boolean;
}

const CourseParticipant: React.FC<ParticipatingStudent & doneProps & breakPointsProps & RouteComponentProps<{}>> = ({ presentAtExam, history, studentId, initials, firstName, prefix, lastName, passed, failed, madeHomeWork, madeSampleExams, sampleExamGrade, done, lg, xs }: ParticipatingStudent & doneProps & breakPointsProps & RouteComponentProps<{}>) => {
    const name = (firstName || initials) + " " + (prefix ? prefix + " "  : "") + lastName;
    const navigateToPage = (studentId?: number) => history.push({
        pathname: `/deelnemer-overzicht/${studentId}`,
    });

    const studentData = [
        { condition: true, toolTip: name || "Deelnemer", icon: <FaUser />, text: name },
        { condition: !isNil(madeHomeWork), toolTip: "Huiswerk", 
            icon: madeHomeWork ? <FaClipboardCheck color="green" /> : <FaClipboard color="red" />, 
            text: madeHomeWork ? 'Huiswerk gemaakt' : "Huiswerk niet gemaakt"
        },
        { condition: !isNil(madeSampleExams), toolTip: "Proefexamens", 
            icon: madeSampleExams ? <FaClipboardCheck color="green" /> : <FaClipboard color="red" />, 
            text: madeSampleExams ? sampleExamGrade ? `Resultaat: ${parseGrade(sampleExamGrade)}` : "Proefexamen gemaakt" : "Resultaat proefexamen: Onbekend"
        },
        { condition: presentAtExam === false, toolTip: "Aanwezigheid", icon: <FaUserTimes color="red" />, text: "Afwezig bij examen" },
        { condition: done, toolTip: "Resultaat", 
            icon: passed === true ? <FaCheck color="green" /> : failed === true ? <FaTimes color="red" /> : <FaQuestion />, 
            text: `Resultaat: ${passed === true ? "Geslaagd" : failed === true ? "Afgewezen" : "Onbekend"}`
        },
    ]

    return <Grid item xs={xs} lg={lg} className="center-v course-summary">
        <CardActionArea style={{ paddingLeft: 10 }} onClick={() => navigateToPage(studentId)}>
            <Grid container justify="flex-start" alignItems="center">
                {studentData.map(({ condition, toolTip, icon, text }) => condition ? <Grid item xs={12} lg={6} style={{ marginTop: 5 }}><Tooltip title={toolTip}>
                    <Grid container spacing={3} justify="flex-start" alignItems="center">
                        {icon}
                        <Grid item xs={10}>
                            <Typography variant="body2">{text}</Typography>
                        </Grid> 
                    </Grid> 
                </Tooltip></Grid> : null)}
            </Grid>
            <Divider style={{ margin: 5 }}/>
        </CardActionArea>
    </Grid>;
}

export default withRouter(CourseParticipant);