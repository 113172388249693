import graphqlHelperService from "./graphqlHelper.service";
import { Invoice, InvoicesObject } from "../types/invoice";

export const InvoiceService = {
    invoiceByNumber: async (invoiceYear: number, invoiceNumber: string): Promise<Invoice> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            singleInvoiceQuery(invoiceYear, invoiceNumber)
        );
        return apolloResult.invoiceByNumber as Invoice;
    },
    invoices: async (): Promise<InvoicesObject> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            invoicesQuery()
        );
        return apolloResult.invoices as InvoicesObject;
    },
    invoicePDF: async (invoiceId: string): Promise<string> => {
        const apolloResult = await graphqlHelperService.sendQuery(
            invoicePDFQuery(invoiceId)
        );
        return apolloResult.invoicePDF as string;
    },
}

const invoicesQuery = () => `query{
    invoices {
        invoices {
            invoiceId
            amount
            date
            paymentDate
            paid
            description
            pending
        }
        outstandingInvoices
    }
}`;

const invoicePDFQuery = (invoiceId: string) => `query{
    invoicePDF (invoiceId: "${invoiceId}")
}`;


const singleInvoiceQuery = (invoiceYear: number, invoiceNumber: string) => `query {
    invoiceByNumber (invoiceYear: ${invoiceYear}, invoiceNumber: "${invoiceNumber}") {
        invoiceId
        amount
        date
        paymentDate
        paid
        description
        pending
    }
}`;
