import React from "react"
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

import CheckoutComponent from "./CheckoutComponent";
import { RegisterState } from "../../types/register";
import { removeFromShoppingCart } from "../../store/register/actions"
import { AuthenticationState } from "../../types/auth";


interface CheckoutContainerProps {
    register: RegisterState
    auth: AuthenticationState
    removeFromShoppingCart: (index: number) => void;
}

const mapStateToProps = ({register, auth}: CheckoutContainerProps) => {
    return ({
        register,
        auth
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ removeFromShoppingCart }, dispatch);
};

const CheckoutContainer = ({ register, removeFromShoppingCart, auth }: CheckoutContainerProps) => {
    return (
        <CheckoutComponent role={auth.role} 
            onRemove={removeFromShoppingCart} 
            checkoutItems={register.shoppingCart} 
        />
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutContainer)
