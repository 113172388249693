import React, { useState } from "react";
import { Grid, Button, List, ListItem, Collapse, Paper, Typography, Theme, makeStyles } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { animated, useSpring, config } from "react-spring";
import { FaBook, FaUsers } from "react-icons/fa";

import { useButtonStyles } from "../../../styles/button";
import { RegisterState } from "../../../types/register";
import RegisterAppConfirmationStudents from "./RegisterAppConfirmationStudents";
import { Role } from "../../../types/enum";
import Course from "../Register/Confirmation/Course";
import { CourseDetail } from "../../../types/course";

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
    },
    listWrapper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    title: {
        marginLeft: theme.spacing(2),
    }
}));

interface RegisterAppConfirmationProps {
    role: string
    courseDetail: CourseDetail;
    register: RegisterState;
    handleBack: () => void;
    handleConfirm: () => void;
    resetCourseRegister: () => void;
    resetCourseDetail: () => void;
    resetStudents: () => void;
}

const RegisterAppConfirmation = ({ courseDetail, register, handleBack, role, handleConfirm, resetCourseRegister, resetCourseDetail, resetStudents }: RegisterAppConfirmationProps) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const checkProps = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        config: config.default
    });
    const [openCourse, setOpenCourse] = useState(true);
    const [openStudents, setOpenStudents] = useState(true);
    const { students } = register;

    return (
        <animated.div style={checkProps}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container>
                        <Typography variant="h4">Bevestigen</Typography>
                        <Grid item xs={12} className={classes.listWrapper}>
                            <Paper>
                                <List>
                                    <ListItem button onClick={() => {
                                        setOpenCourse(!openCourse)}}>
                                        <Grid container justify="space-between">
                                            <Grid item>
                                                <Grid container>
                                                    <div className={classes.icon}>
                                                        <FaBook />
                                                    </div>
                                                    <Typography variant="overline" className={classes.title}>Cursus</Typography>
                                                </Grid>
                                            </Grid>
                                            {openCourse ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                    </ListItem>
                                    <Collapse in={openCourse} timeout="auto" unmountOnExit>
                                        {courseDetail && <Course courseDetail={courseDetail} />}
                                    </Collapse>
                                    {role === Role.COMPANY &&
                                        <>
                                            <ListItem button onClick={() => setOpenStudents(!openStudents)}>
                                                <Grid container justify="space-between">
                                                    <Grid item>
                                                        <Grid container>
                                                            <div className={classes.icon}>
                                                                <FaUsers />
                                                            </div>
                                                            <Typography variant="overline" className={classes.title}>Deelnemers</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {openStudents ? <ExpandLess /> : <ExpandMore />}
                                                </Grid>
                                            </ListItem>
                                            <Collapse in={openStudents} timeout="auto" unmountOnExit>
                                                <RegisterAppConfirmationStudents students={students} />
                                            </Collapse>
                                        </>
                                    }
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify="space-between">
                        <Button
                            onClick={handleBack}
                            classes={{
                                root: buttonClasses.secondaryApp,
                            }}>
                            Terug</Button>
                        <Button
                            onClick={() => {
                                handleConfirm()
                                resetCourseRegister()
                                resetCourseDetail()
                                resetStudents()
                            }}
                            color="secondary"
                            variant="contained"
                            classes={{
                                root: buttonClasses.primary,
                            }}
                        >
                            in winkelwagen</Button>
                    </Grid>
                </Grid>
            </Grid>
        </animated.div >
    );
};

export default RegisterAppConfirmation;