export function trimFields<T>(object: T, depth = 5) {
    if (!object || depth <= 0) return object;

    for (let key of Object.keys(object)) {
        const value = Object(object)[key];

        if (typeof value === "object") {
            Object(object)[key] = trimFields(value, depth - 1);
        }
        if (typeof value === "string") {
            if (value === "undefined") Object(object)[key] = "";
            Object(object)[key] = value.trim();
        }
    }

    return object;
}