import React, { useEffect } from "react"
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { loginRegistrationEntranceCode } from "../../store/auth/actions";
import { RouteComponentProps } from "react-router";
import { ApplicationState } from "../../store";
import Complete from "./Complete";
import { paymentReturned } from "../../store/payment/actions";

interface RegistrationPayedContainerProps {
    token: string;
    refreshToken: string;
    loginRegistrationEntranceCode: (token?: string, refreshToken?: string, entranceCode?: string) => void;
    paymentReturned: (transactionId: string) => void;
}

const mapStateToProps = ({ auth: { token, refreshToken } }: ApplicationState) => {
    return ({
        token,
        refreshToken 
    });
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        loginRegistrationEntranceCode,
        paymentReturned,
    }, dispatch);
};

const RegistrationPayedContainer = ({ loginRegistrationEntranceCode, location, token, refreshToken, paymentReturned}: RegistrationPayedContainerProps & RouteComponentProps) => {
    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const entranceCode = urlParams.get("ec") || undefined;
        const transactionIdOld = urlParams.get('trxid');
        const transactionId = urlParams.get('id');
        loginRegistrationEntranceCode(token, refreshToken, entranceCode);
        if (transactionId){
            paymentReturned(transactionId);
        } else if (transactionIdOld) {
            paymentReturned(transactionIdOld);
        }
    }, [location.search, loginRegistrationEntranceCode, token, refreshToken, paymentReturned]);

    const toDashboard: boolean = token !== undefined && refreshToken !== undefined;

    return (
        <Complete loading={false} error={undefined} toDashboard={toDashboard}/>
    );
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(RegistrationPayedContainer);