import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { uploadKHNFile } from "../../../store/register/actions"
import KHNCusomersComponent from "./KHNCustomersComponent";

interface KHNCustomersContainerProps {
    uploadKHNFile: (file?: File[]) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ uploadKHNFile }, dispatch);
};

const KHNCustomersContainer = ({ uploadKHNFile }: KHNCustomersContainerProps) => {
    return (
        <KHNCusomersComponent
            uploadFile={uploadKHNFile}
        />
    );
};

export default connect(
    null,
    mapDispatchToProps
)(KHNCustomersContainer);