const development = {
    name: 'development',
    url: 'http://localhost:4001/graphql'
}

const test = {
    name: 'test',
    url: 'https://portal-api-test.hsn.nl/graphql'
}

const production = {
    name: 'production',
    url: 'https://portal-api.hsn.nl/graphql'
}

let environment: any;

if (window.location.host.indexOf("portal-test") !== -1) {
    environment = test
} else if (window.location.host.indexOf("portal") !== -1) {
    environment = production;
} else {
    environment = development;
}

export default environment as any;