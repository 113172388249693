import { useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";

import Dashboard from "./Dashboard";
import { getInvoices } from "../../store/invoices/actions";
import { AuthenticationState } from "../../types/auth";
import { InvoiceState } from "../../types/invoice";
import { getLatestResults, getUpcomingCourses } from "../../store/dashboard/actions";
import { getAssignmentsByCourseId, downloadAssignment } from "../../store/assignment/actions";
import { getTestExamsByCategoryId } from "../../store/testExams/actions";
import { DashboardState } from "../../types/dashboard";
import { SampleExamState } from "../../types/sampleExam";
import { AssignmentState } from "../../types/assignment";
import { Role } from "../../types/enum";

interface DashboardContainerProps {
    auth: AuthenticationState;
    dashboard: DashboardState;
    invoice: InvoiceState;
    testExams: SampleExamState;
    assignment: AssignmentState;
    getInvoices: () => void;
    getLatestResults: () => void;
    getUpcomingCourses: () => void;
    getTestExamsByCategoryId: (categoryId: number, projectId?: number) => void;
    getAssignmentsByCourseId: (variables: any) => void;
    downloadAssignment: (assignmentId: number, name: string) => void;
}

const mapStateToProps = ({
    auth,
    dashboard,
    invoice,
    testExams,
    assignment
}: DashboardContainerProps) => {
    return ({
        auth,
        dashboard,
        invoice,
        testExams,
        assignment
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getInvoices, getLatestResults, getUpcomingCourses, getTestExamsByCategoryId, getAssignmentsByCourseId, downloadAssignment }, dispatch);
};

const DashboardContainer = ({ auth, dashboard, downloadAssignment, invoice, getInvoices, getLatestResults, getUpcomingCourses, getTestExamsByCategoryId, testExams, getAssignmentsByCourseId, assignment }: DashboardContainerProps) => {
    useEffect(() => {
        if (auth.role === Role.FREELANCER || auth.role === Role.COMPANY) {
            getInvoices();
        }
        if (auth.role === Role.STUDENT || auth.role === Role.FREELANCER || auth.role === Role.COMPANY) {
            getLatestResults();
            getUpcomingCourses();
        }
    }, [auth.role, getInvoices, getLatestResults, getUpcomingCourses]);

    return <Dashboard
        testExams={testExams}
        getTestExamsByCategoryId={getTestExamsByCategoryId}
        getAssignments={getAssignmentsByCourseId}
        downloadAssignment={downloadAssignment}
        auth={auth}
        assignment={assignment}
        dashboard={dashboard}
        invoice={invoice}
    />
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);