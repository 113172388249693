import React from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";

import { setDestination } from "../../../../store/auth/actions";
import { resetRegisterState } from "../../../../store/register/actions";
import RegisterAsComponent from "./RegisterAsComponent";
import { getWebsiteString } from "../../../../helperfunctions/getWebsite";

interface RegisterAsContainerProps {
    setDestination: (destination: string) => void;
    resetRegisterState: () => void;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        setDestination,
        resetRegisterState
    }, dispatch);
}

const RegisterAsContainer = ({ setDestination, resetRegisterState }: RegisterAsContainerProps) => {
    return (
        <RegisterAsComponent
            setDestination={setDestination}
            company={getWebsiteString()}
            resetRegisterState={resetRegisterState}
        />
    );
};

export default connect(
    null,
    mapDispatchToProps
)(RegisterAsContainer);