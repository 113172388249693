import React from 'react'
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

interface testExamModalProps {
    open: boolean
    getTestExamResult: () => void;
    setResult: (result: boolean) => void;
    setOpen: () => void;
    result: boolean
}

const TestExamModal = ({ open, getTestExamResult, setResult, result, setOpen }: testExamModalProps) => {
    const handleSubmitTestExam = () => {
        getTestExamResult()
        setResult(!result)
        setOpen()
    }

    return (
        <div>
            <Dialog open={open}>
                <DialogTitle>
                    Weet je zeker dat je deze vragen wilt inleveren?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen()} variant="text" color="secondary">Annuleren</Button>
                    <Button onClick={() => handleSubmitTestExam()} variant="contained" color="secondary" style={{ marginLeft: "auto" }}>Bevestigen</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default TestExamModal
