import { Dispatch } from "redux";

import { MetaDataActions } from "./types";
import { metaDataService } from "../../services/metadata.service";
import { CourseMetaData } from "../../types/metaData";
import messages from "../../constants/messages";
import { toast } from "react-toastify";

export const getMetaData = (courseId: string) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: MetaDataActions.REQUEST });
        const metaData = await metaDataService.metaData(courseId);
        dispatch({ type: MetaDataActions.SUCCESS, metaData });
    } catch (error) {
        dispatch({ type: MetaDataActions.FAILURE });
        toast.error(messages.METADATA_NOT_FOUND)
    }
};

export const editMetaData = (metaData: CourseMetaData) => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: MetaDataActions.REQUEST });
        const updated = await metaDataService.updateMetaData(metaData);

        if (updated) dispatch({ type: MetaDataActions.SUCCESS, metaData });
        else {
            dispatch({ type: MetaDataActions.FAILURE });
            toast.error(messages.UPDATE_ERROR)
        }
    } catch (error) {
        dispatch({ type: MetaDataActions.FAILURE });
        toast.error(messages.UPDATE_ERROR)
    }
};
