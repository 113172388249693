import { Discount } from "../types/discount";

export const validDiscount = (discount: Discount) => {
    let error: string | undefined
    if (discount.amount > 0 && discount.percentage > 0 && discount.extra) {
        error = "Kies één van de drie soorten kortingen"
    } else if (discount.amount === 0 && discount.percentage === 0 && !discount.extra) {
        error = "Kies één van de drie soorten kortingen"
    } else if (discount.amount > 0 && discount.percentage > 0) {
        error = "Kies één van de drie soorten kortingen"
    } else if (!discount.message || discount.message.trim() === "") {
        error = "Korting omschrijving ongeldig"
    } else if (discount.message.length > 500) {
        error = "Korting omschrijving te lang"
    } else if (discount.amount > 0 && discount.extra) {
        error = "Kies één van de drie soorten kortingen"
    } else if (discount.percentage > 0 && discount.extra) {
        error = "Kies één van de drie soorten kortingen"
    } else {
        error = undefined
    }
    return error
}