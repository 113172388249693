import React from "react";
import { Grid, Typography, Link, makeStyles, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { animated, useSpring, config } from "react-spring";
import { FaCheck } from "react-icons/fa";

import { useButtonStyles } from "../../../styles/button";
import Loading from "../../FeedBack/Loading";
import Error from "../../FeedBack/Error";
import { Error as ErrorType } from "../../../types/feedback";

const useStyles = makeStyles((theme) => ({
    check: {
        verticalAlign: "middle",
        fontSize: "100px",
        color: theme.palette.primary.main,
    },
    subtitle: {
        marginTop: theme.spacing(2),
    },
    loading: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
    }
}));

interface RegisterCompleteProps {
    loading: boolean;
    error?: ErrorType;
}

const RegisterComplete = ({ error, loading }: RegisterCompleteProps) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const checkProps = useSpring({
        from: { transform: "translate3d(0,-40px,0)", opacity: 0 },
        to: { transform: "translate3d(0,0px,0)", opacity: 1 },
        config: config.default
    });

    const triggerEvent = (event: string) => {
        (window as any).dataLayer.push({ event });
    }

    return (
        <>
            <Grid container justify="center" alignItems="center" style={{ padding: "2em" }} direction="column">
                <Grid item xs={12} md={6}>
                    {loading &&
                        <Grid container justify="center" alignItems="center" direction="column" className={classes.loading}>
                            <animated.div style={checkProps}>
                                <Loading />
                            </animated.div>
                            <Grid container justify="center" alignItems="center" direction="column" className={classes.subtitle}>
                                <Typography variant="h5">Inschrijving aan het indienen</Typography>
                            </Grid>
                        </Grid>
                    }
                    {error &&
                        <>
                            {triggerEvent('register-error')}
                            <Error />
                        </>
                    }
                    {(!error && !loading) &&
                        <>
                            {triggerEvent('register-confirmed')}
                            <Grid container justify="center" alignItems="center" direction="column">
                                <animated.div style={checkProps}>
                                    <FaCheck className={classes.check} />
                                </animated.div>
                                <Grid container justify="center" alignItems="center" direction="column">
                                    <Typography variant="h5">Inschrijving succesvol</Typography>
                                    <Typography variant="subtitle2">Uw inschrijving wordt zo snel mogelijk verwerkt</Typography>
                                </Grid>
                            </Grid>
                            <Grid container justify="center">
                                <Grid item>

                                    <Link
                                        component={RouterLink}
                                        to="/inschrijven-als"
                                        underline="none"
                                        classes={{
                                            root: buttonClasses.primary,
                                        }}>
                                        <Button color="secondary" variant="contained">
                                            nog een inschrijving
                                        </Button>
                                    </Link>

                                </Grid>
                                <Grid item>

                                    <Link
                                        component={RouterLink}
                                        to="/"
                                        underline="none"
                                        classes={{
                                            root: buttonClasses.primary,
                                        }}>
                                        <Button variant="contained">
                                            Terug naar inloggen
                                        </Button>
                                    </Link>

                                </Grid>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default RegisterComplete;