import React from "react";
import { Grid } from "@material-ui/core";

import { useTextStyles } from "../../styles/text";
import SettingsChangePassword from "./SettingsChangePassword";

interface SettingsComponentProps {
    changePassword: (newPassword: string, oldPassword: string) => void;
}

const SettingsComponent = ({ changePassword }: SettingsComponentProps) => {
    const textClasses = useTextStyles()

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <span className={`${textClasses.pageTitle}`}>Instellingen</span>
            </Grid>
            <SettingsChangePassword handleChangePassword={(newPassword: string, oldPassword: string) => changePassword(newPassword, oldPassword)} />
        </Grid>
    )
}

export default SettingsComponent
