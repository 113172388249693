import { StudentActions } from "./types";
import { StudentState } from "../../types/student";

const initialState: StudentState = {
    students: [],
    studentDetail: undefined,
    studentAddSuccess: false,
    loading: false,
    error: undefined
};

export function studentReducer(state = initialState, action: typeof StudentActions): StudentState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case StudentActions.GET_STUDENTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case StudentActions.GET_STUDENTS_SUCCESS:
            return {
                ...state,
                students: action.response,
                loading: false
            };
        case StudentActions.GET_STUDENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case StudentActions.CREATE_NEW_STUDENT_REQUEST:
            return {
                ...state,
                loading: true,
                studentAddSuccess: false,
                error: undefined
            };
        case StudentActions.CREATE_NEW_STUDENT_SUCCESS:
            return {
                ...state,
                studentAddSuccess: action.response,
                loading: false
            };
        case StudentActions.CREATE_NEW_STUDENT_ERROR:
            return {
                ...state,
                loading: false,
                studentAddSuccess: false,
                error: action.error
            };
        case StudentActions.GET_STUDENTDETAIL_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case StudentActions.GET_STUDENTDETAIL_SUCCESS:
            return {
                ...state,
                studentDetail: action.response,
                loading: false
            };
        case StudentActions.GET_STUDENTDETAIL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case StudentActions.SET_EDIT_STUDENT_ADDRESS:
            return {
                ...state,
                studentDetail: {
                    ...action.formValues,
                    place: action.address.place,
                    address: action.address.address,
                },
            }
        default:
            return state;
    }
}