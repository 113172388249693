import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { ApplicationState } from "../../../store";
import { SampleExamLog } from "../../../types/sampleExamLog";
import { getSampleExamLogs } from "../../../store/sampleExamLogs/actions";
import SampleExamLogComponent from "./SampleExamLogComponent";

interface SampleExamLogContainerState {
    getSampleExamLogs: () => void;
    sampleExamLogs: SampleExamLog[];
    loading: boolean;
    error?: string;
}

const mapStateToProps = ({ sampleExamLogs: { sampleExamLogs, loading, error } }: ApplicationState) => {
    return ({
        sampleExamLogs,
        loading,
        error
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({ getSampleExamLogs }, dispatch);
};

const SampleExamLogContainer = ({ getSampleExamLogs, sampleExamLogs, loading, error, }: SampleExamLogContainerState) => {
    useEffect(() => {
        getSampleExamLogs();
    }, [getSampleExamLogs]);

    return (
        <SampleExamLogComponent
            loading={loading}
            error={error}
            sampleExamLogs={sampleExamLogs}
        ></SampleExamLogComponent>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SampleExamLogContainer);