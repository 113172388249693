import { Dispatch } from "redux";

import { DashboardActions } from "./types";
import { DashboardService } from "../../services/dashboard.service";

export const getLatestResults = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: DashboardActions.GET_LATESTRESULTS_REQUEST });
        const response = await DashboardService.latestResults();
        dispatch({ type: DashboardActions.GET_LATESTRESULTS_SUCCESS, response });
    } catch (error) {
        dispatch({ type: DashboardActions.GET_LATESTRESULTS_ERROR, error });
    }
};

export const getUpcomingCourses = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: DashboardActions.GET_UPCOMING_COURSES_REQUEST });
        const response = await DashboardService.upcomingCourses();
        dispatch({ type: DashboardActions.GET_UPCOMING_COURSES_SUCCESS, response });
    } catch (error) {
        dispatch({ type: DashboardActions.GET_UPCOMING_COURSES_ERROR, error });
    }
};