export const foundOptionsStandard: string[] = [
    "Gemeente",
    "Mond tot mond",
    "Internet (Google)",
    "Mailing per post gekregen",
    "Digitale nieuwsbrief",
    "Oude relatie",
    "Eerder cursus gevolgd",
    "SVH",
    "Facebook",
    "KHN",
    "Kamer van Koophandel",
    "Cursusprijsvergelijken.nl",
    "SBB",
    "Lid FNV Horeca",
    "STOOF Opleidingen",
    "Horecabeurs",
    "Leeftijdsticker",
    "HorecaLoket.nl"
]