import React from "react";
import { Grid, ImageList, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import { CourseDetail } from "../../types/course";
import { LoadingCourses } from "../FeedBack/LoadingContent";
import CourseItem from "./CourseItem";
import Error from "../FeedBack/Error";
import { AuthenticationState } from "../../types/auth";
import { SampleExamState } from "../../types/sampleExam";
import { AssignmentState } from "../../types/assignment";
import { Role } from "../../types/enum";

interface CoursesComponentProps {
    auth: AuthenticationState;
    courseDetail: CourseDetail[];
    assignment: AssignmentState;
    loadingParticipatingStudents: boolean;
    loading: boolean;
    error: any;
    testExams: SampleExamState;
    downloadAssignment: (assignmentId: number, name: string) => void;
    getTestExamsByCategoryId: (categoryId: number, projectId?: number) => void;
    getAssignmentsByCourseId: (courseId: string) => void;
    getParticipatingStudents: (projectId: number) => void;
}

const CoursesComponent = ({
    auth,
    courseDetail,
    downloadAssignment,
    loadingParticipatingStudents,
    loading,
    error,
    getTestExamsByCategoryId,
    getAssignmentsByCourseId,
    assignment,
    getParticipatingStudents,
    testExams }: CoursesComponentProps) => {

    return (
        <Grid container>
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <Typography variant="h5">Cursussen</Typography>
                </Grid>
                {!(auth.role === Role.STUDENT || auth.role === Role.ADMIN) &&
                    <Grid item>
                        <Link style={{ textDecoration: "none" }} to="/inschrijven">
                            <Button variant="contained" style={{ marginRight: "10px", marginBottom: "1em" }} size="large" color="secondary">Inschrijven</Button>
                        </Link>
                    </Grid>
                }
                {auth.role === Role.STUDENT &&
                    <Grid item>
                        <Link style={{ textDecoration: "none" }} to="/inschrijven/particulier">
                            <Button variant="contained" style={{ marginRight: "10px", marginBottom: "1em" }} size="large" color="secondary">Inschrijven</Button>
                        </Link>
                    </Grid>
                }
                {auth.role === Role.ADMIN &&
                    <Grid item>
                        <Link style={{ textDecoration: "none" }} to="/studenten">
                            <Button variant="contained" style={{ marginRight: "10px", marginBottom: "1em" }} size="large" color="secondary">Terug</Button>
                        </Link>
                    </Grid>
                }
            </Grid>
            {loading && <Grid item xs={12}> {Array(3)
                .fill("")
                .map((e, i) => (
                    <LoadingCourses key={i}
                    />
                ))} </Grid>}
            {error && <Error />}
            {(!loading && !error && courseDetail && courseDetail.length > 0) &&
                <ImageList className = "full-w">
                    {
                        courseDetail.map((courseDetail, index) => {
                            return (
                                <CourseItem
                                    auth={auth}
                                    key={index}
                                    courseDetail={courseDetail}
                                    loadingParticipatingStudents={loadingParticipatingStudents}
                                    assignment={assignment}
                                    testExams={testExams}
                                    getTestExamsByCategoryId={getTestExamsByCategoryId}
                                    getAssignmentsByCourseId={getAssignmentsByCourseId}
                                    getParticipatingStudents={getParticipatingStudents}
                                    downloadAssignment={downloadAssignment}
                                />
                            )
                        })
                    }
                </ImageList>
            }
            {(!loading && !error && (!courseDetail || courseDetail.length === 0)) && <Typography>U heeft nog geen cursussen!</Typography>}
        </Grid >
    )
};

export default CoursesComponent;
