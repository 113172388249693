import React, { useState } from "react";
import { Card, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import { animated as a, useSpring, config } from "react-spring";
import { FaChevronRight } from "react-icons/fa";

import { CourseType } from "../../../types/registerCourse";

const CourseTypesItem = ({ type, courseId }: CourseType) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));
    const style = useSpring({
        to: { transform: hovered ? "scale(1.05) translateY(-3px)" : "scale(1) translateY(0px)" },
        config: config.wobbly
    });

    return (
        <a.div style={style}>
            <Link to={`/admin/cursus/${courseId}`} style={{ color: hovered ? theme.palette.primary.main : "rgb(0,0,0,0.7)", fontSize: "1.4em", textDecoration: "none" }}>
                <Card style={{ padding: "1em", margin: ".5em", display: "flex", alignItems: "center", justifyContent: small ? "space-between" : "start" }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                    {type}
                    <FaChevronRight size="1.2em" style={{ color: hovered ? theme.palette.primary.main : "rgb(0,0,0,0.7)", fontSize: "1em", marginLeft: ".5em" }} />
                </Card>
            </Link>
        </a.div>
    );
};

export default CourseTypesItem;