import { MailingLinkActions } from "./types";
import { MailingLinkState } from "../../types/mailingLink";

const initialState: MailingLinkState = {
    mailingLinks: [],
    loading: false,
    error: undefined
};

export function mailingLinkReducer(state = initialState, action: typeof MailingLinkActions): MailingLinkState {
    switch (action.type) {
        case "AUTH/LOGOUT_SUCCESS":
            return initialState;
        case MailingLinkActions.GET_MAILING_LINKS_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case MailingLinkActions.GET_MAILING_LINKS_SUCCESS:
            return {
                ...state,
                mailingLinks: action.mailingLinks,
                loading: false
            };
        case MailingLinkActions.GET_MAILING_LINKS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_SUCCESS:
            let mailingLinks = state.mailingLinks ? state.mailingLinks.filter(mailingLink => mailingLink.name !== action.mailingLink.name) : [];
            mailingLinks = [...mailingLinks, action.mailingLink];

            return {
                ...state,
                mailingLinks: mailingLinks,
                loading: false
            };
        case MailingLinkActions.CREATE_OR_UPDATE_MAILING_LINK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case MailingLinkActions.DELETE_MAILING_LINK_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined
            };
        case MailingLinkActions.DELETE_MAILING_LINK_SUCCESS:
            return {
                ...state,
                mailingLinks: state.mailingLinks ? state.mailingLinks.filter(mailingLink => mailingLink.name !== action.name) : [],
                loading: false
            };
        case MailingLinkActions.DELETE_MAILING_LINK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}