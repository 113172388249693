import React, { useState, useEffect } from "react"
import { Grid, Typography, Button, FormControl, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";

import { Question, QuestionFilledInput } from "../../types/question";

interface TestExamProps {
    course?: string;
    questions: Question[];
    examAnswers: QuestionFilledInput[];
    setOpen: () => void;
    addTestExamAnswer: (givenAnswerId: number, questionId: number) => void;
}

const SampleExam = ({ examAnswers, course, questions, addTestExamAnswer, setOpen }: TestExamProps) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState<string>("");

    useEffect(() => {
        const { givenAnswerId } = examAnswers[currentQuestion];

        setSelectedAnswer(givenAnswerId ? givenAnswerId.toString() : "");
    }, [currentQuestion, examAnswers]);

    const handleChange = (selectedAnswerId: string) => {
        const question = questions[currentQuestion];
        const answer = question.possibleAnswers && question.possibleAnswers.find(a => a.answerId === parseInt(selectedAnswerId));

        if (question && answer) {
            addTestExamAnswer(parseInt(selectedAnswerId), question.questionId);
        }
    };

    return (
        <div>
            <Grid container justify="center" style={{ textAlign: "center" }}>
                <Grid style={{ marginTop: "2em" }} item xs={12}>
                    <Typography variant="h5">{course}</Typography>
                </Grid>
                {questions.length > 0 &&
                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{ marginTop: "2em" }} justify='center' alignItems="center">
                            <Grid item xs={12}>
                                <Typography style={{ fontWeight: "bold" }} variant="h6">Vraag {currentQuestion + 1}:</Typography>
                                <Typography variant="body1">{`${questions[currentQuestion].text}`}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} justify='center' alignItems="center">
                                    <Grid item xs={12} md={12}>
                                        <div className="textfield-spacing textfield-spacing-h">
                                            <Grid container alignContent="center" direction="column" justify="center">
                                                <FormControl component="fieldset">
                                                    <RadioGroup aria-label="answer" name="answer" value={selectedAnswer} onChange={(e, v) => handleChange(v)}>
                                                        {questions[currentQuestion].possibleAnswers.map(possibleAnswer =>
                                                            <FormControlLabel key={possibleAnswer.answerId} checked={parseInt(selectedAnswer) === possibleAnswer.answerId} value={possibleAnswer.answerId} control={<Radio />} label={possibleAnswer.text} />
                                                        )}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </div>
                                        <Grid item xs={12}>
                                            <Grid container spacing={2} justify="center">
                                                <Grid item>
                                                    {currentQuestion !== 0 && <Button
                                                        color="secondary"
                                                        variant='contained'
                                                        onClick={() => setCurrentQuestion(currentQuestion - 1)}>
                                                        Vorige
                                                        </Button>}
                                                </Grid >
                                                <Grid item>
                                                    {currentQuestion !== questions.length - 1 ?
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={() => setCurrentQuestion(currentQuestion + 1)}>
                                                            Volgende
                                                        </Button>
                                                        :
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={() => setOpen()}>
                                                            Inleveren
                                                        </Button>}
                                                </Grid >
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </div >
    );
};

export default SampleExam;
