import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Theme, Typography, Stepper, Step, StepLabel, CardContent, Card, useMediaQuery, useTheme } from "@material-ui/core";
import FreelancerStepperContainer from "./FreelancerStepperContainer";
import { useTextStyles } from "../../../../styles/text";
import { useStepperIconStyles, StepperConnector } from "../../../../styles/stepper";
import Check from "@material-ui/icons/Check";
import { StepIconProps } from "@material-ui/core/StepIcon";
import clsx from "clsx";
import { RouteComponentProps, withRouter } from "react-router";
import { getLogo } from "../../../../helperfunctions/getLogo";
import LogoBox from "../../../Login/LogoBox";

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        borderRadius: "0",
        backgroundColor: "#fff",
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
            boxShadow: "none",
            padding: theme.spacing(1),
        },
    },
    full: {
        height: "100%",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: "#fff",
            alignItems: "normal"
        },
    },
    stepper: {
        backgroundColor: "#fff",
    },
    steplabel: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        fontWeight: "bold",
        color: "black"
    }
}));

const FreelancerRegister = ({ location }: RouteComponentProps) => {
    const urlParams = new URLSearchParams(location.search);
    let categoryId: string | number | undefined = urlParams.get("categoryId") || undefined;
    categoryId = categoryId ? parseInt(categoryId) : undefined;
    const courseId = urlParams.get("courseId") || undefined;
    const place = urlParams.get("place") || undefined;
    const locationCode = urlParams.get("locationCode") || undefined;
    const discountCode = urlParams.get("discountCode") || undefined;

    const classes = useStyles();
    const textClasses = useTextStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const steps = ["CURSUS SELECTEREN", "UW GEGEVENS", "BETAALWIJZE", "BEVESTIGEN"];
    const theme: Theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


    function isStepSkipped(step: number) {
        return skipped.has(step);
    }

    function handleNext() {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function StepIcon(props: StepIconProps) {
        const classes = useStepperIconStyles();
        const { active, completed } = props;

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {completed ? <div className={classes.circleCompleted}><Check className={classes.completed} /></div> : <div className={classes.circle}>{active && <div className={classes.innerCircle} />}</div>}
            </div>
        );
    }

    const logo = getLogo();

    return (
        <Grid container justify="center" className={classes.full}>
            <Grid item xs={12} sm={10} md={9} lg={9} xl={8}>
                <Card elevation={8} className={classes.card}>
                    <CardContent>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Typography classes={{
                                    root: `${textClasses.pageTitle}`
                                }}>INSCHRIJVEN</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                {!fullScreen && <LogoBox logo={logo} type="top-right" />}
                                {fullScreen && <LogoBox logo={logo} type="fullscreen" />}
                            </Grid>
                        </Grid>
                        {!fullScreen &&
                            <Stepper activeStep={activeStep} connector={<StepperConnector />}>
                                {steps && steps.map((label: string, index: number) => {
                                    const stepProps: { completed?: boolean } = {};
                                    if (isStepSkipped(index)) {
                                        stepProps.completed = false;
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel StepIconComponent={StepIcon} classes={{
                                                label: `${classes.steplabel}`,
                                                root: `${classes.steplabel}`,
                                                active: `${classes.steplabel}`
                                            }}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        }
                        {fullScreen && <Typography style={{ marginBottom: "1em" }} variant="h5">{steps[activeStep]}</Typography>}
                        <div>
                            <FreelancerStepperContainer
                                categoryId={categoryId}
                                courseId={courseId}
                                place={place}
                                locationCode={locationCode}
                                discountCode={discountCode}
                                currentStep={activeStep}
                                handleNext={handleNext}
                                handleBack={handleBack}
                            />
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
}

export default withRouter(FreelancerRegister);