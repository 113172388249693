import { Dispatch } from "redux";
import { SampleExamLogActions } from "./types";
import { sampleExamService } from "../../services/sampleExam.service";

export const getSampleExamLogs = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: SampleExamLogActions.GET_SAMPLEEXAMLOG_REQUEST });
        const response = await sampleExamService.sampleExamLogs();
        dispatch({ type: SampleExamLogActions.GET_SAMPLEEXAMLOG_SUCCESS, response });
    } catch (error) {
        dispatch({ type: SampleExamLogActions.GET_SAMPLEEXAMLOG_ERROR, error });
    }
};