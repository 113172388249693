import { connect } from "react-redux";
import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";

import { getCategories, getTypes, getPlaces, getAvailableDates, setCategory, setType, setPlace, setDate } from "../../../../store/registerCourse/actions";
import { CourseCategory, CourseType, AvailableDate, RegisterCourse } from "../../../../types/registerCourse";
import { ApplicationState } from "../../../../store";
import CourseComponent from "./CourseComponent";
import { RegisterUserType } from "../../../../types/enum";

interface CourseContainerProps {
    course: RegisterCourse;

    categories: CourseCategory[];
    types: CourseType[];
    places: string[];
    availableDates: AvailableDate[];
    loading: boolean;
    isMailLinkBuilder: boolean;

    userType: RegisterUserType;

    getCategories: (userType: RegisterUserType) => void;
    getTypes: (categoryId: number, userType: RegisterUserType) => void;
    getPlaces: (courseId: string) => void;
    getAvailableDates: (courseId: string, place: string) => void;

    setCategory: (category: CourseCategory) => void;
    setType: (type: CourseType) => void;
    setPlace: (place: string) => void;
    setDate: (date: AvailableDate) => void;
}

const mapStateToProps = ({ registerCourse: { categories, types, places, availableDates, course, loading } }: ApplicationState) => {
    return ({
        categories,
        types,
        places,
        availableDates,
        course,
        loading
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getCategories, getTypes, getPlaces, getAvailableDates,
        setCategory, setType, setPlace, setDate
    }, dispatch);
};

const CourseContainer = ({ getCategories, getTypes, getPlaces, getAvailableDates, setCategory, setType, setPlace, setDate, loading,
    course, categories, types, places, availableDates, userType, isMailLinkBuilder }: CourseContainerProps) => {

    useEffect(() => {
        getCategories(userType);
    }, [userType, getCategories]);

    useEffect(() => {
        if (course.category?.categoryId) {
            getTypes(course.category.categoryId, userType);
        }
    }, [course.category, userType, getTypes]);

    useEffect(() => {
        if (course.type?.courseId) {
            getPlaces(course.type.courseId);
        }
    }, [course.type, getPlaces]);

    useEffect(() => {
        if (course.type?.courseId && course.place) {
            getAvailableDates(course.type.courseId, course.place);
        }
    }, [course.place, course.type, getAvailableDates]);

    return (
        <CourseComponent
            loading={loading}
            course={course}
            categories={categories}
            types={types}
            places={places}
            availableDates={availableDates}
            setCategory={setCategory}
            setType={setType}
            setPlace={setPlace}
            setDate={setDate}
            isMailLinkBuilder={isMailLinkBuilder}
        />
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CourseContainer);