import React, { useState, useEffect } from 'react'
import { Field, reduxForm, InjectedFormProps } from "redux-form";
import { animated, useSpring, config } from "react-spring";
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'

import RenderTextFieldSearch from "../../TextField/TextFieldSearch";
import { StudentDetail } from '../../../types/student';
import StudentPersonal from '../../StudentDetail/StudentDetailPersonal';
import StudentResults from '../../StudentDetail/StudentDetailResults';

interface AdminStudentsComponentProps {
    searchStudentDetails: (search: string) => void;
    studentDetail?: StudentDetail;
    loading: boolean;
    error?: any;
    role?: string;
}

export interface AdminStudentsComponentForm {
    search: number;
}

const useStyles: any = makeStyles((theme: Theme) => ({
    noResults: {
        marginTop: theme.spacing(2),
    },
    searchBar: {
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },
    mainText: {
        marginTop: "10em",
        color: "gray",
        fontWeight: "bolder"
    }
}));

const StudentsComponent = ({ handleSubmit, searchStudentDetails, studentDetail, loading, error, role }: AdminStudentsComponentProps & InjectedFormProps<AdminStudentsComponentForm, AdminStudentsComponentProps>) => {
    const classes = useStyles();
    const [detail, setDetail] = useState(false);

    useEffect(() => {
        if (studentDetail) {
            setDetail(true);
        } else {
            setDetail(false);
        }
    }, [studentDetail])

    const detailProps = useSpring({
        from: { opacity: 0, width: "100%" },
        to: { opacity: detail ? 1 : 0, width: "100%" },
        config: config.stiff
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {detail &&
                    <Grid container alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5">Studenten</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.searchBar}>
                            <form onSubmit={handleSubmit(({ search }: AdminStudentsComponentForm): void => {
                                searchStudentDetails(search.toString())
                            })}
                            >
                                <Field
                                    number={true}
                                    name="search"
                                    placeholder="Zoek op Cursist ID"
                                    component={RenderTextFieldSearch}
                                />
                            </form>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {
                detail === false ?
                    <Grid className={classes.mainText} item xs={12}>
                        <Grid container justify="center">
                            <Grid item xs={12} md={8} style={{ textAlign: "center" }}>
                                <Typography style={{ marginBottom: "1em" }} variant="h2">Zoek een student</Typography>
                                <form onSubmit={handleSubmit(({ search }: AdminStudentsComponentForm): void => {
                                    searchStudentDetails(search.toString())
                                })}
                                >
                                    <Field
                                        number={true}
                                        name="search"
                                        placeholder="Zoek op Cursist ID"
                                        component={RenderTextFieldSearch}
                                    />
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <animated.div style={detailProps}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} lg={6}>
                                <StudentPersonal role={role} studentDetail={studentDetail} loading={loading} error={error} />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <StudentResults courseResults={studentDetail && studentDetail.courseResults} loading={loading} error={error} />
                            </Grid>
                        </Grid>
                    </animated.div>
            }
        </Grid >
    )
}

export default reduxForm<AdminStudentsComponentForm, AdminStudentsComponentProps>({
    form: "adminStudentComponentForm"
})(StudentsComponent);
