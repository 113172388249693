import { getWebsite } from "./getWebsite";
import { Website } from "../types/enum"

export const getContactPage = (): string => {
    switch (getWebsite()) {
        case Website.SH:
            return "https://sociale-hygiene.nl/contact/contact";
        case Website.HOC:
            return "https://hoc.nl/contact/contact";
        default:
            return "https://hsn-horeca.nl/contact/contactformulier/";
    }
}