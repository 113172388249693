import React, { useState } from "react"
import { Card, CardContent, Checkbox, FormControlLabel, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import { CourseType } from "../../../types/registerCourse";
import { FaArrowLeft } from "react-icons/fa";
import { withRouter, RouteComponentProps } from "react-router";
import CourseTypesItem from "./CourseTypesItem";
interface CourseTypesComponentProps {
    types: CourseType[];
    categoryId: number;
    categoryRegisterViaLinkOnly: boolean;
    updateCategoryRegisterViaLinkOnly: (categoryId: number, categoryRegisterViaLinkOnly: boolean) => void;
}

const CourseTypesComponent = ({ types, categoryId, categoryRegisterViaLinkOnly, updateCategoryRegisterViaLinkOnly, history }: CourseTypesComponentProps & RouteComponentProps) => {
    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down("sm"));

    const [registerViaLinkOnly, setRegisterViaLinkOnly] = useState<boolean>(categoryRegisterViaLinkOnly);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid alignItems="center" container>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center">
                                <FaArrowLeft size="1.3em" style={{ marginRight: ".5em", cursor: "pointer" }} onClick={() => history.goBack()} />
                                <Typography variant="h5">Cursussen</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: ".5em" }}>
                    <Grid item xs={12} style={{ display: "flex", flexDirection: small ? "column" : "row", flexWrap: "wrap" }}>
                        {types && types.map(type => <CourseTypesItem {...type} key={type.courseId} />)}
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{ padding: ".5em" }}>
                    <Grid item xs={12}>
                        <Card elevation={2} style={{ marginLeft: ".3em", marginRight: ".3em" }}>
                            <CardContent>
                                <FormControlLabel
                                    label="Alleen inschrijven via uitnodigingslink"
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={registerViaLinkOnly}
                                            value={registerViaLinkOnly}
                                            onChange={() => {
                                                updateCategoryRegisterViaLinkOnly(categoryId, !registerViaLinkOnly);
                                                setRegisterViaLinkOnly(!registerViaLinkOnly);
                                            }}
                                        />
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
};

export default withRouter(CourseTypesComponent);
