import React from "react"
import { Card, CardContent, Typography } from "@material-ui/core";

import CardHeader from "../UI/CardHeader";
import StudentDetailResultsItem from "./StudentDetailResultsItem";
import { CourseResult } from "../../types/student";
import { renderSkeleton } from "../FeedBack/Skeleton";

interface StudentResultsProps {
    courseResults?: CourseResult[];
    loading: boolean;
    error?: any;
}

const StudentResults = ({ courseResults, loading, error }: StudentResultsProps) => {
    return (
        <div>
            <Card elevation={2} >
                <CardHeader text="Resultaten" />
                {courseResults && courseResults.length === 0 ?
                    <CardContent>
                        <Typography variant="subtitle1" color="error">Geen resultaten gevonden</Typography>
                    </CardContent> :
                    <CardContent>
                        {loading && renderSkeleton(2, { xs: 12 }, 200, "rect")
                        }
                        {!loading && courseResults && courseResults.map((result, index) => {
                            return <StudentDetailResultsItem {...result} key={index} />
                        })}
                    </CardContent>
                }
            </Card>
        </div>
    );
};

export default StudentResults;